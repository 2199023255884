import React from 'react';

import { asValidationError } from 'apis';

import { translateAnalytic } from 'module/Analytic/Analytic.api';
import { TranslationResult } from 'module/Analytic/Analytic.type';

import { Status, useAsync } from 'storage';

import { Guid, Ident } from 'types/common';

interface AnalyticTranslationContext {
  translation: string;
  translationLanguageId: Ident;
  error?: string;
  status: Status;
  refresh(): void;
}

export default function useAnalyticTranslation(guid: Guid, languageId: Ident): AnalyticTranslationContext {
  const { data, setData, error: requestError, task, status } = useAsync<TranslationResult>();
  const [translationLanguageId, setTranslationLanguageId] = React.useState(languageId);

  const refresh = React.useCallback(async () => {
    if (guid && languageId) {
      setData(null);
      setTranslationLanguageId(languageId);
      task(translateAnalytic(guid, languageId)).then(setData);
    }
  }, [setData, guid, languageId, task]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  let error = data?.error;
  if (requestError) error = asValidationError(requestError).detail?.[0] as string;

  return {
    translation: data?.raw,
    translationLanguageId,
    error,
    status,
    refresh
  };
}
