import { Guid } from 'types/common';

import { snapattack } from '../../apis/snapattack';
import {
  SecurityProfilePayload,
  SecurityProfileResponse,
  SecurityProfileTag,
  SecurityProfileTagPayload
} from './SecurityProfile.type';

const prefix = '/cyberai/profile';

export async function getProfile(orgGuid: Guid): Promise<SecurityProfileResponse> {
  return snapattack.get(`${prefix}/${orgGuid}/`).then(r => r.data);
}

export async function postProfile(
  orgGuid: Guid,
  payload: Partial<SecurityProfilePayload>
): Promise<SecurityProfileResponse> {
  return snapattack.post(`${prefix}/${orgGuid}/`, payload).then(r => r.data);
}

export async function putProfileBuild(orgGuid: Guid): Promise<void> {
  return snapattack.put(`${prefix}/${orgGuid}/build/`);
}

export async function getProfileTags(orgGuid: Guid): Promise<SecurityProfileTag[]> {
  return snapattack.get(`${prefix}/${orgGuid}/tags/`).then(r => r.data);
}

export async function putProfileTags(orgGuid: Guid, payload: SecurityProfileTagPayload): Promise<void> {
  return snapattack.put(`${prefix}/${orgGuid}/tags/`, payload).then(r => r.data);
}
