import {
  Organization,
  RegisterResponse,
  RegisterRequest,
  RegistrationMethod,
  User,
  UserRequest,
  UserResponse,
  IncidentReport
} from 'types/auth';
import { Guid, UserPublic } from 'types/common';

import { snapattack } from '../snapattack';

const prefix = '/user';

export function getRegistrationMethod(): Promise<RegistrationMethod> {
  return snapattack.get(`${prefix}/registration_method/`).then(r => r.data);
}

export function registerUser(data: RegisterRequest): Promise<RegisterResponse> {
  return snapattack.post(`${prefix}/register/`, data).then(res => res.data);
}

export function getCurrentUser(): Promise<User & { organizations: Organization[] }> {
  return snapattack.get(`${prefix}/me/`).then(res => res.data);
}

export function updateCurrentUser(data: UserRequest): Promise<UserResponse> {
  return snapattack.put(`${prefix}/me/`, data).then(res => res.data);
}

export function updateUser(user: Guid, data: UserRequest): Promise<UserResponse> {
  return snapattack.put(`${prefix}/${user}/`, data).then(res => res.data);
}

export function getUser(user: Guid): Promise<UserResponse> {
  return snapattack.put(`${prefix}/${user}/`).then(res => res.data);
}

export function getVisibleOrganizations(): Promise<Organization[]> {
  return snapattack.get(`${prefix}/organizations/`).then(res => res.data);
}

export function reportIncident(formData: IncidentReport): Promise<void> {
  return snapattack.post(`${prefix}/incident/`, formData).then(r => r.data);
}

/**
 * Lists public info for all active users visible to a user.
 */
export function getUserList(): Promise<UserPublic[]> {
  return snapattack.get(`${prefix}/`).then(res => res.data);
}

export function getAllUser(): Promise<User[]> {
  return snapattack.get(`${prefix}/all/`).then(res => res.data);
}

export function resendVerificationRequest(email: string): Promise<void> {
  return snapattack.post(`${prefix}/resend_verification/`, { email });
}

export function verify(token: string): Promise<void> {
  return snapattack.post(`${prefix}/verify/`, { token });
}
