import { faWheat } from '@fortawesome/pro-light-svg-icons';
import {
  faBus,
  faCar,
  faCartShopping,
  faExplosion,
  faFlask,
  faGasPump,
  faGraduationCap,
  faHandsHoldingHeart,
  faHeadSideBrain,
  faHouseWater,
  faIndustryWindows,
  faLandmark,
  faLightbulb,
  faMartiniGlass,
  faMicrochip,
  faMoneyCheckDollar,
  faPersonMilitaryRifle,
  faPhone,
  faPlane,
  faPrescriptionBottlePill,
  faRadar,
  faScaleBalanced,
  faStethoscope,
  faUserHelmetSafety
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBinoculars,
  faCameraCctv,
  faDollar,
  faFlag,
  faHandFist,
  faHandsPraying,
  faMegaphone,
  faPeopleArrows,
  faTvRetro
} from '@fortawesome/pro-solid-svg-icons';

// Order matters here.
export const TargetIndustryDetails = {
  ['Financial Services']: { icon: faMoneyCheckDollar },
  ['Oil & Gas']: { icon: faGasPump },
  ['Technology']: { icon: faMicrochip },

  ['Governments']: { icon: faLandmark },
  ['Healthcare']: { icon: faStethoscope },
  ['Aerospace & Defense']: { icon: faPlane },

  ['Manufacturing']: { icon: faIndustryWindows },
  ['Telecommunications']: { icon: faPhone },
  ['Retail']: { icon: faCartShopping },

  ['Pharmaceuticals']: { icon: faPrescriptionBottlePill },
  ['Energy & Utilities']: { icon: faLightbulb },
  ['Legal & Professional Services']: { icon: faScaleBalanced },

  ['Agriculture']: { icon: faWheat },
  ['Automotive']: { icon: faCar },
  ['Chemicals & Materials']: { icon: faFlask },
  ['Civil Society & Non-Profits']: { icon: faHandsHoldingHeart },
  ['Construction & Engineering']: { icon: faUserHelmetSafety },
  ['Education']: { icon: faGraduationCap },
  ['Hospitality']: { icon: faMartiniGlass },
  ['Insurance']: { icon: faHouseWater },
  ['Media & Entertainment']: { icon: faTvRetro },
  ['Transportation']: { icon: faBus }
} as const;

export type TargetIndustry = {
  name: string;
  attribution_scope?: string;
  first_seen?: string;
  last_seen?: string;
};

export const TargetIndustries = Object.keys(TargetIndustryDetails);
export type TargetIndustries = keyof typeof TargetIndustryDetails;

export const ActorMotivationDetails = {
  ['Financial Gain']: { icon: faDollar },
  ['Espionage']: { icon: faBinoculars },
  ['Surveillance']: { icon: faCameraCctv },
  ['Influence']: { icon: faPeopleArrows },
  // TODO: custom guy fawkes / anonymous icon would be good here
  ['Hacktivism']: { icon: faHandFist },
  ['Attack / Destruction']: { icon: faExplosion },
  // TODO: These are only on MATI Intel reports and not attached to actors/tools
  // So we can include them to make the input look impressive, but they won't do anything functionally
  ['Military / Diplomatic']: { icon: faPersonMilitaryRifle }, // Renamed from 'Military/Security/Diplomatic'
  ['Ethnic / Nationalist']: { icon: faFlag }, // Renamed from 'Ethnic/nationalist'
  ['Ideological / Religious']: { icon: faHandsPraying }, // Renamed from 'Ideological/Religious'
  ['Opportunistic']: { icon: faRadar },
  ['Ego']: { icon: faHeadSideBrain },
  ['Anti-Establishment']: { icon: faMegaphone } // Renamed from 'Anti-Corruption/ Anti-Establishment/Information Freedom'
  // ['Environmental']: { icon: faLeaf }
} as const;

export const ActorMotivations = Object.keys(ActorMotivationDetails);
export type ActorMotivation = keyof typeof ActorMotivationDetails;

/*
 * These is duplicated in Landing.type.ts but also not quite right it has more values than the BE expects
 */
export enum ExploitationStates {
  WIDE = 'Wide',
  CONFIRMED = 'Confirmed',
  AVAILABLE = 'Available',
  ANTICIPATED = 'Anticipated',
  NO_KNOWN = 'No Known'
}

/*
 * This is duplicated in Landing.type.ts but also not quite right as it has more values than the BE expects
 */
export enum RiskRatings {
  HIGHEST = 'Highest', // Mandiant calls this Critical, but our BE re-mapped this
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low'
}

export const SourceRegions = [
  'Mexico',
  'Canada',
  'Colombia',
  'Kazakhstan',
  'China',
  'North Korea',
  'South Korea',
  'India',
  'Iran',
  'Pakistan',
  'Vietnam',
  'Palestine',
  'Turkey',
  'United Arab Emirates',
  'Belarus',
  'Russia',
  'Ukraine',
  'Latvia',
  'United Kingdom'
];
