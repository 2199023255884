import { DataGrid } from 'snap-ui/DataGrid';
import { spacing } from 'snap-ui/theme/theme.util';
import { styled } from 'snap-ui/util';

import { Page } from 'module/Layout/Styled';

export const TABLE_WIDTH = 510;
const LEFT_COLUMN_WIDTH = 350;
const GAP = spacing[5];
const PADDING = 50;
const TWO_COLUMN_WIDTH = 2 * (TABLE_WIDTH + GAP) + LEFT_COLUMN_WIDTH + 2 * PADDING;
const THREE_COLUMN_WIDTH = 3 * (TABLE_WIDTH + GAP) + LEFT_COLUMN_WIDTH + 2 * PADDING;

export const Container = styled(Page, { label: 'AnalyticTuningContainer' })`
  --table-width: ${TABLE_WIDTH}px;
  --left-column-width: ${LEFT_COLUMN_WIDTH}px;
  --gap: ${GAP}px;

  display: flex;
  flex-direction: column;
  gap: var(--gap);
  padding: ${PADDING}px;
  margin: 0 auto;
  min-width: calc(100px + var(--table-width) + var(--left-column-width));

  .row {
    display: grid;
    grid-template-columns: var(--left-column-width) 1fr;
    gap: var(--gap);
  }

  .column {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }

  .dashboard {
    display: grid;
    grid-template-columns: repeat(1, var(--table-width));
    gap: var(--gap);

    & .span-columns {
      grid-column: 1 / -1;
    }

    @media (min-width: ${TWO_COLUMN_WIDTH}px) {
      grid-template-columns: repeat(2, var(--table-width));
    }

    @media (min-width: ${THREE_COLUMN_WIDTH}px) {
      grid-template-columns: repeat(3, var(--table-width));
    }
  }

  .hits-graph {
    .pie-chart-container {
      width: 90%;
      margin: auto;
    }

    span.recharts-legend-item-text {
      color: white !important;
    }
  }
`;

export const NoResultsContainer = styled('div')`
  .column,
  .row {
    display: flex;
    gap: ${p => p.theme.spacing(3)};
    justify-content: center;
  }

  .column {
    flex-direction: column;
  }

  // match width of dashboard
  @media (min-width: ${TWO_COLUMN_WIDTH}px) {
    min-width: calc(var(--left-column-width) + (2 * (var(--table-width) + var(--gap))));
  }

  @media (min-width: ${THREE_COLUMN_WIDTH}px) {
    min-width: calc(var(--left-column-width) + (3 * (var(--table-width) + var(--gap))));
  }
`;

export const TableContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .field-name {
      display: flex;
      gap: ${p => p.theme.spacing(2)};
      align-items: center;
      overflow: hidden;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .remove-table-button {
      margin-right: ${p => p.theme.spacing(3)};
    }
  }
`;

export const Table = styled(DataGrid)`
  min-height: 400px; // why this can't just be 'height' is beyond me.
  max-height: 400px; // 10 rows
  max-width: var(--table-width);
`;

export const ExclusionsTable = styled(Table)`
  max-width: unset;
  min-height: 220px;
`;

export const CopyButtonContents = styled('span')`
  cursor: pointer;
  &.empty {
    font-style: italic;
  }
`;

export const TransferListItem = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(1)};
  align-items: center;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const TuningPreviewContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(7)};
  padding: ${p => p.theme.spacing(4)};

  strong {
    color: ${p => p.theme.palette.error.main};
  }
`;

export const FineTuningContainer = styled('div', { label: 'AnalyticFineTuningContainer' })`
  flex: 1 1 100%;
  overflow-y: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  margin: var(--Scaffold-spacing);

  .contents {
    display: flex;
    gap: ${p => p.theme.spacing(4)};
    overflow-y: hidden;
  }

  .TuningLogic {
    padding: ${p => p.theme.spacing(3)};
    flex: 1 1 600px;

    height: 100%;
    overflow: auto;

    .MuiCardContent-root {
      display: flex;
      flex-direction: column;
      gap: ${p => p.theme.spacing(4)};
    }
  }

  .TunedDetection {
    padding: ${p => p.theme.spacing(3)};
    flex: 1 1 400px;

    .MuiCardContent-root {
      height: calc(100% - 80px);
      overflow: auto;

      &.column {
        display: flex;
        flex-direction: column;
        gap: ${p => p.theme.spacing(5)};
      }
    }

    .title-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;
