import rison from 'rison';

import { HIT_COUNT_DAYS_BACK } from 'module/Analytic/core/CustomerHits';
import { JobOverview, JobOverviewDetailItem } from 'module/Job';

import { Guid } from 'types/common';

import { formatQueryString } from 'utilities/SearchParam';
import { addDurationISOFormatted } from 'utilities/TimeUtils';

const OPEN_SEARCH_BASE = '/data/app/discover#/';

type OpenSearchUrlGlobalState = {
  index?: string;
  time?: {
    from: string;
    to: string;
  };
  refreshInterval?: {
    pause: boolean;
    value: number;
  };
  filters: {
    $state?: any;
    meta?: any;
    query: any;
  }[];
};

type OpenSearchUrlApplicationState = OpenSearchUrlGlobalState & {
  expandedPanelId?: Guid;
  fullScreenMode: boolean;
  options: any;
  query: any;
  timeRestore: boolean;
  title: string;
  viewMode: string;
};

/* build a query string in the format that OpenSearch uses. It's a rison-enconded (modified JSON syntax) stored in the
 * url `_g` param with the form:
 *  {
 *    time: {
 *      from: 'date string',
 *      to: 'date string'
 *    },
 *    filters: [...]
 *  }
 *  I've reverse engineered these filter structures by generating urls in OpenSearch and decoding them in runkit
 *  (https://npm.runkit.com/rison), then trying different things -JK
 *  https://github.com/opensearch-project/OpenSearch-Dashboards/blob/d7004dc5b0392477fdd54ac66b29d231975a173b/src/plugins/data/common/opensearch_query/filters/meta_filter.ts
 *  https://github.com/opensearch-project/OpenSearch-Dashboards/blob/04065fa2fb60b41a9e40ac046c9137982dd3c761/src/plugins/discover/public/url_generator.ts
 */
export function buildWorkbenchLink(
  startTime: string,
  endTime: string,
  huntGuids: Guid[],
  detectionGuid?: Guid,
  integrationGuid?: Guid,
  embed = true
): string {
  const filterState: OpenSearchUrlGlobalState = {
    time: { from: startTime, to: endTime },
    // top-level filters will be anded
    filters: [
      {
        query: {
          // this is the OpenSearch structure for essentially: huntGuids.includes(<value>)
          bool: {
            minimum_should_match: 1,
            should: huntGuids.map(guid => ({
              match_phrase: {
                'metadata.snapattack_job_guid': encodeURIComponent(guid)
              }
            })),
            must_not: [
              {
                exists: {
                  field: 'metadata.snapattack_null'
                }
              }
            ]
          }
        },
        meta: {
          // meta provides a more user-friendly value in the filter value bar in OpenSearch
          key: 'metadata.snapattack_job_guid',
          params: huntGuids.map(encodeURIComponent),
          type: 'phrases',
          value: huntGuids.join(', ')
        }
      }
    ]
  };
  if (detectionGuid) {
    filterState.filters.push({
      query: { match_phrase: { 'metadata.snapattack_analytic_guid': encodeURIComponent(detectionGuid) } }
    });
  }
  if (integrationGuid) {
    filterState.filters.push({
      query: { match_phrase: { 'metadata.snapattack_integration_guid': encodeURIComponent(integrationGuid) } }
    });
  }

  try {
    var params: any = { _g: rison.encode(filterState) };
  } catch (e) {
    console.warn(e);
    params = {};
  }

  if (embed) params.embed = true;

  return `${OPEN_SEARCH_BASE}${formatQueryString(params)}`;
}

const AUDIT_INDEX = {
  test: '8c059810-5fde-11ef-90a9-d91be272e83c',
  production: '5915d110-5feb-11ef-9821-537798d7eb29'
} as const;

export function buildAuditLogsLink(daysBack: number, embed = true): string {
  const appState = {
    index: process.env.NODE_ENV === 'production' ? AUDIT_INDEX.production : AUDIT_INDEX.test
  };

  const filterState: OpenSearchUrlGlobalState = {
    time: { from: `now-${daysBack}d`, to: 'now' },
    // top-level filters will be anded
    filters: []
  };

  try {
    var params: any = { _g: rison.encode(filterState), _a: rison.encode(appState) };
  } catch (e) {
    console.warn(e);
    params = {};
  }

  if (embed) params.embed = true;

  return `${OPEN_SEARCH_BASE}${formatQueryString(params)}`;
}

export function buildRawOSLink(startTime: string, endTime: string, detectionGuid?: Guid, embed = true): string {
  const filterState: OpenSearchUrlGlobalState = {
    time: { from: startTime, to: endTime },
    // top-level filters will be anded
    filters: []
  };
  if (detectionGuid) {
    filterState.filters.push({
      query: { match_phrase: { 'metadata.snapattack_analytic_guid': encodeURIComponent(detectionGuid) } }
    });
  }

  try {
    var params: any = { _g: rison.encode(filterState) };
  } catch (e) {
    console.warn(e);
    params = {};
  }

  if (embed) params.embed = true;

  return `${OPEN_SEARCH_BASE}${formatQueryString(params)}`;
}

export function getStartTime(jobGroup: JobOverview): string {
  // parameters should be the same across all jobs in a group
  const params = jobGroup?.parameters;
  return addDurationISOFormatted(
    params?.search_latest_time as string,
    -1 * parseInt(params?.max_search_window_seconds as string)
  );
}

export function getEndTime(jobGroup: JobOverview): string {
  // parameters should be the same across all jobs in a group
  // const params = jobGroup?.parameters;
  const params = jobGroup?.parameters;
  return params?.search_latest_time as string;
}

const TUNING_DASHBOARD = {
  GENERAL: '524bcbe0-309a-11ee-8872-0571fc013177',
  FILE: '50583290-a996-11ee-b0c5-879cf8c1a05d',
  PROCESS_CREATION: '41888a80-a996-11ee-b0c5-879cf8c1a05d'
} as const;

export const TUNING_DASHBOARD_PANEL = {
  HITS_PER_DETECTION: 'cb503603-dcf3-4126-ae5c-b08b326c29e1',
  GENERAL_COLUMNS: '2971054b-5c26-4125-a51b-4edf124aa553',
  FILE_COLUMNS: 'd76dcbd0-82e8-4ec9-92c1-4e76dfe81f86',
  PROCESS_COLUMNS: 'b4ee737d-3840-48bf-b5be-48b6e9cde3e4'
} as const;

type TUNING_DASHBOARD_PANEL = (typeof TUNING_DASHBOARD_PANEL)[keyof typeof TUNING_DASHBOARD_PANEL];

function getDashboardByLogsource(logsource: string) {
  if (logsource.match(/process_creation/i)) {
    return { dashboard: TUNING_DASHBOARD.PROCESS_CREATION, columns: TUNING_DASHBOARD_PANEL.PROCESS_COLUMNS };
  } else if (logsource.match(/file/i)) {
    return { dashboard: TUNING_DASHBOARD.FILE, columns: TUNING_DASHBOARD_PANEL.FILE_COLUMNS };
  } else {
    return { dashboard: TUNING_DASHBOARD.GENERAL, columns: TUNING_DASHBOARD_PANEL.GENERAL_COLUMNS };
  }
}

/**
 * See comment on buildWorkbenchLink
 * Encodes application state as well
 */
export function buildRuleHitsLink(
  detectionGuid: Guid,
  logsource: string,
  embed = true,
  panelIdOverride?: TUNING_DASHBOARD_PANEL | ''
) {
  const baseURL = '/data/app/dashboards#/view/';
  const urls = getDashboardByLogsource(logsource);
  const g: OpenSearchUrlGlobalState = {
    time: { from: `now-${HIT_COUNT_DAYS_BACK}d`, to: 'now' },
    refreshInterval: {
      pause: true,
      value: 0
    },
    // top-level filters will be anded
    filters: []
  };

  const a: OpenSearchUrlApplicationState = {
    filters: [
      {
        query: {
          match_phrase: {
            'metadata.snapattack_analytic_guid': detectionGuid
          }
        }
      }
    ],
    fullScreenMode: false,
    options: {
      hidePanelTitles: false,
      useMargins: true
    },
    query: { language: 'kuery', query: '' },
    timeRestore: true,
    title: 'Tuning',
    viewMode: 'view'
  };

  try {
    if (embed) {
      a.expandedPanelId = panelIdOverride ? panelIdOverride?.toString() : urls.columns;
    }
    var params: any = { _g: rison.encode(g), _a: rison.encode(a) };
  } catch (e) {
    console.warn(e);
    params = {};
  }

  if (embed) {
    params.embed = true;
    params['hide-filter-bar'] = true;
  }

  return `${baseURL}${urls.dashboard}${formatQueryString(params)}`;
}

export function removeDuplicateDetailItems(details: JobOverviewDetailItem[]) {
  const uniqueGuids = new Set<string>();
  return details.filter(obj => {
    if (!uniqueGuids.has(obj.guid)) {
      uniqueGuids.add(obj.guid);
      return true;
    }
    return false;
  });
}
