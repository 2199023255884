import React from 'react';

import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import Icon, { saSnapAttackIcon } from 'snap-ui/Icon';
import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import { COMMUNITY_ORGANIZATION } from 'constants/auth';

import { AlphaFeature, CanAlpha } from 'module/AlphaFeatures';
import { RecommendationType } from 'module/Curation/Curation.type';
import { useFilterRegistry } from 'module/GlobalFilter';
import { SupplementalArtifact } from 'module/Search';
import { SimpleBadge } from 'module/Widgets/Badge';

import { useAuth } from 'provider';

import { AnalyticSnapScore, Ident } from 'types/common';

const Badge = styled(SimpleBadge)`
  z-index: 1;
  text-transform: uppercase;
  border-color: ${p => p.theme.palette.primary.main};
  min-width: 62px;
  justify-content: center;
`;

type SnapScoreBadgeProps = {
  display: 'grid' | 'card';
  score: AnalyticSnapScore['score'];
};
export default function SnapScoreBadge({ display, score }: SnapScoreBadgeProps) {
  if (isNull(score)) return null;

  const scoreDisplay = (Number(score) * 100).toFixed(0);

  return (
    <CanAlpha feature={AlphaFeature.SnapScore}>
      <Badge
        className={display}
        icon={<Icon icon={saSnapAttackIcon} />}
        value={scoreDisplay}
        TooltipProps={{
          title: scoreDisplay,
          arrow: true,
          placement: 'top'
        }}
      />
    </CanAlpha>
  );
}

SnapScoreBadge.Placeholder = function SnapScoreBadgePlaceholder({ display }: Pick<SnapScoreBadgeProps, 'display'>) {
  if (display === 'card') return <Placeholder height={24} width={66} variant='rectangular' />;
  else return <Placeholder variant='text' width={66} />;
};

export function useSnapScore() {
  const { defaultOrgId } = useAuth();
  const { values } = useFilterRegistry();
  const { recommendedDetection } = values || {};

  const isDetectionRecommended = recommendedDetection === RecommendationType.Deployment;
  const isHuntRecommended = recommendedDetection === RecommendationType.Hunt;

  const getScore = React.useCallback(
    (supplemental: SupplementalArtifact) => {
      if (isDetectionRecommended) {
        const score = getSnapScoreForOrg(supplemental?.recommendations, defaultOrgId);
        return isEmpty(score) ? null : score.deploy_score;
      } else if (isHuntRecommended) {
        const score = getSnapScoreForOrg(supplemental?.recommendations, defaultOrgId);
        return isEmpty(score) ? null : score.hunt_score;
      } else {
        const score = getSnapScoreForOrg(supplemental?.scores, defaultOrgId);
        return isEmpty(score) ? null : score.score;
      }
    },
    [defaultOrgId, isDetectionRecommended, isHuntRecommended]
  );

  return getScore;
}

function getSnapScoreForOrg(scores: AnalyticSnapScore[], org: Ident): AnalyticSnapScore {
  const orgScore =
    scores?.find(r => r.organization_id === org) || scores?.find(r => r.organization_id === COMMUNITY_ORGANIZATION.id);

  if (isEmpty(orgScore)) return null;

  return orgScore;
}
