import React from 'react';

import { isLandingType } from 'module/Collection/Collection.util';
import { getCollectionContentCounts } from 'module/Curation/Curation.service';
import { getLandingStatsCount } from 'module/Landing/Landing.service';

import { Status, useAsync } from 'storage';

import { Artifact } from 'types/common';

import { COLLECTION_COUNT_SHELL } from './Curation.const';
import { CollectionCountResponse } from './Curation.type';

export default function useArtifactCollectionCount(artifact: Artifact) {
  const { data, run, status } = useAsync<CollectionCountResponse>(COLLECTION_COUNT_SHELL);

  React.useEffect(() => {
    if (isLandingType(artifact)) {
      run(getLandingStatsCount(artifact.type, artifact.name));
    } else if (artifact.guid) {
      run(getCollectionContentCounts(artifact.guid));
    }
  }, [artifact, run]);

  return {
    analyticTotal: data.analytics,
    attackScriptTotal: data.bas_scripts,
    indicatorTotal: data.indicators,
    intelTotal: data.threat_intelligences,
    sessionTotal: data.sessions,
    active: status === Status.pending
  };
}
