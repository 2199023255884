import React from 'react';

import Alert from 'snap-ui/Alert';
import Typography from 'snap-ui/Typography';

import { useIDEState } from 'module/IDE';
import { MetadataBulk, MetadataValue } from 'module/Metadata';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';

export default function CustomMetadata(): JSX.Element {
  const { defaultOrgId, permission } = useAuth();
  const timer = React.useRef<NodeJS.Timeout>();
  const { dispatch, ideState } = useIDEState();

  React.useEffect(() => {
    return () => clearTimeout(timer.current);
  });

  function handleChange(value: MetadataValue) {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      dispatch({ type: 'MetadataUpdate', metadata: { value } });
    }, 1000);
  }

  return (
    <>
      <Typography variant='h3'>Metadata</Typography>
      {defaultOrgId !== ideState.analyticForm.organization_id && (
        <Alert severity='warning'>
          Editing metadata for {permission.find(o => o.id === defaultOrgId)?.name}, but the detection is owned by{' '}
          {permission.find(o => o.id === ideState.analyticForm.organization_id)?.name}.
        </Alert>
      )}
      <MetadataBulk data={ideState.metadata} onChange={handleChange} type={ArtifactType.Analytic} />
    </>
  );
}
