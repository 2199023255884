import React from 'react';

import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import Path from 'constants/paths';

import { JobGroupDetailProvider } from 'module/Job/useJobGroupDetail';

import { Guid } from 'types/common';

import { HuntDetail, HuntList, HuntListProvider, Workbench } from './';
import HuntTasks from './HuntTasks';

export default function HuntRouter(): JSX.Element {
  return (
    <HuntListProvider>
      <Switch>
        <Route exact path={Path.Hunt} component={HuntList} />
        <Route exact path={Path.HuntScheduled} component={HuntTasks} />
        <Route path={Path.HuntDetail} component={HuntDetailRouter} />
      </Switch>
    </HuntListProvider>
  );
}

function HuntDetailRouter({ match }: RouteComponentProps<{ guid: Guid }>): JSX.Element {
  return (
    <JobGroupDetailProvider guid={match.params.guid}>
      <Switch>
        <Route exact path={Path.HuntDetail} component={HuntDetail} />
        <Route exact path={Path.HuntWorkbench} component={Workbench} />
      </Switch>
    </JobGroupDetailProvider>
  );
}
