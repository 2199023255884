import isEmpty from 'lodash/isEmpty';

import { AttackNode } from 'module/Matrix/Matrix.type';
import { Discriminator } from 'module/Tag';

import { Artifact } from 'types/common';

import { SoftwareType } from '../Tag/Tag.type';
import {
  getNamedLanding,
  getNamedLandingStatsCount,
  postNewActor,
  postNewSoftware,
  updateActor,
  updateSoftware
} from './Landing.api';
import { ActorCreationPayload, LandingForm, SoftwareCreationPayload } from './Landing.type';
import { artifactToEndpointFragment } from './Landing.util';

export function getLanding(type: Artifact['type'], name: string, rollup: boolean) {
  const landing = artifactToEndpointFragment(type);
  return getNamedLanding(landing, name, rollup);
}

export function getLandingStatsCount(type: Artifact['type'], name: string) {
  const landing = artifactToEndpointFragment(type);
  return getNamedLandingStatsCount(landing, name);
}

export function createNewLanding(
  type: typeof Discriminator.Actor | typeof Discriminator.Software,
  values: LandingForm
) {
  const payload = payloadFromFrom(values);
  switch (type) {
    case 'actor':
      return postNewActor(payload);
    case 'software':
      return postNewSoftware({ ...payload, type: SoftwareType.Malware });
    default:
      throw new Error('New landing type not allowed');
  }
}

export function updateLanding(
  id: number,
  type: typeof Discriminator.Actor | typeof Discriminator.Software,
  values: LandingForm
) {
  const payload = payloadFromFrom(values);
  delete payload.external_source;

  switch (type) {
    case 'actor':
      return updateActor(id, payload);
    case 'software':
      return updateSoftware(id, payload);
    default:
      throw new Error('New landing type not allowed');
  }
}

function payloadFromFrom(values: LandingForm) {
  const payload: Partial<ActorCreationPayload & SoftwareCreationPayload> = {};
  payload.external_source = 'snapattack';
  payload.name = values.name;
  payload.description = values.overview;

  if (values.actor_names.length > 0) payload.actors = values.actor_names;
  if (values.alias_names.length > 0) payload.aliases = values.alias_names;
  if (values.attack_names.length > 0) payload.attacks = values.attack_names;
  if (values.software_names.length > 0) payload.software = values.software_names;
  if (values.vulnerabilities_names.length > 0) payload.vulnerabilities = values.vulnerabilities_names;
  if (values.type) payload.type = values.type;

  return payload;
}
export function filterForestByMitreIDBasedOnArray(nodes: AttackNode[] = [], summary) {
  return nodes
    .map(node => ({
      ...node,
      isIncluded: !isEmpty(summary.find(sum => sum.mitre_id === node.mitre_id)),
      attack_children: filterForestByMitreIDBasedOnArray(node.attack_children, summary)
    }))
    .filter(node => node.isIncluded || node.attack_children.length);
}
