import isEmpty from 'lodash/isEmpty';

import { DateRange } from 'module/DateRangeSelector';

import { SectionForm, SectionKind, SpecialSigmaModifier } from 'types/analytic';
import { BooleanString, Guid } from 'types/common';

import { Exclusion, FieldType, OSQueryKeyValues, TuningPayload } from './AnalyticTuning.types';

export function buildTuningPayload(
  dates: DateRange,
  integration: Guid,
  fields?: string[],
  fieldType?: FieldType,
  _exclusions?: Exclusion
): TuningPayload {
  const { start: earliest, stop: latest } = dates;

  const filter = Object.entries(_exclusions || {}).reduce((filters, [field, values]) => {
    return [...filters, { field: `${fieldType}.${field}`, values: values.map(v => v.key) }];
  }, []);

  return {
    earliest,
    latest,
    integration,
    filter,
    fields: isEmpty(fields) ? undefined : fields.map(field => `${fieldType}.${field}`)
  };
}

export function mapSectionsFromFilters(filters: OSQueryKeyValues): SectionForm[] {
  return Object.entries(filters).map(filter => {
    const [field, values] = filter;
    const cleanField = field.replace(/[^\w\s]/gi, '_');
    const sectionName = `${cleanField}_Section`;
    return {
      kind: SectionKind.KeyValue,
      name: sectionName,
      rules: [
        {
          all: BooleanString.False,
          field,
          modifier: SpecialSigmaModifier.Equal,
          values
        }
      ],
      values: ['']
    };
  });
}
