import React from 'react';

import { Autocomplete } from 'snap-ui/Autocomplete';

import {
  CoverageTable,
  mitreIdCol,
  mitreNameCol,
  priorityCol,
  deployedCol,
  recommendedCol,
  coverageCol,
  breadthCol,
  depthCol
} from '../Table';
import { AttackRecordType } from '../type';
import { MitreCoverageInterface } from './useMitreCoverage';

type MitreTableProps = MitreCoverageInterface;

export default function MitreTable(props: MitreTableProps): JSX.Element {
  const { isPending, isProfilePending, getProfile, getStats } = props;
  const [selectedRecordType, setSelectedRecordType] = React.useState<AttackRecordType>('techniques');

  return (
    <CoverageTable
      columns={[
        mitreIdCol,
        mitreNameCol,
        priorityCol(isProfilePending, getProfile),
        deployedCol(getStats),
        recommendedCol(getStats),
        coverageCol(getStats),
        breadthCol(getStats),
        depthCol(getStats)
      ]}
      isPending={isPending}
      isProfilePending={isProfilePending}
      rows={props[selectedRecordType]}
      ToolbarContent={
        <Autocomplete
          className='tacticFilter'
          label='Tactics'
          name='tactic_filter'
          options={[
            { value: 'tactics', content: 'Tactics', label: 'Tactics' },
            { value: 'techniques', content: 'Techniques', label: 'Techniques' },
            { value: 'subtechniques', content: 'Subtechniques', label: 'Subtechniques' }
          ]}
          onChange={v => setSelectedRecordType(v as AttackRecordType)}
          value={selectedRecordType}
        />
      }
    />
  );
}
