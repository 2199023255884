import React from 'react';

import { JsonForms, JsonFormsInitStateProps, JsonFormsReactProps } from '@jsonforms/react';

import { ArtifactType } from 'types/common';

type _BaseProps = JsonFormsReactProps & Omit<JsonFormsInitStateProps, 'config'>;

type _JsonView =
  | (_BaseProps & { asFilter?: never; asInline?: never; truncated?: never; asPreview: true; type?: ArtifactType })
  | (_BaseProps & { asFilter?: never; asInline: true; truncated?: boolean; asPreview?: never; type: ArtifactType })
  | (_BaseProps & { asFilter: true; asInline?: never; truncated?: never; asPreview?: never; type: ArtifactType })
  | (_BaseProps & { asFilter?: never; asInline?: never; truncated?: never; asPreview?: never; type: ArtifactType });

export type JsonView = _BaseProps & {
  config: { asFilter?: boolean; asInline?: boolean; truncated?: boolean; asPreview?: boolean; type?: ArtifactType };
};

export function JsonView({ asFilter, asInline, truncated, asPreview, type, ...props }: _JsonView) {
  return (
    <JsonForms validationMode='NoValidation' {...props} config={{ asFilter, asInline, asPreview, type, truncated }} />
  );
}
