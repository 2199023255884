import React, { ReactElement } from 'react';

import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Box from 'snap-ui/Box';
import Button from 'snap-ui/Button';
import Collapse from 'snap-ui/Collapse';
import Icon from 'snap-ui/Icon';
import Typography, { TypographyProps } from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { useFilterRegistry } from 'module/GlobalFilter';
import { MatrixPaletteOrdinal } from 'module/Scaffold/Scaffold.type';
import useSecurityProfile from 'module/SecurityProfile/useSecurityProfile';
import { Discriminator } from 'module/Tag';

import { ArtifactType } from 'types/common';

import MatrixCount from '../Matrix.count';
import Tooltip from '../Matrix.tooltip';
import { HeatMap, StatisticNode } from '../Matrix.type';
import { getFilterValuesOnlyQueryString, getNodeSpecificName, nodeTotal } from '../Matrix.util';
import SubTechnique from '../SubTechnique';
import { Id } from '../Tactic/Tactic';
import useMatrixPalette from '../useMatrixPalette';

export type Props = {
  node: StatisticNode;
  showSubtechniques: boolean;
  showMitreId: boolean;
  showCount: boolean;
  showEmpty: boolean;
  topic: ArtifactType;
  palette: MatrixPaletteOrdinal;
  heatMap: HeatMap;
  maxRange: number;
  perBucket: number;
};

export type ContainerProps = {
  tier: string;
  background: string;
};

export const TechniqueName = styled(Typography, { shouldForwardProp: p => p !== 'tier' })<
  TypographyProps & { tier: string }
>`
  margin-bottom: 0;
  color: ${p => p.tier};
`;

export const TechniqueNameWrapper = styled('div')`
  min-width: 160px;
`;

const Container = styled('div', { shouldForwardProp: p => p !== 'tier' && p !== 'background' })<ContainerProps>`
  background-color: ${p => p.background || p.theme.palette.grey[700]};
  margin: 16px 8px 2px;
  padding: 12px;
  ${p => (p.tier ? 'border: 1px solid ' + p.tier : '')}
  border-radius: 3px 3px 0 0;

  &.rounded-bottom {
    border-radius: 3px;
  }
`;

const Technique = ({
  node,
  showMitreId,
  showSubtechniques,
  showCount,
  showEmpty,
  topic,
  palette,
  heatMap,
  maxRange,
  perBucket
}: Props): ReactElement => {
  const { values } = useFilterRegistry(topic);
  const { tagInProfile } = useSecurityProfile();

  const name = getNodeSpecificName(node);
  const [localExpand, setLocalExpand] = React.useState(true);

  const filterNode = {
    ...node,
    attack_children: node.attack_children.filter(technique =>
      values?.priority.includes(tagInProfile(technique.id)?.score_label)
    )
  };

  const { getTier } = useMatrixPalette(palette, heatMap, maxRange, perBucket);
  const tier = getTier(filterNode);

  const handleExpanded = () => {
    setLocalExpand(v => !v);
  };

  React.useEffect(() => {
    setLocalExpand(showSubtechniques);
  }, [showSubtechniques]);

  const total = nodeTotal(filterNode);
  const allChildrenAreEmpty = filterNode.attack_children?.every(c => nodeTotal(c) === 0);
  if (!showEmpty && total === 0 && allChildrenAreEmpty) return null;
  const showCaret =
    (filterNode.attack_children?.length > 0 && !allChildrenAreEmpty) ||
    (filterNode.attack_children?.length > 0 && showEmpty);

  return (
    <>
      <Tooltip count={total} mitre_id={filterNode.mitre_id}>
        <Container
          className={classNames('brighten', {
            'rounded-bottom': filterNode.attack_children.length === 0 || !localExpand
          })}
          tier={tier?.text}
          background={tier?.background}
        >
          <TechniqueNameWrapper>
            <Link
              target='_blank'
              to={{
                pathname: `${Path.Collection}/${Discriminator.Attack}/${encodeURIComponent(
                  node.name
                )}${getFilterValuesOnlyQueryString(values, window.location.search)}`
              }}
            >
              <TechniqueName variant='body1' tier={tier?.text}>
                {name}
              </TechniqueName>
              {showMitreId && <Id tier={tier?.text}>{filterNode.mitre_id}</Id>}
            </Link>
            <Box sx={{ position: 'relative', minHeight: '1rem', paddingTop: '0.25rem' }}>
              {showCount && (
                <Box sx={{ float: 'right' }} data-testid={`${filterNode.mitre_id}-count`}>
                  <MatrixCount count={total} topic={topic} tier={tier} />
                </Box>
              )}
              {showCaret && (
                <Button
                  variant='text'
                  onClick={handleExpanded}
                  id='MatrixTechnique'
                  ariaLabel='Expand collapse sub-techniques'
                  sx={{ position: 'absolute', bottom: '-0.85rem', minWidth: 'unset', width: '25px' }}
                >
                  <Icon icon={!localExpand ? faAngleUp : faAngleDown} color={tier?.text} />
                </Button>
              )}
            </Box>
          </TechniqueNameWrapper>
        </Container>
      </Tooltip>
      <Collapse in={localExpand}>
        {filterNode.attack_children.map((sub, idx) => {
          const offspring = filterNode.attack_children.length;
          const className = `${offspring === idx + 1 ? 'rounded-bottom' : ''}`;
          return (
            <SubTechnique
              key={sub.id}
              className={className}
              node={sub}
              showMitreId={showMitreId}
              showCount={showCount}
              showEmpty={showEmpty}
              topic={topic}
              palette={palette}
              heatMap={heatMap}
              maxRange={maxRange}
              perBucket={perBucket}
            />
          );
        })}
      </Collapse>
    </>
  );
};

export default Technique;
