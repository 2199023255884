import React from 'react';

import { faSparkles } from '@fortawesome/pro-regular-svg-icons';

import Alert from 'snap-ui/Alert';
import CircularProgress from 'snap-ui/CircularProgress';
import { ConfirmDialog } from 'snap-ui/Dialog';

import { regenerateAnalyticDescription } from 'apis/resources/analytic';

import { useAiFeatures } from 'hooks/useAiFeatures';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import { usePushSnack } from 'provider';

import { Guid } from 'types/common';

type AiDescriptionCatalog = {
  isOpen: boolean;
  setIsOpen(open: boolean): void;
  isDialogOpen: boolean;
  setIsDialogOpen(open: boolean): void;
};

export type AiButtonProps = {
  aiDescription: string;
  canEdit: boolean;
  logsource: string;
};

const AiDescriptionContext = React.createContext<AiDescriptionCatalog>(null);

AiDescriptionContext.displayName = 'AiDescriptionContext';

function useAiDescriptionCatalog(): AiDescriptionCatalog {
  const context = React.useContext(AiDescriptionContext);
  if (!context) {
    throw new Error('useAiDescriptionCatalog must be used within the AnalyticVersionContext');
  }

  return context;
}

export function AiDescriptionInterface({ children }: { children: React.ReactNode }): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

  return (
    <AiDescriptionContext.Provider value={{ isOpen, setIsOpen, isDialogOpen, setIsDialogOpen }}>
      {children}
    </AiDescriptionContext.Provider>
  );
}
export function AiDescriptionButton({ aiDescription, canEdit, logsource }: AiButtonProps): JSX.Element {
  const { canUseAiFeatures } = useAiFeatures();
  const { setIsOpen } = useAiDescriptionCatalog();
  const title = aiDescription ? 'Regenerate' : 'Generate';
  const disabled = !canUseAiFeatures || logsource === 'Native';
  const tooltip =
    logsource === 'Native'
      ? 'AI enhanced descriptions are not currently available for native detections.'
      : !canUseAiFeatures
      ? 'Generative AI features have been disabled by your organization administrator.'
      : null;
  if (!canEdit) return null;
  return (
    <BurgerClicker
      key='AiDescription'
      icon={faSparkles}
      disabled={disabled}
      TooltipProps={{
        title: tooltip
      }}
      onClick={() => setIsOpen(true)}
      title={`${title} AI Description`}
    />
  );
}

export function AiDescriptionConfirmDialog({ guid }: { guid: Guid }) {
  const pushSnack = usePushSnack();
  const { isOpen, setIsOpen } = useAiDescriptionCatalog();
  const [submitting, isSubmitting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  function handleRegenerateDescription() {
    isSubmitting(true);
    setError(false);
    regenerateAnalyticDescription(guid)
      .then(() => {
        setIsOpen(false);
        isSubmitting(false);
        pushSnack('AI description is being generated.', 'info', 'center', 'bottom', 5000);
      })
      .catch(() => {
        setError(true);
        isSubmitting(false);
      });
  }

  return (
    <ConfirmDialog
      title='Generate AI Description'
      DialogProps={{
        open: isOpen,
        onClose: () => setIsOpen(false)
      }}
      ConfirmProps={{
        children: submitting ? <CircularProgress size={25} /> : 'Confirm',
        disabled: submitting,
        onClick: handleRegenerateDescription
      }}
    >
      {error && <Alert severity='error'>An error occurred. Please try again later.</Alert>}
      <p>
        An AI description will be generated for this detection by sending the title, description, references, tags,{' '}
        <strong>and detection logic</strong> to a large language model. It may take 1-2 minutes for the description to
        be created and updated on this page. Please periodically refresh the page to check for the new description.
      </p>
    </ConfirmDialog>
  );
}
