import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { create as createAnalytic, save as saveAnalytic } from 'apis/resources/analytic';

import { RawTranslationContext, useRawTranslations } from 'aso/useRawTranslation';
import useWorkflow, { WorkflowResponse } from 'aso/useWorkflow';

import { DateRange, getInitialDatesByPreset } from 'module/DateRangeSelector';
import { Integration, IntegrationTarget } from 'module/Integration/Integration.type';

import { useAuth, usePushSnack } from 'provider';
import { useIntegrationCatalog } from 'provider/Integration';

import { defaultFprDetection } from 'services/analyticService';
import { checkContentPermission } from 'services/authService';

import { Status, useAsync } from 'storage';

import { CustomizationFormUpdate } from 'types/analytic';
import { ContentPermission } from 'types/auth';
import { Guid } from 'types/common';

import { FPRCreate, FPROutput } from '../Analytic.type';
import { buildAnalyticPayload } from '../Analytic.util';
import { useAnalyticCatalog } from '../core/AnalyticProvider';
import useEditorStateContext from '../core/EditorStateProvider';
import useFalsePositiveReduction, { TuningBag } from '../useFalsePositiveReduction';
import { mapSectionsFromFilters } from './AnalyticTuning.service';
import { Exclusion, FieldType } from './AnalyticTuning.types';

interface TuningInterface {
  applyTuning: () => Promise<Guid>;
  isSaving: boolean;
  canEdit: boolean;
  fieldType: FieldType;
  fpr: FPROutput;
  dates: DateRange;
  setDates: React.Dispatch<React.SetStateAction<DateRange>>;
  exclusions: Exclusion;
  setExclusions: React.Dispatch<React.SetStateAction<Exclusion>>;
  exclusionsLoaded: boolean;
  setExclusionsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLanguage: IntegrationTarget;
  integrationGuid: Guid;
  setIntegrationGuid(guid: Guid): void;
  integrationType: Integration['type'];
  deploymentBag: WorkflowResponse;
  taskBag: ReturnType<typeof useAsync>;
  translationBag: RawTranslationContext;
  tuningBag: TuningBag;
  setShouldTranslate(shouldTranslate: boolean): void;
}

const TuningContext = React.createContext<TuningInterface>(null);
TuningContext.displayName = 'TuningContext';

export default function useTuningContext() {
  const state = React.useContext(TuningContext);
  if (!state) throw new Error('useTuningContext must be used in the TuningProvider');
  return state;
}

export const INITIAL_PRESET = 30;
export function TuningProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const { defaultOrgId, user } = useAuth();
  const pushSnack = usePushSnack();
  const [fieldType] = React.useState<FieldType>(FieldType.Raw);
  const { integrations } = useIntegrationCatalog();
  const { dispatch, state, tagOptions } = useEditorStateContext();

  const tuningBag = useFalsePositiveReduction();

  const [dates, setDates] = React.useState<DateRange>(() => getInitialDatesByPreset(INITIAL_PRESET));
  const [integrationGuid, setIntegrationGuid] = React.useState<Guid>(null);
  const [integrationType, setIntegrationType] = React.useState<string>('');
  const [exclusions, setExclusions] = React.useState<Exclusion>({});
  const [exclusionsLoaded, setExclusionsLoaded] = React.useState<boolean>(false);
  const [selectedLanguages, setSelectedLanguages] = React.useState<IntegrationTarget[]>([]);
  const [shouldTranslate, setShouldTranslate] = React.useState<boolean>();
  const taskBag = useAsync<void>(null);

  React.useEffect(() => {
    const integration = [...integrations.deployable, ...integrations.huntable].find(i => i.guid === integrationGuid);
    if (integration) {
      setIntegrationType(integration.type);
      const targets = !isEmpty(integration?.deployment_targets)
        ? integration.deployment_targets
        : integration.hunt_targets;
      setSelectedLanguages(targets);
    } else {
      setSelectedLanguages([]);
    }
  }, [integrationGuid, integrations.deployable, integrations.huntable]);

  const [{ analytic, supplemental, permissions, getFpr, supplementalRefresh }] = useAnalyticCatalog();
  const canEdit = checkContentPermission(permissions, ContentPermission.Edit);
  const deploymentBag = useWorkflow(analytic.guid);

  const isSaving = [taskBag.status, deploymentBag.status, tuningBag.status].includes(Status.pending);

  const deployments = React.useMemo(
    () => supplemental?.deployments?.filter(d => d.organization_id === defaultOrgId),
    [defaultOrgId, supplemental]
  );

  const fpr = React.useMemo(() => getFpr(integrationGuid), [getFpr, integrationGuid]);

  React.useEffect(() => {
    setExclusions({});
    setExclusionsLoaded(false);
  }, [fpr]);

  React.useEffect(() => {
    const sections = mapSectionsFromFilters(
      Object.entries(exclusions).reduce((exclusions, [field, buckets]) => {
        return isEmpty(buckets)
          ? exclusions
          : {
              ...exclusions,
              [field]: buckets.map(b => b.key.toString())
            };
      }, {})
    );

    let customization: CustomizationFormUpdate = {
      detection: defaultFprDetection()
    };

    if (!isEmpty(sections)) {
      const newSectionConditions = `NOT ( ${sections.map(section => section.name).join(' OR ')} ) `;
      customization = {
        detection: {
          condition: newSectionConditions,
          sections
        }
      };
    }

    dispatch({ type: 'CustomizationUpdate', customization });
  }, [dispatch, exclusions]);

  const fprRaw = state._customization_raw;
  const canTranslate = !isEmpty(selectedLanguages) && !!fprRaw;
  const selectedLanguageIds = React.useMemo(() => selectedLanguages?.map(l => l.id), [selectedLanguages]);

  const { translations, status } = useRawTranslations(
    canTranslate && shouldTranslate,
    analytic.raw,
    selectedLanguageIds,
    fprRaw,
    analytic.is_native
  );

  const validTranslation = React.useMemo(() => {
    return translations.find(t => !t.error && !isEmpty(t.fpr)) || translations.find(t => !t.error && !isEmpty(t.raw));
  }, [translations]);

  const translationError = React.useMemo(() => {
    return validTranslation ? null : translations.find(t => t.error)?.error;
  }, [translations, validTranslation]);

  const translationBag: RawTranslationContext = React.useMemo(
    () => ({
      translation: validTranslation?.raw ?? analytic.raw,
      fprTranslation: validTranslation?.fpr?.raw,
      status,
      error: translationError
    }),
    [analytic.raw, status, translationError, validTranslation?.fpr?.raw, validTranslation?.raw]
  );

  const selectedLanguage = React.useMemo(() => {
    if (isEmpty(selectedLanguages)) return null;
    return validTranslation?.fpr ? selectedLanguages.find(s => s.id === validTranslation.id) : selectedLanguages[0];
  }, [selectedLanguages, validTranslation]);

  React.useEffect(() => {
    if (analytic.is_native && validTranslation?.fpr?.raw) {
      dispatch({ type: 'RawUpdateAction', raw: validTranslation?.fpr?.raw, tagOptions });
    }
  }, [analytic.is_native, dispatch, tagOptions, validTranslation?.fpr?.raw]);

  const applyTuning = React.useCallback(async () => {
    if (analytic.is_native || state.isNative) {
      // originally native with edit permission
      const payload = buildAnalyticPayload(state, user.name);
      const action = canEdit && analytic.is_native ? saveAnalytic(analytic.guid, payload) : createAnalytic(payload);
      const { guid } = await taskBag.task(action);

      if (!analytic.is_native) {
        await Promise.all(
          deployments.map(deployment => deploymentBag.removeDeployment(deployment.id, integrationGuid))
        );
      }

      await deploymentBag.deploy({
        overwrite: analytic.is_native,
        organization_id: defaultOrgId,
        integrations: [integrationGuid]
      });

      supplementalRefresh();

      pushSnack('Saved', 'info', 'center', 'bottom', 5000);
      return guid;
    } else {
      const payload: FPRCreate = isEmpty(fpr)
        ? {
            organization_id: defaultOrgId,
            integrations: [integrationGuid],
            analytics: [analytic.guid],
            raw: fprRaw
          }
        : {
            organization_id: fpr.organization_id,
            raw: fprRaw
          };
      return tuningBag.saveAndApply(fpr?.id, payload).then(() => {
        pushSnack('Saved', 'info', 'center', 'bottom', 5000);
        return analytic.guid;
      });
    }
  }, [
    analytic.is_native,
    analytic.guid,
    state,
    user.name,
    canEdit,
    taskBag,
    deploymentBag,
    defaultOrgId,
    integrationGuid,
    supplementalRefresh,
    pushSnack,
    deployments,
    fpr,
    fprRaw,
    tuningBag
  ]);

  const value: TuningInterface = React.useMemo(
    () => ({
      applyTuning,
      isSaving,
      canEdit,
      fieldType,
      fpr,
      dates,
      setDates,
      exclusions,
      setExclusions,
      exclusionsLoaded,
      setExclusionsLoaded,
      selectedLanguage,
      integrationGuid,
      setIntegrationGuid,
      integrationType,
      deploymentBag,
      taskBag,
      translationBag,
      tuningBag,
      setShouldTranslate
    }),
    [
      applyTuning,
      canEdit,
      dates,
      deploymentBag,
      exclusions,
      exclusionsLoaded,
      fieldType,
      fpr,
      integrationGuid,
      integrationType,
      isSaving,
      selectedLanguage,
      taskBag,
      translationBag,
      tuningBag
    ]
  );

  return <TuningContext.Provider value={value}>{children}</TuningContext.Provider>;
}
