import Card from 'snap-ui/Card';
import { styled } from 'snap-ui/util';

export const AbSection = styled(Card)`
  &.disabled {
    opacity: 0.6;
  }

  .ab-section-condition {
    display: grid;
    grid-template-columns: minmax(225px, auto) 150px 1fr ${p => p.theme.spacing(7)};
    grid-gap: ${p => p.theme.spacing(3)};
    align-items: center;
    justify-items: center;

    &:not(:last-child) {
      margin-bottom: ${p => p.theme.spacing(7)};
    }

    & > * {
      margin-bottom: 0;
    }

    .value {
      justify-self: stretch;
      grid-column-start: 3;

      &.custom-event-id fieldset {
        border: 1px solid ${p => p.theme.palette.warning.main};
      }
    }

    .field {
      grid-column-start: 1;
      justify-self: stretch;

      &.custom-field fieldset {
        border: 1px solid ${p => p.theme.palette.warning.main};
      }
    }
    .modifier {
      grid-column-start: 2;
      justify-self: stretch;

      & > .dropdown:not('.icon') {
        min-width: 150px;
      }
    }

    .ab-all {
      grid-column-start: 3;
      align-self: stretch;
      grid-row-gap: ${p => p.theme.spacing(1)};
    }

    .add-field-rule {
      grid-column-start: 4;
      align-self: start;
    }

    &.strings {
      grid-template-columns: 1fr ${p => p.theme.spacing(7)};

      .value {
        grid-column-start: 1;
      }

      .add-field-rule {
        grid-column-start: 2;
      }
    }
  }
  .ab-all {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    & > :not(:last-child) {
      margin-right: ${p => p.theme.spacing(9)};
    }
  }

  .ab-section-actions {
    text-align: right;
  }

  .ButtonEndAdornment {
    position: absolute;
    right: ${p => p.theme.spacing(1)};
  }
`;
