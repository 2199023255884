import React from 'react';

import CircularProgress from 'snap-ui/CircularProgress';
import { GridRowSelectionModel } from 'snap-ui/DataGrid';
import ConfirmDialog from 'snap-ui/Dialog/ConfirmDialog';
import { styled } from 'snap-ui/util';

import { asValidationError, ValidationError } from 'apis';
import { bulkDeploy, bulkUnDeploy } from 'apis/resources/analytic';
import { CancelToken } from 'apis/snapattack';

import { CommonEvent, Engage, Fingerprint, Widget } from 'lib/Engagement';

import { ExpandedArtifact } from 'module/Analytic/Analytic.util';
import ValidationAlert from 'module/Widgets/ValidationAlert';

import { useAuth, useIntegrationCatalog, usePushSnack } from 'provider';

import { Guid } from 'types/common';
import { Query } from 'types/filter';

import { presentPerfectBeVerb, renderValueWithLabel } from 'utilities/TextUtils';

import IntegrationsTable, { IntegrationCounts } from './IntegrationsTable';
import useDeployableDetections from './useDetectionDeployment';

type Message = { severity?: string; data: ValidationError };

const BUSY_MESSAGE = {
  data: {
    detail: [<div key='warning'>Handling your request...</div>]
  },
  severity: 'info'
};

const Container = styled('div')`
  margin: ${p => p.theme.spacing(2, 0)};
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(4)};

  .sub-text {
    color: ${p => p.theme.palette.text.secondary};
    font-size: smaller;
    margin-left: ${p => p.theme.spacing(4)};
  }
`;

const Message = styled(ValidationAlert)`
  width: 100%;
  min-height: fit-content;
  margin: 0;
`;

export type BulkDetectionDeployment = {
  criteria: Query;
  onClose(): void;
  isOpen: boolean;
  onDeployed?(): void;
  selectionModel?: GridRowSelectionModel;
  unDeploy: boolean;
};

/**
 * @deprecated This should not be used directly. Use useDetectionDeploymentInterface
 */
export function BulkDetectionDeployment(props: BulkDetectionDeployment) {
  const pushSnack = usePushSnack();
  const { criteria, onClose, onDeployed, isOpen, unDeploy } = props;
  const { defaultOrgId, isSubscriber } = useAuth();
  const [activity, setActivity] = React.useState(false);
  const { isLoading, refresh, ...feed } = useDeployableDetections(criteria);
  const [selected, setSelected] = React.useState<Guid[]>([]);
  const [message, setMessage] = React.useState<Message>();
  const [userDeployed, setUserDeployed] = React.useState<Guid[]>([]);
  const deployable = feed.items.filter(i => !i.preview).length;
  const { integrations } = useIntegrationCatalog();
  const [selectedIntegrations, setSelectedIntegrations] = React.useState<Guid[]>([]);
  const title = unDeploy
    ? `Undeploy ${deployable || ''} ${deployable === 1 ? 'Detection' : 'Detections'}`
    : `Deploy ${deployable || ''} ${deployable === 1 ? 'Detection' : 'Detections'}`;
  const confirmButtonText = unDeploy ? 'Undeploy' : 'Deploy';

  const isAllSelected = React.useMemo(
    () => feed.items.every(item => selected.includes(item.guid)),
    [feed.items, selected]
  );

  React.useEffect(() => {
    setSelected(feed.items.map(i => i.guid));
    const selectedIntegrationsList = integrations.deployable.map(i => i.guid);
    setSelectedIntegrations(selectedIntegrationsList);
  }, [feed.items, integrations.deployable, defaultOrgId]);

  const integrationCounts: IntegrationCounts = React.useMemo(() => {
    const countObj: IntegrationCounts = integrations.deployable.reduce((composed, integration) => {
      return {
        ...composed,
        [integration.guid]: {
          deployed: [],
          notDeployed: { compatible: [], incompatible: [] },
          name: integration.name
        }
      };
    }, {});

    feed.items.forEach(({ deployable_integrations, deployments, guid: detectionGuid }: ExpandedArtifact) => {
      Object.entries(countObj).forEach(([integrationGuid, { deployed, notDeployed }]) => {
        const isDeployed = deployments?.find(deployment =>
          deployment.integrations.find(integration => integration.guid === integrationGuid)
        );

        const isCompatible = deployable_integrations?.find(deployable => deployable.guid === integrationGuid);

        if (isDeployed) deployed.push(detectionGuid);
        else if (isCompatible) notDeployed.compatible.push(detectionGuid);
        else notDeployed.incompatible.push(detectionGuid);
      });
    });

    Object.values(countObj).forEach(count => {
      count.deployed = [...new Set(count.deployed)];
      count.notDeployed.compatible = [...new Set(count.notDeployed.compatible)];
      count.notDeployed.incompatible = [...new Set(count.notDeployed.incompatible)];
    });

    return countObj;
  }, [feed.items, integrations.deployable]);

  const handleClose = () => {
    setSelected([]);
    setSelectedIntegrations([]);
    setMessage(null);
    setActivity(false);
    onClose();
  };

  const handleUnDeploy = async () => {
    setMessage(BUSY_MESSAGE);
    setActivity(true);
    try {
      await bulkUnDeploy(defaultOrgId, selected, selectedIntegrations);
      pushSnack(getSuccessMessage('undeployment', selected), 'info', 'center', 'bottom', 3000);
      handleClose();
    } catch (e) {
      setMessage(getErrorMessage(asValidationError(e)));
    }
    setActivity(false);
  };

  const handleDeploy = async () => {
    setMessage(BUSY_MESSAGE);
    setActivity(true);

    try {
      await bulkDeploy(defaultOrgId, selected, selectedIntegrations).then(result => {
        // Using result here would be better but no data is returned which I can make a correlation
        // So regardless of items that didn't get deployed, stuff everything into user attempted
        setUserDeployed([...userDeployed, ...selected]);

        Engage.track(
          Fingerprint.of(Widget.Modal).withCommon(CommonEvent.Submit).withQualifier('deploy detections').withData({
            defaultOrgId,
            selected: selected?.length,
            selectedIntegrations: selectedIntegrations
          })
        );
        if (onDeployed) setTimeout(() => onDeployed(), 2500);
        pushSnack(getSuccessMessage('deployment', result), 'info', 'center', 'bottom', 3000);
        handleClose();
      });
    } catch (e) {
      const message = getErrorMessage(asValidationError(e));
      Engage.track(
        Fingerprint.error(Widget.Modal)
          .withCommon(CommonEvent.Submit)
          .withQualifier('deploy detections failed')
          .withData({
            message
          })
      );
      setMessage(message);
    }

    setActivity(false);
  };

  const toggleSelectAll = (_event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    setSelectedIntegrations(checked ? integrations.deployable.map(i => i.guid) : []);
  };

  const handleSelect = React.useCallback(
    (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      const guid = event.currentTarget.id;
      const newSelectedItems = checked ? [...selectedIntegrations, guid] : selectedIntegrations.filter(s => s !== guid);
      setSelectedIntegrations(newSelectedItems);
    },
    [selectedIntegrations]
  );

  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    if (isOpen) refresh(cancelSource);
    return () => cancelSource.cancel();
  }, [isOpen, refresh]);

  return (
    <ConfirmDialog
      title={title}
      DialogProps={{ open: isOpen, onClose: handleClose }}
      ConfirmProps={{
        children: isLoading || activity ? <CircularProgress size={25} /> : confirmButtonText,
        onClick: unDeploy ? handleUnDeploy : handleDeploy,
        disabled: !isSubscriber || isLoading || activity || deployable === 0
      }}
    >
      <Container>
        {message?.data && <Message error={message.data} severity={message.severity as any} />}
        <IntegrationsTable
          toggleSelectAll={toggleSelectAll}
          isAllSelected={isAllSelected}
          loading={isLoading}
          disabled={activity || isLoading}
          selectedIntegrations={selectedIntegrations}
          deployable={deployable}
          integrationsList={integrations.deployable}
          integrationCounts={integrationCounts}
          handleSelect={handleSelect}
          unDeploy={unDeploy}
        />
      </Container>
    </ConfirmDialog>
  );
}

function getSuccessMessage(action: 'deployment' | 'undeployment', items: unknown[]) {
  return `${renderValueWithLabel(items.length, 'detection')} ${presentPerfectBeVerb(items)} been queued for ${action}.`;
}

function getErrorMessage(error: ValidationError) {
  return { data: error, severity: 'error' };
}
