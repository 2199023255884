import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { InlineAlert } from 'snap-ui/Alert';
import Checkbox from 'snap-ui/Checkbox';
import Placeholder from 'snap-ui/Placeholder';
import Table from 'snap-ui/Table';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { Integration } from 'module/Integration/Integration.type';

import { Guid } from 'types/common';

import { formatQueryString, parseAnyQueryString } from 'utilities/SearchParam';
import { renderValueWithLabel } from 'utilities/TextUtils';

const Row = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};

  .MuiCheckbox-root > svg {
    width: 24px;
  }

  a {
    flex-grow: 1;

    svg {
      margin-right: ${p => p.theme.spacing(2)};
      color: ${p => p.theme.palette.secondary.light};
    }
  }
`;

export type IntegrationCounts = {
  [key: Guid]: {
    deployed: Guid[];
    notDeployed: {
      compatible: Guid[];
      incompatible: Guid[];
    };
  };
};

type Props = {
  toggleSelectAll: (_event: React.SyntheticEvent<Element, Event>, checked: boolean) => void;
  isAllSelected: boolean;
  loading: boolean;
  disabled: boolean;
  selectedIntegrations: Guid[];
  deployable: number;
  integrationsList: Integration[];
  integrationCounts: IntegrationCounts;
  unDeploy?: boolean;
  handleSelect: (event: React.SyntheticEvent<Element, Event>, checked: boolean) => void;
};

function IntegrationsTable({
  toggleSelectAll,
  isAllSelected,
  loading,
  disabled,
  selectedIntegrations,
  deployable,
  integrationsList,
  integrationCounts,
  handleSelect,
  unDeploy
}: Props) {
  const { pathname, search } = useLocation();
  const columnTwoTitle = unDeploy ? 'To Undeploy' : 'To Deploy';
  const columnThreeTitle = unDeploy ? 'Not Deployed' : 'Incompatible';

  const mapping = React.useMemo(() => {
    const placeHolder = [
      <Placeholder key='1' variant='text' />,
      <Placeholder key='1' variant='text' />,
      <Placeholder key='1' variant='text' />
    ];
    if (loading) {
      return [placeHolder, placeHolder, placeHolder, placeHolder];
    }

    return integrationsList.map(integration => {
      const { name, guid, deployment_targets } = integration;
      const languages = deployment_targets.map(target => target.id);

      const { deployed, notDeployed } = integrationCounts[guid];

      const deployedCount = deployed.length;
      const deployableCount = notDeployed.compatible.length;
      const notDeployedCount = notDeployed.compatible.length + notDeployed.incompatible.length;
      const incompatibleCount = notDeployed.incompatible.length;

      const deployedToolTipText =
        deployedCount === 0 ? `${deployedCount} deployed` : `${deployedCount} already deployed`;
      const columnTwoToolTip = unDeploy
        ? `${renderValueWithLabel(deployedCount, 'detection')} to Undeploy`
        : deployedToolTipText;
      const columnTwoContent = unDeploy ? deployedCount : deployableCount;

      const columnThreeToolTip = unDeploy
        ? `${renderValueWithLabel(notDeployedCount, 'detection')} not deployed`
        : `${incompatibleCount} are not compatible`;
      const columnThreeContent = unDeploy ? notDeployedCount : incompatibleCount;

      const searchQuery = (languageOp: string) =>
        formatQueryString({
          ...parseAnyQueryString(search),
          languageOp,
          page: '1',
          languages,
          topic: 'detection'
        });

      return [
        <Row key={guid}>
          <Checkbox
            id={guid}
            onChange={handleSelect}
            checked={selectedIntegrations.includes(guid)}
            disabled={disabled}
          />
          {name}
        </Row>,
        <div key={`${guid}-deployable`}>
          <Tooltip arrow placement='left' title={columnTwoToolTip}>
            <Link
              to={{
                pathname: pathname,
                search: searchQuery('any')
              }}
              target='_blank'
              rel='noopener noreferrer'
            >
              <InlineAlert severity='success'>{columnTwoContent}</InlineAlert>
            </Link>
          </Tooltip>
        </div>,
        <div key={`${guid}-incompatible`}>
          {!unDeploy && incompatibleCount === 0 ? (
            ' '
          ) : (
            <Tooltip arrow placement='left' title={columnThreeToolTip}>
              <Link
                to={{
                  pathname: pathname,
                  search: searchQuery('not_in')
                }}
                target='_blank'
                rel='noopener noreferrer'
              >
                <InlineAlert severity='warning'>{columnThreeContent}</InlineAlert>
              </Link>
            </Tooltip>
          )}
        </div>
      ];
    });
  }, [selectedIntegrations, disabled, deployable, integrationCounts, loading, integrationsList]); // eslint-disable-line react-hooks/exhaustive-deps

  const getIntegrationColumns = () => [
    <>
      <Checkbox
        key='all'
        disabled={disabled}
        checked={isAllSelected}
        onChange={toggleSelectAll}
        intermediate={isAllSelected && selectedIntegrations.length > 0 && selectedIntegrations.length !== deployable}
      />{' '}
      {selectedIntegrations.length} Integrations selected
    </>,
    <>{columnTwoTitle}</>,
    <>{columnThreeTitle}</>
  ];

  return <Table rows={mapping} columns={getIntegrationColumns()} size='small' />;
}

export default IntegrationsTable;
