import React from 'react';

import { getCampaign, postCampaignSupplemental } from 'module/BAS/BAS.api';
import { BASCampaign, BASCampaignSupplemental } from 'module/BAS/BAS.type';

import { Status, useAsync } from 'storage';

import { Guid } from 'types/common';

import { composeCampaignWithSupplement } from '../BAS.util';

type CampaignData = {
  campaign: BASCampaign;
  refresh(): void;
  isLoading: boolean;
  error: any;
};

export default function useCampaign(guid: Guid): CampaignData {
  const { data, run, status: campaignStatus, error } = useAsync({} as BASCampaign);
  const {
    data: supplementals,
    run: supplementalRun,
    status: supplementalStatus
  } = useAsync<BASCampaignSupplemental[]>([]);

  const isLoading = [campaignStatus, supplementalStatus].includes(Status.pending);

  const refresh = React.useCallback(() => {
    if (guid) run(getCampaign(guid));
  }, [guid, run]);

  React.useEffect(() => {
    supplementalRun(postCampaignSupplemental([guid]));
  }, [guid, supplementalRun]);

  const campaign = React.useMemo(() => composeCampaignWithSupplement(data, supplementals), [data, supplementals]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    campaign,
    refresh,
    isLoading,
    error
  };
}
