import React from 'react';

import { ErrorProps } from 'module/ApiError';
import { getAsyncJobResult, getSingleAsyncJob, useJobGroupResult } from 'module/Job';

import { Status, useAsync } from 'storage';

import { Guid, Named, Tracked } from 'types/common';

import { Hunt, HuntGroup, HuntResult, useHuntList } from './';

export type HuntDetailInterface = {
  detections: (Named & Tracked)[];
  dispatchErrors: Record<Guid, Record<string, string>>;
  errorProps?: ErrorProps;
  hits: Record<Guid, number>;
  hitsIsPending: boolean;
  hunts: Hunt[];
  huntGroup: HuntGroup;
  isPending: boolean;
  results: HuntResult[];
  resultsIsPending: boolean;
};

const HuntDetailContext = React.createContext<HuntDetailInterface>(null);
HuntDetailContext.displayName = 'HuntDetailContext';

function _useHuntDetail(groupGuid: Guid): HuntDetailInterface {
  const { result: hitsResult, status: hitsStatus } = useJobGroupResult(groupGuid);
  const { errorProps: groupsErrorProps, getHunt, isPending: groupsIsPending } = useHuntList();
  const { data: hunts, errorProps, run, status } = useAsync<Hunt[]>([]);
  const {
    data: results,
    errorProps: resultErrorProps,
    run: resultRun,
    status: resultStatus
  } = useAsync<HuntResult[]>([]);

  const huntGroup = React.useMemo(() => getHunt(groupGuid), [getHunt, groupGuid]);

  React.useEffect(() => {
    if (huntGroup) {
      run(Promise.all(huntGroup?.jobs?.map(job => getSingleAsyncJob(job.job_guid))));
    }
  }, [huntGroup, run]);

  React.useEffect(() => {
    if (huntGroup) {
      resultRun(Promise.all(huntGroup?.jobs?.map(job => getAsyncJobResult(job.job_guid))));
    }
  }, [huntGroup, resultRun]);

  const detectionsByGuid = React.useMemo(() => {
    const detectionsByGuid = {};
    hunts.forEach(hunt => {
      hunt.items.forEach(detection => {
        detectionsByGuid[detection.guid] = detection;
      });
    });

    return detectionsByGuid;
  }, [hunts]);

  const dispatchErrors = React.useMemo(() => {
    return hitsResult.reduce<Record<Guid, Record<string, string>>>((errors, detectionResult) => {
      if (detectionResult.error) {
        errors[detectionResult.guid] = errors[detectionResult.guid] ?? {};
        errors[detectionResult.guid][detectionResult.integration_name] = detectionResult.error;
      }
      return errors;
    }, {});
  }, [hitsResult]);

  const hits = React.useMemo(() => {
    return hitsResult.reduce<Record<Guid, number>>((aggregate, detectionResult) => {
      aggregate[detectionResult.guid] = aggregate[detectionResult.guid] ?? 0;
      aggregate[detectionResult.guid] += detectionResult.job_hit_count;
      return aggregate;
    }, {});
  }, [hitsResult]);

  return {
    detections: Object.values(detectionsByGuid),
    dispatchErrors,
    errorProps: errorProps || groupsErrorProps || resultErrorProps,
    hits,
    hitsIsPending: hitsStatus === Status.pending,
    hunts,
    huntGroup,
    isPending: groupsIsPending || status === Status.pending,
    results,
    resultsIsPending: groupsIsPending || resultStatus === Status.pending
  };
}

export function HuntDetailProvider({ children, guid }: { children: React.ReactChild; guid: Guid }): JSX.Element {
  const hdi = _useHuntDetail(guid);
  return <HuntDetailContext.Provider value={hdi}>{children}</HuntDetailContext.Provider>;
}

export default function useHuntDetail(): HuntDetailInterface {
  const context = React.useContext(HuntDetailContext);
  if (!context) throw new Error('useHuntDetail must be used inside HuntDetailContext');
  return context;
}
