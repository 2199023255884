import React from 'react';

import { faLayerPlus } from '@fortawesome/pro-solid-svg-icons';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import { AddArtifactToCollection } from './AddArtifactToCollection';

type Button = Partial<BurgerClicker>;
type Dialog = Omit<AddArtifactToCollection, 'isOpen' | 'onClose'>;

interface AddArtifactToCollectionInterface {
  isOpen: boolean;
  setIsOpen(open: boolean): void;
}

const AddArtifactToCollectionContext = React.createContext<AddArtifactToCollectionInterface>(null);
AddArtifactToCollectionContext.displayName = 'AddArtifactToCollectionContext';

export function AddArtifactToCollectionInterface({ children }: { children: React.ReactNode }): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <AddArtifactToCollectionContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </AddArtifactToCollectionContext.Provider>
  );
}

function useAddArtifactToCollection(): AddArtifactToCollectionInterface {
  const context = React.useContext(AddArtifactToCollectionContext);
  if (!context) {
    throw new Error('useAddArtifactToCollection must be used within an AddArtifactToCollectionInterface');
  }
  return context;
}

export function AddArtifactToCollectionButton({ onClick, ...others }: Button): JSX.Element {
  const { setIsOpen } = useAddArtifactToCollection();
  return (
    <BurgerClicker
      title='Add to Collection'
      icon={faLayerPlus}
      onClick={() => {
        setIsOpen(true);
        if (onClick) onClick();
      }}
      {...others}
    />
  );
}

export function AddArtifactToCollectionDialog(props: Dialog) {
  const { isOpen, setIsOpen } = useAddArtifactToCollection();
  return <AddArtifactToCollection key='value' isOpen={isOpen} onClose={() => setIsOpen(false)} {...props} />;
}
