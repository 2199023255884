import React from 'react';

import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';

import { ActionIconButton } from 'snap-ui/Button';
import FormControl from 'snap-ui/FormControl';
import FormControlLabel from 'snap-ui/FormControlLabel';
import Radio from 'snap-ui/Radio';
import RadioGroup from 'snap-ui/RadioGroup';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { SectionForm, SectionKind } from 'types/analytic';

import SectionNameInput from './SectionNameInput';

const DuplicateName = styled('div')`
  color: ${p => p.theme.palette.error.main};
`;

type SectionHeaderProps = {
  disabled: boolean;
  isDupeName: boolean;
  onChange: (value: Partial<SectionForm>) => void;
  onRemove: () => void;
  section: SectionForm;
};

const CardHeader = styled('div')`
  .title {
    font-size: 1.325rem;
    font-weight: 700;
  }

  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    margin-bottom: ${p => p.theme.spacing(3)};

    div.actions {
      display: flex;
      gap: ${p => p.theme.spacing(4)};
      align-items: center;
    }
  }
`;

const SectionHeader = ({
  disabled,
  isDupeName,
  onChange,
  onRemove,
  section
}: SectionHeaderProps): React.ReactElement => {
  const [isEditingTitle, setIsEditingTitle] = React.useState<boolean>(false);

  function handleSaveName(name: string) {
    setIsEditingTitle(false);
    onChange({ name });
  }

  function handleChangeKind(e, kind: SectionKind) {
    onChange({ kind });
  }

  return (
    <>
      {isEditingTitle ? (
        <SectionNameInput
          data-testid={`section-name`}
          initialValue={section.name || ''}
          onSave={handleSaveName}
          textHandler={text => text.replace(/ /g, '_')}
        />
      ) : (
        <>
          <CardHeader className='header'>
            <Typography className='title'>{section.name}</Typography>

            <div className='options'>
              <div className='actions'>
                {!disabled && (
                  <>
                    <Tooltip title='Edit Section Name' placement='top' arrow wrap>
                      <ActionIconButton
                        aria-label='Edit section name'
                        disabled={disabled}
                        icon={faPen}
                        className='link'
                        onClick={(): void => setIsEditingTitle(true)}
                        data-testid={`edit-section`}
                      />
                    </Tooltip>

                    <Tooltip title='Delete Section' placement='top' arrow wrap>
                      <ActionIconButton
                        aria-label='Delete section'
                        disabled={disabled}
                        icon={faTrash}
                        className='link'
                        onClick={onRemove}
                      />
                    </Tooltip>
                  </>
                )}
              </div>
              <FormControl disabled={disabled}>
                <RadioGroup row onChange={handleChangeKind} value={section.kind}>
                  <FormControlLabel control={<Radio />} label='Field search' value={SectionKind.KeyValue} />
                  <FormControlLabel control={<Radio />} label='Full text search' value={SectionKind.Strings} />
                </RadioGroup>
              </FormControl>
            </div>
          </CardHeader>
        </>
      )}
      {isDupeName && <DuplicateName>Section Name must be unique</DuplicateName>}
    </>
  );
};

export default SectionHeader;
