import React from 'react';

import { faCube } from '@fortawesome/pro-regular-svg-icons';

import Icon, { IntegrationIcon } from 'snap-ui/Icon';
import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import { useLanguagePlatforms } from 'module/Integration';

import { Ident } from 'types/common';

import { SimpleBadge } from '../../Widgets/Badge';

const Badge = styled(SimpleBadge)`
  z-index: 1;
  text-transform: uppercase;

  &.grid {
    min-width: 185px;
  }

  &.card {
    min-width: auto;
  }
`;

type LogsourceBadgeProps = {
  display: 'grid' | 'card';
  languageId?: Ident;
  value: string;
};

export default function LogsourceBadge({ display, languageId, value }: LogsourceBadgeProps) {
  const { getPlatformDetails } = useLanguagePlatforms();
  const isNative = value?.toLowerCase() === 'native';
  const platformDetails = React.useMemo(
    () => (isNative ? getPlatformDetails(languageId) : undefined),
    [getPlatformDetails, isNative, languageId]
  );

  const isNotReady = isNative && !platformDetails;
  if (!value) return null;
  if (isNotReady) return <Placeholder variant='rectangular' height={24} width={80} />;

  let props;
  let tooltip;
  if (isNative) {
    const integrationType = platformDetails.platforms?.[0]?.integrationType;
    tooltip =
      platformDetails.platforms?.[0]?.typeLabel.display_name ||
      (platformDetails.backendKey ? `Native ${platformDetails.backendKey} code` : 'Native detection code');
    props = {
      value: platformDetails.platforms?.[0]?.typeLabel.short_name || platformDetails.backendKey || 'Native',
      icon: integrationType ? <IntegrationIcon type={integrationType} /> : <Icon icon={faCube} />
    };
  } else {
    tooltip = value;
    props = {
      value,
      icon: <Icon icon={faCube} />
    };
  }

  return (
    <Badge
      className={display}
      {...props}
      TooltipProps={{
        title: tooltip,
        arrow: true,
        placement: 'top'
      }}
    />
  );
}
