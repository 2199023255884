import React from 'react';

import uniq from 'lodash/uniq';

import { getAnalyticVersionsByGuids } from 'module/Analytic/Analytic.api';
import { AnalyticVersionOverview } from 'module/Analytic/Analytic.type';

import { Status, useAsync } from 'storage';

import { Guid, Ident } from 'types/common';

import { convertTimezone } from 'utilities/TimeUtils';

import { Deltas } from './Tables/Tables.type';

export default function useBulkActions(detections: Guid[]): {
  getLatest: (guid: Guid) => AnalyticVersionOverview;
  getDeltas: (guid: Guid, versionId: Ident) => Deltas;
} {
  const { data, run, status } = useAsync<AnalyticVersionOverview[]>([]);

  React.useEffect(() => {
    if (detections.length > 0 && status !== Status.resolved) {
      run(getAnalyticVersionsByGuids(uniq(detections)));
    }
  }, [detections, run, status]);

  const versionsByGuid = React.useMemo(() => {
    if (data) {
      const versionsByGuid: Record<Guid, AnalyticVersionOverview[]> = data?.reduce((allVersions, version) => {
        if (allVersions[version.root_analytic]) {
          allVersions[version.root_analytic] = [version, ...allVersions[version.root_analytic]];
        } else {
          allVersions[version.root_analytic] = [version];
        }
        return allVersions;
      }, {});
      return versionsByGuid;
    }
  }, [data]);

  const getLatest = React.useCallback((guid: Guid) => versionsByGuid[guid]?.[0], [versionsByGuid]);

  const getDeltas = React.useCallback(
    (guid: Guid, versionId: Ident) => {
      const versions = versionsByGuid[guid];
      const thisVersionIdx = versions?.findIndex(v => v.id === versionId);
      if (thisVersionIdx < 0) return { versions: null, timeInMs: null };

      const nextVersionIdx = thisVersionIdx - 1;
      const nextVersion = versions?.[nextVersionIdx];
      return {
        versions: thisVersionIdx,
        timeInMs: new Date().getTime() - convertTimezone(nextVersion?.creation).valueOf()
      };
    },
    [versionsByGuid]
  );

  return { getLatest, getDeltas };
}
