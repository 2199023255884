import React from 'react';

import { useFilterRegistry } from 'module/GlobalFilter';
import { TagWeightFilterOptions } from 'module/SecurityProfile/SecurityProfile.type';
import useSecurityProfile from 'module/SecurityProfile/useSecurityProfile';

import { StatisticNode } from './Matrix.type';

export default function useFilterTechnique(matrix: StatisticNode[]): StatisticNode[] {
  const { values: filter } = useFilterRegistry();
  const { tagInProfile } = useSecurityProfile();

  const threatProfile = filter?.threatProfile as string[];
  const threatPriority = filter?.priority as string[];

  const isThreatProfile = threatProfile?.every(value => value === 'true');
  const isNotInThreatProfile = threatProfile?.every(value => value === 'false');
  const isThreatPriority = !Object.values(TagWeightFilterOptions).every(weight => threatPriority?.includes(weight));

  const shouldIncludeTechnique = React.useCallback(
    (technique: StatisticNode) => {
      let conditionKey = 'default';

      if (isThreatProfile || isThreatPriority) {
        conditionKey = 'threatOrPriority';
      } else if (isNotInThreatProfile) {
        conditionKey = 'notInThreatProfile';
      }

      const scoreLabel = tagInProfile(technique.id)?.score_label;

      switch (conditionKey) {
        case 'threatOrPriority':
          return filter?.priority.includes(scoreLabel);
        case 'notInThreatProfile':
          return !filter?.priority.includes(scoreLabel);
        default:
          return true;
      }
    },
    [filter?.priority, isNotInThreatProfile, isThreatPriority, isThreatProfile, tagInProfile]
  );

  const filterMatrix = React.useMemo(() => {
    return matrix.map(node => {
      return {
        ...node,
        attack_children: node.attack_children.filter(technique => shouldIncludeTechnique(technique))
      };
    });
  }, [matrix, shouldIncludeTechnique]);

  return filterMatrix;
}
