import React from 'react';

import Icon, {
  saAdobe,
  saApache,
  saAtlassian,
  saCisco,
  saCitrix,
  saF5,
  saFortinet,
  saIvanti,
  saMicrosoft,
  saOracle,
  saVmware,
  saZoho
} from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import { CheckableCardCheckboxFormik } from 'module/Form/CheckableCardFormik';

import SecurityProfileStep from './SecurityProfileStep';

const ApplicationsCheckboxFormik = styled(CheckableCardCheckboxFormik)`
  .CheckableCard-icon {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .CheckableCard-label {
    min-height: 30px;
    text-align: center;
  }
`;

const applications = [
  {
    value: 'adobe',
    detail: 'Acrobat Reader, ColdFusion, Creative Suite',
    icon: <Icon icon={saAdobe} size='xl' />
  },
  {
    value: 'atlassian',
    detail: 'Jira, Confluence',
    icon: <Icon icon={saAtlassian} size='lg' />
  },
  {
    value: 'apache',
    detail: 'Airflow, Struts, Tomcat, Solr',
    icon: <Icon icon={saApache} size='4x' />
  },
  { value: 'cisco', detail: 'AnyConnect, IOS', icon: <Icon icon={saCisco} size='2x' /> },
  {
    value: 'citrix',
    detail: 'NetScalar ADC, NetScalar Gateway',
    icon: <Icon icon={saCitrix} size='lg' />
  },
  { value: 'f5', detail: 'F5 BIG-IP, F5 NGINX', icon: <Icon icon={saF5} size='2x' /> },
  {
    value: 'fortinet',
    detail: 'FortiOS, FortiProxy, FortiADC',
    icon: <Icon icon={saFortinet} />
  },
  {
    value: 'ivanti',
    detail: 'Pulse Connect Secure, MobileIron',
    icon: <Icon icon={saIvanti} size='xl' />
  },
  {
    value: 'microsoft',
    detail: 'Exchange, SharePoint, IIS',
    icon: <Icon icon={saMicrosoft} size='xl' />
  },
  {
    value: 'oracle',
    detail: 'Java, WebLogic, Fusion',
    icon: <Icon icon={saOracle} />
  },
  {
    value: 'vmware',
    detail: 'Workstation, Fusion, ESX, vCenter',
    icon: <Icon icon={saVmware} size='lg' />
  },
  {
    value: 'zoho',
    detail: 'ManageEngine ServiceDesk',
    icon: <Icon icon={saZoho} size='3x' />
  }
];

export default function SecurityProfileStepApplications(): JSX.Element {
  return (
    <SecurityProfileStep
      title='What applications do you use?'
      description='Software applications, while essential for productivity, are often targeted by threat actors, with vulnerabilities and security flaws exploited to gain unauthorized access, execute malware, or compromise sensitive data. Regular patching and security updates are critical to mitigate these risks and maintain the resilience of software applications against evolving cyber threats.'
      content={<ApplicationsCheckboxFormik name='applications' options={applications} />}
    />
  );
}
