import { styled } from 'snap-ui/util';

export const DetectionFormRoot = styled('div', { label: 'DetectionForm' })`
  padding: ${p => p.theme.spacing(6)};

  h3 {
    margin: ${p => p.theme.spacing(3, 0, 6)};
  }

  .logsource-selection {
    display: flex;
    justify-content: left;

    .selection {
      min-width: 30%;
    }
  }

  .logsource {
    flex: 1;
  }

  .disabled.field {
    opacity: 1;
  }

  .detection-form-choice {
    display: flex;
    & > * {
      margin-right: ${p => p.theme.spacing(4)};
    }

    .field:last-child {
      margin-bottom: 1em;
    }
  }
`;

export const RuleBuilder = styled('div')`
  margin: 0;
  padding: ${p => p.theme.spacing(4)};

  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(4)};
`;

export const IntegrationLanguageContainer = styled('div')`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  gap: ${p => p.theme.spacing(4)};

  & > .MuiAutocomplete-root {
    flex: 1 0 250px;
    max-width: calc(50% - ${p => p.theme.spacing(2)}); // account for half the gap
  }
`;
