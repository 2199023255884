import { faBarsProgress, faDatabase } from '@fortawesome/pro-regular-svg-icons';
import {
  faBuilding,
  faCameraCctv,
  faCogs,
  faFilePen,
  faIdCard,
  faKey,
  faLaptop,
  faListCheck,
  faSkullCrossbones,
  faSplit,
  faUnlockAlt,
  faUser,
  faUserSecret
} from '@fortawesome/pro-solid-svg-icons';

import { AuditLogs } from 'module/AuditLogs';
import { Authentication, superOnly } from 'module/Authentication';
import { Blacklist } from 'module/Blacklist';
import Impersonate from 'module/Impersonate';
import Integration from 'module/Integration';
import Task from 'module/Job/Task';
import { Metadata } from 'module/Metadata';
import { SecurityProfile } from 'module/SecurityProfile';
import AgentList from 'module/Setting/core/BasAgents/AgentList';

import { checkTaskPermission } from 'services/authService';

import { FunctionalPermission, Organization, User } from 'types/auth';

import { SettingItem } from './Setting.type';
import ApiKeys from './core/ApiKeys/ApiKeys';
import Entitlements from './core/Entitlements/Entitlements';
import LanguageConfigWrapper from './core/Language';
import OrgManagement from './core/OrgManagement/OrgManagement';
import UserManagement from './core/UserManagement/UserManagement';
import { UserPreferences } from './core/UserPreferences';

const settingItems: readonly SettingItem[] = Object.freeze([
  {
    name: 'Authentication',
    path: 'authentication',
    icon: faUnlockAlt,
    component: Authentication,
    getAllowed: (user: User) => !user.sso
  },
  {
    name: 'Preferences',
    path: 'preferences',
    icon: faFilePen,
    component: UserPreferences
  },
  {
    name: 'Impersonate User',
    path: 'impersonate',
    icon: faUserSecret,
    component: Impersonate,
    getAllowed: (user: User, _orgs, permission: Organization[]) =>
      !!user.impersonator || checkTaskPermission(permission, FunctionalPermission.ImpersonateUser)
  },
  {
    subscriber: true,
    name: 'Language Configuration',
    path: 'language',
    enablePathIdVariable: true,
    icon: faSplit,
    component: LanguageConfigWrapper
  },
  {
    subscriber: true,
    name: 'Integrations',
    path: 'integrations',
    icon: faCogs,
    component: Integration
  },
  {
    name: 'Threat Profile',
    path: 'threat-profile',
    icon: faIdCard,
    fullPageStyle: true,
    component: SecurityProfile,
    getAllowed: (_auth, _orgs, permission: Organization[]) =>
      checkTaskPermission(permission, FunctionalPermission.SetSecurityProfile)
  },
  {
    name: 'User Management',
    path: 'user-management',
    enablePathIdVariable: true,
    icon: faUser,
    component: UserManagement,
    getAllowed: (_user: User, organizations: Organization[]) => organizations?.length > 0
  },
  {
    name: 'Organization Management',
    path: 'organization-management',
    icon: faBuilding,
    component: OrgManagement,
    getAllowed: (_user: User, organizations: Organization[]) => organizations?.length > 0
  },
  {
    name: 'Entitlements',
    path: 'entitlements',
    icon: faListCheck,
    component: Entitlements,
    getAllowed: superOnly
  },
  {
    subscriber: true,
    name: 'Metadata',
    path: 'metadata',
    icon: faDatabase,
    component: Metadata
  },
  {
    subscriber: true,
    name: 'API Keys',
    path: 'apikeys',
    icon: faKey,
    component: ApiKeys
  },
  {
    subscriber: true,
    name: 'Attack Simulation Agents',
    path: 'agents',
    icon: faLaptop,
    component: AgentList
  },
  {
    subscriber: true,
    name: 'Tasks',
    path: 'tasks',
    icon: faBarsProgress,
    component: Task,
    getAllowed: (_auth, _orgs, permission: Organization[]) =>
      checkTaskPermission(permission, FunctionalPermission.DetectionHuntFeatures),
    enablePathIdVariable: true
  },
  {
    name: 'Blacklist',
    path: 'blacklist',
    icon: faSkullCrossbones,
    component: Blacklist,
    getAllowed: superOnly
  },
  {
    name: 'Audit Logs',
    path: 'audit',
    icon: faCameraCctv,
    fullPageStyle: true,
    component: AuditLogs,
    getAllowed: superOnly
  }
]);

export default settingItems;
