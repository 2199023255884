import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { FilterAugment, FilterControl } from 'module/GlobalFilter';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';
import { addOrRemoveValue } from '../GlobalFilter.util';

type ThreatProfileKeys = {
  threatProfile: string[];
};

type ThreatProfileFilterProps = {
  onChange(values: ThreatProfileKeys): void;
  values: ThreatProfileKeys;
};

function ThreatProfileFilter({ onChange, values }: ThreatProfileFilterProps): React.ReactElement {
  const { isSubscriber } = useAuth();
  return (
    <FilterControl disabled={!isSubscriber}>
      <FormLabel id='threat-profile-checkbox-group-label' className='title-tooltip'>
        By Threat Profile
        <Tooltip
          title={
            !isSubscriber
              ? 'This filter is only available to subscribers'
              : 'Prioritized threats tailored to your organization'
          }
          placement='right'
          arrow
          wrap
        >
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <FormGroup aria-labelledby='threat-profile-checkbox-group-label'>
        <FormControlLabel
          key='threatProfile-included'
          control={<Checkbox onChange={handleChange} value='true' checked={values.threatProfile.includes('true')} />}
          label='In Threat Profile'
        />
        <FormControlLabel
          key='threatProfile-notIncluded'
          control={<Checkbox onChange={handleChange} value='false' checked={values.threatProfile.includes('false')} />}
          label='Not in Threat Profile'
        />
      </FormGroup>
    </FilterControl>
  );

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as string;
    const threatProfile = addOrRemoveValue(checked, values.threatProfile, v);
    onChange({ threatProfile });
  }
}

function checkForCompleteSet(values: ThreatProfileKeys): boolean {
  return (
    !values.threatProfile?.length || (values.threatProfile?.includes('true') && values.threatProfile?.includes('false'))
  );
}

function toQuery(values: ThreatProfileKeys, { orgId }: FilterAugment): Query {
  if (checkForCompleteSet(values)) return;

  if (values.threatProfile.includes('true')) {
    return {
      op: Ops.equals,
      field: 'security_profile_tags.organization_id',
      value: orgId
    };
  }

  return {
    op: Ops.equals,
    field: 'security_profile_tags.organization_id',
    value: null
  };
}

function toAttackTagQuery(values: ThreatProfileKeys, { orgId }: FilterAugment): Query {
  if (checkForCompleteSet(values)) return;

  if (values.threatProfile.every(v => v === 'true')) {
    return {
      op: Ops.equals,
      field: 'profiles.organization_id',
      value: orgId
    };
  }
  // Waiting for BE to fix this edge case
  if (values.threatProfile.every(v => v === 'false')) {
    return {
      op: Ops.not_equals,
      field: 'profiles.organization_id',
      value: orgId
    };
  }
}

const fromQuery = zod
  .object({
    op: zod.literal(Ops.equals),
    field: zod.literal('security_profile_tags.organization_id'),
    value: zod.union([zod.number(), zod.literal(null)])
  })
  .transform(query => ({
    threatProfile: query.value ? ['true'] : ['false']
  }));

const ThreatProfileFilterConfig: FilterConfig<ThreatProfileKeys> = {
  defaults: {
    default: () => ({ threatProfile: ['true', 'false'] })
  },
  supportedTopics: [ArtifactType.Collection, ArtifactType.AttackTag],
  component: ThreatProfileFilter,
  toQuery: { default: toQuery, [ArtifactType.AttackTag]: toAttackTagQuery },
  fromQuery: { default: fromQuery }
};
export default ThreatProfileFilterConfig;
