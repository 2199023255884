import React from 'react';

import { DataGrid, GridColDef } from 'snap-ui/DataGrid';
import Placeholder from 'snap-ui/Placeholder';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { Job, JobGroup, JobStatus, JobType } from 'module/Job';

import { useIntegrationCatalog } from 'provider';

import { formatDayTimestamp } from 'utilities/TimeUtils';

import { getIntegrationNameFromJob } from './BulkConfidence.helper';

const Container = styled('div')`
  height: calc(75vh - ${p => p.theme.spacing(9)} * 2 - ${p => p.theme.spacing(7)});
  .no-break {
    width: 100%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .disable {
    font-style: italic;
    color: ${p => p.theme.palette.grey[500]};
  }
`;

type BulkConfidenceTaskProps = {
  getJob: (JobGroup) => void;
  isPending: boolean;
  jobGroupTask: JobGroup[];
  jobs: Job[];
};

function BulkConfidenceTask({ getJob, isPending, jobGroupTask, jobs }: BulkConfidenceTaskProps) {
  const { integrations } = useIntegrationCatalog();
  const bulkConfidenceMap = jobGroupTask
    .map(group => {
      const jobTask = jobs?.find(eachJobData => group.jobs[0]?.guid === eachJobData.guid);
      return {
        ...group,
        name: group?.name || jobTask?.name,
        status:
          group.status === JobStatus.CompletedWithErrors || group.status === JobStatus.Success ? 'done' : group.status,
        creation: jobTask?.creation,
        integrationName: getIntegrationNameFromJob(group.jobs, jobs, integrations.all).join(', '),
        detection: group.jobs
          .map(job =>
            jobs
              .map(eachJobData => (job.guid === eachJobData.guid ? eachJobData?.items.length : 0))
              .reduce((sum, count) => sum + count, 0)
          )
          .reduce((sum, count) => sum + count, 0),
        type: jobTask?.type
      };
    })
    ?.filter(group => group.type === JobType.Rank);
  const jobColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      renderCell: p => p.row.name,
      minWidth: 350,
      flex: 1,
      cellClassName: p => (p.row.status !== 'done' ? 'disable' : '')
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: p => p.row.status,
      minWidth: 105,
      cellClassName: p => (p.row.status !== 'done' ? 'disable' : '')
    },
    {
      field: 'detection',
      headerName: 'Detections',
      minWidth: 100,
      cellClassName: p => (p.row.status !== 'done' ? 'disable' : '')
    },
    {
      field: 'creation',
      headerName: 'Created',
      renderCell: p => formatDayTimestamp(p.row.creation),
      minWidth: 150,
      cellClassName: p => (p.row.status !== 'done' ? 'disable' : '')
    },
    {
      field: 'integrationName',
      headerName: 'Integrations',
      minWidth: 250,
      renderCell: p => (
        <Tooltip arrow placement='top-end' title={p.row.integrationName}>
          <span className='no-break'>{p.row.integrationName}</span>
        </Tooltip>
      ),
      cellClassName: p => (p.row.status !== 'done' ? 'disable' : '')
    }
  ];
  return (
    <Container>
      {isPending ? (
        <>
          <Placeholder variant='text' width={'100%'} height={100} />
          <Placeholder variant='text' width={'100%'} height={70} />
          <Placeholder variant='text' width={'100%'} height={70} />
          <Placeholder variant='text' width={'100%'} height={70} />
          <Placeholder variant='text' width={'100%'} height={70} />
          <Placeholder variant='text' width={'100%'} height={70} />
        </>
      ) : (
        <DataGrid
          columns={jobColumns}
          rows={bulkConfidenceMap}
          getRowId={row => row.guid}
          disableMultipleRowSelection
          getRowClassName={p => (p.row.status !== 'done' ? `disableRow` : null)}
          onRowClick={p => (p.row.status === 'done' ? getJob(p.row) : null)}
          initialState={{
            sorting: {
              sortModel: [{ field: 'creation', sort: 'desc' }]
            }
          }}
        />
      )}
    </Container>
  );
}

export default BulkConfidenceTask;
