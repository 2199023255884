import React from 'react';

import { countries as countriesSrc } from 'country-data-list';
import omit from 'lodash/omit';

const countries = countriesSrc.all
  .map(country =>
    country.alpha3 === 'USA'
      ? { ...country, name: 'United States of America' }
      : country.alpha3 === 'KOR'
      ? { ...country, name: 'South Korea' }
      : country.alpha3 === 'PRK'
      ? { ...country, name: 'North Korea' }
      : country.alpha3 === 'IRN'
      ? { ...country, name: 'Iran' }
      : country.alpha3 === 'PSE'
      ? { ...country, name: 'Palestine' }
      : country
  )
  .map(country => ({
    ...omit(country, 'emoji'), // preventing accidental use of emoji flags that are incompatible with Windows
    flag: <span className={`fi fi-${country.alpha2.toLowerCase()}`} />
  }));

function getCountryBy(field: 'name' | 'alpha3', value: string) {
  return countries.find(country => country[field].toLowerCase() === value.toLowerCase());
}
function getCountriesByAlpha3(values: string[]) {
  const lowercasedValues = values.map(value => value.toLowerCase());
  return countries.filter(country => lowercasedValues.includes(country.alpha3.toLowerCase()));
}

export { countries, getCountryBy, getCountriesByAlpha3 };
