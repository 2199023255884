import React from 'react';

import get from 'lodash/get';
import set from 'lodash/set';

import { fixupCondition } from 'module/Analytic/core/Detection/Detection.util';

import { emptySection } from 'services/analyticService';

import { SectionForm } from 'types/analytic';

import { useIDEState } from './';
import { IDEState } from './reducer';

export function useIDEValue<N extends keyof IDEState['analyticForm']>(
  name: N
): {
  value: IDEState['analyticForm'][N];
  setValue(value: IDEState['analyticForm'][N]): void;
} {
  const { dispatch, ideState } = useIDEState();
  const value = get(ideState, ['analyticForm', name], '');

  const setValue = React.useCallback(
    (newValue: string) => {
      const analyticForm = set({}, name, newValue);
      dispatch({ type: 'FormUpdateAction', analyticForm });
    },
    [dispatch, name]
  );

  return { value, setValue };
}

function sectionName(name: 'analyticForm' | 'customization', sectionNumber: number): string | undefined {
  if (name === 'customization') return `Custom_Section_${sectionNumber}`;
}

export function useIDEDetectionValue<N extends 'analyticForm' | 'customization'>(
  name: N
): {
  addSection(): void;
  removeSection(idx: number): void;
  updateCondition(condition: string): void;
  updateSection(idx: number, update: Partial<SectionForm>): void;
  value: IDEState[N]['detection'];
} {
  const { ideState, dispatch } = useIDEState();
  const value = get(ideState, [name, 'detection']);
  const logsourceType = ideState.analyticForm.logsource?.type;

  const setValue = React.useCallback(
    (newValue: IDEState[N]['detection']) => {
      if (name === 'analyticForm') {
        dispatch({ type: 'FormUpdateAction', analyticForm: { detection: newValue } });
      } else if (name === 'customization') {
        dispatch({ type: 'CustomizationUpdate', customization: { detection: newValue } });
      }
    },
    [dispatch, name]
  );

  const nextSectionName = sectionName(name, (value?.sections?.length || 0) + 1);
  const addSection = React.useCallback(() => {
    setValue({
      ...value,
      sections: [...value.sections, emptySection(value.sections.length + 1, logsourceType, nextSectionName)]
    });
  }, [value, setValue, logsourceType, nextSectionName]);

  const removeSection = React.useCallback(
    (idx: number) => {
      const nameToRemove = value.sections[idx]?.name;
      let newSections = value.sections.filter((s, i) => i !== idx);
      if (!newSections.length) newSections = [emptySection(1, logsourceType, sectionName(name, 1))];

      const newCondition = fixupCondition(value.condition, nameToRemove);

      setValue({ ...value, sections: newSections, condition: newCondition });
    },
    [value, setValue, logsourceType, name]
  );

  const updateCondition = React.useCallback(
    (condition: string) => {
      setValue({ ...value, condition });
    },
    [value, setValue]
  );

  const updateSection = React.useCallback(
    (sectionIndex: number, update: Partial<SectionForm>) => {
      const newDetection = {
        ...value,
        sections: value.sections.map((section, idx) => (idx === sectionIndex ? { ...section, ...update } : section))
      };

      // if we changed a section name, also change it in the condition
      if ('name' in update) {
        const currentName = value.sections[sectionIndex].name;
        newDetection.condition = value.condition.replaceAll(currentName, update.name);
      }

      setValue(newDetection);
    },
    [value, setValue]
  );

  return {
    addSection,
    removeSection,
    updateCondition,
    updateSection,
    value
  };
}
