import React from 'react';

import startCase from 'lodash/startCase';

import { Autocomplete } from 'snap-ui/Autocomplete';
import { BarChart } from 'snap-ui/Charts';
import Placeholder from 'snap-ui/Placeholder';
import Typography from 'snap-ui/Typography';
import { useTheme } from 'snap-ui/util';

import { TagWeight } from 'module/SecurityProfile';
import { ScoreBaseMap } from 'module/Widgets/StateLabel';

import { BarChartContainer, BarChartPanel, BarChartToolbar, BarChartPlacholders } from '../style';
import { AttackRecordType } from '../type';
import { MitreCoverageInterface } from './useMitreCoverage';

type BarChartProps = MitreCoverageInterface;

export default function MitreCoverageBarChart({
  aggregatedCoverage,
  isPending,
  isCoveragePending
}: BarChartProps): JSX.Element {
  const { palette } = useTheme();
  const [recordType, setRecordType] = React.useState<AttackRecordType>('techniques');
  const [coverageMetricName, setCoverageMetricName] = React.useState('coverage');

  return (
    <BarChartPanel>
      <BarChartToolbar>
        <Typography variant='h2'>
          {startCase(recordType)} {startCase(coverageMetricName)} by Priority
        </Typography>
        <div className='filters'>
          <Autocomplete
            className='metricSelect'
            disableClearable
            disableUserAdditions
            label='Type'
            name='recordType'
            onChange={v => setRecordType(v as AttackRecordType)}
            options={[
              { value: 'techniques', content: 'Techniques', label: 'Techniques' },
              { value: 'subtechniques', content: 'Subtechniques', label: 'Subtechniques' }
            ]}
            value={recordType}
          />
          <Autocomplete
            className='metricSelect'
            disableClearable
            disableUserAdditions
            label='Coverage Metric'
            name='metric'
            onChange={v => setCoverageMetricName(v as string)}
            options={[
              { value: 'coverage', content: 'Coverage', label: 'Coverage' },
              { value: 'breadth', content: 'Breadth', label: 'Breadth' },
              { value: 'depth', content: 'Depth', label: 'Depth' }
            ]}
            value={coverageMetricName}
          />
        </div>
      </BarChartToolbar>
      {isPending || isCoveragePending ? (
        <BarChartPlacholders>
          <Placeholder variant='rectangular' height={250} />
          <Placeholder variant='rectangular' height={125} />
          <Placeholder variant='rectangular' height={200} />
          <Placeholder variant='rectangular' height={50} />
          <Placeholder variant='rectangular' height={100} />
        </BarChartPlacholders>
      ) : (
        <BarChartContainer>
          <BarChart
            height={300}
            data={Object.values(TagWeight)
              .filter(v => v !== TagWeight.Ignored)
              .map(score => {
                const average = aggregatedCoverage?.[recordType]?.[score]?.[coverageMetricName]?.average ?? 0;
                const coverage = Math.floor(average * 100);
                return {
                  key: score,
                  color: palette[ScoreBaseMap[score]?.color]?.main,
                  label: score,
                  value: coverage
                };
              })}
          />
        </BarChartContainer>
      )}
    </BarChartPanel>
  );
}
