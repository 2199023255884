import Card from 'snap-ui/Card';
import { DataGrid } from 'snap-ui/DataGrid';
import Paper from 'snap-ui/Paper';
import { css, styled } from 'snap-ui/util';

import { DashboardPage } from 'module/Layout/Styled';
import { ThreatProfilePriorityLabel } from 'module/Widgets/StateLabel';
import { Toolbar } from 'module/Widgets/TableToolbar';

const restrictPanelHeight = css`
  max-height: 75vh;
  overflow-y: auto;
`;

export const MitreDashboardPage = styled(DashboardPage)`
  max-width: calc(100vw - var(--Scaffold-spacing));
`;

export const ProfileDashboardPage = styled(DashboardPage)`
  .CoverageTable {
    max-height: 40vh;
  }
`;

export const MitreChartRow = styled('div')`
  display: flex;
  flex-flow: row wrap;
  gap: ${p => p.theme.spacing(6)};
`;

export const MitreStatGrid = styled(Card)`
  // these numbers are magic and work with the dimensions of the StatPanels, which are hard coded because charts
  flex: 0 1 500px;
  display: inline-grid;
  grid-template-columns: 275px 1fr;
  align-items: center;
  gap: ${p => p.theme.spacing(4)};
  padding: ${p => p.theme.spacing(4, 4, 6)};

  .minis {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: ${p => p.theme.spacing(4)};
    .value {
      font-size: 3em;
    }
  }
`;

export const ProfileStatGrid = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: 45% 1fr 1fr;
  gap: ${p => p.theme.spacing(4)};

  .total {
    grid-row: 1 / span 2;
  }

  @media (max-width: 1280px) {
    grid-template-columns: 532px 1fr; // magic number = 500 chart + 32 padding
  }

  @media (max-width: 920px) {
    grid-template-columns: 1fr 1fr;

    .total {
      grid-column: 1 / span 2;
    }
  }
`;

export const StatPanelPlaceholderContainer = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const COMMON_STAT_CONTAINER_STYLES = `
  flex: 1;

  .chart {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .value {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5em;
  }
`;

export const StatPanelContainer = styled(Card)`
  padding: ${p => p.theme.spacing(4, 4, 8)};
  ${COMMON_STAT_CONTAINER_STYLES}
`;

export const PlainStatContainer = styled('div')`
  text-align: center;
  ${COMMON_STAT_CONTAINER_STYLES}
`;

export const ChartPanelContent = styled('div')`
  padding: ${p => p.theme.spacing(0, 4, 8)};
  ${restrictPanelHeight}
`;

export const BarChartPanel = styled(Paper)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const BarChartToolbar = styled(Toolbar)`
  padding: ${p => p.theme.spacing(4, 4, 2)};
  align-items: flex-start;

  h2 {
    font-size: 1.5rem;
    margin: 0;
  }

  .filters {
    display: flex;
    flex-flow row nowrap;
    gap: ${p => p.theme.spacing(2)}
  }

  .metricSelect {
    width: 200px;
  }

  .MuiToggleButton-root {
    padding-left: ${p => p.theme.spacing(4)};
    padding-right: ${p => p.theme.spacing(4)};
  }
`;

export const BarChartContainer = styled(ChartPanelContent)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${p => p.theme.spacing(4)};

  h3 {
    font-size: 1.25rem;
  }

  .cluster {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: ${p => p.theme.spacing(4)};
    align-items: center;
  }

  .bars {
    /* height: 100%; */
    /* border-radius: 0; */
    /* border: 1px solid ${p => p.theme.palette.divider}; */
  }

  .uncovered {
    opacity: 0.25;
  }
`;

export const BarChartPlacholders = styled(ChartPanelContent)`
  height: 340px; // magic. matches chart + padding
  display: grid;
  grid-template-columns: repeat(5, auto);
  align-items: flex-end;
  grid-gap: ${p => p.theme.spacing(2)};
`;

export const TablePanel = styled(Paper)`
  .tacticFilter {
    width: 265px;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(6)};
  }

  .quicksort label {
    margin-right: ${p => p.theme.spacing(2)};
  }

  .MuiFab-root {
    height: ${p => p.theme.spacing(8)};
    width: ${p => p.theme.spacing(8)};
  }
`;

export const Table = styled(DataGrid)`
  ${restrictPanelHeight}
`;

export const TablePriorityLabel = styled(ThreatProfilePriorityLabel)`
  width: 100%;
`;
