import React, { ReactElement } from 'react';

import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { canI } from 'module/Can/util';
import IOCTranslator from 'module/IOC/IOCParser/IOCTranslator';
import { Intel } from 'module/Intel/Intel.type';
import { MetadataView } from 'module/Metadata';
import PayWall from 'module/PayWall';
import PayWallTextBlur from 'module/PayWall/PayWallTextBlur';
import Reference from 'module/Reference';
import TagList, { TagListProps } from 'module/Tag/TagList';
import { MarkdownRead, draftJSToMarkdownRawParser } from 'module/Widgets/Markdown';

import { useAuth } from 'provider';

import { ContentPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

type Props = {
  intel: Intel;
  preview: boolean;
  onQuickAddTag: TagListProps['onSubmit'];
};

const MAX_CHARS = 800;

const Container = styled('div', { label: 'IntelDetail' })`
  .summary {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: ${p => p.theme.spacing(4)};
  }
`;

const IntelDetail = ({ intel, preview, onQuickAddTag }: Props): ReactElement => {
  let description = preview ? (intel.description || '').substring(0, MAX_CHARS) : intel.description;
  description += `${preview && intel.description?.length > MAX_CHARS ? '...' : ''}`;
  description = draftJSToMarkdownRawParser(description);

  const { user } = useAuth();
  const isEditable = user.superuser || canI(ContentPermission.Edit, intel);

  return (
    <Container>
      <PayWallTextBlur preview={preview}>
        <Typography className='summary' variant='h2'>
          Summary
        </Typography>
        <MarkdownRead value={description} />
      </PayWallTextBlur>
      {!preview && (
        <>
          <Reference reference={intel.references} file={intel.files} />
          <MetadataView type={ArtifactType.Intel} guid={intel.guid} />
          <TagList
            actor={intel.actor_names}
            attack={intel.attack_names}
            software={intel.software_names}
            vulnerability={intel.vulnerability_names}
            onSubmit={isEditable ? onQuickAddTag : undefined}
          />
          {intel?.indicators && <IOCTranslator iocs={intel?.indicators} title='Hunt for IOCs' />}
        </>
      )}
      {preview && <PayWall fixed />}
    </Container>
  );
};

export default IntelDetail;
