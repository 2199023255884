import { DataGridProps, GridColDef } from 'snap-ui/DataGrid';

import { formatMiniTimestamp } from 'utilities/TimeUtils';

import { HIDDEN_FIELDS, JOB_STATUS_COMPLETE_JOB_STATUS, SUPERUSER_HIDDEN_FIELDS } from './Job.const';
import { JobOverview, JobStatus } from './Job.type';

export function inferValue(key: string, value: string | number) {
  if (typeof value === 'string') {
    if (key.includes('modified')) return formatMiniTimestamp(value);
  }

  if (typeof value === 'number') {
    if (key.includes('duration')) {
      if (value >= 3600) return `${(value / 3600).toFixed(3)} hours`;
      if (value >= 60) return `${(value / 60).toFixed(3)} minutes`;
      return `${value.toFixed(3)} seconds`;
    }
  }
  return value;
}

export const getRowId: DataGridProps<JobOverview>['getRowId'] = row => row.analytic_job_guid;

export const getGridColumnsTask = (columns: GridColDef[], superuser: boolean) => {
  return columns
    .filter(column => !(superuser ? SUPERUSER_HIDDEN_FIELDS : HIDDEN_FIELDS).includes(column.field))
    .map(column => column.field);
};

export function addGroupPropertyToJobs<T extends { analytic_job_group_guid?: string; analytic_job_guid?: string }>(
  details: T[],
  propertyName: string
): (T & Record<string, string[]>)[] {
  if (!details) {
    return [];
  }

  const groupGuidCounts: Record<string, number> = {};
  details.forEach(job => {
    groupGuidCounts[job.analytic_job_group_guid] = (groupGuidCounts[job.analytic_job_group_guid] || 0) + 1;
  });

  return details.map(job => ({
    ...job,
    [propertyName]: groupGuidCounts[job.analytic_job_group_guid] > 1 ? [job.analytic_job_group_guid] : null
  }));
}

export function getJobStatusMessage(status: JobStatus, percent: number) {
  const progressPercent = Math.floor(percent * 100);

  let message;
  if (status === JobStatus.CompletedWithErrors) {
    message = 'Job completed with errors';
  } else if (progressPercent !== 100) {
    message = `In Progress - ${progressPercent}%`;
  } else {
    message = '';
  }

  return message;
}

export function getGroupJobStatus(values: JobStatus[]): JobStatus {
  const filterValues = values.filter(value => value !== JobStatus.Canceled);

  if (filterValues.every(status => JOB_STATUS_COMPLETE_JOB_STATUS.includes(status))) {
    if (filterValues.some(s => s === JobStatus.CompletedWithErrors)) {
      return JobStatus.CompletedWithErrors;
    } else {
      return JobStatus.Success;
    }
  }

  if (filterValues.some(status => [JobStatus.Pending, JobStatus.Paused, JobStatus.Started].includes(status))) {
    return JobStatus.Started;
  } else {
    return JobStatus.Failed;
  }
}
