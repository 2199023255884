import React from 'react';

import { ExternalLink } from 'snap-ui/Link';

import Path from 'constants/paths';

import Can from 'module/Can';
import { DetectionDeploymentStatus } from 'module/GlobalFilter/Filters/DeployedEnvironment';

import { useAuth, useIntegrationCatalog } from 'provider';

import { FunctionalPermission } from 'types/auth';
import { ArtifactScore, ArtifactType } from 'types/common';

import { formatQueryString } from 'utilities/SearchParam';

import ListItemButton from './ListItemButton';
import ListItemLink from './ListItemLink';

export default function ListItemsDetect({ onClose }: { onClose(): void }) {
  const { isSubscriber } = useAuth();
  const { integrations } = useIntegrationCatalog();
  const languages = integrations.preferred_org_aware
    .filter(integration => !integration.name.includes('SnapAttack -'))
    .flatMap(integration => integration.deployment_targets?.map(target => target.id));
  const viewPath =
    Path.FeedReset +
    formatQueryString({
      topic: ArtifactType.Analytic,
      page: 1
    });
  const recommendedPath =
    Path.FeedReset +
    formatQueryString({
      topic: ArtifactType.Analytic,
      posture: 'validatedDetections',
      severity: [ArtifactScore.MEDIUM, ArtifactScore.HIGH, ArtifactScore.HIGHEST],
      confidence: [ArtifactScore.MEDIUM, ArtifactScore.HIGH, ArtifactScore.HIGHEST],
      deployedStatus: DetectionDeploymentStatus.false,
      languages: languages,
      page: 1
    });
  const deployedPath =
    Path.FeedReset +
    formatQueryString({
      topic: ArtifactType.Analytic,
      deployedStatus: DetectionDeploymentStatus.success,
      page: 1
    });

  return (
    <>
      {isSubscriber && (
        <>
          <ListItemLink onClick={onClose} title='Recommended Detections' to={recommendedPath} />
          <ListItemLink onClick={onClose} title='Deployed Detections' to={deployedPath} />
        </>
      )}
      <ListItemLink onClick={onClose} title='Detection Library' to={viewPath} />
      <Can I={FunctionalPermission.CreateAnalyticJob}>
        <Can.Grant>
          <ListItemLink
            aria-label='Go to confidence tailoring'
            id='MenuItemConfidenceTailoring'
            onClick={onClose}
            title='Confidence Tailoring'
            to={Path.ConfidenceTailoring}
            testId='menu-confidence-tailoring'
          />
        </Can.Grant>
        <Can.Block>
          <ListItemButton
            title='Confidence Tailoring'
            disabled
            tooltip='You do not have permission to modify detection confidence'
          />
        </Can.Block>
      </Can>
      <Can I={FunctionalPermission.CreateAnalytic}>
        <Can.Grant>
          <ListItemLink
            aria-label='Go to create a new detection page'
            id='MenuItemAnalyticsCreate'
            onClick={onClose}
            title='Create a New Detection'
            to={Path.IDEReset}
          />
        </Can.Grant>
        <Can.Block>
          <ListItemButton
            disabled
            title='Create a New Detection'
            tooltip={
              isSubscriber ? (
                'You do not have permission to create a new analytic.'
              ) : (
                <>
                  Creating detections is currently restricted to vetted contributors.{' '}
                  <ExternalLink href='https://www.snapattack.com/community' target='_blank' rel='noopener noreferrer'>
                    Request contributor access.
                  </ExternalLink>
                </>
              )
            }
          />
        </Can.Block>
      </Can>
    </>
  );
}
