import React from 'react';

import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import partition from 'lodash/partition';

import Button, { ActionIconButton, RouterButton } from 'snap-ui/Button';
import Chip from 'snap-ui/Chip';
import { SimpleNoRowsOverlay } from 'snap-ui/DataGrid';
import Divider from 'snap-ui/Divider';
import Fade from 'snap-ui/Fade';
import Placeholder, { PlaceholderProps } from 'snap-ui/Placeholder';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import VerticalTransferList from 'snap-ui/VerticalTransferList';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { buildCuratedFilter } from 'module/Curation/Curation.service';
import { JobType, huntInterfaceFactory } from 'module/Job';
import { Fallback, Suggest } from 'module/Util/Fallback';
import NotFound from 'module/Util/Fallback/NotFound';
import withFunctionalPermission from 'module/Util/withFunctionalPermission';
import EmptyState from 'module/Widgets/EmptyState';

import { useIntegrationCatalog } from 'provider';

import { parseAnalyticYaml } from 'services/analyticService';

import { Status } from 'storage';

import { ContentPermission, FunctionalPermission } from 'types/auth';
import { ArtifactType, Guid } from 'types/common';

import { useAnalyticCatalog } from '../core/AnalyticProvider';
import useAnalyticPermission from '../core/useAnalyticPermission';
import {
  Container,
  ExclusionsTable,
  NoResultsContainer,
  TABLE_WIDTH,
  Table,
  TableContainer,
  TransferListItem
} from './AnalyticTuning.style';
import { Aggregation, Bucket } from './AnalyticTuning.types';
import { AnalyticTuningHeader } from './AnalyticTuningHeader';
import { HitsChartProps } from './AnalyticTuningHeader/HitsChart';
import useTuningContext from './AnalyticTuningProvider';
import { COMMON_GRID_CONFIG, count, excludeAction, field, includeAction, percentage, value } from './Columns';
import useTuningHits, { useTuningConfig } from './useTuningHits';

const EXCLUDE_FIELD_THRESHOLD = 42;

const [HuntInterface, HuntButton, HuntDialog] = huntInterfaceFactory('HuntContext');

const AnalyticTuning = withFunctionalPermission(function AnalyticTuning() {
  useTitle('SnapAttack | Detection Tuning');
  const {
    fpr,
    dates,
    exclusions,
    setExclusions,
    exclusionsLoaded,
    setExclusionsLoaded,
    fieldType,
    integrationGuid,
    setIntegrationGuid
  } = useTuningContext();

  const [chosen, setChosen] = React.useState<string[]>([]);
  const [choices, setChoices] = React.useState<string[]>([]);
  const [excludedChoices, setExcludedChoices] = React.useState<string[]>([]);
  const { config, setConfig } = useTuningConfig();

  const [{ analytic, analyticError, analyticStatus, supplementalStatus }] = useAnalyticCatalog();
  const [canTuneThisDetection, noTuneReason] = useAnalyticPermission(FunctionalPermission.Tuning);

  const { status: integrationStatus } = useIntegrationCatalog();

  const { fields, values, getFields, getValues, fieldsStatus, valuesStatus, reset } = useTuningHits(analytic.guid);
  const {
    values: differences,
    valuesStatus: differencesStatus,
    getValues: getDifferences,
    reset: differencesReset
  } = useTuningHits(analytic.guid);

  const initialLoading =
    [analyticStatus, supplementalStatus, integrationStatus, fieldsStatus].includes(Status.pending) ||
    fieldsStatus === Status.idle;
  const isLoading = initialLoading || valuesStatus === Status.pending;

  const [hitsChartProps, setHitsChartProps] = React.useState<HitsChartProps>(null);

  React.useEffect(() => {
    if (differencesStatus !== Status.pending) {
      const hits = isEmpty(differences) ? fields?.total : differences?.total ?? 0;
      const reduction = isEmpty(differences) ? 0 : fields?.total - (differences?.total ?? 0);
      const percentReduction = differences?.total ? ((fields?.total - differences?.total) / fields?.total) * 100 : 0;

      setHitsChartProps({ hits, reduction, percentReduction });
    }
  }, [differences, differencesStatus, fields?.total]);

  const partitionedFields = React.useMemo(() => {
    return Object.entries(fields?.[fieldType] ?? {}).reduce(
      (partitioned, [field, { value }]) => {
        if (value > 1 && value <= EXCLUDE_FIELD_THRESHOLD) {
          return { ...partitioned, included: { ...partitioned.included, [field]: value } };
        } else {
          return { ...partitioned, excluded: { ...partitioned.excluded, [field]: value } };
        }
      },
      { included: {}, excluded: {} }
    );
  }, [fieldType, fields]);

  const fieldNames = React.useMemo(() => Object.keys(partitionedFields.included), [partitionedFields.included]);

  const existingFprFieldNames = React.useMemo(() => {
    let fields = [];
    if (fpr) {
      const [parsed, parseError] = parseAnalyticYaml(fpr.raw, []);
      if (!parseError && parsed?.detection) {
        fields = parsed.detection.sections.flatMap(t => t.rules.map(r => r.field));
      }
    }
    return fields;
  }, [fpr]);

  // This split of field names ensures that we only display fields
  // that are relevant to the detection hits we are receiving.
  const [relevantFieldNames, irrelevantFieldNames] = React.useMemo(() => {
    return partition(config, c => fieldNames.includes(c));
  }, [config, fieldNames]);

  const chosenSortedString = React.useMemo(() => chosen.slice().sort().join(''), [chosen]);
  const chosenSorted = React.useMemo(() => chosen.slice().sort(), [chosenSortedString]); // eslint-disable-line

  React.useEffect(() => {
    if (isEmpty(chosen) && isEmpty(choices) && !isEmpty(fieldNames)) {
      const [initialChosen, initialChoices] = partition(fieldNames, f => relevantFieldNames.includes(f));

      setChoices(initialChoices.sort());
      setChosen(initialChosen);
      setExcludedChoices(Object.keys(partitionedFields.excluded));
    }
  }, [choices, chosen, config, fieldNames, partitionedFields, relevantFieldNames]);

  // Update tuning field configuration when fields are added/removed
  React.useEffect(() => {
    if (!isEmpty(chosen)) {
      const newConfig = [...new Set([...chosen, ...irrelevantFieldNames])];
      if (!isEqual(config, newConfig)) {
        setConfig(newConfig);
      }
    }
  }, [chosen, config, integrationGuid, irrelevantFieldNames, setConfig]);

  React.useEffect(() => {
    if (!isEmpty(dates) && integrationGuid) {
      getFields(dates, integrationGuid);
    }
  }, [dates, getFields, integrationGuid]);

  React.useEffect(() => {
    if (!isEmpty(dates) && !isEmpty(chosenSorted) && integrationGuid) {
      getValues(dates, integrationGuid, [...new Set([...chosenSorted, ...existingFprFieldNames])], fieldType);
    }
  }, [chosenSorted, dates, existingFprFieldNames, fieldType, getValues, integrationGuid]);

  React.useEffect(() => {
    if (integrationGuid && !isEmpty(chosenSorted) && !isEmpty(exclusions)) {
      getDifferences(
        dates,
        integrationGuid,
        [...new Set([...chosenSorted, ...existingFprFieldNames])],
        fieldType,
        exclusions
      );
    }
  }, [chosenSorted, dates, exclusions, existingFprFieldNames, fieldType, getDifferences, integrationGuid]);

  const [dataSource, setDataSource] = React.useState<Record<string, Aggregation>>({});

  React.useEffect(() => {
    const dataSource = isEmpty(differences) ? values?.[fieldType] : differences?.[fieldType];

    if (isLoading || differencesStatus === Status.pending) {
      //do nothing
    } else {
      setDataSource(dataSource || {});
    }
  }, [differences, differencesStatus, fieldType, isLoading, values]);

  React.useEffect(() => {
    if (fpr && !isEmpty(dataSource) && !exclusionsLoaded) {
      const [parsed, parseError] = parseAnalyticYaml(fpr.raw, []);
      if (!parseError && parsed?.detection) {
        const reduced = parsed.detection.sections
          ?.flatMap(t => t.rules)
          .reduce(
            (exclusions, rule) => ({
              ...exclusions,
              [rule.field]: rule.values.map(v => {
                const bucket = dataSource[rule.field]?.buckets?.find(b => b.key === v);
                return bucket ? bucket : { key: v, doc_count: 0 };
              })
            }),
            {}
          );

        if (!isEmpty(reduced)) {
          setExclusionsLoaded(true);
          setExclusions(reduced);
        }
      }
    }
  }, [dataSource, exclusionsLoaded, fpr, setExclusions, setExclusionsLoaded]);

  function toggleExclude(field: string) {
    return function (value: Bucket) {
      setExclusions(exclusions => {
        if (field in exclusions) {
          const excluded = exclusions[field];
          if (excluded.some(e => e.key === value.key)) {
            //removing value
            return { ...exclusions, [field]: excluded.filter(e => e.key !== value.key) };
          } else {
            // adding value
            return { ...exclusions, [field]: [...exclusions[field], value] };
          }
        } else {
          return { ...exclusions, [field]: [value] };
        }
      });
    };
  }

  const handleRemoveField = (field: string) => {
    setChosen(chosen => chosen.filter(r => r !== field));
    setChoices(choices => [...choices, field]);
  };

  function handleIntegrationChange(value: Guid): void {
    reset();
    differencesReset();
    setChosen([]);
    setChoices([]);
    setIntegrationGuid(value);
  }

  if (!canTuneThisDetection)
    return <Fallback action={<Suggest artifact={ArtifactType.Analytic} />} message={noTuneReason} />;
  if (analyticError) return <NotFound artifact={ArtifactType.Analytic} error={analyticError} />;

  return (
    <Container>
      <AnalyticTuningHeader
        onIntegrationChange={handleIntegrationChange}
        tuningDisabled={isLoading}
        skipPreview={isEmpty(dataSource) || isEmpty(Object.values(exclusions || {}).flat())}
        HitsChartProps={hitsChartProps}
      />

      <Fade in={initialLoading} timeout={{ enter: 1000, exit: 0 }}>
        <DashboardPlaceholder variant='rectangular' height={400} width={TABLE_WIDTH} />
      </Fade>
      <Fade in={!initialLoading && isEmpty(fieldNames)} timeout={{ enter: 1000, exit: 200 }}>
        <HuntInterface jobType={JobType.Hunt}>
          <NoResults />
          <HuntDialog
            selectedIntegrations={integrationGuid ? [integrationGuid] : []}
            query={buildCuratedFilter([analytic.guid])}
            jobName={analytic.name}
          />
        </HuntInterface>
      </Fade>

      <Fade in={!initialLoading && !isEmpty(fieldNames)} timeout={{ enter: 1000, exit: 200 }}>
        <div className='row'>
          <VerticalTransferList
            top={chosen}
            setTop={setChosen}
            bottom={choices}
            setBottom={setChoices}
            excluded={excludedChoices}
            excludedAddable
            renderItem={(item: string) => {
              const count = fields?.[fieldType]?.[item]?.value;
              return (
                <TransferListItem>
                  <Chip label={count} size='small' />
                  <span>{item}</span>
                </TransferListItem>
              );
            }}
            addAriaLabel='add field for tuning'
            removeAriaLabel='remove field for tuning'
          />
          <div className='column'>
            <div className='dashboard'>
              <TableContainer className='span-columns'>
                <Typography variant='h3'>Exclusions</Typography>
                <ExclusionsTable
                  {...COMMON_GRID_CONFIG}
                  density='compact'
                  columns={[field, value, count, includeAction(toggleExclude)]}
                  rows={Object.entries(exclusions).flatMap(([field, values]) => {
                    return values.map(value => ({ field, ...value }));
                  })}
                  slots={{
                    noRowsOverlay: () => (
                      <SimpleNoRowsOverlay>Exclusions selected below will display here.</SimpleNoRowsOverlay>
                    )
                  }}
                />
              </TableContainer>
            </div>

            <Divider />
            {isEmpty(chosen) ? (
              <div className='column'>
                <div className='dashboard'>
                  <EmptyState className='span-columns' title='Ready to tune?'>
                    Choose some fields to get started.
                  </EmptyState>
                </div>
              </div>
            ) : (
              <div className='dashboard'>
                {chosen.map(field => {
                  if (isEmpty(dataSource)) return null;
                  const details = dataSource[field];
                  if (isEmpty(details)) return null;
                  const docTotal = details.buckets.reduce(
                    (total, bucket) => (total += bucket.doc_count),
                    details.sum_other_doc_count
                  );
                  const hitsTotal = details.buckets.reduce((total, bucket) => {
                    const isExcluded = (exclusions[field] || []).some(e => e.key === bucket.key);
                    return (total += isExcluded ? 0 : bucket.doc_count);
                  }, details.sum_other_doc_count);
                  return (
                    <Fade key={field} in timeout={1000}>
                      <TableContainer key={field}>
                        <div className='header'>
                          <Typography className='field-name' variant='h3'>
                            <Chip label={hitsTotal} size='small' />
                            <span>{field}</span>
                          </Typography>

                          <Tooltip arrow title='remove field for tuning'>
                            <ActionIconButton
                              className='remove-table-button'
                              aria-label='remove field for tuning'
                              icon={faMinusCircle}
                              onClick={() => handleRemoveField(field)}
                            />
                          </Tooltip>
                        </div>
                        <Table
                          {...COMMON_GRID_CONFIG}
                          className='data-grid'
                          density='compact'
                          columns={[
                            value,
                            count,
                            percentage(docTotal),
                            excludeAction(exclusions[field], toggleExclude(field))
                          ]}
                          rows={details.buckets}
                        />
                      </TableContainer>
                    </Fade>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Fade>
    </Container>
  );
}, FunctionalPermission.Tuning);

export default AnalyticTuning;

function DashboardPlaceholder(props: PlaceholderProps): JSX.Element {
  return (
    <div className='row'>
      <Placeholder {...props} width={350} height={820} />
      <div className='column'>
        <div className='dashboard'>
          <Placeholder {...props} className='span-columns' width='100%' height={260} />
          <Placeholder {...props} />
          <Placeholder {...props} />
          <Placeholder {...props} />
          <Placeholder {...props} />
          <Placeholder {...props} />
          <Placeholder {...props} />
        </div>
      </div>
    </div>
  );
}

function NoResults(): JSX.Element {
  const [{ analytic }] = useAnalyticCatalog();

  const canEdit = useAnalyticPermission(ContentPermission.Edit);

  return (
    <NoResultsContainer>
      <EmptyState title='No Results'>
        <div className='column'>
          Tuning requires detection hits from your SIEM/EDR. Run a hunt for this detection to bring hits into
          SnapAttack.
          <div className='row'>
            {canEdit && (
              <RouterButton to={`${Path.IDE}?detection=${analytic.guid}`} variant='outlined'>
                Edit Detection
              </RouterButton>
            )}
            <HuntButton>
              {({ onClick, disabled }) => (
                <Button onClick={onClick} disabled={disabled}>
                  Hunt
                </Button>
              )}
            </HuntButton>
          </div>
        </div>
      </EmptyState>
    </NoResultsContainer>
  );
}
