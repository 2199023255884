import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { Discriminator, TagGroup } from 'module/Tag';

import { Helper, Status } from 'storage';

import { Permitted } from 'types/auth';
import { ArtifactType, Guid, Named, Timestamp, Tracked } from 'types/common';
import { Query } from 'types/filter';

export const CollectionDiscriminator = {
  Dynamic: 'Dynamic',
  Static: 'Static',
  Hybrid: 'Hybrid',
  ...omit(Discriminator, 'Action', 'DataSource')
} as const;

export type CollectionDiscriminator = (typeof CollectionDiscriminator)[keyof typeof CollectionDiscriminator];

export const FilterableCollections = {
  ...pick(CollectionDiscriminator, CollectionDiscriminator.Static, 'Actor', 'Attack', 'Software', 'Vulnerability')
} as const;

export type FilterableCollections = (typeof FilterableCollections)[keyof typeof FilterableCollections];

export interface Collection extends Named, Tracked, TagGroup, Permitted {
  id: number;
  short_id: string;
  type: CollectionDiscriminator;
  updated_by: Named;
  created_by: Named;
  creation: Timestamp;
  modified: Timestamp;
  small_image: string;
  large_image: string;
  organization: Named &
    Tracked & {
      id: number;
      small_image: string;
      large_image: string;
      abbreviation: string;
    };
  description: string;
  references: string[];
  analytic_filter?: Query;
  bas_script_filter?: Query;
  session_filter?: Query;
  threat_intelligence_filter?: Query;
}

export type CollectionRequest = { organization_id: number } & TagGroup &
  Pick<Collection, 'description' | 'name' | 'type'> &
  Partial<
    Pick<
      Collection,
      'small_image' | 'large_image' | 'analytic_filter' | 'session_filter' | 'threat_intelligence_filter'
    >
  >;

export type CollectionAsync = {
  collection: Collection;
  isPreview: boolean;
  status: Status;
  error: any;
  refresh(): void;
  setData: Helper<Collection>['setData'];
};

export const BulkCollectionField = {
  [ArtifactType.Intel]: 'threat_intelligences',
  [ArtifactType.Session]: 'sessions',
  [ArtifactType.Analytic]: 'analytics',
  [ArtifactType.AttackScript]: 'bas_scripts'
} as const;

export type BulkUpdateCollectionField = {
  [BulkCollectionField.intelligence]: Guid[];
  [BulkCollectionField.threat]: Guid[];
  [BulkCollectionField.detection]: Guid[];
  [BulkCollectionField.attack_script]: Guid[];
};

export type BulkCollectionField = (typeof BulkCollectionField)[keyof typeof BulkCollectionField];

export type AssociatedCollections = { name: string; guid: Guid; id: number };
