import React from 'react';

import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

const StepContent = styled('div', { name: 'StepContent' })`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};
  padding: ${p => p.theme.spacing(5)};
  flex-grow: 1;
  overflow: auto;

  .Divider {
    font-weight: bold;
    ::before,
    ::after {
      border-width: 2px;
      border-color: ${p => p.theme.palette.primary.main};
    }
  }
`;

export default function SecurityProfileStep({
  className,
  title,
  description,
  content
}: {
  className?: string;
  title: string;
  description: string;
  content?: JSX.Element;
}) {
  return (
    <StepContent className={className}>
      <Typography variant='h3'>{title}</Typography>
      <p>{description}</p>
      {content}
    </StepContent>
  );
}
