import React from 'react';

import Tooltip from 'snap-ui/Tooltip';

import { renderValueWithLabel } from 'utilities/TextUtils';

import { CampaignResultStatus } from '../BAS.type';

type CampaignAttackCountProps = {
  result_status: CampaignResultStatus;
};

export default function CampaignAttackCount({ result_status }: CampaignAttackCountProps): JSX.Element {
  const count = Object.values(result_status).reduce((sum, result) => (sum += result), 0);
  return (
    <Tooltip arrow placement='top' title={`${renderValueWithLabel(count, 'attack')} in the attack simulation.`}>
      <span>{count}</span>
    </Tooltip>
  );
}
