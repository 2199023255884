import React from 'react';

import FormLabel from 'snap-ui/FormLabel';
import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

import RadioGroupFormik from 'module/Form/RadioGroupFormik';
import SliderFormik from 'module/Form/SliderFormik';

import { ExploitationStateOrderedList } from './SecurityProfile.type';
import SecurityProfileStep from './SecurityProfileStep';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(5)};
`;

const PaddedPaper = styled(Paper)`
  padding: ${p => p.theme.spacing(7)};
`;

const Row = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(5)};

  & > div,
  .RadioGroup {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }

  .exploited {
    width: 160px;
  }
`;

const marks = ExploitationStateOrderedList.map((label, index) => ({
  value: index,
  label
}));

function valuetext(value: number) {
  return marks[value].label;
}
export default function SecurityProfileStepVulnerabilityCharacteristics(): JSX.Element {
  return (
    <SecurityProfileStep
      title='What vulnerability characteristics are you most concerned with?'
      description='Vulnerability characteristics encompass a range of attributes, including their exploitability in the wild or classification as zero-day vulnerabilities. Zero-day vulnerabilities are particularly concerning as they are unknown to the vendor and have no available patch, making them highly attractive targets for threat actors who can exploit them before the issue is addressed, underscoring the need for proactive security measures and rapid response strategies to mitigate these risks effectively.'
      content={
        <Container>
          <PaddedPaper elevation={8}>
            <FormLabel>Exploitation State</FormLabel>
            <SliderFormik
              name='exploitation_state'
              fixedBoundary='right'
              className='ExploitationSlider'
              step={null}
              marks={marks}
              min={0}
              max={marks.length - 1}
              valueLabelDisplay='auto'
              valueLabelFormat={valuetext}
            />
          </PaddedPaper>
          <Row>
            <PaddedPaper>
              <RadioGroupFormik
                className='exploited'
                name='exploited_zero_day'
                label='Exploited as a Zero Day'
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' }
                ]}
                row
              />
            </PaddedPaper>
            <PaddedPaper>
              <RadioGroupFormik
                className='exploited'
                name='exploited_in_wild'
                label='Exploited in the Wild'
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' }
                ]}
                row
              />
            </PaddedPaper>
          </Row>
        </Container>
      }
    />
  );
}
