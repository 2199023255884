import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';

import {
  create as createAnalytic,
  erase as eraseAnalytic,
  publish as publishAnalytic,
  save as saveAnalytic
} from 'apis/resources/analytic';

import Path from 'constants/paths';

import useLocationState from 'hooks/useLocationState';

import { Engage, Fingerprint } from 'lib/Engagement';

import { AnalyticEditRouterState } from 'module/Analytic/Analytic.type';
import { buildAnalyticPayload } from 'module/Analytic/Analytic.util';
import { IDEAction, IDEState } from 'module/IDE/reducer';
import { transformWithSchema, useMetadata } from 'module/Metadata';
import { SupplementalArtifact } from 'module/Search';
import { SigmaTag } from 'module/Tag';

import { useAuth, usePushSnack } from 'provider';

import { ErrorProps, Status, useAsync } from 'storage';

import { Analytic, isNativeAnalyticCreate } from 'types/analytic';
import { ContentPermission, FunctionalPermission, Permitted } from 'types/auth';
import { Artifact, ArtifactType, Guid, Tracked } from 'types/common';

import useAnalytic from './useAnalytic';

export interface AnalyticEditorState {
  analytic?: Analytic;
  supplemental: SupplementalArtifact;
  permissions: Permitted;
  fetchStatus: Status;
  fetchError?: any;
  save(fprOnly?: boolean, waitForCompilation?: boolean): Promise<Tracked>;
  saveStatus: Status;
  saveError?: ErrorProps;
  publish(): Promise<void>;
  publishStatus: Status;
  publishError?: ErrorProps;
  erase(): Promise<void>;
  eraseStatus: Status;
  eraseError?: ErrorProps;
}

export default function useAnalyticEditor(
  ideState: IDEState,
  dispatch: (action: IDEAction) => void,
  tagOptions: SigmaTag[],
  guid?: Guid
): AnalyticEditorState {
  const { user } = useAuth();
  const pushSnack = usePushSnack();
  const { replace, location } = useHistory();

  const { status: saveStatus, errorProps: saveError, task: updateTask } = useAsync<Analytic>(null);
  const { status: publishStatus, errorProps: publishError, task: publishTask } = useAsync();
  const { status: eraseStatus, errorProps: eraseError, task: eraseTask } = useAsync();

  const [analytic, setAnalytic] = React.useState<Analytic>();

  const { editor, item, supplementalItem, referenceItem, permissionItem } =
    useLocationState<AnalyticEditRouterState>() || {};

  React.useEffect(() => {
    if (editor && !ideState.refreshKey) {
      dispatch({ type: 'InitWithState', state: editor });
    }
  }, [dispatch, editor, ideState.refreshKey, replace, location]);

  const {
    analytic: analyticData,
    updateAnalytic,
    analyticStatus,
    analyticError,
    supplemental,
    supplementalStatus,
    updateSupplemental,
    references,
    updateReferences,
    referencesStatus,
    permissions,
    permissionsStatus
  } = useAnalytic(editor ? undefined : guid, item, supplementalItem, referenceItem, permissionItem, tagOptions);

  const { data: metadata, formUpdate: metadataUpdate } = useMetadata(ArtifactType.Analytic, guid);

  const isLoading = React.useMemo(() => {
    return [analyticStatus, supplementalStatus, referencesStatus, permissionsStatus].some(
      status => status === Status.pending
    );
  }, [analyticStatus, supplementalStatus, referencesStatus, permissionsStatus]);

  const compositeAnalytic = React.useMemo(() => {
    if (!isLoading && analyticData.raw) {
      return {
        ...analyticData,
        ...supplemental,
        ...references
      };
    } else {
      return undefined; // explicit return for us humans
    }
  }, [analyticData, supplemental, references, isLoading]);

  // set the analytic after the initial fetch
  const compositeAnalyticString = JSON.stringify(compositeAnalytic);

  React.useEffect(() => {
    if (compositeAnalytic) {
      setAnalytic(compositeAnalytic);
      if (!editor) {
        dispatch({ type: 'LoadAnalyticUpdate', analytic: compositeAnalytic, tagOptions });
      }
    }
  }, [compositeAnalyticString, editor, tagOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!isEmpty(metadata?.meta)) {
      dispatch({ type: 'MetadataUpdate', metadata });
    }
  }, [JSON.stringify(metadata)]); // eslint-disable-line react-hooks/exhaustive-deps

  const analyticFormString = JSON.stringify(ideState.analyticForm);
  const metadataFormString = JSON.stringify(ideState.metadata.value);
  const userString = JSON.stringify(user);
  const save = React.useCallback(
    (waitForCompilation?: boolean) => {
      const payload = buildAnalyticPayload(ideState, user.name);

      if (guid) {
        Engage.track(
          Fingerprint.of(Path.IDE)
            .withContent(ContentPermission.Edit)
            .withData({ guid, ...payload })
        );

        saveMetadata();

        return updateTask(saveAnalytic(guid, payload, waitForCompilation)).then(newAnalytic => {
          completeSave(newAnalytic);
          return newAnalytic;
        });
      }

      Engage.track(
        Fingerprint.of(Path.IDE)
          .withFunctional(FunctionalPermission.CreateAnalytic)
          .withData({ guid, ...payload })
      );

      return updateTask(createAnalytic(payload, waitForCompilation)).then(newAnalytic => {
        pushSnack('Created', 'info', 'center', 'bottom', 5000);
        replace({ search: `?detection=${newAnalytic.guid}` });

        saveMetadata(newAnalytic.guid);

        return newAnalytic;
      });

      function saveMetadata(guid?: Guid) {
        if (!isEmpty(ideState.metadata.value))
          metadataUpdate(transformWithSchema(ideState.metadata.value, ideState.metadata.activeMeta), guid);
      }

      function completeSave(analytic: Artifact) {
        pushSnack('Saved', 'info', 'center', 'bottom', 5000);
        updateAnalytic(analytic);
        dispatch({ type: 'ResetBaseState' });
        if (isNativeAnalyticCreate(payload)) {
          updateReferences({ ...references, references: payload.references });
        }
        updateSupplemental({ ...supplemental, pinned_events: ideState.pinned_events });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      analyticFormString,
      guid,
      ideState._customization_raw,
      ideState.isNative,
      ideState.pinned_events,
      ideState.raw,
      metadataFormString,
      userString
    ]
  );

  const publish = React.useCallback(() => {
    if (ideState.isModified) {
      return save().then(analytic => {
        return publishTask(publishAnalytic(analytic.guid));
      });
    } else if (guid) {
      return publishTask(publishAnalytic(guid));
    } else {
      return publishTask(Promise.reject('Cant publish null detection'));
    }
  }, [save, publishTask, ideState.isModified, guid]);

  const erase = React.useCallback(() => {
    if (!guid) return Promise.reject();
    return eraseTask(eraseAnalytic(guid));
  }, [guid, eraseTask]);

  return {
    analytic,
    supplemental,
    permissions,
    fetchStatus: isLoading ? Status.pending : Status.idle,
    fetchError: analyticError?.response?.data,
    save,
    saveStatus,
    saveError,
    publish,
    publishStatus,
    publishError,
    erase,
    eraseStatus,
    eraseError
  };
}
