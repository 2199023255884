import React from 'react';

import { getAnalyticAttackScripts, getSessionAttackScripts } from 'module/AttackScript/AttackScript.service';
import { AttackScript } from 'module/AttackScript/AttackScript.type';

import { Status, useAsync } from 'storage';

import { ArtifactType, Guid } from 'types/common';

export type ScriptsAsyncInterface = {
  data: AttackScript[];
  status: Status;
  setScripts(scripts: AttackScript[]): void;
};

function useScripts(guid: Guid, type: ArtifactType.Session | ArtifactType.Analytic): ScriptsAsyncInterface {
  const { data, run, status, setData: setScripts } = useAsync<AttackScript[]>([]);

  React.useEffect(() => {
    if (guid) {
      if (type === ArtifactType.Session) {
        run(getSessionAttackScripts(guid));
      } else if (type === ArtifactType.Analytic) {
        run(getAnalyticAttackScripts(guid));
      }
    }
  }, [guid, run, type]);

  return {
    data,
    status,
    setScripts
  };
}

export default useScripts;
