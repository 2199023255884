import React from 'react';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import { styled } from 'snap-ui/util';

import { useFeedExtra } from 'provider';

import { Status } from 'storage';

import { Artifact, ArtifactType, Guid } from 'types/common';

import { handleDebug } from 'utilities/FunctionUtils';

import { buildLinkWithState, cleanHTML } from './Card.util';
import {
  CardBadges,
  CardCounter,
  CardDescription,
  CardHeader,
  CardImage,
  CardTags,
  LockedOverlay
} from './Card.widgets';

const Container = styled('div')`
  position: relative;
  border: 1px solid transparent;

  padding: ${p => p.theme.spacing(5)};
  background: ${p => p.theme.palette.secondary.main};
  color: ${p => p.theme.palette.common.white};

  display: grid;
  grid-template-columns: 90px 1fr 1fr;
  grid-template-rows: repeat(6, min-content);
  column-gap: ${p => p.theme.spacing(3)};
  row-gap: ${p => p.theme.spacing(2)};
  align-items: center;
  isolation: isolate;

  :hover {
    border-color: ${p => p.theme.palette.grey[200]};
  }

  :hover .LockedOverlayContainer {
    opacity: 1;

    svg {
      opacity: 0.8;
    }
  }

  .CardHeader {
    grid-column: 1 / span 3;
    display: flex;
    justify-content: space-between;
    min-height: 26px;

    h5 {
      display: flex;
      align-items: center;
      gap: ${p => p.theme.spacing(1)};
      color: ${p => p.theme.palette.grey[400]};
    }
  }

  .CardState {
    min-height: 25px;
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(1)};
    z-index: 1;
  }

  .CardImage {
    grid-area: 2 / 1 / span 3 / auto;
    display: flex;
    width: 90px;
    height: 90px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .CardImageIntel {
    grid-row: 2 / span 2;
    grid-column: 1;
    display: flex;
    width: 90px;
    height: 90px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .CardBadges {
    grid-column: 2 / span 2;
    display: flex;
    gap: ${p => p.theme.spacing(1)};
    overflow: hidden;

    & > * {
      height: 1.5rem;
    }

    .SimpleBadge {
      svg {
        height: ${p => p.theme.palette.dimension.svg};
      }

      .SimpleBadge-value {
        font-size: 0.75rem; /* Taken from components > Widgets > Badge */
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }

  .CardTitle {
    grid-column: 2 / span 2;
    font-size: x-large;
    line-height: 1;

    span {
      color: ${p => p.theme.palette.primary.main};
    }

    a {
      display: block;
      color: ${p => p.theme.palette.common.white};
      padding: ${p => p.theme.spacing(1, 0)};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .CardSubsection {
    grid-column: 1 / span 3;
  }

  .CardDescription {
    grid-column: 2 / span 2;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    span {
      color: ${p => p.theme.palette.primary.main};
    }
  }

  .CardTagsContainer {
    grid-column: 1 / span 3;
  }

  .CardCounter {
    display: flex;
    width: max-content;
    gap: ${p => p.theme.spacing(6)};
    white-space: nowrap;
    grid-column: 1 / span 3;
  }

  .CardHeader {
    text-transform: capitalize;
  }

  .CardAliases {
    color: ${p => p.theme.palette.grey[400]};
    font-size: 1rem;
    line-height: 1;

    span {
      color: ${p => p.theme.palette.primary.main};
    }
  }

  .logsource {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export type CardProps = {
  className?: string;
  item: Artifact;
  topic: ArtifactType;
  onDelete?(id: Guid): void;
};

export default function Card({ className, item, onDelete, topic }: CardProps) {
  const { detectionCatalog, supplementalCatalog, supplementalPending } = useFeedExtra();
  const detection = detectionCatalog[item.guid];
  const supplemental = supplementalCatalog[item.guid];
  const preview = item?.preview;
  let name = isEmpty(item.name_highlights) ? item.name : item.name_highlights.slice().shift();
  name = cleanHTML(name);

  const handleMouseEvent = (e: React.MouseEvent<HTMLDivElement>) =>
    handleDebug(e, () => {
      console.groupCollapsed(item.guid);
      console.info('item', item);
      console.info('detection', detection);
      console.info('supplemental', supplemental);
      console.groupEnd();
    });

  return (
    <Container className={classNames('Card', className)} onMouseEnter={handleMouseEvent}>
      <CardHeader item={item} supplemental={supplemental} status={Status.resolved} topic={topic} onDelete={onDelete} />
      <CardImage item={item} topic={topic} />
      <CardBadges
        item={item}
        supplemental={supplemental}
        supplementalPending={supplementalPending}
        preview={preview}
        topic={topic}
        count={detection}
      />
      {preview ? (
        <span aria-label={item.name} className='CardTitle' dangerouslySetInnerHTML={{ __html: name }} />
      ) : (
        <div className='CardTitle'>
          <Link
            aria-label={item.name}
            to={buildLinkWithState(topic, item, supplemental, detection)}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        </div>
      )}

      <CardDescription item={item} />
      <CardTags item={item} />
      {!preview && <CardCounter detection={detection} item={item} supplemental={supplemental} topic={topic} />}
      {preview && <LockedOverlay />}
    </Container>
  );
}
