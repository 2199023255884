import React from 'react';

import sum from 'lodash/sum';

import { ErrorProps } from 'module/ApiError';
import { getHuntJobOverview, useJobGroups } from 'module/Job';

import { useAsync, Status } from 'storage';

import { Guid } from 'types/common';

import { HuntGroup, HuntOverview } from './Hunt.type';

type HuntListInterface = {
  errorProps?: ErrorProps;
  getHunt(guid: Guid): HuntGroup;
  hunts: HuntGroup[];
  isPending: boolean;
  refresh(): void;
};

const HuntListContext = React.createContext<HuntListInterface>(null);
HuntListContext.displayName = 'HuntListContext';

function _useHuntList(): HuntListInterface {
  const { data: groups, errorProps: groupErrorProps, status: groupStatus } = useJobGroups();
  const { data: jobs, errorProps, run, status } = useAsync<HuntOverview[]>([]);

  const refresh = React.useCallback(() => {
    run(getHuntJobOverview());
  }, [run]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  /* map group guids to job guids for more efficient group lookups */
  const groupGuidsByJobGuid = React.useMemo(
    () =>
      groups.reduce((groupMap, group) => {
        group.jobs.forEach(job => {
          groupMap[job.guid] = group.guid;
        });
        return groupMap;
      }, {}),
    [groups]
  );

  /*
   * huntGroupGuids: filtered list of group guids that are associated with Hunt jobs
   * jobsByGuid: hunt jobs mapped to their guids for more efficient lookups
   */
  const [huntGroupGuids, jobsByGuid] = React.useMemo(() => {
    const huntGroupGuids = new Set<Guid>();
    const jobsByGuid: Record<Guid, HuntOverview> = {};

    jobs.forEach(job => {
      huntGroupGuids.add(groupGuidsByJobGuid[job.job_guid]);
      jobsByGuid[job.job_guid] = job;
    });

    return [huntGroupGuids, jobsByGuid];
  }, [groupGuidsByJobGuid, jobs]);

  /* filter groups to only those that are Hunts, and augment `jobs` property with data from Overview */
  const hunts = React.useMemo(() => {
    return groups
      .filter(group => huntGroupGuids.has(group.guid))
      .map(group => {
        const jobs = group.jobs.map(j => jobsByGuid[j.guid]).filter(Boolean);
        return {
          ...group,
          date: jobs[0]?.date,
          detections: Math.max(...jobs.map(job => job?.detections || 0)),
          hits: sum(jobs.map(job => job.hits)),
          jobs
        };
      });
  }, [groups, huntGroupGuids, jobsByGuid]);

  const getHunt = React.useCallback((guid: Guid) => hunts.find(hunt => hunt.guid === guid), [hunts]);

  return {
    errorProps: errorProps || groupErrorProps,
    getHunt,
    hunts,
    isPending: [status, groupStatus].includes(Status.pending),
    refresh
  };
}

export function HuntListProvider({ children }: { children: React.ReactChild }): JSX.Element {
  const hli = _useHuntList();
  return <HuntListContext.Provider value={hli}>{children}</HuntListContext.Provider>;
}

export default function useHuntList(): HuntListInterface {
  const context = React.useContext(HuntListContext);
  if (!context) throw new Error('useHuntList must be used inside HuntListContext');
  return context;
}
