import React from 'react';

import { Link } from 'react-router-dom';

import Path from 'constants/paths';

import { postAttackScriptSessions } from 'module/AttackScript/AttackScript.api';
import { getAttackScriptList } from 'module/AttackScript/AttackScript.service';
import { AttackScript } from 'module/AttackScript/AttackScript.type';
import { ScriptsAsyncInterface } from 'module/BAS/Launcher/useScripts';
import { useFilterRegistry } from 'module/GlobalFilter';
import AssociateArtifactsModal, { Message } from 'module/Widgets/AssociateArtifactsModal';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { ArtifactType, Guid, IconButtonRenderProps } from 'types/common';

const IDLE_MESSAGE: Message = {
  data: <div key='info'>Link an Attack Script to this Threat.</div>,
  severity: 'info'
};

const BUSY_MESSAGE: Message = {
  data: <div key='warning'>Handling your request...</div>,
  severity: 'warning'
};

const EMPTY_MESSAGE: Message = {
  data: <div key='warning'>There are no Attack Scripts you can link to this Threat.</div>,
  severity: 'warning'
};

export type AddAttackScriptToSessionProps = IconButtonRenderProps & {
  guid?: Guid;
  onClose: () => void;
  isOpen: boolean;
  scripts: ScriptsAsyncInterface;
};

export default function AddAttackScriptToSession({ guid, onClose, isOpen, scripts }: AddAttackScriptToSessionProps) {
  const {
    user: { id }
  } = useAuth();
  const { generateQuery } = useFilterRegistry();

  const [isActing, setIsActing] = React.useState(false);
  const [action, setAction] = React.useState<boolean>();
  const [message, setMessage] = React.useState<Message>(IDLE_MESSAGE);
  const [selectedAttackScript, setSelectedAttackScript] = React.useState<Partial<AttackScript>>();
  const { data: allScripts, status, run, reset } = useAsync<AttackScript[]>([]);

  const isPending = status === Status.pending || scripts.status === Status.pending;

  const refresh = React.useCallback(
    (searchTerm?: string) => {
      const query = searchTerm ? generateQuery(ArtifactType.AttackScript, { query: searchTerm }) : undefined;
      if (id) run(getAttackScriptList(query), false);
    },
    [generateQuery, id, run]
  );

  React.useEffect(() => {
    if (status === Status.resolved && allScripts.length === 0) setMessage(EMPTY_MESSAGE);
    else setMessage(IDLE_MESSAGE);
  }, [allScripts, status]);

  const handleToggleClick = React.useCallback(
    async (attackScript: AttackScript, modifier: boolean) => {
      setAction(modifier);
      setSelectedAttackScript(attackScript);
      setIsActing(true);
      setMessage(BUSY_MESSAGE);
      let _message: Message = null;

      const updater = async () => {
        try {
          await postAttackScriptSessions(attackScript.guid, modifier ? 'add' : 'delete', [guid]);
          _message = {
            data: (
              <div key='success'>
                {`Your request to ${modifier ? 'add' : 'remove'} `}
                <strong>
                  <Link to={`${Path.AttackScript}/${attackScript.guid}`} target='_blank' rel='noopener noreferrer'>
                    {attackScript.name}
                  </Link>
                </strong>{' '}
                has been queued. Thanks!
              </div>
            ),
            severity: 'success'
          };
          if (modifier) {
            scripts.setScripts([...scripts.data, attackScript]);
          } else {
            scripts.setScripts(scripts.data.filter(a => a.guid !== attackScript.guid));
          }
        } catch (e) {
          _message = {
            data: (
              <div key='error'>
                {`Oh... Didn't expect that trying to ${modifier ? 'add' : 'remove'} `}
                <strong>{attackScript.name}.</strong> to this threat. {e.message}
              </div>
            ),
            severity: 'error'
          };
        }
      };

      await updater();
      setIsActing(false);
      setMessage(_message);
    },
    [guid, scripts]
  );

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      reset();
      setMessage(IDLE_MESSAGE);
      setSelectedAttackScript(null);
    }, 500);
  };

  return (
    <AssociateArtifactsModal
      onClose={handleClose}
      open={isOpen}
      message={message}
      title='Add Attack Script'
      refresh={refresh}
      isPending={isPending}
      options={allScripts}
      associated={scripts.data}
      isActing={isActing}
      action={action}
      selectedItem={selectedAttackScript}
      handleToggleClick={handleToggleClick}
      path={Path.AttackScript}
      searchedArtifactType={ArtifactType.AttackScript}
    />
  );
}
