import React from 'react';

import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons';

import { ActionIconButton } from 'snap-ui/Button';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { updateIntelDirect } from 'module/Intel/Intel.service';

import { usePushSnack } from 'provider';

import { Guid, Visibility } from 'types/common';

const Container = styled(Tooltip)`
  z-index: 1;
`;

type Props = {
  guid: Guid;
  title: string;
  setVisibility(string): void;
};

export default function CardPublishIntelButton({ guid, title, setVisibility }: Props) {
  const [disable, setDisable] = React.useState(false);
  const pushSnack = usePushSnack();
  function handleUpdateIntel() {
    setDisable(true);
    updateIntelDirect(guid, { visibility: Visibility.Published })
      .then(() => setVisibility(Visibility.Published))
      .then(() => pushSnack(<>Intelligence Published: {title}</>, 'info', 'center', 'bottom', 3000))
      .catch(() => pushSnack(<>Something Went Wrong</>, 'error', 'center', 'bottom', 3000));
  }

  return (
    <Container arrow placement='top' title={`Publish`}>
      <ActionIconButton
        aria-label={`Publish`}
        color='inherit'
        icon={faThumbsUp}
        onClick={handleUpdateIntel}
        disabled={disable}
      />
    </Container>
  );
}
