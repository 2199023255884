import React from 'react';

import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { useHistory } from 'react-router-dom';

import { Autocomplete, Option, getDisplayValue } from 'snap-ui/Autocomplete';
import Button from 'snap-ui/Button';
import Typography from 'snap-ui/Typography';

import Path from 'constants/paths';

import DateRangeSelector from 'module/DateRangeSelector';
import { BurgerCancel, BurgerLink, Menu } from 'module/Layout/Artifact.widgets';

import { useIntegrationCatalog } from 'provider';

import { Guid } from 'types/common';

import { useAnalyticCatalog } from '../../core/AnalyticProvider';
import { TuningPreviewButton, TuningPreviewDialog, TuningPreviewInterface } from '../AnalyticTuningPreview';
import useTuningContext, { INITIAL_PRESET } from '../AnalyticTuningProvider';
import { HeaderContainer } from './AnalyticTuningHeader.style';
import HitsChart, { HitsChartProps } from './HitsChart';

type AnalyticTuningHeaderProps = {
  onIntegrationChange: (value: Guid) => void;
  tuningDisabled: boolean;
  skipPreview: boolean;
  HitsChartProps: HitsChartProps;
};

function AnalyticTuningHeader({
  onIntegrationChange,
  tuningDisabled,
  skipPreview,
  HitsChartProps: hitsChartProps
}: AnalyticTuningHeaderProps) {
  const { push } = useHistory();
  const [{ analytic, supplemental }] = useAnalyticCatalog();
  const { integrationGuid, setIntegrationGuid, dates, setDates } = useTuningContext();

  const { integrations } = useIntegrationCatalog();
  const integrationOptions = React.useMemo(
    () =>
      uniqBy([...integrations.deployable, ...integrations.huntable], 'guid').map<Option>(integration => ({
        content: integration.name,
        label: integration.name,
        value: integration.guid
      })),
    [integrations.deployable, integrations.huntable]
  );

  React.useEffect(() => {
    const firstDeployment = get(supplemental, 'deployments.0.integrations.0.guid');

    if (!integrationGuid && !isEmpty(integrationOptions)) {
      setIntegrationGuid(firstDeployment ?? integrationOptions[0].value);
    }
  }, [integrationGuid, integrationOptions, setIntegrationGuid, supplemental]);

  return (
    <TuningPreviewInterface>
      <HeaderContainer>
        <div>
          <Typography variant='h1'>Detection Tuning</Typography>
          {analytic.name && <Typography variant='h2'>{analytic.name}</Typography>}
          <div className='input-controls'>
            <Autocomplete
              className='integration-selection'
              label='Integration'
              name='integration'
              onChange={onIntegrationChange}
              options={integrationOptions}
              value={getDisplayValue(integrationOptions, integrationGuid)}
              disabled={isEmpty(integrationOptions) || tuningDisabled}
              disableClearable
              disableUserAdditions
            />
            <DateRangeSelector
              disabled={tuningDisabled}
              dates={dates}
              onDateRangeChange={setDates}
              initialPreset={INITIAL_PRESET}
            />
          </div>
        </div>
        <div className='actions'>
          <div className='buttons'>
            {/* Send to fine tuning if no data / exclusions empty */}
            {skipPreview ? (
              <Button
                onClick={() => {
                  push(`${Path.Detection}/${analytic.guid}/fine_tuning`);
                }}
                disabled={tuningDisabled}
              >
                Review Logic
              </Button>
            ) : (
              <TuningPreviewButton disabled={tuningDisabled} />
            )}
            <Menu>
              <BurgerCancel
                to={{
                  pathname: `${Path.Detection}/${analytic.guid}/`
                }}
              />
              <BurgerLink
                icon={faFileAlt}
                title='View Event Logs'
                to={{
                  pathname: `${Path.Detection}/${analytic.guid}/tuning_raw`,
                  state: { dates }
                }}
              />
            </Menu>
          </div>

          <HitsChart {...hitsChartProps} />
        </div>
      </HeaderContainer>
      <TuningPreviewDialog />
    </TuningPreviewInterface>
  );
}

export default AnalyticTuningHeader;
