import React from 'react';

import isEmpty from 'lodash/isEmpty';

import {
  DataGrid,
  GRID_AGGREGATION_FUNCTIONS,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridValueGetterParams,
  gridStringOrNumberComparator,
  useGridApiRef,
  useKeepGroupedColumnsHidden
} from 'snap-ui/DataGrid';
import { getRowForSort } from 'snap-ui/DataGrid/DatGrid.helper';

import Path from 'constants/paths';

import { JobOverview, JobTypeTableMap, cancelJob, deleteJob, pauseJob, resumeJob } from 'module/Job';
import { getGridColumnsTask, getRowId } from 'module/Job/Job.util';
import { JobGridTreeDataGroupingCell, TaskTableToolbar } from 'module/Job/Job.widgets';
import {
  aggregateCreated,
  aggregateCreatedBy,
  aggregateIntegration,
  aggregateOrg,
  aggregateProgress,
  aggregateStatus,
  aggregateTimeRemaining,
  aggregateType,
  created_by,
  creation,
  detections,
  hits,
  integrationNames,
  jobGuid,
  jobName,
  jobsByGroup,
  modified,
  organization,
  progress,
  sumUp,
  taskActions,
  taskStatus,
  timeRemaining
} from 'module/Job/Task/Column';
import { useMayI } from 'module/May';

import { useAuth, useManagedOrganizations, useUserConfig } from 'provider';

import { FunctionalPermission } from 'types/auth';
import { Guid, Ident } from 'types/common';

import { HuntTableContainer } from '../Hunt.style';
import { HuntGroup } from '../Hunt.type';

type HuntTableProps = {
  hunts: HuntGroup[];
  isPending: boolean;
  refresh(): void;
  task(promise): Promise<void>;
};

const COLUMN_MODEL_HUNT_LIST_TABLE = 'huntJobOverview';

export default function HuntTable({ hunts, isPending, task, refresh }: HuntTableProps): JSX.Element {
  const { columnModel, setColumnModel } = useUserConfig();
  const { defaultOrgId, user } = useAuth();
  const isDetectionFeaturesUser = useMayI(FunctionalPermission.DetectionHuntFeatures);
  const orgId = user.superuser ? null : defaultOrgId;
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
    quickFilterValues: []
  });
  const apiRef = useGridApiRef();
  const { organizations } = useManagedOrganizations();
  const [orgFilter, setOrgFilter] = React.useState<Ident>(orgId);

  const filterJobs = hunts.filter((j: JobOverview) =>
    [JobTypeTableMap.Hunt, JobTypeTableMap.IOC].includes(j.type)
  ) as JobOverview[];

  const handleSetColumn = (value: Record<string, boolean>) =>
    setColumnModel({ ...columnModel, [COLUMN_MODEL_HUNT_LIST_TABLE]: value });

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['jobsByGroup']
      },
      filter: {
        filterModel: {
          items: []
        }
      },
      sorting: {
        sortModel: [{ field: 'modified', sort: 'desc' }]
      },
      aggregation: {
        model: {
          created_by: 'aggregateCreatedBy',
          type: 'aggregateType',
          integration_name: 'aggregateIntegration',
          job_status: 'aggregateStatus',
          progress_percent: 'aggregateProgress',
          detections_completed: 'sumUp',
          total_hits: 'sumUp',
          estimated_remaining_sec: 'aggregateTimeRemaining',
          creation: 'aggregateCreated',
          modified: 'aggregateCreated',
          organization_id: 'aggregateOrg'
        }
      }
    }
  });

  function onOrgChange(org: number) {
    setOrgFilter(org);
  }

  function onSearchChange(event) {
    setFilterModel(model => ({
      ...model,
      items: [
        {
          id: 1,
          field: 'name',
          operator: 'contains',
          value: event.target.value
        },
        {
          id: 2,
          field: 'integration_name',
          operator: 'contains',
          value: event.target.value
        }
      ],
      logicOperator: GridLogicOperator.Or
    }));
  }

  const deleteJobs = React.useCallback(
    (guid: Guid[]) => task(Promise.all(guid.map(g => deleteJob(g)))).then(refresh),
    [refresh, task]
  );

  const pause = React.useCallback(
    (guid: Guid[]) => task(Promise.all(guid.map(g => pauseJob(g)))).then(refresh),
    [refresh, task]
  );

  const resume = React.useCallback(
    (guid: Guid[]) => task(Promise.all(guid.map(g => resumeJob(g)))).then(refresh),
    [refresh, task]
  );

  const cancel = React.useCallback(
    (guid: Guid[]) => task(Promise.all(guid.map(g => cancelJob(g)))).then(refresh),
    [refresh, task]
  );

  return (
    <HuntTableContainer>
      <DataGrid
        apiRef={apiRef}
        loading={isPending}
        columns={[
          jobsByGroup,
          jobGuid(user.superuser),
          created_by,
          integrationNames,
          taskStatus(apiRef),
          progress,
          detections,
          hits,
          timeRemaining,
          creation,
          modified,
          organization(organizations),
          taskActions({ isDetectionFeaturesUser, deleteJobs, pause, resume, cancel, apiRef }),
          jobName
        ]}
        rows={filterJobs}
        groupingColDef={{
          headerName: 'Name',
          flex: 1,
          minWidth: 300,
          sortingOrder: ['asc', 'desc'],
          sortComparator: (v1, v2, param1, param2) => gridStringOrNumberComparator(v1, v2, param1, param2),
          valueGetter(params: GridValueGetterParams) {
            const row = getRowForSort<JobOverview>(apiRef.current, params);
            return row?.name;
          },
          renderCell: params => (
            <JobGridTreeDataGroupingCell {...params} idPath='analytic_job_group_guid' linkPath={Path.Hunt} />
          )
        }}
        initialState={initialState}
        getAggregationPosition={groupNode => (groupNode == null ? null : 'inline')}
        getRowId={getRowId}
        columnVisibilityModel={{
          creation: false,
          estimated_remaining_sec: false,
          progress_percent: false,
          analytic_job_guid: user.superuser ? true : false,
          organization_id: user.superuser ? true : false,
          ...(isEmpty(columnModel[COLUMN_MODEL_HUNT_LIST_TABLE])
            ? undefined
            : columnModel[COLUMN_MODEL_HUNT_LIST_TABLE]),
          jobsByGroup: false,
          name: false
        }}
        onColumnVisibilityModelChange={handleSetColumn}
        filterModel={filterModel}
        aggregationFunctions={{
          ...GRID_AGGREGATION_FUNCTIONS,
          aggregateType,
          aggregateIntegration,
          aggregateCreatedBy,
          aggregateStatus,
          aggregateProgress,
          aggregateTimeRemaining,
          aggregateCreated,
          sumUp,
          aggregateOrg
        }}
        onFilterModelChange={newModel => setFilterModel(newModel)}
        slots={{
          toolbar: TaskTableToolbar
        }}
        slotProps={{
          toolbar: { orgFilter, onSearchChange, onOrgChange, includeByType: false, includeExport: false },
          columnsPanel: {
            getTogglableColumns: (columns: GridColDef[]) => getGridColumnsTask(columns, user.superuser)
          }
        }}
      />
    </HuntTableContainer>
  );
}
