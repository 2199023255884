import React from 'react';

import { AttackNode } from 'module/Matrix/Matrix.type';
import useForest from 'module/Matrix/useForest';
import { SecurityProfileTag, useSecurityProfile } from 'module/SecurityProfile';

import { Status } from 'storage';

import { Ident } from 'types/common';

import { useCoverage, CoverageInterface } from '../';
import { AggregatedCoverage, TagCoverage } from '../type';
import { aggregateCoverage } from './util';

export type MitreCoverageInterface = {
  tactics: AttackNode[];
  techniques: AttackNode[];
  subtechniques: AttackNode[];
  isPending: boolean;
  getProfile(id: Ident): SecurityProfileTag;
  isProfilePending: boolean;
  getStats(id: Ident): TagCoverage;
  getTagTypeReport: CoverageInterface['getTagTypeReport'];
  aggregatedCoverage?: AggregatedCoverage;
  isCoveragePending: boolean;
  isCoverageReportPending: boolean;
};

export default function useMitreCoverage(): MitreCoverageInterface {
  const { getTagCoverage, getTagTypeReport, loadCoverage, tagCoverageIsPending, coverageReportIsPending } =
    useCoverage();
  const { tagInProfile, tagsStatus } = useSecurityProfile();

  const { forest, status: forestStatus } = useForest();

  React.useEffect(() => {
    loadCoverage();
  }, [loadCoverage]);

  const [tactics, techniques, subtechniques]: [AttackNode[], AttackNode[], AttackNode[]] = React.useMemo(() => {
    const tactics: AttackNode[] = [];
    const techniques: AttackNode[] = [];
    const subtechniques: AttackNode[] = [];
    forest.forEach(tactic => {
      if (['Resource Development', 'Reconnaissance'].includes(tactic.name)) return;
      if (!tactic.deprecated) tactics.push(tactic);
      tactic.attack_children.forEach(technique => {
        if (techniques.some(t => t.id === technique.id)) return;
        if (!technique.deprecated) techniques.push(technique);
        technique.attack_children?.forEach(subtechnique => {
          subtechniques.push(subtechnique);
        });
      });
    });
    return [tactics, techniques, subtechniques];
  }, [forest]);

  const getProfile = React.useCallback(
    (id: Ident) => {
      return tagInProfile([id]);
    },
    [tagInProfile]
  );

  const aggregatedCoverage = React.useMemo(() => {
    if (tagCoverageIsPending) return;
    if (tagsStatus !== Status.resolved) return;

    return aggregateCoverage(tactics, getTagCoverage, getProfile);
  }, [getProfile, getTagCoverage, tagCoverageIsPending, tactics, tagsStatus]);

  const getStats = React.useCallback((id: Ident) => getTagCoverage(id), [getTagCoverage]);

  return {
    tactics,
    techniques,
    subtechniques,
    isPending: forestStatus === Status.pending,
    isProfilePending: tagsStatus === Status.pending,
    getProfile,
    getStats,
    getTagTypeReport,
    aggregatedCoverage,
    isCoveragePending: !aggregatedCoverage,
    isCoverageReportPending: coverageReportIsPending
  };
}
