import React from 'react';

import { faFilterList } from '@fortawesome/pro-regular-svg-icons';

import { ActionIconButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Tooltip, { TooltipConstrained } from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import { CommonEvent, Engage, Fingerprint, Widget } from 'lib/Engagement';

import { AlphaFeature, CanAlpha } from 'module/AlphaFeatures';
import { RECOMMENDED_ACTION_MAP } from 'module/Collection/Collection.const';
import { Actionability, RecommendationType } from 'module/Curation/Curation.type';
import { PendingCount } from 'module/Curation/CurationCount';
import { useFilterRegistry } from 'module/GlobalFilter';
import { RecommendedDetectionKeys } from 'module/GlobalFilter/Filters/RecommendedDetection';

import { useRecommenderCatalog } from 'provider/Recommender/RecommenderProvider';

import { ArtifactType } from 'types/common';
import { StrictReactNode } from 'types/core';

import {
  NumberValue,
  RecommendationPanelContainer,
  ScoreScoreTypeLabel,
  SnapLogo,
  SnapScoreEvaluation,
  SnapScoreLabel,
  SnapScoreLabelContainer,
  SnapScoreNumber
} from './Recommender.style';

type RecommenderPanelProp = {
  type: RecommendationType;
  pending: boolean;
  count: number;
  action: JSX.Element;
};

type SnapScoreProp = {
  pending: boolean;
  score: number;
  snapScoreTrend?: number;
};

export function recommenderLabel(type: RecommendationType): {
  title: string;
  description: string;
  action: string;
  icon: StrictReactNode;
  recommendedFilter?: RecommendedDetectionKeys & { topic: ArtifactType };
} {
  switch (type) {
    case RecommendationType.Deployment:
      return {
        title: 'Detect',
        description: 'Recommended Detections for Deployment',
        action: 'Deploy Detections',
        icon: <Icon.Analytic color='grey' />,
        recommendedFilter: {
          topic: ArtifactType.Analytic,
          recommended: RecommendationType.Deployment
        }
      };
    case RecommendationType.Hunt:
      return {
        title: 'Hunt',
        description: 'Recommended Detections for Hunting',
        action: 'Start Hunt',
        icon: <Icon.Hunt color='grey' />,
        recommendedFilter: {
          topic: ArtifactType.Analytic,
          recommended: RecommendationType.Hunt
        }
      };
    case RecommendationType.Validation:
      return {
        title: 'Validate',
        description: 'Recommended Attack Scripts',
        action: 'Start Validation',
        icon: <Icon.AttackScript color='grey' />,
        recommendedFilter: {
          topic: ArtifactType.AttackScript,
          recommended: 'true'
        }
      };
  }
}

export function SnapMessage({ score }: { score: string }) {
  const { data: recommendation } = useRecommenderCatalog();

  const allDeploymentActions =
    recommendation?.[RECOMMENDED_ACTION_MAP[RecommendationType.Deployment][Actionability.ALL]] ?? [];
  const unDeploymentAction =
    recommendation?.[RECOMMENDED_ACTION_MAP[RecommendationType.Deployment][Actionability.UNACTIONED]] ?? [];
  const allHuntActions = recommendation?.[RECOMMENDED_ACTION_MAP[RecommendationType.Hunt][Actionability.ALL]] ?? [];
  const unHuntAction =
    recommendation?.[RECOMMENDED_ACTION_MAP[RecommendationType.Hunt][Actionability.UNACTIONED]] ?? [];
  const allValidationActions =
    recommendation?.[RECOMMENDED_ACTION_MAP[RecommendationType.Validation][Actionability.ALL]] ?? [];
  const unValidationActions =
    recommendation?.[RECOMMENDED_ACTION_MAP[RecommendationType.Validation][Actionability.UNACTIONED]] ?? [];

  const deploymentFractionScore = {
    numerator: allDeploymentActions?.length - unDeploymentAction?.length,
    denominator: allDeploymentActions?.length
  };
  const huntFractionScore = {
    numerator: allHuntActions?.length - unHuntAction?.length,
    denominator: allHuntActions?.length
  };
  const validationFractionScore = {
    numerator: allValidationActions?.length - unValidationActions?.length,
    denominator: allValidationActions?.length
  };

  return (
    <div>
      <div> Your SnapScore is {score}/100 for completing all recommendations.</div>
      <br />
      <div>
        <ScoreScoreTypeLabel>Detections</ScoreScoreTypeLabel>: {deploymentFractionScore.numerator}/
        {deploymentFractionScore.denominator} recommended detections deployed
      </div>
      <div>
        <ScoreScoreTypeLabel>Hunts</ScoreScoreTypeLabel>: {huntFractionScore.numerator}/{huntFractionScore.denominator}{' '}
        recommended hunts completed in last 90 days
      </div>
      <div>
        <ScoreScoreTypeLabel>Validations</ScoreScoreTypeLabel>: {validationFractionScore?.numerator}/
        {validationFractionScore?.denominator} recommended attack scripts executed in last 90 days
      </div>
      <br />
      <div>Complete recommended actions to improve your score.</div>
    </div>
  );
}

export function SnapScore({ pending, score }: SnapScoreProp) {
  const scorePercentage = (parseFloat(score.toString()) * 100).toFixed();
  return (
    <RecommendationPanelContainer className='SnapScore'>
      <div>
        <SnapScoreLabelContainer>
          <SnapScoreLabel className='title'>
            <SnapLogo>
              <img src={process.env.PUBLIC_URL + '/images/logo.png'} decoding='async' />
            </SnapLogo>
            <Typography>SnapScore&trade;</Typography>
          </SnapScoreLabel>
          <Tooltip
            title='SnapScore&trade; measures how well your organization is defended against this threat based on activities completed in SnapAttack. Deploy recommended detections, execute hunts, or validate controls with attack scripts to improve your SnapScore.'
            placement='bottom'
            arrow
            wrap
          >
            <Icon.Info />
          </Tooltip>
        </SnapScoreLabelContainer>
        <SnapScoreEvaluation>
          <PendingCount unavailable={false} active={pending}>
            <TooltipConstrained max={600} arrow title={<SnapMessage score={scorePercentage} />}>
              <span>
                <SnapScoreNumber>{scorePercentage}</SnapScoreNumber>
                /100
                {/* We haven't decided how to represent the trending Percentage
          {snapScoreTrend > 0 && (
            <TrendPercentage>
              {snapScoreTrend}%
              <Icon icon={faArrowTrendUp} />
            </TrendPercentage>
          )} */}
              </span>
            </TooltipConstrained>
          </PendingCount>
        </SnapScoreEvaluation>
      </div>
    </RecommendationPanelContainer>
  );
}

export function RecommendationPanel({ pending, count, type, action }: RecommenderPanelProp) {
  const label = recommenderLabel(type);
  const { update } = useFilterRegistry();
  const { data: recommendation } = useRecommenderCatalog();

  const allActions = recommendation?.[RECOMMENDED_ACTION_MAP[type][Actionability.ALL]] ?? [];
  const unAction = recommendation?.[RECOMMENDED_ACTION_MAP[type][Actionability.UNACTIONED]] ?? [];

  const fractionScore = {
    numerator: unAction?.length,
    denominator: allActions?.length
  };

  function handleClick(): void {
    Engage.track(
      Fingerprint.of(Widget.LandingRecommender)
        .withCommon(CommonEvent.Click)
        .withQualifier('recommendation quick filter')
        .withData({
          recommendationType: label.recommendedFilter.recommended
        })
    );

    update({ ...label.recommendedFilter, actionability: Actionability.UNACTIONED });
  }

  return (
    <RecommendationPanelContainer className='RecommendationPanel'>
      <div className='title'>
        {label.icon}
        <Typography>{label.title}</Typography>
      </div>
      <PendingCount unavailable={false} active={pending}>
        <NumberValue>{fractionScore.numerator}</NumberValue> /{fractionScore.denominator}
      </PendingCount>
      <div className='description'>
        {label.description}

        {label.recommendedFilter && (
          <CanAlpha feature={AlphaFeature.RecommendationFilter}>
            <Tooltip arrow title={`Quick filter to see ${label.description}`} wrap>
              <ActionIconButton
                aria-label='quick filter'
                disabled={count === 0}
                icon={faFilterList}
                onClick={handleClick}
              />
            </Tooltip>
          </CanAlpha>
        )}
      </div>
      {action}
    </RecommendationPanelContainer>
  );
}
