import React from 'react';

import isEqual from 'lodash/isEqual';

import { Discriminator } from 'module/Tag';

import { StatPanel } from '../Widgets';
import { MitreStatGrid } from '../style';
import { decimalToPercent } from '../util';
import { MitreCoverageInterface } from './useMitreCoverage';

function Stats({ getTagTypeReport, isCoverageReportPending }: MitreCoverageInterface): JSX.Element {
  const [coverage, breadth, depth] = React.useMemo(() => {
    const report = getTagTypeReport(Discriminator.Attack);
    return [
      decimalToPercent(report?.coverage_avg ?? 0),
      decimalToPercent(report?.breadth_avg ?? 0),
      decimalToPercent(report?.depth_avg ?? 0)
    ];
  }, [getTagTypeReport]);

  return (
    <MitreStatGrid>
      <StatPanel disableCard isPending={isCoverageReportPending} title='Coverage' value={coverage} />
      <div className='minis'>
        <StatPanel
          disableCard
          dimensions={{ innerRadius: 50, outerRadius: 60 }}
          isPending={isCoverageReportPending}
          title='Breadth'
          value={breadth}
        />
        <StatPanel
          disableCard
          dimensions={{ innerRadius: 50, outerRadius: 60 }}
          isPending={isCoverageReportPending}
          title='Depth'
          value={depth}
        />
      </div>
    </MitreStatGrid>
  );
}

export default React.memo(Stats, isEqual);
