import React from 'react';

import { isDataFresh, parseStorageItem, stowItem } from 'storage';

import { Cancelable, Catalog, Helper, Meta, Scheme, Status } from './Storage.type';
import useAsync from './useAsync';

/**
 * Thin wrapper around useAsync for leveraging browser storage cache. Unmount safe.
 * Params should be stable, free from mutations. SSR window usage warning. Nested
 * components should pass the resulting data down. Consider using a Provider otherwise.
 *
 * local storage uses a 1 month timeout
 * session storage uses a 1 minute timeout
 *
 * @param type Storage
 * @param key Catalog - storage key
 * @param initialData - initial and fallback data when things go awry
 * @param version - Signature identifier - This should change with a storage model change. Defaults to 1.
 * @param debug - Show debug info for deciding on when to use storage and when to fetch. Careful, prints in all environments, even production.
 */
function useAsyncStorage<Signature>(
  type: Scheme,
  key: Catalog | string,
  initialData: Signature = null,
  version: string | number = 1,
  debug?: boolean
): Meta<Signature> & Omit<Helper<Signature>, 'run'> & { run(runnable: () => Promise<Signature & Cancelable>): void } {
  const {
    data,
    reset: _reset,
    setData: _setData,
    task,
    error,
    status,
    run: _run, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...others
  } = useAsync(initialData);

  const setData = React.useCallback(
    (data: Signature) => {
      _setData(data, Status.resolved);
      stowItem(type, key, data, version);
    },
    [_setData, key, type, version]
  );

  const setWith = React.useCallback((func: (d: Signature) => Signature) => setData(func(data)), [setData, data]);

  const setDataFromStorage = React.useCallback(
    (showDebug?: boolean) => {
      const { time, data: valueInStorage, variant } = parseStorageItem(type, key);
      const isFresh = isDataFresh(type, time);
      if (showDebug) {
        console.group('Storage');
        console.log('time', time);
        console.log('match version variant', version === variant, version, variant);
        console.groupCollapsed('value in storage');
        console.log(valueInStorage);
        console.groupEnd();
        console.log('should use value in storage', version === variant && time && valueInStorage && isFresh);
        console.groupEnd();
      }
      if (version === variant && time && valueInStorage && isFresh) {
        setData(valueInStorage);
        return true;
      }
      return false;
    },
    [key, setData, type, version]
  );

  React.useEffect(() => {
    setDataFromStorage();
  }, [setDataFromStorage]);

  const run = React.useCallback(
    (runnable: () => Promise<Signature & Cancelable>) => {
      if (!setDataFromStorage(debug)) task(runnable()).then(d => setData(d));
    },
    [setDataFromStorage, debug, task, setData]
  );

  const reset = React.useCallback(() => {
    window?.[type]?.removeItem(key);
    _reset();
  }, [key, _reset, type]);

  return {
    ...others,
    data,
    setWith,
    task,
    error,
    status,
    setData,
    run,
    reset
  };
}

export default useAsyncStorage;
