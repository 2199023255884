import React from 'react';

import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { useTheme } from '../util';

export default function SABarChart({
  data,
  height
}: {
  data: { key: string; color: string; label: string; value: number }[];
  height: number;
}): JSX.Element {
  const { palette } = useTheme();

  return (
    <ResponsiveContainer height={height}>
      <BarChart data={data}>
        <XAxis stroke={palette.common.white} dataKey='label' />
        <YAxis stroke={palette.common.white} />
        <Bar dataKey='value'>
          {data.map((entry, idx) => (
            <Cell key={`cell-${idx}`} fill={entry.color} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
