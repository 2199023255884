import React from 'react';

import { useField } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { Autocomplete, Option } from 'snap-ui/Autocomplete';
import Divider from 'snap-ui/Divider';
import Icon from 'snap-ui/Icon';

import { CheckableCardCheckboxFormik } from 'module/Form/CheckableCardFormik';

import { TargetIndustryDetails } from 'types/mati';

import SecurityProfileStep from './SecurityProfileStep';

const targetIndustries = Object.keys(TargetIndustryDetails);

export default function SecurityProfileStepIndustries(): JSX.Element {
  const [field, , helpers] = useField('industries');
  const [searchTerm, setSearchTerm] = React.useState<string>('');

  const [curatedIndustries, setCuratedIndustries] = React.useState<string[]>(targetIndustries.slice(0, 12));
  const curatedIndustryOptions = curatedIndustries.map(industry => ({
    value: industry,
    label: industry,
    icon: <Icon icon={TargetIndustryDetails[industry]?.icon} size='lg' />
  }));

  const [additionalIndustries, setAdditionalIndustries] = React.useState<string[]>(targetIndustries.slice(12));
  const additionalIndustryOptions: Option[] = additionalIndustries
    .sort()
    .map(industry => ({ value: industry, content: industry }));

  React.useEffect(() => {
    setCuratedIndustries(curatedIndustries => {
      const newCuratedIndustries = [...curatedIndustries];
      if (!isEmpty(field.value))
        field.value.forEach(code => {
          if (!curatedIndustries.includes(code)) newCuratedIndustries.push(code);
        });
      return newCuratedIndustries;
    });
  }, [field.value]);

  React.useEffect(() => {
    setAdditionalIndustries(targetIndustries.filter(industry => !curatedIndustries.includes(industry)));
  }, [curatedIndustries]);

  return (
    <SecurityProfileStep
      title='In which industries do you operate?'
      description='Threat actors strategically target diverse industries, tailoring their approaches to exploit sector-specific vulnerabilities and valuable assets, from financial institutions to healthcare and critical infrastructure, posing a complex and ever-evolving challenge for cybersecurity professionals. This dynamic landscape demands industry-tailored defense strategies that anticipate the motives and methods of adversaries across sectors.'
      content={
        <>
          <CheckableCardCheckboxFormik name='industries' options={curatedIndustryOptions} size='sm' />
          <Divider textAlign='left'>Specify another industry</Divider>
          <Autocomplete
            name=''
            onInputChange={setSearchTerm}
            inputValue={searchTerm}
            disableUserAdditions
            disableClearable
            onChange={(industry: string) => {
              if (industry) {
                helpers.setValue([...field.value, industry]);
                setSearchTerm('');
              }
            }}
            value=''
            options={additionalIndustryOptions}
          />
        </>
      }
    />
  );
}
