import { ArtifactType } from 'types/common';

export const FILTER_PREFIX = '_m_';

export const APPLIED_TO_DEFAULT = [
  ArtifactType.Intel,
  ArtifactType.Session,
  ArtifactType.Analytic,
  ArtifactType.AttackScript,
  ArtifactType.Collection
] as const;
