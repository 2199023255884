import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { RouteComponentProps } from 'react-router-dom';

import {
  DataGrid,
  GRID_AGGREGATION_FUNCTIONS,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridValueGetterParams,
  gridStringOrNumberComparator,
  useGridApiRef,
  useKeepGroupedColumnsHidden
} from 'snap-ui/DataGrid';
import { getRowForSort } from 'snap-ui/DataGrid/DatGrid.helper';
import Typography from 'snap-ui/Typography';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { ApiError } from 'module/ApiError';
import { useMayI } from 'module/May';

import { useAuth, useManagedOrganizations, useUserConfig } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Guid, Ident } from 'types/common';

import { cancelJob, deleteJob, pauseJob, resumeJob } from '../Job.api';
import { COLUMN_MODEL_JOB_OVERVIEW, DEFAULT_JOB_TYPE } from '../Job.const';
import { JobOverview, JobType } from '../Job.type';
import { getGridColumnsTask, getRowId } from '../Job.util';
import { JobGridTreeDataGroupingCell, TaskTableToolbar } from '../Job.widgets';
import useJobGroupOverview from '../useTaskFeed';
import {
  aggregateCreated,
  aggregateCreatedBy,
  aggregateIntegration,
  aggregateOrg,
  aggregateProgress,
  aggregateStatus,
  aggregateTimeRemaining,
  aggregateType,
  created_by,
  creation,
  detections,
  hits,
  integrationNames,
  jobGuid,
  jobName,
  jobsByGroup,
  modified,
  organization,
  progress,
  sumUp,
  taskActions,
  taskStatus,
  timeRemaining,
  type
} from './Column';
import { Container, GridSizer } from './Task.style';

type TaskProps = RouteComponentProps<{ id: Guid }>;

export default function Task(props: TaskProps) {
  useTitle('Tasks | SnapAttack');
  const { columnModel, setColumnModel } = useUserConfig();
  const { defaultOrgId, user } = useAuth();
  const isDetectionFeaturesUser = useMayI(FunctionalPermission.DetectionHuntFeatures);
  const orgId = user.superuser ? null : defaultOrgId;

  const { jobs, status: jobsStatus, jobErrorProps, task, refresh } = useJobGroupOverview();
  const { organizations } = useManagedOrganizations();

  const apiRef = useGridApiRef();

  const isLoading = jobsStatus === Status.pending;
  const guid = props.match.params.id;
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
    quickFilterValues: []
  });

  const [jobTypeFilter, setJobTypeFilter] = React.useState<JobType | 'All'>(DEFAULT_JOB_TYPE);
  const [orgFilter, setOrgFilter] = React.useState<Ident>(orgId);

  const filteredJobs = React.useMemo(() => {
    const filteredJobs = jobs;

    return filteredJobs
      .filter(job => {
        if (jobTypeFilter !== DEFAULT_JOB_TYPE) return job.type === jobTypeFilter;
        else return true;
      })
      .filter(job => (orgFilter === null ? job : job.organization_id === orgFilter));
  }, [jobs, jobTypeFilter, orgFilter]);

  function onTypeChange(type: JobType | 'All') {
    setJobTypeFilter(type);
  }

  function onOrgChange(org: number) {
    setOrgFilter(org);
  }

  function onSearchChange(event) {
    setFilterModel(model => ({
      ...model,
      items: [
        {
          id: 1,
          field: 'name',
          operator: 'contains',
          value: event.target.value
        },
        {
          id: 2,
          field: 'integration_name',
          operator: 'contains',
          value: event.target.value
        }
      ],
      logicOperator: GridLogicOperator.Or
    }));
  }

  const deleteJobs = React.useCallback(
    (guid: Guid[]) => task(Promise.all(guid.map(g => deleteJob(g)))).then(refresh),
    [refresh, task]
  );

  const pause = React.useCallback(
    (guid: Guid[]) => task(Promise.all(guid.map(g => pauseJob(g)))).then(refresh),
    [refresh, task]
  );

  const resume = React.useCallback(
    (guid: Guid[]) => task(Promise.all(guid.map(g => resumeJob(g)))).then(refresh),
    [refresh, task]
  );

  const cancel = React.useCallback(
    (guid: Guid[]) => task(Promise.all(guid.map(g => cancelJob(g)))).then(refresh),
    [refresh, task]
  );

  const handleSetColumn = (value: Record<string, boolean>) =>
    setColumnModel({ ...columnModel, [COLUMN_MODEL_JOB_OVERVIEW]: value });

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['jobsByGroup']
      },
      filter: {
        filterModel: {
          items: []
        }
      },
      sorting: {
        sortModel: [{ field: 'modified', sort: 'desc' }]
      },
      aggregation: {
        model: {
          created_by: 'aggregateCreatedBy',
          type: 'aggregateType',
          integration_name: 'aggregateIntegration',
          job_status: 'aggregateStatus',
          progress_percent: 'aggregateProgress',
          detections_completed: 'sumUp',
          total_hits: 'sumUp',
          estimated_remaining_sec: 'aggregateTimeRemaining',
          creation: 'aggregateCreated',
          modified: 'aggregateCreated',
          organization_id: 'aggregateOrg'
        }
      }
    }
  });
  return (
    <Container>
      <Typography variant='h1'>Tasks</Typography>
      <ApiError {...jobErrorProps} />

      <GridSizer>
        <DataGrid
          columns={[
            jobsByGroup,
            jobGuid(user.superuser),
            type,
            created_by,
            integrationNames,
            taskStatus(apiRef),
            progress,
            detections,
            hits,
            timeRemaining,
            creation,
            modified,
            organization(organizations),
            taskActions({ isDetectionFeaturesUser, deleteJobs, pause, resume, cancel, apiRef }),
            jobName
          ]}
          rows={filteredJobs}
          columnVisibilityModel={{
            creation: false,
            estimated_remaining_sec: false,
            progress_percent: false,
            analytic_job_guid: user.superuser ? true : false,
            organization_id: user.superuser ? true : false,
            ...(isEmpty(columnModel[COLUMN_MODEL_JOB_OVERVIEW]) ? undefined : columnModel[COLUMN_MODEL_JOB_OVERVIEW]),
            jobsByGroup: false,
            name: false
          }}
          onColumnVisibilityModelChange={handleSetColumn}
          loading={isLoading}
          getRowId={getRowId}
          filterModel={filterModel}
          onFilterModelChange={newModel => setFilterModel(newModel)}
          getAggregationPosition={groupNode => (groupNode == null ? null : 'inline')}
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
            aggregateType,
            aggregateIntegration,
            aggregateCreatedBy,
            aggregateStatus,
            aggregateProgress,
            aggregateTimeRemaining,
            aggregateCreated,
            sumUp,
            aggregateOrg
          }}
          groupingColDef={{
            headerName: 'Name',
            flex: 1,
            minWidth: 300,
            sortingOrder: ['asc', 'desc'],
            sortComparator: (v1, v2, param1, param2) => gridStringOrNumberComparator(v1, v2, param1, param2),
            valueGetter(params: GridValueGetterParams) {
              const row = getRowForSort<JobOverview>(apiRef.current, params);
              return row?.name;
            },
            renderCell: params => (
              <JobGridTreeDataGroupingCell
                {...params}
                idPath='analytic_job_group_guid'
                linkPath={Path.Hunt}
                matchingGuid={guid}
              />
            )
          }}
          apiRef={apiRef}
          initialState={initialState}
          slots={{
            toolbar: TaskTableToolbar
          }}
          slotProps={{
            toolbar: { orgFilter, jobTypeFilter, onSearchChange, onTypeChange, onOrgChange, includeExport: false },
            columnsPanel: {
              getTogglableColumns: (columns: GridColDef[]) => getGridColumnsTask(columns, user.superuser)
            }
          }}
        />
      </GridSizer>
    </Container>
  );
}
