import { styled } from 'snap-ui/util';

import Artifact from 'module/Layout/Artifact';

export const AttackScriptContainer = styled(Artifact)`
  .Artifact-content {
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(5)};
  }

  .SyntaxEditor {
    max-height: 800px;
    max-width: 100vw;
  }

  .HeroImage-window {
    min-width: fit-content;
    display: grid;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};

    grid-template-columns: 130px 1fr;
    grid-template-rows: 4fr;
    grid-template-areas:
      'type type'
      'title title'
      'platforms actions';

    .Artifact-type {
      grid-area: type;
      justify-self: start;
      height: 1.25rem;
    }

    .AttackScriptHeader-action {
      grid-area: action;
      justify-self: end;
      display: flex;
      gap: ${p => p.theme.spacing(1)};
      align-items: center;
    }

    .ArtifactWidget-title {
      grid-area: title;
    }

    .AttackScriptHeader-platforms {
      grid-area: platforms;
      font-size: 14px;

      display: flex;
      gap: ${p => p.theme.spacing(2)};
      align-items: center;
    }

    .AttackScriptHeader-actions {
      grid-area: actions;
      justify-self: end;
      align-self: end;

      display: flex;
      gap: ${p => p.theme.spacing(3)};
      align-items: center;
    }

    .AttackScriptEditHeader-menuWrapper {
      display: flex;
      align-items: center;
      gap: ${p => p.theme.spacing(3)};
    }
  }
`;

export const AttackScriptDetailContainer = styled('div')`
  height: 100%;
  .overview {
    flex: 10 0 575px;
  }

  .AttackScriptDetail-info {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin-bottom: ${p => p.theme.spacing(6)};
    gap: ${p => p.theme.spacing(6)};
  }

  .AttackScriptDetail-scripts {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(6)};
  }

  .Stepper,
  .AttackScriptDetail-scripts {
    padding: ${p => p.theme.spacing(5)};
  }
`;

export const AttackScriptFeedTabContainer = styled('div')`
  border-top-left-radius: 0;
  position: relative;
  padding: ${p => p.theme.spacing(3)};

  .BackdropLoader {
    position: absolute;
    display: flex;
    align-items: flex-start;
  }

  .CircularProgress {
    margin-top: 50px;
  }

  .AttackScriptFeedTab-loading {
    align-items: flex-start;
    display: flex;
    gap: ${p => p.theme.spacing(2)};
    padding: ${p => p.theme.spacing(5)};
  }
`;

export const AttackScriptFormContainer = styled('div')`
  margin-top: ${p => p.theme.spacing(3)};
  height: 100%;
`;
