import React from 'react';

import { GridColDef } from 'snap-ui/DataGrid';

import PlatformBadge from 'module/Widgets/PlatformBadge';

import { Artifact, ArtifactType } from 'types/common';

import { NameContainer, PlatformBadgeContainer, SeverityContainer } from './DisplayGrid.helper';

export const GridColumnsValidation: (GridColDef<Artifact> & { hideToStart?: boolean })[] = [
  {
    field: 'name',
    headerName: 'Name',
    hideable: false,
    width: 500,
    renderCell: cell => <NameContainer artifact={cell.row} topic={ArtifactType.AttackScript} value={cell.value} />
  },
  {
    field: 'severity',
    headerName: 'Severity',
    hideable: true,
    sortable: false,
    filterable: false,
    width: 130,
    renderCell: cell => <SeverityContainer guid={cell.row.guid} severity={cell.value} />
  },
  {
    field: 'platforms',
    headerName: 'Platforms',
    hideable: true,
    sortable: false,
    filterable: false,
    width: 330,
    renderCell: cell => (
      <PlatformBadgeContainer>
        <PlatformBadge platforms={cell.row.platforms} />
      </PlatformBadgeContainer>
    )
  }
];
