import React from 'react';

import { useFormikContext } from 'formik';

import Typography from 'snap-ui/Typography';

import { JsonView } from '../JsonView/JsonView';
import { MetadataFieldInput } from './Metadata.type';
import { buildRenderersFromSchema, buildSchemasFromInput, convertNameToIdentifier } from './Metadata.util';

export function MetadataPreview() {
  const { values } = useFormikContext<MetadataFieldInput>();
  if (!(values.type && values.label))
    return (
      <div className='group-layout'>
        <div>
          <Typography component='div' variant='body1'>
            Component Preview
          </Typography>
          <Typography component='div' variant='body2' textAlign='justify'>
            A preview will show here when a{' '}
            <code>
              <b>type</b>
            </code>{' '}
            and{' '}
            <code>
              <b>name</b>
            </code>{' '}
            are chosen
          </Typography>
        </div>
      </div>
    );
  const schema = buildSchemasFromInput(values);
  const renderers = buildRenderersFromSchema(schema);
  const key = convertNameToIdentifier(values.label);
  const data = { [key]: schema.properties[key].default };

  return <JsonView asPreview schema={schema} uischema={schema.uischema} renderers={renderers} data={data} />;
}
