import { Guid } from 'types/common';

export const FieldType = {
  Raw: 'raw',
  Normalized: 'normalized'
} as const;

export type FieldType = (typeof FieldType)[keyof typeof FieldType];

export type Exclusion = Record<string, Bucket[]>;
export type OSQueryKeyValues = Record<string, string[]>;
export type ReverseFieldMap = Record<string, string[]>;

export type Bucket = {
  key: string;
  doc_count: number;
};

export type Aggregation = {
  doc_count_error_upper_bound?: 0;
  sum_other_doc_count: 0;
  buckets: Bucket[];
};

export type TuningFilter = {
  field: string; //value is expected to be prefixed with `raw` or `normalized`
  values: string[];
};

export type TuningPayload = {
  analytic_version_id?: number;
  integration: Guid;
  earliest: Date;
  latest: Date;
  filter: TuningFilter[];
  fields?: string[];
};

export type TuningFieldsResponse = {
  raw: Record<string, { value: number }>; // value is buckets.length, assuming buckets are unbounded
  normalized: Record<string, { value: number }>;
  total: number;
};

export type TuningValuesResponse = {
  raw: Record<string, Aggregation>;
  normalized: Record<string, Aggregation>;
  total: number;
};
