import React from 'react';

import { CancelToken, CancelTokenSourceType } from 'apis/snapattack';

import { useFeedAsync } from 'aso/useFeed';

import { ExpandedArtifact, transformDatasets } from 'module/Analytic/Analytic.util';
import { FeedAsync } from 'module/Feed/Feed.type';
import { SupplementalArtifact } from 'module/Search';
import { getSupplementalDetectionItems } from 'module/Search/Search.api';

import { Status, useAsync } from 'storage';

import { ArtifactType } from 'types/common';
import { Query, Ops } from 'types/filter';

type DeployableDetectionsUtils = Omit<FeedAsync, 'status' | 'items'> & {
  refresh(cancelSource: CancelTokenSourceType): void;
  isLoading: boolean;
  items: ExpandedArtifact[];
};

export default function useDetectionDeployment(query: Query, orgIDs?: string[]): DeployableDetectionsUtils {
  const {
    fetchFeed,
    items,
    status: feedStatus,
    ...stuff
  } = useFeedAsync(
    ArtifactType.Analytic,
    { op: Ops.and, items: [{ field: 'deployable', op: Ops.equals, value: 'true' }, query] },
    { page: 1, size: 5000 }
  );
  const { data: supplementals, status: supplementalStatus, run } = useAsync<SupplementalArtifact[]>([]);
  const cancelTokenSourceRef = React.useRef(CancelToken.source());

  const refresh = React.useCallback(
    (cancelSource: CancelTokenSourceType) => {
      fetchFeed(cancelSource);
    },
    [fetchFeed]
  );

  const fullAccess = React.useMemo(() => items.filter(i => !i.preview), [items]);

  React.useEffect(() => {
    cancelTokenSourceRef.current.cancel();
    const source = CancelToken.source();
    cancelTokenSourceRef.current = source;
    if (fullAccess.length) {
      run(
        getSupplementalDetectionItems(
          fullAccess.map(i => i.guid),
          { params: { org_id: orgIDs }, cancelToken: source.token }
        )
      );
    }
    return () => source.cancel();
  }, [fullAccess, orgIDs, run]);

  const augmented = React.useMemo(() => transformDatasets(fullAccess, supplementals), [fullAccess, supplementals]);

  return {
    ...stuff,
    items: augmented,
    isLoading: feedStatus === Status.pending || supplementalStatus === Status.pending,
    refresh
  };
}
