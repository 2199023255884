import { DataGridPremium, GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license-pro';

import Toolbar from 'snap-ui/Toolbar';

import { styled } from '../util';

export {
  GRID_AGGREGATION_FUNCTIONS,
  GridActionsCellItem,
  GridCellEditStopReasons,
  GridFooterContainer,
  GridLogicOperator,
  GridPagination,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  gridExpandedSortedRowIdsSelector,
  gridFilteredDescendantCountLookupSelector,
  gridFilteredSortedRowEntriesSelector,
  gridFilteredSortedRowIdsSelector,
  gridPageCountSelector,
  gridPaginationModelSelector,
  gridStringOrNumberComparator,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
  useKeepGroupedColumnsHidden
} from '@mui/x-data-grid-premium';
export type {
  DataGridPremiumProps as DataGridProps,
  FooterPropsOverrides,
  GridAggregationFunction,
  GridApi,
  GridCellEditStopParams,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridComparatorFn,
  GridCsvGetRowsToExportParams,
  GridEventListener,
  GridFilterModel,
  GridFooterContainerProps,
  GridGroupingColDefOverride,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  GridRowsProp,
  GridSortModel,
  GridTreeNodeWithRender,
  GridValidRowModel,
  GridValueFormatterParams,
  GridValueGetterParams,
  MuiEvent
} from '@mui/x-data-grid-premium';

// Installation instructions: https://mui.com/r/x-license-key-installation
// Technical support: https://mui.com/r/x-technical-support
export function installMuiPro() {
  LicenseInfo.setLicenseKey(
    'dc09bed354ab2c0dc10151891d54ed86Tz04NDk1OSxFPTE3NDAzMTk4ODgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
  );
}

export const DataGrid = styled(DataGridPremium)`
  background: ${p => p.theme.palette.surface.odd};

  .MuiDataGrid-row:nth-of-type(even) {
    background-color: ${p => p.theme.palette.surface.even};
  }

  .MuiDataGrid-row:hover {
    background-color: ${p => p.theme.palette.surface.hover};
  }

  .MuiDataGrid-columnHeaders {
    background-color: ${p => p.theme.palette.primary.dark};
    background: ${p => p.theme.palette.surface.gradient};
  }

  &.table-height {
    max-height: ${p =>
      p.rows?.length < 3 ? p.rows?.length * 225 : p.rows?.length < 10 ? p.rows?.length * 100 : 600}px;
  }
`;

export const GridToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;

  .search-field {
    width: 300px;
  }
`;

export const CheckboxColumnWithoutSelectAll: GridColDef = {
  ...GRID_CHECKBOX_SELECTION_COL_DEF,
  renderHeader: () => null
};

/**
 * Table will require a min-height in order for this to display.
 * Thanks, MUI!
 */
export const SimpleNoRowsOverlay = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

/**
 * MUI should automatically apply a '...' to truncate text that is not visible in a cell;
 * however, it cannot always calculate this if we're using flex boxes or there are nested
 * elements in a renderCell call. Using this should fix it, but YMMV.
 */
export const TruncateCell = styled('div')`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
