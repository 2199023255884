import React from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import useIDEReducer, { IDEAction, IDEState } from 'module/IDE/reducer';
import { SigmaTag } from 'module/Tag';
import useTagCatalog, { DiscriminatorOrdinal } from 'module/Tag/useTagCatalog';

import { CompilationTargetId } from 'types/analytic';
import { Guid, Ident } from 'types/common';

import { useAnalyticCatalog } from './AnalyticProvider';

type EditorStateContextState = {
  state: IDEState;
  isLoaded: boolean;
  dispatch: React.Dispatch<IDEAction>;
  tagOptions: SigmaTag[];
};

const EditorStateContext = React.createContext<EditorStateContextState>(null);
EditorStateContext.displayName = 'EditorStateContext';

export default function useEditorStateContext(): EditorStateContextState {
  const state = React.useContext(EditorStateContext);
  if (!state) throw new Error('useEditorStateContext must be used in the EditorStateProvider');
  return state;
}

export function EditorStateProvider({ children }: { children: React.ReactChild }): JSX.Element {
  const { data: tagOptions } = useTagCatalog(DiscriminatorOrdinal.All, true);
  const [{ analytic, references, supplemental }] = useAnalyticCatalog();
  const [state, dispatch] = useIDEReducer();
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    if (analytic.raw && !isEmpty(supplemental) && !isEmpty(references) && tagOptions?.length && !state.refreshKey) {
      dispatch({
        type: 'LoadAnalyticUpdate',
        analytic: { ...analytic, ...supplemental, ...references },
        tagOptions
      });
      setTimeout(() => {
        setIsLoaded(true);
      });
    }
  }, [dispatch, state.refreshKey, analytic, references, supplemental, tagOptions]);

  return (
    <EditorStateContext.Provider value={{ state, isLoaded, dispatch, tagOptions }}>
      {children}
    </EditorStateContext.Provider>
  );
}

export function getCloneState(editorState: IDEState, defaultOrgId: Ident, parentGuid: Guid): IDEState {
  const newState: IDEState = cloneDeep(omit(editorState, 'guid'));
  newState.analyticForm.organization_id = defaultOrgId;
  newState.isModified = true;
  newState.analyticForm.parent_analytic_guid = parentGuid;
  newState.metadata = {} as IDEState['metadata'];
  return newState;
}

export function getNativeCloneState(
  editorState: IDEState,
  defaultOrgId: Ident,
  parentGuid: Guid,
  languageId: Ident,
  backendKey: string,
  raw: string
): IDEState {
  const nativeState = getCloneState(editorState, defaultOrgId, parentGuid);
  if (languageId === CompilationTargetId.Sigma) return nativeState;
  nativeState.isNative = true;
  nativeState.raw = raw;
  nativeState.analyticForm.languageId = languageId;
  return nativeState;
}
