import React from 'react';

import isEqual from 'lodash/isEqual';

import { Autocomplete } from 'snap-ui/Autocomplete';
import TextField from 'snap-ui/TextField';

import { useIDEState } from 'module/IDE/IDEStateProvider';

import { useLogsourceOptions } from 'provider';

export type LogsourceFieldProps = { readonly?: boolean };

export default function LogsourceField({ readonly }: LogsourceFieldProps): JSX.Element {
  const { ideState, dispatch } = useIDEState();
  const value = ideState.analyticForm?.logsource;
  const options = useLogsourceOptions();

  function handleChange(valueJSON: string) {
    let newValue;
    if (!valueJSON) newValue = {};
    else {
      try {
        newValue = JSON.parse(valueJSON);
      } catch (e) {
        const [product, service] = valueJSON.split(' ');
        newValue = { product, service };
      }
    }

    dispatch({
      type: 'FormUpdateAction',
      analyticForm: { logsource: newValue }
    });
  }

  const displayValue =
    options.find(o => isEqual(JSON.parse(o.value), value))?.content.toString() || value
      ? `${value.product || ''}${value.service ? ` ${value.service}` : ''}${value.category ? ` ${value.category}` : ''}`
      : '';

  return readonly ? (
    <TextField data-testid='logSource' label='Log Source' elevated fullWidth disabled value={displayValue} readOnly />
  ) : (
    <Autocomplete
      elevated
      label='Log Source'
      name='logsource'
      onChange={handleChange}
      options={options}
      value={displayValue}
      disableUserAdditions
    />
  );
}
