import React from 'react';

import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

import SliderFormik from 'module/Form/SliderFormik';

import SecurityProfileStep from './SecurityProfileStep';

const Container = styled(Paper, { name: 'SliderContainer' })`
  padding: 60px;
`;

const marks = [
  { value: 0, label: 'Low', tooltip: 'CVSS Score: 0.1 - 3.9' },
  { value: 4, label: 'Medium', tooltip: 'CVSS Score: 4.0 - 6.9' },
  { value: 7, label: 'High', tooltip: 'CVSS Score: 7.0 - 8.9' },
  { value: 10, label: 'Critical', tooltip: 'CVSS Score: 9.0 - 10.0' }
];

function valuetext(value: number) {
  return marks.find(mark => mark.value === value)?.tooltip;
}

export default function SecurityProfileStepVulnerabilityRisk(): JSX.Element {
  return (
    <SecurityProfileStep
      title='What is your vulnerability risk tolerance?'
      description="Vulnerability prioritization involves assessing the potential impact and exploitability of security vulnerabilities within an organization's systems and networks. The Common Vulnerability Scoring System (CVSS) provides a standardized framework for assigning scores to vulnerabilities, helping organizations make informed decisions about which vulnerabilities to address first based on their severity and potential impact on their specific environment."
      content={
        <Container>
          <SliderFormik
            name='risk_tolerance'
            className='VulnerabilitySlider'
            fixedBoundary='right'
            step={null}
            marks={marks}
            min={0}
            max={10}
            valueLabelDisplay='on'
            valueLabelFormat={valuetext}
          />
        </Container>
      }
    />
  );
}
