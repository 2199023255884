import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import Divider from 'snap-ui/Divider';
import Paper from 'snap-ui/Paper';

import { quickAddTags, quickDeleteTags, tagRefresh } from 'apis/resources/analytic';

import { AnalyticLoadState } from 'aso/useAnalytic';

import Path from 'constants/paths';

import useLocationState from 'hooks/useLocationState';
import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import { useJobList } from 'module/BAS/JobList';
import { EntityScriptsProvider, useEntityScripts } from 'module/BAS/ScriptList';
import { useAnalyticDetectionSummary } from 'module/Detection';
import { Page } from 'module/Layout/Styled';
import Paywall from 'module/PayWall';
import AttackScriptList from 'module/Session/SessionView/AttackScriptList';
import { getTagListDiffs } from 'module/Tag';
import { TagListProps } from 'module/Tag/TagList';
import { ViewAnalyticTranslate } from 'module/TranslateAnalytic';
import NotFound from 'module/Util/Fallback/NotFound';

import { useAuth } from 'provider';

import { Status } from 'storage';

import { ContentPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import { Container } from './Analytic.style';
import { AnalyticRouterState } from './Analytic.type';
import AnalyticVersionList from './AnalyticVersion/AnalyticVersionList';
import AnalyticHero from './core/AnalyticHero';
import AnalyticOverview from './core/AnalyticOverview';
import { useAnalyticCatalog } from './core/AnalyticProvider';
import { useAnalyticVersionCatalog } from './core/AnalyticVersionProvider';
import MetadataPanel from './core/MetadataPanel';
import Deployment from './core/MetadataPanel/Deployment';
import State from './core/MetadataPanel/State';
import Validation from './core/MetadataPanel/Validation';
import ViewAnalyticTabs from './core/ViewAnalyticTabs';

type Props = RouteComponentProps<{ analyticGuid: string }>;

function Analytic(props: Props): JSX.Element {
  const analyticGuid = props.match.params.analyticGuid;
  const [showVersionList, setShowVersionList] = React.useState(false);
  const { permission: organizations, user } = useAuth();
  const { count } = useLocationState<AnalyticRouterState>() || {};
  const scripts = useEntityScripts();
  const detectionSummary = useAnalyticDetectionSummary(analyticGuid, count);
  const jobListAsync = useJobList({ analytic: analyticGuid });
  const { versions, status: versionStatus } = useAnalyticVersionCatalog();

  const [
    {
      analytic,
      analyticStatus,
      analyticError,
      setWithPartialData,
      supplemental,
      supplementalStatus,
      references,
      permissions,
      permissionsStatus,
      supplementalRefresh
    },
    derivedState
  ] = useAnalyticCatalog();
  const preview = analyticError?.response?.status === 402;
  useTitle(`${(analytic && analytic.name) || `Detection ${analyticGuid}`} | SnapAttack`);

  React.useEffect(() => {
    if (analytic.guid && analytic.name) {
      Engage.track(
        Fingerprint.load(Path.Detection).withData({
          content: ContentPermission.Read,
          guid: analytic.guid,
          name: analytic.name,
          artifact_organization_id: analytic.organization_id,
          artifact_organization_name: organizations?.find(o => o.id === analytic.organization_id)?.name
        })
      );
      Engage.trackPersonIncrement(`view ${Path.Detection}`, 1);
    }
  }, [analytic.guid, analytic.name, analytic.organization_id, organizations]);

  const handleQuickAddTag: TagListProps['onSubmit'] = React.useCallback(
    async values => {
      const [addition, deletion, key] = getTagListDiffs(values, analytic);
      const promised: Promise<unknown>[] = [];
      if (addition?.length > 0 || deletion?.length > 0) {
        if (addition?.length > 0) promised.push(quickAddTags(analytic.guid, addition));
        if (deletion?.length > 0) promised.push(quickDeleteTags(analytic.guid, deletion));

        await Promise.all(promised);
        const refreshedTags = await tagRefresh(analytic.guid);

        setWithPartialData({ [key]: refreshedTags[key] } as Partial<AnalyticLoadState['analytic']>);
      }
    },
    [analytic, setWithPartialData]
  );

  if (analyticError && !preview) return <NotFound artifact={ArtifactType.Analytic} error={analyticError} />;
  return (
    <Container>
      <AnalyticVersionList
        open={showVersionList}
        onClose={() => setShowVersionList(false)}
        versions={versions}
        versionStatus={versionStatus}
        guid={supplemental?.guid}
        parentGuid={supplemental?.parent?.guid}
      />
      <AnalyticHero
        analytic={analytic}
        analyticStatus={analyticStatus}
        permissions={permissions}
        references={references}
        supplemental={supplemental}
        supplementalStatus={supplementalStatus}
        preview={preview}
        preferredOrg={user.preferred_organization}
        onShowVersionList={() => setShowVersionList(true)}
      />
      <Page matchHero>
        {analytic && supplemental && (
          <div className='Analytic-details'>
            <Paper className='overview'>
              <AnalyticOverview
                analytic={analytic}
                analyticStatus={analyticStatus}
                onShowVersionList={() => setShowVersionList(true)}
                permissions={permissions}
                references={references}
                supplemental={supplemental}
                onQuickAddTag={handleQuickAddTag}
              />
            </Paper>
            {!preview && (
              <MetadataPanel>
                <>
                  <Validation
                    validated={detectionSummary.summary.validated}
                    unvalidated={detectionSummary.summary.unvalidated}
                    validated_gaps={detectionSummary.summary.validated_gaps}
                  />
                  <Divider />
                  <Deployment artifact={analytic} supplemental={supplemental} status={supplementalStatus} />
                  <Divider />
                  <AttackScriptList
                    guid={analyticGuid}
                    preview={preview}
                    scripts={scripts}
                    type={ArtifactType.Analytic}
                  />
                  <Divider />
                  <State
                    analytic={analytic}
                    supplemental={supplemental}
                    supplementalStatus={supplementalStatus}
                    supplementalRefresh={supplementalRefresh}
                  />
                </>
              </MetadataPanel>
            )}
          </div>
        )}
        <>
          {preview ? (
            <Paywall fixed />
          ) : (
            <>
              <ViewAnalyticTranslate analytic={analytic} analyticGuid={analyticGuid} supplemental={supplemental} />
              <div className='matching-log'>
                <ViewAnalyticTabs
                  analyticGuid={analyticGuid}
                  logsource={analytic.logsource}
                  compilationTarget={analytic.analytic_compilation_targets}
                  detectionSummary={detectionSummary}
                  isLoading={[detectionSummary.status, analyticStatus, permissionsStatus].includes(Status.pending)}
                  processor={derivedState.highlightProcessor}
                  jobListAsync={jobListAsync}
                />
              </div>
            </>
          )}
        </>
      </Page>
    </Container>
  );
}

export default function AnalyticWrapper(props: Props) {
  return (
    <EntityScriptsProvider guid={props.match.params.analyticGuid} type={ArtifactType.Analytic}>
      <Analytic {...props} />
    </EntityScriptsProvider>
  );
}
