import React from 'react';

import Button from 'snap-ui/Button';
import { ConfirmDialog } from 'snap-ui/Dialog';

import { DeployedFeeds, useBulkVersions, useDeploymentUpdateActions } from '../';
import { Table } from '../DeployedHealth.style';
import { RenderTable } from '../DeployedHealth.widget';
import {
  COMMON_GRID_CONFIG,
  detectionName,
  detectionUrl,
  integrationName,
  versionsBehind,
  timeBehind,
  outdatedActions
} from './Columns';
import { CommonTableProps, OutdatedAugment } from './Tables.type';

type OutdatedDeploymentsTableProps = DeployedFeeds['outdatedDeployments'] & CommonTableProps;

export default function OutdatedDeploymentsTable(props: OutdatedDeploymentsTableProps): JSX.Element {
  const { items: _items } = props;
  const [showDeployConfirm, setShowDeployConfirm] = React.useState<number | 'all'>(null);
  const guids = React.useMemo(() => props.items.map(i => i.detection.guid), [props.items]);
  const { getLatest, getDeltas } = useBulkVersions(guids);

  const { items, deployOne, deployAll, isPending } = useDeploymentUpdateActions(_items);

  const gridItems: ((typeof props.items)[number] & OutdatedAugment)[] = React.useMemo(
    () =>
      items.map(item => ({
        ...item,
        latest: getLatest(item.detection.guid),
        deltas: getDeltas(item.detection.guid, item.versionId)
      })),
    [items, getLatest, getDeltas]
  );

  const deployItem =
    typeof showDeployConfirm === 'number' ? gridItems.find(i => i.deploymentId === showDeployConfirm) : null;

  function handleDeploy() {
    (deployItem ? deployOne(deployItem.deploymentId) : deployAll()).then(() => setShowDeployConfirm(null));
  }

  return (
    <>
      <RenderTable
        {...props}
        items={gridItems}
        emptyMessage='All deployed detections are up to date'
        toolbar={({ disableButtons }) => (
          <>
            <div />
            <div className='buttons'>
              <Button variant='outlined' disabled={disableButtons} onClick={() => setShowDeployConfirm('all')}>
                Update All
              </Button>
            </div>
          </>
        )}
        table={
          <Table
            {...COMMON_GRID_CONFIG}
            columns={[
              detectionName,
              detectionUrl,
              integrationName,
              versionsBehind,
              timeBehind,
              outdatedActions(setShowDeployConfirm)
            ]}
            rows={gridItems}
          />
        }
      />
      <ConfirmDialog
        isPending={isPending}
        ConfirmProps={{
          children: 'Redeploy',
          onClick: handleDeploy
        }}
        DialogProps={{ open: showDeployConfirm !== null, onClose: () => setShowDeployConfirm(null) }}
        title={deployItem ? `Redeploy ${deployItem.detection.name}` : 'Redeploy Detections'}
      >
        Are you sure you want to redeploy {deployItem ? 'this detection' : 'these detections'}?
      </ConfirmDialog>
    </>
  );
}
