import React from 'react';

import { Link } from 'react-router-dom';

import { CancelToken } from 'apis';

import Path from 'constants/paths';

import { FEED_SKELETON } from 'module/Feed/Feed.const';
import { getSearchPage } from 'module/Feed/Feed.service';
import { Feed } from 'module/Feed/Feed.type';
import { useFilterRegistry } from 'module/GlobalFilter';
import { Session } from 'module/Session/Session.type';
import AssociateArtifactsModal, { Message } from 'module/Widgets/AssociateArtifactsModal';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { Artifact, ArtifactType, IconButtonRenderProps } from 'types/common';
import { Identity } from 'types/common.zod';
import { Ops, Query } from 'types/filter';

import { postAttackScriptSessions } from '../AttackScript.api';

const IDLE_MESSAGE: Message = {
  data: <div key='info'>Link Threats to this Attack Script.</div>,
  severity: 'info'
};

const BUSY_MESSAGE: Message = {
  data: <div key='warning'>Handling your request...</div>,
  severity: 'warning'
};

const EMPTY_MESSAGE: Message = {
  data: <div key='warning'>There are no Threats you can link to this Attack Script.</div>,
  severity: 'warning'
};

export type AddSessionsToAttackScriptProps = IconButtonRenderProps & {
  attackScript: Identity;
  onClose: () => void;
  isOpen: boolean;
};

const ITEMS_PER_PAGE = 50;

export default function AddSessionsToAttackScript({ attackScript, onClose, isOpen }: AddSessionsToAttackScriptProps) {
  const {
    user: { id }
  } = useAuth();
  const { generateQuery } = useFilterRegistry();
  const [isActing, setIsActing] = React.useState(false);

  const [action, setAction] = React.useState<boolean>();

  const [message, setMessage] = React.useState<Message>(IDLE_MESSAGE);
  const [selectedSession, setSelectedSession] = React.useState<Partial<Session>>();
  const {
    data: sessionFeed,
    status: sessionFeedStatus,
    run: sessionFeedRun,
    reset: sessionFeedReset
  } = useAsync<Feed>(FEED_SKELETON);
  const {
    data: associatedSessions,
    status: associatedSessionsStatus,
    run: associatedSessionsRun,
    reset: associatedSessionsReset,
    setData: setAssociatedSessions
  } = useAsync<Feed>(FEED_SKELETON);

  const isPending = associatedSessionsStatus === Status.pending || sessionFeedStatus === Status.pending;
  const cancelTokenSourceRef = React.useRef(CancelToken.source());

  const associatedFilter: Query = React.useMemo(
    () => (attackScript.id ? { field: 'session.bas_scripts.id', op: Ops.equals, value: attackScript.id } : null),
    [attackScript.id]
  );

  const cancelQuery = React.useCallback(() => {
    cancelTokenSourceRef.current.cancel();
    sessionFeedReset();
    associatedSessionsReset();
  }, [sessionFeedReset, associatedSessionsReset]);

  const refresh = React.useCallback(
    (query: string) => {
      if (id) {
        cancelQuery();
        const source = CancelToken.source();
        cancelTokenSourceRef.current = source;
        sessionFeedRun(
          getSearchPage(
            ArtifactType.Session,
            1,
            ITEMS_PER_PAGE,
            generateQuery(ArtifactType.Session, { query }),
            '',
            source
          ),
          true
        );

        associatedSessionsRun(
          getSearchPage(ArtifactType.Session, 1, ITEMS_PER_PAGE, associatedFilter, '', source),
          true
        );
      }
    },
    [associatedFilter, associatedSessionsRun, cancelQuery, generateQuery, id, sessionFeedRun]
  );

  React.useEffect(() => {
    if (sessionFeedStatus === Status.resolved && sessionFeed.items.length === 0) setMessage(EMPTY_MESSAGE);
    else setMessage(IDLE_MESSAGE);
  }, [sessionFeed.items.length, sessionFeedStatus]);

  const handleToggleClick = React.useCallback(
    async (session: Artifact, modifier: boolean) => {
      setAction(modifier);
      setSelectedSession(session);
      setIsActing(true);
      setMessage(BUSY_MESSAGE);
      let _message: Message = null;

      const updater = async () => {
        try {
          await postAttackScriptSessions(attackScript.guid, modifier ? 'add' : 'delete', [session.guid]);
          _message = {
            data: (
              <div key='success'>
                {`Your request to ${modifier ? 'add' : 'remove'} `}
                <strong>
                  <Link to={`${Path.Threat}/${session.guid}`} target='_blank' rel='noopener noreferrer'>
                    {session.name}
                  </Link>
                </strong>{' '}
                has been queued. Thanks!
              </div>
            ),
            severity: 'success'
          };
          if (modifier) {
            setAssociatedSessions({
              ...associatedSessions,
              items: [...associatedSessions.items, session]
            });
          } else {
            setAssociatedSessions({
              ...associatedSessions,
              items: associatedSessions.items.filter(item => item.guid !== session.guid)
            });
          }
        } catch (e) {
          _message = {
            data: (
              <div key='error'>
                {`Oh... Didn't expect that trying to ${modifier ? 'add' : 'remove'} `}
                <strong>{session.name}.</strong> to this threat. {e.message}
              </div>
            ),
            severity: 'error'
          };
        }
      };

      await updater();
      setIsActing(false);
      setMessage(_message);
    },
    [associatedSessions, setAssociatedSessions, attackScript]
  );

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      sessionFeedReset();
      associatedSessionsReset();
      setMessage(IDLE_MESSAGE);
      setAssociatedSessions(null);
    }, 500);
  };

  return (
    <AssociateArtifactsModal
      onClose={handleClose}
      open={isOpen}
      message={message}
      title='Associate Attack Script with Threats'
      refresh={refresh}
      isPending={isPending}
      options={sessionFeed.items}
      associated={associatedSessions.items}
      isActing={isActing}
      action={action}
      selectedItem={selectedSession}
      handleToggleClick={handleToggleClick}
      path={Path.Threat}
      searchedArtifactType={ArtifactType.Session}
    />
  );
}
