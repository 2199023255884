import React from 'react';

import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${p => p.theme.spacing(1)};
  .versionIcon {
    padding: ${p => p.theme.spacing(2)};
  }

  .versionAvailable {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: ${p => p.theme.spacing(2)};
    padding: 0;
  }

  .complexityTime {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: ${p => p.theme.spacing(2)};
  }
`;
type AnalyticFooterItemProps = {
  label: string;
  content: JSX.Element;
  toolTipMessage?: string | JSX.Element;
  action?: JSX.Element;
};
function AnalyticFooterItem({ content, label, action, toolTipMessage }: AnalyticFooterItemProps) {
  return (
    <Container>
      {action && (
        <Tooltip arrow placement='top' title={toolTipMessage} enterDelay={300} enterNextDelay={300}>
          {action}
        </Tooltip>
      )}

      <div>
        <Typography variant='h6'>{label}</Typography>
        {!action ? (
          <Tooltip arrow placement='top' title={toolTipMessage} enterDelay={300} enterNextDelay={300}>
            {content}
          </Tooltip>
        ) : (
          content
        )}
      </div>
    </Container>
  );
}

export default AnalyticFooterItem;
