import React from 'react';

import { PieChart } from 'snap-ui/Charts';
import Placeholder from 'snap-ui/Placeholder';
import Typography from 'snap-ui/Typography';
import { useTheme } from 'snap-ui/util';

import { PlainStatContainer, StatPanelContainer, StatPanelPlaceholderContainer } from './style';

export function StatPanel({
  className,
  disableCard,
  dimensions,
  isPending,
  title,
  value
}: {
  className?: string;
  disableCard?: boolean;
  dimensions?: { innerRadius: number; outerRadius: number };
  isPending?: boolean;
  title: string;
  /** value in percent (0 - 100) */
  value: number;
}): JSX.Element {
  const { palette } = useTheme();
  const mainColor = value > 65 ? palette.lime : value > 33 ? palette.yellow : palette.red;
  const colors = [mainColor, palette.secondary];
  const height = dimensions ? dimensions.outerRadius * 2 : 260;

  const el = disableCard ? PlainStatContainer : StatPanelContainer;

  return React.createElement(el, { className }, [
    <>
      <Typography variant='h3'>{title}</Typography>
      {isPending ? (
        <StatPanelPlaceholderContainer>
          <Placeholder variant='circular' height={height} width={height} />
        </StatPanelPlaceholderContainer>
      ) : (
        <div className='chart'>
          <label className='value'>{value}%</label>
          <PieChart
            colors={colors}
            height={height}
            outerRadius={dimensions?.outerRadius ?? 120}
            innerRadius={dimensions?.innerRadius ?? 90}
            data={[
              { name: 'Value', value },
              { name: 'Not Value', value: 100 - value }
            ]}
          />
        </div>
      )}
    </>
  ]);
}
