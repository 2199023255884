import React from 'react';

import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { ActionIconButton } from 'snap-ui/Button';
import { GridColDef } from 'snap-ui/DataGrid';
import TableErrorMessage from 'snap-ui/TableErrorMessage';
import Tooltip from 'snap-ui/Tooltip';

import { DeployedFeeds } from 'module/DeployedHealth';
import CopyButton from 'module/Widgets/CopyButton';

import { truncateMiddle } from 'utilities/StringUtils';

import { CopyButtonContents } from './AnalyticTuning.style';
import { Bucket } from './AnalyticTuning.types';

type Col<T = Bucket> = GridColDef<T>;
type ExclusionCol<T = Bucket & { field: string }> = GridColDef<T>;

export const value: Col = {
  field: 'key',
  headerName: 'Value',
  renderCell: params => {
    const [truncated, isTruncated] = truncateMiddle(params.value + '', 32);
    const empty = isEmpty(truncated);

    return (
      <CopyButton arrow tooltip={isTruncated ? params.value : ''} value={params.value} tooltipWidth={500}>
        <CopyButtonContents className={classnames({ empty })}>
          {empty ? '<empty string>' : truncated}
        </CopyButtonContents>
      </CopyButton>
    );
  },
  flex: 1,
  minWidth: 300
};

export const count: Col = {
  field: 'doc_count',
  headerName: 'Count',
  renderCell: params => params.value,
  maxWidth: 60
};

export const percentage = (divisor: number): Col => ({
  field: 'percentage',
  headerName: 'Percentage',
  renderCell: params => {
    const percentage = ((params.row.doc_count / divisor) * 100).toFixed(2);
    return percentage;
  },
  sortable: false,
  maxWidth: 80
});

export const errorMessage: Col<DeployedFeeds['deploymentErrors']['items'][number]> = {
  field: 'error_message',
  headerName: 'Error Message',
  renderCell: params => <TableErrorMessage message={params.row.error_message} />,
  minWidth: 375
};

export const field: Col = {
  field: 'field',
  headerName: 'Field',
  renderCell: params => {
    const [truncated] = truncateMiddle(params.value + '', 16);

    return <span>{truncated}</span>;
  },
  minWidth: 200
};

export const excludeAction = (exclusions: Bucket[], onExclude: (value: Bucket) => void): Col => ({
  field: 'actions',
  headerName: 'Actions',
  disableColumnMenu: true,
  renderHeader: () => null,
  resizable: false,
  sortable: false,
  maxWidth: 50,
  renderCell: params => {
    const isExcluded = (exclusions || []).some(e => e.key === params.row.key);
    return (
      <>
        {isExcluded ? (
          <Tooltip arrow placement='right' title='add row back to detection'>
            <ActionIconButton
              aria-label='add row back to detection'
              icon={faPlusCircle}
              onClick={() => {
                onExclude(params.row);
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip arrow placement='right' title='exclude from detection'>
            <ActionIconButton
              aria-label='exclude row from detection'
              icon={faMinusCircle}
              onClick={() => {
                onExclude(params.row);
              }}
            />
          </Tooltip>
        )}
      </>
    );
  }
});

export const includeAction = (onExclude: (field: string) => (value: Bucket) => void): ExclusionCol => ({
  field: 'actions',
  headerName: 'Actions',
  disableColumnMenu: true,
  renderHeader: () => null,
  resizable: false,
  sortable: false,
  maxWidth: 50,
  renderCell: params => {
    return (
      <Tooltip arrow placement='right' title='add row back to detection'>
        <ActionIconButton
          aria-label='add row back to detection'
          icon={faPlusCircle}
          onClick={() => {
            onExclude(params.row.field)(params.row);
          }}
        />
      </Tooltip>
    );
  }
});

export const COMMON_GRID_CONFIG = {
  disableColumnReorder: true,
  disableMultipleRowSelection: true,
  disableColumnSelector: true,
  getRowId: () => Math.ceil(Math.random() * 100_000),
  hideFooter: true
};
