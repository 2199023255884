import React from 'react';

import {
  faArrowUpRightFromSquare,
  faLayerPlus,
  faMinus,
  faPlus,
  faSearch,
  faSpinnerThird
} from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import startCase from 'lodash/startCase';
import { Link } from 'react-router-dom';

import Alert from 'snap-ui/Alert';
import { DisplayDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import Placeholder from 'snap-ui/Placeholder';
import Table from 'snap-ui/Table';
import TextField from 'snap-ui/TextField';
import { useTheme } from 'snap-ui/util';

import Path from 'constants/paths';

import useDebounce from 'hooks/useDebounce';

import { ArtifactType, IconButtonRenderProps } from 'types/common';
import { Identity } from 'types/common.zod';
import { StrictReactNode } from 'types/core';

import { Container, Row, ToggleActionButton } from './AssociateArtifactsModal.style';

export type AssociateArtifactsModalProps = IconButtonRenderProps & {
  action: boolean;
  associated?: Identity[];
  options: Identity[];
  disabled?: boolean;
  handleToggleClick: (item: Identity, modifier: boolean) => Promise<void>;
  isActing: boolean;
  isPending: boolean;
  message: Message;
  onClose: () => void;
  onCreate?: (value: string) => Promise<void>;
  open: boolean;
  path: Path;
  refresh?: (query: string) => void;
  selectedItem: Partial<Identity>;
  title: string;
  searchedArtifactType: ArtifactType;
};

export type Message = { severity?: 'success' | 'error' | 'info' | 'warning'; data: StrictReactNode };

function sortByAssociated(associated: Identity[]) {
  return function (a: Identity, b: Identity) {
    const aFound = associated?.some(assoc => assoc.guid === a.guid);
    const bFound = associated?.some(assoc => assoc.guid === b.guid);

    if ((aFound && bFound) || (!aFound && !bFound))
      return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
    if (aFound) return -1;
    if (bFound) return 1;
  };
}

export default function AssociateArtifactsModal(props: AssociateArtifactsModalProps) {
  const { palette } = useTheme();
  const [query, setQuery] = React.useState<string>('');
  const debouncedQuery = useDebounce(query);
  const [onTheFly, setOnTheFly] = React.useState('');
  const {
    disabled,
    onClose,
    onCreate,
    open,
    message,
    title,
    refresh,
    isPending,
    options,
    associated,
    isActing,
    action,
    selectedItem,
    handleToggleClick,
    path
  } = props;

  React.useEffect(() => {
    if (open && refresh) refresh(debouncedQuery);
  }, [debouncedQuery, open, refresh]);

  function handleClose() {
    setQuery('');
    onClose();
  }

  const mapping = React.useMemo(() => {
    if (isPending) return placeholders();
    const result = options.sort(sortByAssociated(associated)).map(item => {
      const shouldSpin = selectedItem?.guid === item.guid && isActing;

      // if associated data set is not included, default to true e.g. bulk actions
      const included = associated?.some(a => a.guid === item.guid);

      return [
        <Row key={item.guid}>
          <Link to={`${path}/${item.guid}`} target='_blank' rel='noopener noreferrer'>
            <Icon icon={faArrowUpRightFromSquare} color={palette.secondary.light} /> {item.name}
          </Link>
          <ToggleActionButton
            value='minus'
            disabled={disabled || isActing || (associated && !included)}
            onClick={() => handleToggleClick(item, false)}
          >
            <Icon
              icon={action === false && shouldSpin ? faSpinnerThird : faMinus}
              className={classNames('minus', {
                included: included || !associated,
                disabled: disabled || (isActing && !shouldSpin)
              })}
              spin={action === false && shouldSpin}
            />
          </ToggleActionButton>
          <ToggleActionButton
            value='plus'
            disabled={disabled || isActing || (associated && included)}
            onClick={() => handleToggleClick(item, true)}
          >
            <Icon
              icon={action === true && shouldSpin ? faSpinnerThird : faPlus}
              className={classNames('plus', { included, disabled: disabled || (isActing && !shouldSpin) })}
              spin={action === true && shouldSpin}
            />
          </ToggleActionButton>
        </Row>
      ];
    });

    if (onCreate)
      result.unshift([
        <Row key='new'>
          <Icon icon={faLayerPlus} color={palette.secondary.light} />
          <TextField
            sx={{ width: '100%' }}
            placeholder={`Or create a new ${props.searchedArtifactType?.toLowerCase()}`}
            value={onTheFly}
            onChange={e => setOnTheFly(e.currentTarget.value)}
            disabled={disabled}
          />
          <ToggleActionButton
            value='plus'
            disabled={disabled || isActing || !onTheFly}
            onClick={async () => {
              await onCreate(onTheFly);
              setOnTheFly('');
            }}
          >
            <Icon
              icon={faPlus}
              className={classNames('plus', { disabled: disabled || isActing || !onTheFly })}
              spin={isActing}
            />
          </ToggleActionButton>
        </Row>
      ]);
    return result;
  }, [
    action,
    associated,
    options,
    disabled,
    handleToggleClick,
    isActing,
    isPending,
    onCreate,
    onTheFly,
    palette.secondary.light,
    path,
    selectedItem?.guid,
    props.searchedArtifactType
  ]);

  return (
    <DisplayDialog title={title} DialogProps={{ open, onClose: handleClose, maxWidth: 'sm' }}>
      <Container>
        {message?.data && <Alert severity={message.severity}>{message.data}</Alert>}
        <TextField
          className='query'
          label={`Search ${startCase(props.searchedArtifactType)}s`}
          startAdornment={<Icon icon={faSearch} />}
          onChange={event => setQuery(event.currentTarget.value.toLowerCase())}
        />
        <Table rows={mapping} columns={null} size='small' />
      </Container>
    </DisplayDialog>
  );
}

function placeholders() {
  return new Array(5)
    .fill(0)
    .map((_, index) => [<Placeholder key={index} variant='text' animation={Math.random() > 0.5 ? false : 'wave'} />]);
}
