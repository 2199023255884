import React from 'react';

import { AutocompleteV2 } from 'snap-ui/Autocomplete/AutocompleteV2';
import TextField from 'snap-ui/TextField';

import { JsonSchema, JsonSchema4, withJsonFormsControlProps } from 'module/JsonView';

import { InlineEditorForm } from '../../JsonView/InlineEditorForm';
import { useInlineEditor } from '../../JsonView/useInlineEditor';
import { isMetaEmpty, sortControlOptions } from '../Metadata.util';
import { Control, ControlProps } from './Control.type';
import { isControlApplied } from './Control.util';

const _Base = (props: {
  description?: string;
  disabled?: boolean;
  label: string;
  onChange: (value: readonly string[]) => void;
  options: readonly string[];
  value: readonly string[];
}) => {
  const { description, label, onChange, options, value } = props;
  return (
    <AutocompleteV2
      disabled={props.disabled}
      value={value}
      options={options}
      autoHighlight
      onChange={(_e, v) => onChange(v)}
      multiple
      renderInput={params => <TextField {...params} label={label} helperText={description} elevated />}
      className={`Control Control-${Control.MultiSelect}`}
    />
  );
};

function InlineMultiSelect(props: {
  data: readonly string[];
  enabled: boolean;
  options: readonly string[];
  path: string;
  schema: JsonSchema;
  type: string;
}) {
  const { data, enabled, options, path, schema, type } = props;
  const editor = useInlineEditor(data);
  return (
    <InlineEditorForm data={data} editor={editor} enabled={enabled} path={path} title={schema.title} type={type}>
      <_Base label={schema.title} value={editor.queue || []} options={options} onChange={v => editor.setQueue(v)} />
    </InlineEditorForm>
  );
}

const _MultiSelectControl = (props: ControlProps<readonly string[]>) => {
  const { config, data, enabled, handleChange, path, schema } = props;

  if ((!config.asPreview && !isControlApplied(schema, config)) || (config.truncated && isMetaEmpty(data))) return null;
  const options = ((schema.items as JsonSchema4).enum || []).sort(sortControlOptions);
  if (config.asInline)
    return (
      <InlineMultiSelect
        data={data}
        enabled={enabled}
        options={options}
        path={path}
        schema={schema}
        type={config.type}
      />
    );
  return (
    <_Base
      label={schema.title}
      value={data || []}
      options={options}
      onChange={v => handleChange(path, v)}
      disabled={!enabled}
    />
  );
};
export const MultiSelectControl = withJsonFormsControlProps(_MultiSelectControl);
