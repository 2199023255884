import React from 'react';

import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

import ArtifactScoreSliderFormik from 'module/Form/ArtifactScoreSliderFormik';

import SecurityProfileStep from './SecurityProfileStep';

const Container = styled(Paper, { name: 'SliderContainer' })`
  padding: 75px;
`;

export default function SecurityProfileStepSeverityThresholds(): JSX.Element {
  return (
    <SecurityProfileStep
      title='What are your severity thresholds?'
      description="Attack severity levels are determined by a combination of the likelihood of an attack's success and its potential impact on systems and data. Assessing these factors helps prioritize responses, enabling organizations to allocate resources and focus on mitigating high-severity threats that have both a significant likelihood of occurring and the potential to cause substantial damage."
      content={
        <Container>
          <ArtifactScoreSliderFormik type='SEVERITY' name='severity_thresholds' />
        </Container>
      }
    />
  );
}
