import React from 'react';

import { Autocomplete } from 'snap-ui/Autocomplete';

import {
  useLanguagePlatforms,
  useIntegrationOptions,
  useLanguageOptions,
  IntegrationAutocomplete
} from 'module/Integration';
import { getIntegrationValueFromLanguageId } from 'module/TranslateAnalytic';

import { useIntegrationCatalog } from 'provider';

import { IntegrationLanguageContainer } from './DetectionForm.style';

type LanguageField = {
  disabled?: boolean;
  onChange?(languageId: number): void;
  value: number;
};

export default function LanguageField({ disabled, onChange, value }: LanguageField): JSX.Element {
  const { supportedPlatforms } = useLanguagePlatforms();
  const { integrationOptions } = useIntegrationOptions(false);
  const [selectedIntegrationOptionValue, setSelectedIntegrationOptionValue] = React.useState('');
  const languageOptions = useLanguageOptions(selectedIntegrationOptionValue);

  const { integrations } = useIntegrationCatalog();

  React.useEffect(() => {
    // set selected integration on load
    // use the first integration that supports the detection's source language
    if (value && !selectedIntegrationOptionValue) {
      const integrationValue = getIntegrationValueFromLanguageId(
        value,
        integrationOptions,
        integrations.all,
        supportedPlatforms
      );
      if (integrationValue) setSelectedIntegrationOptionValue(integrationValue);
    }
  }, [integrationOptions, integrations.all, selectedIntegrationOptionValue, supportedPlatforms, value]);

  function handleIntegrationChange(value: string): void {
    setSelectedIntegrationOptionValue(value);

    let languageId: number;

    const integration = integrations.all.find(i => i.id.toString() === value);
    if (integration) {
      // selected option is a real integration
      // set language to the first target supported by the integration
      languageId =
        integration.deployment_targets[0]?.id ?? integration.hunt_targets[0]?.id ?? integration.search_targets[0]?.id;
    }

    const platform = supportedPlatforms[value];
    if (platform) {
      // selected option is a platform
      languageId = platform.deploy[0] ?? platform.hunt[0] ?? platform.search[0];
    }

    if (value.includes('language-')) {
      // selected option is a language with no platform support
      languageId = parseInt(value.replace('language-', ''));
    }

    if (languageId) onChange(languageId);
    else onChange(undefined);
  }

  function handleLanguageChange(value: string): void {
    onChange(parseInt(value, 10));
  }

  return (
    <IntegrationLanguageContainer>
      <IntegrationAutocomplete
        className='IntegrationSelection'
        disabled={disabled}
        disableClearable
        disableUserAdditions
        name='IntegrationSelection'
        onChange={handleIntegrationChange}
        options={integrationOptions}
        value={selectedIntegrationOptionValue}
      />
      {languageOptions?.length > 1 && (
        <Autocomplete
          disabled={disabled}
          name='languageId'
          onChange={handleLanguageChange}
          options={languageOptions}
          value={value?.toString() ?? ''}
        />
      )}
    </IntegrationLanguageContainer>
  );
}
