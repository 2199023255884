import React from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Paper from 'snap-ui/Paper';
import Placeholder from 'snap-ui/Placeholder';
import Stepper, { StepDetail } from 'snap-ui/Stepper';
import Tabs, { TabItem } from 'snap-ui/Tabs';
import Typography from 'snap-ui/Typography';

import useExtractDetails from 'hooks/useExtractDetails';

import { JobList, useJobList } from 'module/BAS/JobList';
import { canI } from 'module/Can';
import { useMayI } from 'module/May';
import { MetadataView } from 'module/Metadata';
import { getTagListDiffs } from 'module/Tag';
import TagList, { TagListProps } from 'module/Tag/TagList';
import OverviewTab, { Subtitle } from 'module/Widgets/OverviewTab';
import { Highlight } from 'module/Widgets/SyntaxEditor';
import { SyntaxViewer } from 'module/Widgets/SyntaxEditor/SyntaxEditor';

import { Status } from 'storage/Storage.type';

import { ContentPermission, FunctionalPermission } from 'types/auth';
import { ArtifactType, Guid } from 'types/common';
import { Ops, Query } from 'types/filter';

import { formatShortTimestamp } from 'utilities/TimeUtils';

import { quickAddTags, quickDeleteTags, tagRefresh } from '../AttackScript.api';
import { AttackScriptDetailContainer } from '../AttackScript.style';
import { AttackScript } from '../AttackScript.type';
import { CountChip } from '../AttackScript.widgets';
import { useAttackScriptCatalog } from '../AttackScriptProvider';
import useAttackScriptFeedTab from './useAttackScriptFeedTab';
import useAttackScriptRecommendedFilter from './useAttackScriptRecommendedFilter';

type AttackScriptDetailProps = {
  className?: string;
};

function getSteps(attackScript: AttackScript): StepDetail[] {
  const commandString = get(attackScript, 'script.atomic_tests.0.executor.command') || '';
  return commandString
    .split('\n')
    .filter(Boolean)
    .map(step => ({ label: step }));
}

export default function AttackScriptDetail({ className }: AttackScriptDetailProps) {
  const { attackScript, isPending } = useAttackScriptCatalog();
  const attackScriptTestResults = useAttackScriptTestResultsTab(attackScript.guid);
  const filter: Query = React.useMemo(
    () => (attackScript.id ? { field: 'session.bas_scripts.id', op: Ops.equals, value: attackScript.id } : null),
    [attackScript.id]
  );

  const attackScriptThreats = useAttackScriptThreatsTab(filter, ArtifactType.Session);
  const attackScriptDetections = useAttackScriptDetectionsTab(filter, ArtifactType.Analytic);

  if (isPending) return <AttackScriptDetailPlaceholder />;

  const tabs: TabItem[] = [
    {
      value: 'timeline',
      content: <Stepper steps={getSteps(attackScript)} vertical nonLinear />,
      label: 'Timeline'
    },
    {
      value: 'script',
      content: (
        <div className='AttackScriptDetail-scripts'>
          <div>
            Attack Script
            <SyntaxViewer highlight={Highlight.yaml} value={attackScript.script_yaml || ''} elevation={4} />
          </div>
          <div>
            Success Criteria
            <SyntaxViewer highlight={Highlight.yaml} value={attackScript.validation_yaml || ''} elevation={4} />
          </div>
        </div>
      ),
      label: 'Attack Script'
    },
    attackScriptTestResults,
    attackScriptDetections,
    attackScriptThreats
  ];

  return (
    <AttackScriptDetailContainer className={classnames('AttackScriptDetail', className)}>
      <div className='AttackScriptDetail-info'>
        <Paper className='overview'>
          <AttackScriptOverview />
        </Paper>
      </div>
      <Tabs className='AttackScriptDetail-tabs' tabs={tabs} />
    </AttackScriptDetailContainer>
  );
}

function AttackScriptOverview(): JSX.Element {
  const { attackScript, setAttackScript } = useAttackScriptCatalog();
  const authorMetadata = useExtractDetails(attackScript);

  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);
  const isEditable = isBasUser && canI(ContentPermission.Edit, attackScript);

  const hasTags = !isEmpty(
    [
      attackScript.attack_names,
      attackScript.actor_names,
      attackScript.vulnerability_names,
      attackScript.software_names
    ].flat()
  );

  const handleQuickAddTag: TagListProps['onSubmit'] = React.useCallback(
    async values => {
      const [addition, deletion] = getTagListDiffs(values, attackScript);
      const promised: Promise<unknown>[] = [];
      if (addition?.length > 0 || deletion?.length > 0) {
        if (addition?.length > 0) promised.push(quickAddTags(attackScript.guid, addition));
        if (deletion?.length > 0) promised.push(quickDeleteTags(attackScript.guid, deletion));
        await Promise.all(promised);
        const refreshedTags = await tagRefresh(attackScript.guid);
        setAttackScript({ ...attackScript, ...refreshedTags });
      }
    },
    [attackScript, setAttackScript]
  );

  return (
    <OverviewTab
      title={authorMetadata.organization?.name}
      description={attackScript.description || ''}
      references={{ references: attackScript.references }}
      subtitle={
        <Subtitle className='CreatorSubtitle'>
          <Typography>CREATOR: {authorMetadata.created_by}</Typography>
          <Typography>UPDATED: {formatShortTimestamp(authorMetadata.modified)} </Typography>
        </Subtitle>
      }
      orgImage={authorMetadata.organization?.small_image}
    >
      <MetadataView type={ArtifactType.AttackScript} guid={attackScript.guid} />
      {hasTags && (
        <TagList
          actor={attackScript.actor_names}
          attack={attackScript.attack_names}
          software={attackScript.software_names}
          vulnerability={attackScript.vulnerability_names}
          onSubmit={isEditable ? handleQuickAddTag : undefined}
        />
      )}
    </OverviewTab>
  );
}

function useAttackScriptTestResultsTab(scriptId: Guid): TabItem {
  const jobListAsync = useJobList({ script: scriptId });

  const isActive = jobListAsync.status === Status.pending;
  const disabled = isActive || !jobListAsync?.data?.total;

  return {
    value: 'test_results',
    content: <JobList includeTestCaseColumn={false} {...jobListAsync} />,
    label: (
      <>
        Attack Simulation Results <CountChip count={jobListAsync?.data?.total} />
      </>
    ),
    disabled
  };
}

function useAttackScriptThreatsTab(filter: Query, type: ArtifactType): TabItem {
  const { AttackScriptFeedTab, count, disabled } = useAttackScriptFeedTab(type, filter);

  return {
    value: 'threats',
    content: <AttackScriptFeedTab />,
    label: (
      <>
        Threats <CountChip count={count} />
      </>
    ),

    disabled
  };
}

function useAttackScriptDetectionsTab(filter: Query, type: ArtifactType): TabItem {
  const { deploymentFilter } = useAttackScriptRecommendedFilter(filter);
  const { AttackScriptFeedTab, count, disabled } = useAttackScriptFeedTab(type, deploymentFilter);

  return {
    value: 'detections',
    content: <AttackScriptFeedTab />,
    label: (
      <>
        Detections <CountChip count={count} />
      </>
    ),

    disabled
  };
}

export function AttackScriptDetailPlaceholder() {
  return (
    <AttackScriptDetailContainer className='AttackScriptDetail'>
      <div className='AttackScriptDetail-info'>
        <Placeholder className='overview' variant='rectangular' height={200} />
      </div>

      <div className='AttackScriptDetail-tabs'>
        <Placeholder variant='rectangular' height={400} />
      </div>
    </AttackScriptDetailContainer>
  );
}
