import yaml from 'js-yaml';
import get from 'lodash/get';

import { IDEState } from 'module/IDE/reducer';
import { Integration } from 'module/Integration/Integration.type';
import { Requester } from 'module/May';
import { SupplementalArtifact } from 'module/Search';
import { SigmaTag } from 'module/Tag/Tag.type';

import { checkContentPermission } from 'services/authService';

import { AnalyticCreate, CompilationTargetId } from 'types/analytic';
import { ContentPermission, FunctionalPermission, Permitted } from 'types/auth';
import { Artifact } from 'types/common';

import { simpleParse } from 'utilities/YamlUtils';

export type ExpandedArtifact = Artifact & {
  deployable_integrations: SupplementalArtifact['deployable_integrations'];
  huntable_integrations: SupplementalArtifact['huntable_integrations'];
  deployments: SupplementalArtifact['deployments'];
};

export function transformDatasets(artifacts: Artifact[], supplementals: SupplementalArtifact[]): ExpandedArtifact[] {
  const artifactCatalog = artifacts.reduce<{ [index: string]: ExpandedArtifact }>(
    (pre, cur) => ({
      ...pre,
      [cur.guid]: cur as ExpandedArtifact
    }),
    {}
  );

  supplementals.forEach(item => {
    if (item.guid in artifactCatalog) {
      const artifact = artifactCatalog[item.guid];
      artifact.deployable_integrations = item.deployable_integrations;
      artifact.huntable_integrations = item.huntable_integrations;
      artifact.deployments = item.deployments;
    }
  });

  return Object.values(artifactCatalog);
}

export function buildAnalyticPayload(ideState: IDEState, authorName: string): Partial<AnalyticCreate> {
  // parent_analytic_guid is not valid in PUT payload
  const parent_analytic_guid = ideState.guid ? undefined : ideState.analyticForm.parent_analytic_guid;

  return ideState.isNative
    ? {
        organization_id: ideState.analyticForm.organization_id,
        source_analytic_compilation_target_id: ideState.analyticForm.languageId,
        author: authorName,
        name: ideState.analyticForm.title,
        description: ideState.analyticForm.description,
        raw: ideState.raw,
        attack_names: (ideState.analyticForm['attack_names'] || []).map(t => t.value),
        actor_names: (ideState.analyticForm['actor_names'] || []).map(t => t.value),
        software_names: (ideState.analyticForm['software_names'] || []).map(t => t.value),
        vulnerability_names: (ideState.analyticForm['vulnerability_names'] || []).map(t => t.value),
        references: ideState.analyticForm.references,
        license_url: ideState.analyticForm.license_url,
        pinned_events: ideState.pinned_events,
        parent_analytic_guid
      }
    : {
        license_url: ideState.analyticForm.license_url,
        organization_id: ideState.analyticForm.organization_id,
        raw: ideState.raw,
        pinned_events: ideState.pinned_events,
        parent_analytic_guid
      };
}

function getSigmaNameFactory(tagCatalog?: SigmaTag[]) {
  return function (tagName: string) {
    return tagCatalog?.find(tag => tag.name == tagName)?.sigma_names?.[0] || tagName;
  };
}

export function augmentSigmaTags(raw: string, languageId: number, analytic: Artifact, tagCatalog?: SigmaTag[]): string {
  if (languageId !== CompilationTargetId.Sigma) return raw;
  if (
    !(
      analytic?.actor_names?.length ||
      analytic?.attack_names?.length ||
      analytic?.software_names?.length ||
      analytic?.vulnerability_names?.length
    )
  ) {
    return raw;
  }
  const [parsed, err] = simpleParse<unknown & { tags?: string[] }>(raw);
  if (err) return raw;
  const getSigmaName = getSigmaNameFactory(tagCatalog);
  parsed.tags = Array.from(
    new Set([
      ...(get(parsed, 'tags') || []),
      ...(get(analytic, 'actor_names') || []).map(getSigmaName),
      ...(get(analytic, 'attack_names') || []).map(getSigmaName),
      ...(get(analytic, 'software_names') || []).map(getSigmaName),
      ...(get(analytic, 'vulnerability_names') || []).map(getSigmaName)
    ])
  );
  return yaml.dump(parsed);
}

export function getReadonlyReason(integrationName?: string): string {
  return `This detection is managed ${
    integrationName ? `by ${integrationName}` : 'outside SnapAttack'
  }.  Any changes should be made directly in ${
    integrationName ?? 'the integration'
  } and will be synced back to SnapAttack.`;
}

function getIntegrationName(
  supplemental: SupplementalArtifact | null,
  integrations: Integration[] = []
): string | undefined {
  // read_only detections should have one integration in their deployment record, which is the source integration
  // there's no validation of this fact, however
  if (!supplemental?.read_only) return;
  return integrations.find(i => i.guid === supplemental.deployments?.[0]?.integrations?.[0]?.guid)?.name;
}

export function checkAnalyticPermission(
  permissions: Permitted,
  supplemental: SupplementalArtifact | null,
  action: ContentPermission,
  integrations?: Integration[]
): [boolean, string?] {
  if (supplemental?.read_only && (action === ContentPermission.Edit || action === ContentPermission.Delete)) {
    return [false, getReadonlyReason(getIntegrationName(supplemental, integrations))];
  }
  const can = checkContentPermission(permissions, action);
  if (can) return [can];
  return [can, `You don't have permission to ${action.toLowerCase()} this detection`];
}

export function checkAnalyticFunctionalPermission(
  requester: Requester,
  supplemental: SupplementalArtifact | null,
  action: FunctionalPermission,
  integrations?: Integration[]
): [boolean, string?] {
  if (
    supplemental?.read_only &&
    (action === FunctionalPermission.DeployAnalytic ||
      // in the analytic context, CreateAnalytic means "clone", and read_only analytics should not be cloned
      action === FunctionalPermission.CreateAnalytic)
  ) {
    return [false, getReadonlyReason(getIntegrationName(supplemental, integrations))];
  }
  const can = requester(action);
  if (can) return [can];
  return [can, `You don't have permission to ${action}`];
}
