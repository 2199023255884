import Typography from 'snap-ui/Typography';
import { theme } from 'snap-ui/theme';
import { styled } from 'snap-ui/util';

import { Artifact } from 'module/Layout';
import { AttackNode } from 'module/Matrix/Matrix.type';
import MiniMatrix from 'module/Matrix/MiniMatrix';
import { AttackType } from 'module/Tag';

import { MandiantSeverityStatus, VulnerabilitiesScore } from './Landing.type';

export const StyledArtifact = styled(Artifact)`
  .ArtifactWidget-title {
    display: flex;
    gap: ${p => p.theme.spacing(3)};
    margin-left: 0;
  }

  .HeroImage-window {
    display: grid;
    grid-template-columns: 1fr 200px;
    grid-template-rows: repeat(5, min-content);

    grid-template-areas:
      'type profile'
      'title profile'
      'subtype subtype'
      'alias alias'
      'footer footer';

    .Artifact-type {
      grid-area: type;
      justify-content: flex-start;
    }

    .ArtifactWidget-title,
    .MuiSkeleton-text {
      grid-area: title;
    }

    .Landing-alias {
      grid-area: alias;
    }

    .Landing-subtype {
      grid-area: subtype;
      span {
        text-transform: uppercase;
      }
    }

    .AddToSecurityProfile {
      grid-area: profile;
    }

    .ArtifactWidget-footer,
    .MuiSkeleton-rectangular {
      grid-area: footer;
    }
  }

  .group-detail {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .motivation-icon {
    align-items: center;
    display: flex;
    gap: ${p => p.theme.spacing(2)};

    svg {
      width: 20px;
    }
  }
  .country-flag {
    display: flex;
    align-items: flex-start;
    gap: ${p => p.theme.spacing(1)};
  }

  .data-grid {
    .even {
      background: ${p => p.theme.palette.background.default};
    }
    .cell-space {
      padding-top: ${p => p.theme.spacing(4)};
      padding-bottom: ${p => p.theme.spacing(4)};
    }
  }

  .Landing-alias {
    display: flex;
    gap: ${p => p.theme.spacing(4)};
    margin: ${p => p.theme.spacing(5, 0)};
    align-items: center;
  }

  .LandingSection-accordion {
    background-color: inherit;
    margin: ${p => p.theme.spacing(8, 0)};

    .Landing-accordion-detail {
      padding: 0;
    }
  }

  .LandingSection-accordionSummary {
    padding: 0;
    margin-top: ${p => p.theme.spacing(8)};

    &.MuiAccordionSummary-root.Mui-expanded {
      min-height: inherit;
    }

    .MuiAccordionSummary-content.Mui-expanded {
      margin: ${p => p.theme.spacing(3, 0)};
    }
  }

  .LandingHeaderFooterContent {
    margin-right: ${p => p.theme.spacing(2)};
  }

  .collapse {
    flex: 1 0 100%;
    justify-content: flex-start;
  }

  .industryChip {
    justify-content: flex-start;
    min-width: 0;
  }

  .flex-break {
    flex-basis: 100%;
    height: 0;
  }

  .LandingSection-accordionDetails,
  .LandingSection-accordionDetails-section {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};
    padding: 0;
  }

  .LandingSection-accordionDetails-section:not(:last-of-type) {
    margin-bottom: ${p => p.theme.spacing(3)};
  }

  .LandingSection-content.side {
    display: flex;
    flex-wrap: wrap;
    gap: ${p => p.theme.spacing(3)};
  }

  .LandingSection-chip:hover {
    color: inherit;
  }

  .LandingSection-chip {
    border: 1px solid ${p => p.theme.palette.secondary.dark};
  }

  .LandingSection-chip.actor {
    border: 1px solid ${p => p.theme.palette.yellow.main};
  }

  .LandingSection-chip.aliases {
    border: 1px solid ${p => p.theme.palette.blue.main};
  }

  .LandingSection-chip.sigma {
    border: 1px solid ${p => p.theme.palette.info.light};
  }

  .LandingSection-chip.software.malware {
    border: 1px solid ${p => p.theme.palette.orange.main};
  }

  .LandingSection-chip.software,
  .LandingSection-chip.software.tool {
    border: 1px solid ${p => p.theme.palette.secondary.contrastText};
  }

  .LandingSection-chip.technique {
    border: 2px solid ${p => p.theme.palette.mitre.main};
  }

  .LandingSection-chip.subtechnique {
    border: 1px solid ${p => p.theme.palette.mitre.main};
  }

  .LandingSection-chip.vulnerability {
    border: 1px solid ${p => p.theme.palette.mint.main};
  }

  .section-title {
    text-transform: uppercase;
  }

  .copy-button {
    margin: 0;
    align-items: center;
  }
  .mandiantLogo-prefix {
    margin-left: -1.5px;
    padding-bottom: ${p => p.theme.spacing(1)};
  }

  .cwe-link {
    display: flex;
    gap: ${p => p.theme.spacing(2)};
    align-items: center;
    text-decoration: none;
  }

  .${VulnerabilitiesScore.CRITICAL},
    .${MandiantSeverityStatus.Yes},
    .${MandiantSeverityStatus.Wide},
    .${VulnerabilitiesScore.HIGHEST},
    .${MandiantSeverityStatus.Yes} {
    color: ${p => p.theme.palette.error.main};
  }

  .${VulnerabilitiesScore.HIGH}, .${MandiantSeverityStatus.Confirmed} {
    color: ${p => p.theme.palette.orange.main};
  }

  .${VulnerabilitiesScore.MEDIUM}, .${MandiantSeverityStatus.Available} {
    color: ${p => p.theme.palette.warning.main};
  }

  .${VulnerabilitiesScore.LOW}, .${MandiantSeverityStatus.Anticipated} {
    color: ${p => p.theme.palette.success.main};
  }
`;

export const CVSSContainer = styled('div')`
  flex: 1 0 40%;
  padding: ${p => p.theme.spacing(4)};
  background-color: ${p => p.theme.palette.grey[900]};

  @media (max-width: 1000px) {
    flex-basis: 100%;
  }

  .cvssCard-detail {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(6)};

    .score-detail-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: ${p => p.theme.spacing(6)};
    }
  }
`;
export const CategorySection = styled('div')`
  margin: ${p => p.theme.spacing(8, 0)};
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(1)};
`;
export const CategoryContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};

  &.row {
    flex-direction: row;
  }

  .h4,
  .h6 {
    margin: 0;
  }

  .category {
    margin: 0;
  }

  .category-detail {
    font-size: 0.85em;
    display: grid;
    gap: ${p => p.theme.spacing(3, 6)};
    grid-template-columns: 1fr;
    @media (min-width: 800px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 1400px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .icon-title {
    svg {
      width: 20px;
    }
    display: flex;
    gap: ${p => p.theme.spacing(3)};
    align-items: center;
  }

  .category-detail-item {
    flex-basis: 25%;
  }

  .category-details-title {
    color: ${p => p.theme.palette.grey[400]};
  }

  .category-title {
    display: grid;
    grid-gap: ${p => p.theme.spacing(3)};
  }

  .epss-title {
    display: inline-block;
  }
`;

export const SubTitle = styled(Typography)`
  color: ${p => p.theme.palette.grey[400]};
  margin: 0;
`;

export function getLandingMatrixStyle(node: AttackNode, isIncluded: boolean) {
  if (!node || node.type === AttackType.Tactic) return;

  if (isIncluded)
    return {
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.getContrastText(theme.palette.grey[700])
    };

  return {
    display: 'none'
  };
}

export const LandingMiniMatrix = styled(MiniMatrix)`
  justify-content: flex-start;
  margin-top: -8px;
  min-height: 600px;

  .Tactic {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
  }
  .Technique,
  .SubTechnique {
    margin-left: 0;
    border-left: 2px solid transparent;
  }

  .Technique {
    border-left: 2px solid ${p => p.theme.palette.yellow.main};
  }
`;
