import React from 'react';

import isEqual from 'lodash/isEqual';

import { Discriminator } from 'module/Tag';

import { StatPanel } from '../Widgets';
import { ProfileStatGrid } from '../style';
import { decimalToPercent } from '../util';
import { ProfileCoverageInterface } from './useProfileCoverage';

function Stats({
  getTagTypeReport,
  isCoverageReportPending,
  totalCoverage,
  isTotalCoveragePending
}: ProfileCoverageInterface): JSX.Element {
  const [actors, software, vulnerabilities, attack] = React.useMemo(
    () =>
      [Discriminator.Actor, Discriminator.Software, Discriminator.Vulnerability, Discriminator.Attack].map(tagType =>
        decimalToPercent(getTagTypeReport(tagType)?.coverage_avg ?? 0)
      ),
    [getTagTypeReport]
  );
  return (
    <ProfileStatGrid>
      <StatPanel
        className='total'
        dimensions={{
          outerRadius: 250,
          innerRadius: 200
        }}
        isPending={isTotalCoveragePending}
        title='Threat Profile Coverage'
        value={decimalToPercent(totalCoverage)}
      />
      <StatPanel isPending={isCoverageReportPending} title='Actors' value={actors} />
      <StatPanel isPending={isCoverageReportPending} title='Software' value={software} />
      <StatPanel isPending={isCoverageReportPending} title='Vulnerabilities' value={vulnerabilities} />
      <StatPanel isPending={isCoverageReportPending} title='MITRE ATT&CK' value={attack} />
    </ProfileStatGrid>
  );
}

export default React.memo(Stats, isEqual);
