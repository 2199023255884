import React from 'react';

import { faChevronCircleLeft } from '@fortawesome/pro-regular-svg-icons';
import { useLocation } from 'react-router-dom';

import { IconButton, RouterButton } from 'snap-ui/Button';
import IFrame from 'snap-ui/IFrame';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import { useCannotRedirect } from 'aso/useCannotRedirect';

import useJobGroupDetail, { JobGroupDetailInterface } from 'module/Job/useJobGroupDetail';
import { List } from 'module/Scaffold/Scaffold.style';
import ListItemLink from 'module/Scaffold/core/ListItemLink';
import ToolbarItemLogo from 'module/Scaffold/core/ToolbarItemLogo';

import { useSidebar } from 'provider';
import { DrawerHeader } from 'provider/SidebarProvider/Sidebar.style';
import { SidebarState } from 'provider/SidebarProvider/Sidebar.type';

import { FunctionalPermission } from 'types/auth';

import { formatQueryString, getQueryParam } from 'utilities/SearchParam';

import { buildWorkbenchLink, getEndTime, getStartTime, removeDuplicateDetailItems } from './';
import { WorkbenchSidebarContainer } from './Hunt.style';
import { DetectionHits, ExportButton } from './Hunt.widget';

export default function Workbench(): JSX.Element {
  useCannotRedirect(FunctionalPermission.OpenSearchUIAccess);
  const { search } = useLocation();
  const detectionGuid = getQueryParam(search, 'detection');
  const integrationGuid = getQueryParam(search, 'integrationGuid');
  const { jobGroup, jobs, details, exportDataAsCsv, isExportPending, hits, dispatchErrors } = useJobGroupDetail();
  const sidebarContent = React.useCallback(
    (state: SidebarState) => {
      return (
        <WorkbenchSidebar
          details={details}
          dispatchErrors={dispatchErrors}
          hits={hits}
          exportDataAsCsv={exportDataAsCsv}
          isExportPending={isExportPending}
          {...state}
        />
      );
    },
    [details, dispatchErrors, exportDataAsCsv, hits, isExportPending]
  );
  useSidebar(true, { content: sidebarContent });

  const startTime = getStartTime(jobGroup);
  const endTime = getEndTime(jobGroup);
  const huntGuids = jobs.map(h => h.analytic_job_guid);

  const url = buildWorkbenchLink(startTime, endTime, huntGuids, detectionGuid, integrationGuid, true);

  return <IFrame src={url} />;
}

function WorkbenchSidebar({
  details,
  exportDataAsCsv,
  isExportPending,
  dispatchErrors,
  hits,
  open,
  setOpen
}: SidebarState &
  Pick<
    JobGroupDetailInterface,
    'details' | 'dispatchErrors' | 'hits' | 'exportDataAsCsv' | 'isExportPending'
  >): JSX.Element {
  const { pathname } = useLocation();
  const uniqueDetection = removeDuplicateDetailItems(details);

  return open ? (
    <>
      <DrawerHeader>
        <ToolbarItemLogo />
      </DrawerHeader>
      <WorkbenchSidebarContainer>
        <div className='header'>
          <RouterButton to={pathname.replace('/workbench', '')}>Back to Hunt Details</RouterButton>
          <IconButton onClick={() => setOpen(false)}>
            <Icon icon={faChevronCircleLeft} />
          </IconButton>
        </div>
        <Typography variant='h3'>Export Detection Hits</Typography>
        <ExportButton isExportPending={isExportPending} exportDataAsCsv={exportDataAsCsv}>
          All Hits
        </ExportButton>
        <Typography variant='h3'>Quick Filters</Typography>
        <RouterButton to={{ pathname, search: '' }} variant='outlined'>
          All Detections
        </RouterButton>
        <Typography variant='h4'>By Detection</Typography>
        <List>
          {uniqueDetection.map(detection => (
            <ListItemLink
              key={detection.guid}
              title={
                <div className='detection'>
                  <span>{detection.name}</span>
                  <DetectionHits
                    detection={{ ...detection, hit_count: hits[detection.guid] }}
                    dispatchErrors={dispatchErrors}
                  />
                </div>
              }
              to={formatQueryString({ detection: detection.guid })}
            />
          ))}
        </List>
      </WorkbenchSidebarContainer>
    </>
  ) : null;
}
