import { snapattack } from 'apis/snapattack';

import { Guid } from 'types/common';

import { JobResult, PostJobsInput } from './Job.type';

const prefix = '/jobs';
const jobs = `${prefix}/`;

export function postJobs(body: PostJobsInput) {
  return snapattack.post(jobs, body).then(res => res.data);
}

export function getJobs() {
  return snapattack.get(jobs).then(res => res.data);
}
export function getJobSchemaCatalog() {
  return snapattack.get(`${jobs}schema/`).then(res => res.data);
}

export function getJobOverview() {
  return snapattack.get(`${prefix}/overview/v1/`).then(r => r.data);
}

export function getJobOverview_v2() {
  return snapattack.get(`${prefix}/overview/`).then(r => r.data);
}

export function getJobOverviewResult(guid: Guid) {
  return snapattack.get(`${prefix}/overview/${guid}/`).then(res => res.data);
}

export function getJobGroups() {
  return snapattack.get(`${jobs}groups/`).then(res => res.data);
}

export function getSingleJob(guid: Guid) {
  return snapattack.get(`${jobs}${guid}/`).then(r => r.data);
}

export function pauseJob(guid: Guid): Promise<void> {
  return snapattack.post(`${jobs}${guid}/pause/`).then(res => res.data);
}

export function resumeJob(guid: Guid): Promise<void> {
  return snapattack.post(`${jobs}${guid}/resume/`).then(res => res.data);
}

export function cancelJob(guid: Guid): Promise<JobResult> {
  return snapattack.post(`${jobs}${guid}/cancel/`).then(res => res.data);
}

export function deleteJob(guid: Guid) {
  return snapattack.delete(`${jobs}${guid}/`).then(res => res.data);
}

export function getJobResult(guid: Guid) {
  return snapattack.get(`${jobs}${guid}/result/`).then(res => res.data);
}

export function postClientHitsExport(groupGuid: Guid, params: { start: string; end: string }): Promise<string> {
  return snapattack
    .post(`/detection/events/client/analytic_hits/job/groups/export/${groupGuid}/raw/`, { csv: true, ...params })
    .then(r => r.data);
}
