import React from 'react';

import { faLayerPlus } from '@fortawesome/pro-solid-svg-icons';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import { useAuth } from 'provider/Account';

import { checkTaskPermission } from 'services/authService';

import { FunctionalPermission } from 'types/auth';

import { AddSelectedArtifactsToCollection } from './AddSelectedArtifactsToCollection';

type Provider = { children: React.ReactNode };
type Button = Partial<Omit<BurgerClicker, 'onClick'>>;
type Dialog = Omit<AddSelectedArtifactsToCollection, 'isOpen' | 'onClose'>;
interface AddToCollectionInterface {
  isOpen: boolean;
  setIsOpen(open: boolean): void;
}

export function addToCollectionInterfaceFactory(
  displayName: string
): [React.ComponentType<Provider>, React.ComponentType<Button>, React.ComponentType<Dialog>] {
  const AddToCollectionContext = React.createContext<AddToCollectionInterface>(null);
  AddToCollectionContext.displayName = displayName;

  function AddToCollectionInterface({ children }: Provider): JSX.Element {
    const [isOpen, setIsOpen] = React.useState(false);

    return <AddToCollectionContext.Provider value={{ isOpen, setIsOpen }}>{children}</AddToCollectionContext.Provider>;
  }

  function useAddToCollection(): AddToCollectionInterface {
    const context = React.useContext(AddToCollectionContext);
    if (!context) {
      throw new Error(`AddToCollection components must be used within ${displayName}`);
    }
    return context;
  }

  function AddToCollectionButton(props: Button): JSX.Element {
    const { setIsOpen } = useAddToCollection();
    const { permission } = useAuth();
    const notAllowed = !checkTaskPermission(permission, FunctionalPermission.CreateCollection);

    return (
      <BurgerClicker
        title='Add to Collection'
        icon={faLayerPlus}
        onClick={() => setIsOpen(true)}
        {...props}
        disabled={notAllowed}
        TooltipProps={{
          title: notAllowed ? 'You do not have permission to create a collection.' : ''
        }}
      />
    );
  }

  function AddToCollectionDialog(props: Dialog) {
    const { isOpen, setIsOpen } = useAddToCollection();

    return (
      <AddSelectedArtifactsToCollection
        key={`${displayName}_dialog`}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        {...props}
      />
    );
  }

  return [AddToCollectionInterface, AddToCollectionButton, AddToCollectionDialog];
}
