import React from 'react';

import {
  faHexagonCheck as faHexagonCheckOutlined,
  faHexagon as faHexagonOutlined,
  faHexagonXmark as faHexagonXmarkOutlined,
  faRotateExclamation,
  IconDefinition
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCloudArrowUp,
  faFileCircleInfo,
  faHexagonCheck as faHexagonCheckSolid,
  faHexagonXmark as faHexagonXmarkSolid,
  faTrash
} from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { Link, useHistory } from 'react-router-dom';

import Button, { ActionIconButton } from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import { ConfirmDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import { MenuButton, MenuTrigger } from 'snap-ui/Menu';
import { Placeholders } from 'snap-ui/Placeholder';
import Tooltip from 'snap-ui/Tooltip';

import useWorkflow from 'aso/useWorkflow';

import Path from 'constants/paths';

import { getReadonlyReason } from 'module/Analytic/Analytic.util';
import { useAnalyticLanguageCatalog } from 'module/Analytic/AnalyticLanguages';
import { Integration } from 'module/Integration/Integration.type';
import { BurgerClicker } from 'module/Layout/Artifact.widgets';
import { useMayI } from 'module/May';
import { SupplementalArtifact } from 'module/Search';
import { TimeAgoTimestamp } from 'module/Widgets/TimeAgoTimestamp';

import { useAuth, useIntegrationCatalog, usePushSnack } from 'provider';

import { Status } from 'storage';

import { AnalyticDeploymentDetail, AnalyticRecommendation, DeploymentIntegration } from 'types/analytic';
import { FunctionalPermission } from 'types/auth';
import { Artifact, Guid, Ident } from 'types/common';
import { StrictReactNode } from 'types/core';

import { renderValueWithLabel } from 'utilities/TextUtils/TextUtils';

import { useAnalyticVersionCatalog } from '../AnalyticVersionProvider';
import {
  createDeployableIntegration,
  isCustomerManaged,
  isNotDeployable,
  isSupplementallyDeployable
} from './Deployment.helper';
import { UndeployButton, UndeployDialog, UndeployInterface } from './UndeploymentInterface';
import {
  addDeployment,
  getDeployDisabledTooltip,
  removeDeploymentOrIntegration,
  Section,
  SectionTitle,
  sortDeployment
} from './util';

type DeployIntegrationButtonProps = {
  guid: Guid;
  existingDeployment?: AnalyticDeploymentDetail;
  integration: DeploymentIntegration;
  onDeploy(deployment: AnalyticDeploymentDetail): void;
  showIfDisabled?: boolean;
  children?(props: { disabled: boolean; handleClickDeploy(): void }): StrictReactNode;
};

export function DeployIntegrationButton({
  guid,
  existingDeployment,
  integration,
  onDeploy,
  showIfDisabled,
  children
}: DeployIntegrationButtonProps): JSX.Element {
  const { defaultOrgId } = useAuth();
  const pushSnack = usePushSnack();
  const canDeploy = useMayI(FunctionalPermission.DeployAnalytic);
  const { deploy, status } = useWorkflow(guid);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const isLoading = status === Status.pending;
  const isDisabled = !integration?.compatible;

  if (!canDeploy) return null;
  // integration hasn't loaded yet, so we don't know where to deploy to
  if (!integration) return null;
  // If you publish a detection and a compilation is still active, compatible will return 'false' from the backend
  if (isDisabled && !showIfDisabled) return null;

  function handleClickDeploy() {
    setShowConfirm(true);
  }

  function handleConfirmDeploy() {
    let payload;
    if (existingDeployment) {
      payload = {
        overwrite: true,
        organization_id: existingDeployment.organization_id,
        integrations: [...existingDeployment.integrations.map(i => i.guid), integration.guid]
      };
    } else {
      payload = {
        organization_id: defaultOrgId,
        integrations: [integration.guid]
      };
    }
    deploy(payload).then(handleDeploySuccess).catch(handleDeployFailure);
  }

  function handleDeploySuccess(newDeployment: AnalyticDeploymentDetail) {
    setShowConfirm(false);
    onDeploy(newDeployment);
  }

  function handleDeployFailure() {
    pushSnack('Something went wrong', 'error');
  }

  function handleCancel() {
    setShowConfirm(false);
  }

  return (
    <>
      <Tooltip arrow wrap title={isDisabled ? getDeployDisabledTooltip(integration) : undefined}>
        <>{children && children({ disabled: isDisabled, handleClickDeploy })}</>
      </Tooltip>
      <ConfirmDialog
        ConfirmProps={{
          children: isLoading ? <CircularProgress size={25} /> : 'Deploy',
          disabled: isLoading,
          onClick: handleConfirmDeploy
        }}
        DialogProps={{ open: showConfirm, onClose: handleCancel }}
        title='Deploy Detection'
      >
        Are you sure you want to deploy this detection to {integration?.name || 'this integration'}?
      </ConfirmDialog>
    </>
  );
}

type DeploymentProps = {
  status: Status;
  artifact?: Artifact & {
    raw?: string;
  };
  analytic?: AnalyticRecommendation;
  supplemental?: SupplementalArtifact;
};

export default function Deployment(props: DeploymentProps): JSX.Element {
  const { push } = useHistory();
  const { defaultOrgId } = useAuth();

  const { integrations, status: integrationStatus } = useIntegrationCatalog();
  const { data: languageList } = useAnalyticLanguageCatalog();
  const { versions } = useAnalyticVersionCatalog();

  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const canCreateIntegrations = useMayI(FunctionalPermission.CreateIntegration);

  // copy the list of deployments for the preferred org into local state
  // it will need to be modified locally for optimistic updates due to BE caching
  const [orgDeployments, setOrgDeployments] = React.useState<AnalyticDeploymentDetail[]>([]);
  const customerManagedIntegrations: Integration[] = React.useMemo(() => {
    return integrations.preferred_org_aware?.filter(
      integration =>
        isCustomerManaged(integration) &&
        isNotDeployable(integration, integrations.deployable) &&
        isSupplementallyDeployable(integration, props.supplemental)
    );
  }, [integrations.deployable, integrations.preferred_org_aware, props.supplemental]);

  React.useEffect(() => {
    if (props.supplemental?.deployments?.length) {
      setOrgDeployments(props.supplemental?.deployments?.filter(d => d.organization_id === defaultOrgId));
    } else if (props.analytic?.deployments?.length) {
      setOrgDeployments(props.analytic.deployments?.filter(d => d.organization_id === defaultOrgId));
    }
  }, [defaultOrgId, props.analytic?.deployments, props.supplemental?.deployments]);

  const isIntegrationsLoading = [props.status, integrationStatus].some(status => status === Status.pending);

  const deployableIntegrations: DeploymentIntegration[] = React.useMemo(() => {
    return [...integrations.deployable, ...customerManagedIntegrations]
      .map(integration => createDeployableIntegration(integration, props.analytic, orgDeployments, languageList))
      .sort(sortDeployment);
  }, [customerManagedIntegrations, integrations.deployable, languageList, orgDeployments, props.analytic]);

  const guid = props.artifact ? props.artifact.guid : props.analytic.guid;
  const reducedFilterIntegration = isCollapsed ? deployableIntegrations.slice(0, 4) : deployableIntegrations;

  function optimisticallyDeleteDeployment(deploymentId: Ident, integrationGuid?: Guid) {
    setOrgDeployments(removeDeploymentOrIntegration(orgDeployments, deploymentId, integrationGuid));
  }

  function optimisticallyAddDeployment(guid: Guid, newDeployment: AnalyticDeploymentDetail) {
    setOrgDeployments(orgDeployements => addDeployment(orgDeployements, newDeployment, guid));
  }

  function isDeploymentLatestVersion(deployments: AnalyticDeploymentDetail[], integration: DeploymentIntegration) {
    return deployments?.find(deploy => deploy.analytic_version_id === integration.deployment?.analytic_version_id)
      ?.is_current;
  }

  function toggleCollapsed(): void {
    setIsCollapsed(collapsed => !collapsed);
  }

  function getDeploymentStatus(integration: DeploymentIntegration): {
    color?: 'success' | 'error';
    icon: IconDefinition;
    message: string;
    action?: string;
    tooltip?: string;
  } {
    const { guid: integrationGuid, deployment, compatible } = integration;
    if (deployment?.success_deployment?.some(({ guid }) => guid === integrationGuid))
      return { color: 'success', icon: faHexagonCheckSolid, message: 'Deployed', action: 'undeploy' };
    if (deployment?.pending_deployment?.some(({ guid }) => guid === integrationGuid))
      return { color: 'success', icon: faHexagonCheckOutlined, message: 'Queued', action: 'undeploy' };
    if (deployment?.failed_deployment?.some(({ guid }) => guid === integrationGuid)) {
      const error_msg = deployment?.status?.find(({ integration }) => integration === integrationGuid)?.error_message;
      return {
        color: 'error',
        icon: faHexagonXmarkSolid,
        message: 'Error',
        action: 'undeploy',
        tooltip: error_msg
      };
    }
    if (compatible) return { icon: faHexagonOutlined, message: 'Not deployed', action: 'deploy' };

    return { icon: faHexagonXmarkOutlined, color: 'error', message: 'Not compatible' };
  }

  function versionMessage(number) {
    return number > 0 ? (
      <>
        {renderValueWithLabel(number, 'Version')} Behind
        <br />
        Click To See Version Changes
      </>
    ) : null;
  }

  return (
    <UndeployInterface>
      <Section>
        <SectionTitle>Deployment</SectionTitle>
        {isIntegrationsLoading ? (
          <Placeholders count={5} />
        ) : props.supplemental?.read_only ? (
          props.supplemental?.deployments?.map(deployment => {
            const integration = integrations.all.find(i => i.guid === deployment.integrations?.[0].guid);
            const deploymentStatus = getDeploymentStatus(deployableIntegrations.find(i => i.guid === integration.guid));
            return (
              !!integration && (
                <dl key={integration.guid}>
                  <div className={classnames(deploymentStatus.color)}>
                    <dt>
                      <Icon className='icon' icon={deploymentStatus.icon} color={deploymentStatus.color} />
                      <span className='text'>{integration.name}</span>
                    </dt>
                    <dd>
                      <span className='text'>{deploymentStatus.message}</span>
                    </dd>
                  </div>
                  <em>{getReadonlyReason(integration.name)}</em>
                </dl>
              )
            );
          })
        ) : (
          <>
            <dl>
              {deployableIntegrations.length ? (
                reducedFilterIntegration.map(integration => {
                  const amountOfIntegrationVersion = versions.findIndex(
                    v => v.id === integration.deployment?.analytic_version_id
                  );
                  const diffVersion = versions?.[amountOfIntegrationVersion]?.id;
                  const deploymentStatus = getDeploymentStatus(integration);
                  return (
                    <div className={classnames(deploymentStatus.color)} key={integration.guid}>
                      <dt>
                        <Icon className='icon' icon={deploymentStatus.icon} color={deploymentStatus.color} />
                        <span className='text'>{integration.name}</span>
                      </dt>
                      <dd>
                        <span className='text'>
                          <Tooltip wrap arrow placement='bottom' title={deploymentStatus?.tooltip}>
                            <>
                              {deploymentStatus.message}
                              <br />
                              <TimeAgoTimestamp
                                timestamp={integration.deployment?.creation}
                                disabledTooltip={!!deploymentStatus?.tooltip}
                              />
                            </>
                          </Tooltip>
                        </span>
                        <div className='deploy-options'>
                          {deploymentStatus.action === 'deploy' && (
                            <DeployIntegrationButton
                              guid={guid}
                              existingDeployment={orgDeployments[0]}
                              integration={integration}
                              onDeploy={(newDeployment: AnalyticDeploymentDetail) => {
                                optimisticallyAddDeployment(integration.guid, newDeployment);
                              }}
                            >
                              {({ handleClickDeploy }) => (
                                <ActionIconButton
                                  aria-label='Deploy'
                                  className='icon'
                                  icon={faCloudArrowUp}
                                  onClick={handleClickDeploy}
                                />
                              )}
                            </DeployIntegrationButton>
                          )}
                          {deploymentStatus.action === 'undeploy' && (
                            <>
                              {isDeploymentLatestVersion(props.supplemental?.deployments, integration) ? (
                                <UndeployButton deployment={integration.deployment} integration={integration} />
                              ) : (
                                <Tooltip arrow placement='top' title='Update available. Review and Redeploy'>
                                  <div>
                                    <MenuTrigger
                                      className='versioning'
                                      aria-label='deploy-menu-trigger'
                                      trigger={
                                        <MenuButton aria-label='deploy-menu-options' icon={faRotateExclamation} />
                                      }
                                    >
                                      <BurgerClicker
                                        title='Review and Redeploy'
                                        label='ReviewAndRedeploy'
                                        icon={faFileCircleInfo}
                                        onClick={() =>
                                          push(
                                            `${Path.Detection}/${guid}/version/${diffVersion}/diff/${versions?.[0].id}?deployedEnvironment=${integration.guid}`
                                          )
                                        }
                                        disabled={!integration.compatible}
                                        TooltipProps={
                                          !integration.compatible
                                            ? {
                                                title: getDeployDisabledTooltip(integration)
                                              }
                                            : undefined
                                        }
                                      />
                                      <UndeployButton deployment={integration.deployment} integration={integration}>
                                        {({ handleClickUndeploy }) => (
                                          <BurgerClicker
                                            label='Undeploy'
                                            title='Undeploy'
                                            icon={faTrash}
                                            onClick={() => handleClickUndeploy()}
                                          />
                                        )}
                                      </UndeployButton>
                                    </MenuTrigger>
                                  </div>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </div>
                        {!isDeploymentLatestVersion(props.supplemental?.deployments, integration) && (
                          <span className='versionLineage'>{versionMessage(amountOfIntegrationVersion)}</span>
                        )}
                      </dd>
                    </div>
                  );
                })
              ) : (
                <p>
                  {canCreateIntegrations ? (
                    <Link to={Path.Integrations}>Configure integrations</Link>
                  ) : (
                    'Ask your organization admin to configure integrations'
                  )}{' '}
                  to have this detection deployed to your live environment.
                </p>
              )}
            </dl>
            {deployableIntegrations.length > 4 && (
              <Button
                variant='text'
                onClick={toggleCollapsed}
                aria-label={isCollapsed ? 'show all integrations' : 'hide integrations'}
              >
                {isCollapsed ? 'view all integrations' : 'hide integrations'}
              </Button>
            )}
            <UndeployDialog guid={guid} onDelete={optimisticallyDeleteDeployment} />
          </>
        )}
      </Section>
    </UndeployInterface>
  );
}
