import React from 'react';

import { Toolbar as MuiToolbar, ToolbarProps } from '@mui/material';
import classNames from 'classnames';

type Props = ToolbarProps & {
  className?: string;
};

const Toolbar = React.forwardRef<HTMLDivElement, Props>(function Toolbar(props, ref) {
  return <MuiToolbar className={classNames('Toolbar', props.className)} {...props} ref={ref} />;
});

export default Toolbar;
