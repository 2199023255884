import { TagWeight } from 'module/SecurityProfile';
import { AttackType, Discriminator } from 'module/Tag';

import { Ident } from 'types/common';

type BasicTagTaxonomy = {
  discriminator: Omit<Discriminator, 'Attack'>;
  type: never;
};

type AttackTagTaxonomy = {
  discriminator: typeof Discriminator.Attack;
  type: AttackType;
};

type TagCoverageBase = {
  organization_id: Ident;
  tag_id: Ident;
  name: string;
  recommended: number;
  deployed: number;
  available: number;
  extra: number;
  score_depth: number;
  score_breadth: number;
  score_coverage: number;
};

type BasicTagCoverage = TagCoverageBase & BasicTagTaxonomy;

type AttackTagCoverage = TagCoverageBase & AttackTagTaxonomy;

export type TagCoverage = BasicTagCoverage | AttackTagCoverage;

export function isAttackTagCoverage(coverage: TagCoverage): coverage is AttackTagCoverage {
  return coverage.discriminator === Discriminator.Attack;
}

export type CoverageStatName = 'coverage' | 'breadth' | 'depth';

export type RawValues = number[];
export type Calculated = { average: number; count: number };

export type ScoreAggregation<T extends RawValues | Calculated = Calculated> = Record<
  TagWeight,
  Record<CoverageStatName, T>
>;

export type AttackRecordType = 'tactics' | 'techniques' | 'subtechniques';

export type AggregatedCoverage = Record<AttackRecordType, ScoreAggregation>;

type CoverageReportBase = {
  discriminator: Discriminator;
  type?: AttackType;

  organization_id: Ident;
  name: string;
  tags: number;
  rec_avg: number;
  depth_avg: number;
  breadth_avg: number;
  coverage_avg: number;
};

type BasicCoverageReport = CoverageReportBase & BasicTagTaxonomy;

type AttackCoverageReport = CoverageReportBase & AttackTagTaxonomy;

export type CoverageReport = BasicCoverageReport | AttackCoverageReport;

export function isAttackCoverageReport(report: CoverageReport): report is AttackCoverageReport {
  return report.discriminator === Discriminator.Attack;
}
