import React from 'react';

import MuiCircularProgress from '@mui/material/CircularProgress';

import { Base } from 'snap-ui/type';

type Props = Base & {
  size?: number | string;
  color?: 'primary' | 'secondary';
};

export default function PrimaryCircularProgress({ size = 40, ...others }: Props) {
  return <MuiCircularProgress size={size} {...others} />;
}
