import { snapattack } from 'apis';

import { CollectionRequest, CollectionDiscriminator } from 'module/Collection/Collection.type';

import { Artifact, ArtifactType, Guid } from 'types/common';
import { CompositeFilter, Ops } from 'types/filter';

import { CurationInput, ExistingComposite, DuckedArtifact } from './Curation.type';

export function buildCurationPayload(values: CurationInput) {
  const payload: Partial<CollectionRequest> = { type: CollectionDiscriminator.Static };
  if (values.organization) payload.organization_id = Number(values.organization);
  if (values.name) payload.name = values.name;
  if (values.description) payload.description = values.description;
  if (values.actor_names?.length > 0) payload.actor_names = values.actor_names;
  if (values.attack_names?.length > 0) payload.attack_names = values.attack_names;
  if (values.software_names?.length > 0) payload.software_names = values.software_names;
  if (values.vulnerability_names?.length > 0) payload.vulnerability_names = values.vulnerability_names;

  return payload;
}

export function buildCuratedFilter(items: Guid[]): CompositeFilter {
  return {
    op: Ops.and,
    items: [
      {
        op: Ops.and,
        items: [
          {
            op: Ops.in,
            field: 'guid',
            value: items
          }
        ]
      }
    ]
  };
}

export function getCollectionContentCounts(guid: Guid): Promise<any> {
  return snapattack.get(`/collections/${guid}/items/count/`).then(r => r.data);
}

export function getCollectionItems(guid: Guid): Promise<ExistingComposite> {
  return snapattack.get(`/collections/${guid}/items/`).then(r => r.data);
}

export function buildAddToBulkList(
  type: ArtifactType,
  existing: DuckedArtifact[],
  attached: Partial<DuckedArtifact>[]
) {
  return attached
    .filter(a => a.duck === type)
    .filter(a => !existing.some(e => e.guid === a.guid))
    .map(i => i.guid);
}

export function buildRemoveFromBulkList(
  type: ArtifactType,
  existing: DuckedArtifact[],
  attached: Partial<DuckedArtifact>[]
) {
  return existing
    .filter(e => e.duck === type)
    .filter(e => !attached.some(a => a.guid === e.guid))
    .map(i => i.guid);
}

export function exportBulkRecommenderDetectionFilter(recommenders: Artifact[]): CompositeFilter {
  const recommender = recommenders.map(detection => detection.guid);
  return buildCuratedFilter(recommender);
}
