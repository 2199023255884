import React from 'react';

import { Autocomplete, AutocompleteOptionContainer } from 'snap-ui/Autocomplete';
import { ActionIconButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import { ListItemIcon, ListItemText } from 'snap-ui/List';
import Paper from 'snap-ui/Paper';
import Toolbar from 'snap-ui/Toolbar';
import { styled } from 'snap-ui/util';

import IntegrationAutocomplete from 'module/Integration/IntegrationAutocomplete';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  border: 1px solid ${p => p.theme.palette.secondary.dark};

  .BasicEditor {
    overflow-y: auto !important;
  }
`;

export const StyledToolbar = styled(Toolbar)`
  flex: 0 auto;
  background-color: ${p => p.theme.palette.secondary.main};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};
`;

export const StyledToolbarButtons = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};

  #nativeAnalyticWidgetLanguageDropdown {
    width: 250px;
  }
`;

export const StyledActionIconButton = styled(ActionIconButton)`
  padding: ${p => p.theme.spacing(3)};

  :hover {
    color: inherit;
  }

  svg {
    font-size: ${p => p.theme.palette.dimension.svg};
  }
`;

export const CodeMirrorContainer = styled('div')`
  flex: 1 0;
  max-height: calc(50vh - 1px);
  overflow-x: hidden;
  overflow-y: scroll;
  .syntaxEditor {
    background-image: unset;
    box-shadow: none;
    background-color: unset;
    padding-bottom: 50px;
  }
`;

export const StyledIntegrationAutocomplete = styled(IntegrationAutocomplete)`
  min-width: 250px;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  min-width: 250px;
`;

export const StyledAutocompletePaper = styled(Paper)`
  .MuiAutocomplete-option {
    padding-left: ${p => p.theme.spacing(3)};
    padding-right: ${p => p.theme.spacing(3)};
  }
`;

export function AutoCompleteOptionContent({
  supported,
  text,
  recompile
}: {
  supported: boolean;
  text: string;
  recompile?: boolean;
}) {
  return (
    <AutocompleteOptionContainer>
      <ListItemIcon>{supported || recompile ? '' : <Icon.Warning />}</ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </AutocompleteOptionContainer>
  );
}
