import * as React from 'react';

import { faArrowDown, faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown, faCaretUp, faMinusCircle, faPlusCircle, faSearch } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import List, { ListItem, ListItemText } from 'snap-ui/List';
import Typography from 'snap-ui/Typography';

import useSearch from 'hooks/useSearch';

import { move } from 'utilities/ArrayUtils';

import { ActionIconButton, ActionIconButtonProps } from './Button';
import Divider from './Divider';
import Icon from './Icon';
import TextField from './TextField';
import Tooltip from './Tooltip';
import { styled } from './util';

const Container = styled(List)`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  margin-bottom: auto;

  .search-field {
    padding: ${p => p.theme.spacing(3)};
  }

  .MuiListItem-root {
    gap: ${p => p.theme.spacing(2)};
    justify-content: space-between;
  }

  .MuiListItemText-primary {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @-webkit-keyframes pulse {
    from,
    to {
      border-color: ${p => p.theme.palette.grey[700]};
    }
    50% {
      border-color: ${p => p.theme.palette.primary.main};
    }
  }
  @keyframes pulse {
    from,
    to {
      border-color: ${p => p.theme.palette.grey[700]};
    }
    50% {
      border-color: ${p => p.theme.palette.primary.main};
    }
  }

  border: 1px solid ${p => p.theme.palette.grey[700]};
  border-radius: 4px;

  &.pulse {
    -webkit-animation: pulse 2s ease-in-out infinite;
    animation: pulse 2s ease-in-out infinite;
  }
`;

const MoveArrowContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-width: 10px;

  .MuiButtonBase-root {
    font-size: small;
    padding: 0;
  }
`;

const PseudoAccordionHeader = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(2)};
  align-items: center;
  padding: ${p => p.theme.spacing(0, 2)};
  cursor: pointer;
`;

type TransferListProps<T> = {
  className?: string;
  top: T[];
  setTop: React.Dispatch<React.SetStateAction<T[]>>;
  bottom: T[];
  setBottom: React.Dispatch<React.SetStateAction<T[]>>;
  excluded?: T[];
  excludedAddable?: boolean;
  renderItem?: (T) => JSX.Element;
  addAriaLabel?: string;
  removeAriaLabel?: string;
};

export default function VerticalTransferList<T>({
  className,
  top,
  setTop,
  bottom,
  setBottom,
  excluded,
  excludedAddable,
  renderItem = T => T,
  addAriaLabel = 'add',
  removeAriaLabel = 'remove'
}: TransferListProps<T>) {
  const filterFn = React.useCallback((query: string, item: T) => {
    return item.toString().toLowerCase().includes(query?.toLowerCase());
  }, []);

  const { filteredData: filteredTop, setQuery: setTopQuery } = useSearch(top, filterFn);
  const { filteredData: filteredBottom, setQuery: setBottomQuery } = useSearch(bottom, filterFn);
  const { filteredData: filteredExcluded, setQuery: setExcludedQuery } = useSearch(excluded, filterFn);

  const handleAdd = React.useCallback(
    (item: T) => {
      setTop(top => [...top, item]);
      setBottom(bottom => bottom.filter(r => r !== item));
    },
    [setBottom, setTop]
  );

  const handleRemove = React.useCallback(
    (item: T) => {
      setTop(top => top.filter(r => r !== item));
      setBottom(bottom => [...bottom, item]);
    },
    [setBottom, setTop]
  );

  const handleSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setTopQuery(event.target.value);
      setBottomQuery(event.target.value);
      setExcludedQuery(event.target.value);
    },
    [setBottomQuery, setExcludedQuery, setTopQuery]
  );

  const moveUp = (setArray: (value: React.SetStateAction<T[]>) => void) => (index: number) => {
    setArray(array => move(array, index, index - 1));
  };

  const moveDown = (setArray: (value: React.SetStateAction<T[]>) => void) => (index: number) => {
    setArray(array => move(array, index, index + 1));
  };

  return (
    <Container className={classnames('VerticalTransferList', className, { pulse: isEmpty(top) })}>
      <TextField
        className='search-field'
        onChange={handleSearchChange}
        placeholder='Search for field name'
        startAdornment={<Icon icon={faSearch} />}
      />
      <TransferListItems
        title='Chosen'
        items={filteredTop}
        renderItem={renderItem}
        moveUp={moveUp(setTop)}
        moveDown={moveDown(setTop)}
        ActionButtonProps={{
          'aria-label': removeAriaLabel,
          icon: faMinusCircle,
          onClick: handleRemove
        }}
      />
      <TransferListItems
        title='Choices'
        items={filteredBottom}
        renderItem={renderItem}
        ActionButtonProps={{
          'aria-label': addAriaLabel,
          icon: faPlusCircle,
          onClick: handleAdd
        }}
      />
      {!isEmpty(excluded) && (
        <TransferListItems
          title='Excluded'
          items={filteredExcluded}
          renderItem={renderItem}
          ActionButtonProps={
            excludedAddable
              ? {
                  'aria-label': addAriaLabel,
                  icon: faPlusCircle,
                  onClick: handleAdd
                }
              : undefined
          }
        />
      )}
    </Container>
  );
}

function TransferListItems<T>({
  title,
  items,
  moveUp,
  moveDown,
  ActionButtonProps,
  renderItem
}: {
  title: React.ReactNode;
  items: T[];
  moveUp?: (index: number) => void;
  moveDown?: (index: number) => void;
  ActionButtonProps?: Omit<ActionIconButtonProps, 'onClick'> & {
    onClick: (item: T) => void;
  };
  renderItem: (item: T) => JSX.Element;
}) {
  const { onClick } = ActionButtonProps ?? {};
  const [showList, setShowList] = React.useState(!!ActionButtonProps);

  return (
    <>
      <Divider>
        <Tooltip arrow title={`${showList ? 'Hide' : 'Show'} ${title}`} enterDelay={250} enterNextDelay={250}>
          <PseudoAccordionHeader
            aria-label={showList ? 'hide list' : 'show list'}
            onClick={() => {
              setShowList(showList => !showList);
            }}
          >
            <Typography color='primary'>{title}</Typography>
            <Icon icon={showList ? faCaretDown : faCaretUp} color='white' />
          </PseudoAccordionHeader>
        </Tooltip>
      </Divider>

      {showList
        ? items.map((item: T, index, list) => {
            const labelId = `transfer-list-item-${item}-label`;

            return (
              <ListItem key={item.toString()} role='listitem'>
                <MoveArrowContainer>
                  {moveUp && index > 0 ? (
                    <ActionIconButton onClick={() => moveUp(index)} aria-label='move item up' icon={faArrowUp} />
                  ) : null}
                  {moveDown && index < list.length - 1 ? (
                    <ActionIconButton onClick={() => moveDown(index)} aria-label='move item down' icon={faArrowDown} />
                  ) : null}
                </MoveArrowContainer>
                <Tooltip arrow title={item}>
                  <ListItemText id={labelId}>{renderItem(item)}</ListItemText>
                </Tooltip>
                {ActionButtonProps && (
                  <Tooltip arrow title={ActionButtonProps['aria-label']}>
                    <ActionIconButton {...ActionButtonProps} onClick={() => onClick(item)} />
                  </Tooltip>
                )}
              </ListItem>
            );
          })
        : null}
    </>
  );
}
