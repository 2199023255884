import React, { ReactElement } from 'react';

import { FormikProps } from 'formik';
import omit from 'lodash/omit';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import BackdropLoader from 'snap-ui/BackdropLoader';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { ApiError } from 'module/ApiError';
import { canI } from 'module/Can';
import { standardFormikBaseProps } from 'module/Form';
import Formik from 'module/Form/Formik';
import { useMayI } from 'module/May';
import NotFound from 'module/Util/Fallback/NotFound';

import { useAuth, useUserCatalog } from 'provider';

import { orgIdOrDefault } from 'services/organizationService';

import { Status, useAsync } from 'storage';

import { ContentPermission, FunctionalPermission } from 'types/auth';
import { BASProductName } from 'types/bas';
import { ArtifactType } from 'types/common';

import { DEFAULT_ATTACK_CODE, DEFAULT_VALIDATION_CODE } from '../AttackScript.const';
import { postAsyncAttackScripts, putAsyncAttackScripts } from '../AttackScript.service';
import { AttackScriptContainer } from '../AttackScript.style';
import { AttackScript, AttackScriptFormInput, AttackScriptFormSchema } from '../AttackScript.type';
import { useAttackScriptCatalog } from '../AttackScriptProvider';
import { AttackScriptDetailPlaceholder } from '../AttackScriptView/AttackScriptDetail';
import AttackScriptEditHeader from './AttackScriptEditHeader';
import AttackScriptForm from './AttackScriptForm';

export type AttackScriptEditProps = RouteComponentProps<{ guid: string }> & {
  className?: string;
};

function AttackScriptEdit(props: AttackScriptEditProps): ReactElement {
  const { replace, location } = useHistory();
  const { defaultOrgId, user } = useAuth();
  const { users } = useUserCatalog();

  const guid = props.match.params.guid;

  useTitle(guid ? 'Edit Attack Script | SnapAttack' : 'Create Attack Script | SnapAttack');

  const { attackScript, supplemental, error, isPending } = useAttackScriptCatalog();
  const { task, status: actingStatus, errorProps } = useAsync<AttackScript>();
  const isActing = actingStatus === Status.pending;
  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);
  const isAtomicRedTeam = attackScript.product_name === BASProductName.AtomicRedTeam;

  const canIEditValidation =
    location.pathname === Path.AttackScriptCreate ? isBasUser : canI(ContentPermission.Edit, attackScript);

  const AttackScriptFormInitial: AttackScriptFormInput & { owner: string } = {
    owner: users.find(user => user.id === attackScript.created_by_id)?.name || user.name,
    organization_id: orgIdOrDefault(attackScript.organization_id, defaultOrgId).toString(),
    attack_name: attackScript.attack_name || '',
    attack_names: attackScript.attack_names || [],
    actor_names: attackScript.actor_names || [],
    software_names: attackScript.software_names || [],
    vulnerability_names: attackScript.vulnerability_names || [],
    references: attackScript.references || [],
    script: guid ? attackScript.script_yaml || '' : DEFAULT_ATTACK_CODE,
    validation: guid ? attackScript.validation_yaml || '' : DEFAULT_VALIDATION_CODE
  };

  if (error.attackScript) return <NotFound artifact={ArtifactType.AttackScript} error={error.attackScript} />;

  return (
    <Formik<AttackScriptFormInput>
      {...standardFormikBaseProps}
      initialValues={AttackScriptFormInitial}
      onSubmit={handleSubmit}
      zodSchema={AttackScriptFormSchema}
    >
      {({ submitForm }: FormikProps<AttackScriptFormInput>): React.ReactElement => {
        function handleCreatePublish() {
          return submitForm().then((response: any) => {
            if (response) redirectToViewPage(response);
          });
        }

        return (
          <AttackScriptContainer
            className={props.className}
            meta={
              <AttackScriptEditHeader
                onCancel={handleCancel}
                onPublish={() => handleCreatePublish()}
                permissionBlocked={!canIEditValidation}
                isActing={isPending}
              />
            }
            type={ArtifactType.AttackScript}
          >
            <BackdropLoader title='Saving...' open={isActing} fixed />
            <ApiError {...errorProps} />
            {isPending ? <AttackScriptDetailPlaceholder /> : <AttackScriptForm disableCoreFields={isAtomicRedTeam} />}
          </AttackScriptContainer>
        );
      }}
    </Formik>
  );

  async function handleSubmit(values: Partial<AttackScriptFormInput>) {
    let payload = omit(values, 'owner');
    if (isAtomicRedTeam) payload = omit(payload, ['attack_name', 'organization_id']);
    if (guid) return task(putAsyncAttackScripts(guid, payload));
    return task(postAsyncAttackScripts(payload as AttackScriptFormInput));
  }

  function handleCancel() {
    if (guid) replace(`${Path.AttackScript}/${guid}`, { item: attackScript, supplementalItem: supplemental });
    else replace(`${Path.Feed}?topic=${ArtifactType.AttackScript}`);
  }

  function redirectToViewPage(attackScript: AttackScript, replace = false): void {
    const action = replace ? props.history.replace : props.history.push;
    action(`${Path.AttackScript}/${attackScript.guid}`, {
      item: attackScript,
      supplementalItem: supplemental
    });
  }
}

export default AttackScriptEdit;
