import React from 'react';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import { Link } from 'react-router-dom';

import Chip from 'snap-ui/Chip';
import { styled } from 'snap-ui/util';

import { LandingArtifact, VulnerabilitiesScore } from 'module/Landing/Landing.type';
import { filterOutSelf, getSeverityLevel } from 'module/Landing/Landing.util';
import { PlatformIcons } from 'module/Landing/Landing.widgets';
import { SupplementalArtifact } from 'module/Search';
import { Discriminator } from 'module/Tag';
import TagList from 'module/Tag/TagList';
import CollectionCounter from 'module/Widgets/CollectionCounter';
import Motivations from 'module/Widgets/MATI/Motivations';
import { LastSeen } from 'module/Widgets/MATI/Seen';
import SourceRegion from 'module/Widgets/MATI/SourceRegion';
import TargetedCountries from 'module/Widgets/MATI/TargetedCountries';
import TargetedIndustries from 'module/Widgets/MATI/TargetedIndustries';

import { useFeedExtra } from 'provider';

import { Status } from 'storage';

import { ArtifactType } from 'types/common';

import { getHighlightsByType } from 'utilities/ArtifactUtils';
import { handleDebug } from 'utilities/FunctionUtils';
import { renderValueWithLabel } from 'utilities/TextUtils';

import { LandingCardProps } from './Card';
import {
  CardActorTargetsContainer,
  CardAliasContainer,
  CardContainer,
  CardSubsection,
  CardTitleContainer,
  CardVulnerabilitiesContainer
} from './Card.style';
import { buildLinkWithState, cleanHTML } from './Card.util';
import { CardDescription, CardHeader, LockedOverlay } from './Card.widgets';

const Container = styled(CardContainer, { label: 'LandingCard' })`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};

  :hover {
    border-color: ${p => p.theme.palette.grey[200]};
  }

  :hover .LockedOverlayContainer {
    opacity: 1;

    svg {
      opacity: 0.8;
    }
  }

  .Tooltip-wrapper {
    position: relative;
    z-index: 1;
  }

  .CardTargets {
    .CardSubsection {
      flex-basis: 50%;
    }
  }

  .CardAssociatedArtifacts {
    .CardCounter {
      display: flex;
      gap: ${p => p.theme.spacing(3)};
      align-items: center;
    }
  }
`;

const ChipContainer = styled('div')`
  .MuiChip-label {
    display: flex;
    gap: ${p => p.theme.spacing(2)};
    align-items: center;
    line-height: 1;
  }
`;

const StyledCvssChip = styled(Chip)`
  span {
    color: ${p => p.theme.palette.common.black};
    font-weight: bold;
  }

  &.${VulnerabilitiesScore.CRITICAL} {
    background-color: ${p => p.theme.palette.error.main};
  }

  &.${VulnerabilitiesScore.HIGH} {
    background-color: ${p => p.theme.palette.error.main};
  }

  &.${VulnerabilitiesScore.MEDIUM} {
    background-color: ${p => p.theme.palette.orange.main};
  }

  &.${VulnerabilitiesScore.LOW} {
    background-color: ${p => p.theme.palette.warning.main};
  }
`;

function LandingCardTitle({
  topic,
  item,
  supplemental
}: {
  topic: ArtifactType;
  item: LandingArtifact;
  supplemental: SupplementalArtifact;
}) {
  // Preview is ignored for landing cards, since we return minimal landing pages for community users
  const preview = Object.values(Discriminator).includes(item?.type as Discriminator) ? false : item?.preview;
  let name = isEmpty(item.name_highlights) ? item.name : item.name_highlights.slice().shift();
  name = cleanHTML(name);

  return (
    <CardTitleContainer className='CardTitle'>
      {preview ? (
        <span className='preview' dangerouslySetInnerHTML={{ __html: name }} />
      ) : (
        <div>
          <Link to={buildLinkWithState(topic, item, supplemental)} dangerouslySetInnerHTML={{ __html: name }} />
        </div>
      )}
      <SourceRegion item={item} />
      <CvssChip item={item} />
    </CardTitleContainer>
  );
}

function CardAliases({ item }: { item: LandingArtifact }) {
  const highlights = getHighlightsByType(item);

  const sortedAliases = highlights
    .filter(filterOutSelf(item?.name))
    .map(highlight => {
      const count = (highlight.match(/<span>/g) || []).length;

      return {
        alias: highlight,
        count
      };
    })
    .sort((a, b) => b.count - a.count)
    .map(({ alias }) => alias);

  if (isEmpty(sortedAliases)) return null;

  const aliasList = sortedAliases.slice(0, 5);

  const remainingAliases = sortedAliases.length - aliasList.length;

  let htmlString = aliasList.join(', ');
  if (remainingAliases > 0)
    htmlString += ` and ${renderValueWithLabel(remainingAliases, 'more alias', 'more aliases')}`;
  const cleanedHTML = cleanHTML(htmlString);

  return <CardAliasContainer className='CardAliases' dangerouslySetInnerHTML={{ __html: cleanedHTML }} />;
}

function CardTargets({ item }: { item: LandingArtifact }) {
  if (item?.preview) return null;
  switch (item.type) {
    case Discriminator.Actor:
      return (
        <CardActorTargetsContainer className='CardTargets'>
          <CardSubsection title='Targeted Industries'>
            <TargetedIndustries industries={item?.industries} />
          </CardSubsection>
          <CardSubsection title='Targeted Countries'>
            <TargetedCountries countries={item?.target_regions} />
          </CardSubsection>
          <CardSubsection title='Motivations'>
            <Motivations motivations={item?.motivations} />
          </CardSubsection>
        </CardActorTargetsContainer>
      );
    case Discriminator.Software:
      return (
        <CardActorTargetsContainer className='CardTargets'>
          <CardSubsection title='Targeted Industries'>
            <TargetedIndustries industries={item?.industries} />
          </CardSubsection>
          <CardSubsection title='Operating Systems'>
            {item?.platforms?.length > 0 ? (
              <PlatformIcons platforms={item?.platforms} />
            ) : (
              <ChipContainer>
                <Chip label='No Operating Systems Listed' />
              </ChipContainer>
            )}
          </CardSubsection>
          <CardSubsection title='Role'>
            <ChipContainer>{item?.role ? <Chip label={item.role} /> : <Chip label='No Roles Listed' />}</ChipContainer>
          </CardSubsection>
        </CardActorTargetsContainer>
      );
    default:
      return null;
  }
}

function CardActorsToolsTTPs({ item }: { item: LandingArtifact }) {
  // Actors have links to attack, software and vulnerabilities
  if (item.type === Discriminator.Actor) {
    if (isEmpty(item?.software_names) && isEmpty(item?.attack_names) && isEmpty(item?.vulnerability_names)) return null;

    return (
      <CardSubsection title='Associated Tools and TTPs' className='CardActorsToolsTTPs'>
        <TagList
          inline
          software={item?.software_names}
          attack={item?.attack_names}
          vulnerability={item?.vulnerability_names}
        />
      </CardSubsection>
    );
  }
  // Software has links to attack, actors, and vulnerabilities
  if (item.type === Discriminator.Software) {
    if (isEmpty(item?.actor_names) && isEmpty(item?.attack_names) && isEmpty(item?.vulnerability_names)) return null;

    return (
      <CardSubsection title='Associated Actors and TTPs' className='CardActorsToolsTTPs'>
        <TagList
          inline
          actor={item?.actor_names}
          attack={item?.attack_names}
          vulnerability={item?.vulnerability_names}
        />
      </CardSubsection>
    );
  }

  // Attack and Vulnerabilities have nothing (or at least no hydrated from the backend)
  return null;
}

function CardVulnerabilities({ item }: { item: LandingArtifact }) {
  const { risk_rating, exploitation_state, was_zero_day, observed_in_the_wild, preview, type } = item;
  if (type !== Discriminator.Vulnerability || preview) return null;

  const wasZeroDay = was_zero_day ? 'Yes' : 'No';
  const inTheWild = observed_in_the_wild ? 'Yes' : 'No';

  return (
    <CardVulnerabilitiesContainer className='CardVulnerabilities'>
      <CardSubsection className={risk_rating} title='Risk Rating'>
        {risk_rating}
      </CardSubsection>
      <CardSubsection className={exploitation_state} title='Exploitation State'>
        {startCase(exploitation_state)}
      </CardSubsection>
      <CardSubsection className={wasZeroDay ? 'affirmative' : ''} title='Exploited as Zero-day'>
        {wasZeroDay}
      </CardSubsection>
      <CardSubsection className={inTheWild ? 'affirmative' : ''} title='Exploited in the Wild'>
        {inTheWild}
      </CardSubsection>
    </CardVulnerabilitiesContainer>
  );
}

function CardAssociatedArtifacts({ item }: { item: LandingArtifact }) {
  return (
    <CardSubsection title='Associated Content' className='CardAssociatedArtifacts'>
      <CollectionCounter item={item} />
    </CardSubsection>
  );
}

function CvssChip({ item }: { item: LandingArtifact }) {
  if (!item?.cvss_3_base_score) return null;
  const severity = getSeverityLevel(item?.cvss_3_base_score);
  return (
    <StyledCvssChip
      className={severity}
      size='small'
      label={'CVSS: ' + item?.cvss_3_base_score + ' (' + severity + ')'}
    />
  );
}

export default function LandingCard({ className, item, onDelete, topic }: LandingCardProps) {
  const { supplementalCatalog } = useFeedExtra();
  const supplemental = supplementalCatalog[item.guid];
  // Preview is ignored for landing cards, since we return minimal landing pages for community users
  const preview = Object.values(Discriminator).includes(item?.type as Discriminator) ? false : item?.preview;

  const handleMouseEvent = (e: React.MouseEvent<HTMLDivElement>) =>
    handleDebug(e, () => {
      console.groupCollapsed(item.guid);
      console.info('item', item);
      console.info('supplemental', supplemental);
      console.groupEnd();
    });

  return (
    <Container className={classNames('Landing Card', className)} onMouseEnter={handleMouseEvent}>
      <CardHeader item={item} supplemental={supplemental} status={Status.resolved} topic={topic} onDelete={onDelete} />
      <LandingCardTitle item={item} supplemental={supplemental} topic={topic} />
      <CardAliases item={item} />
      <LastSeen timestamp={item.last_seen} />
      <CardDescription item={item} />
      <CardTargets item={item} />
      <CardVulnerabilities item={item} />
      <CardActorsToolsTTPs item={item} />
      <CardAssociatedArtifacts item={item} />
      {preview && <LockedOverlay />}
    </Container>
  );
}
