import React from 'react';

import Typography from 'snap-ui/Typography';

import useTitle from 'hooks/useTitle';

import { AlphaFeature, CanAlpha } from 'module/AlphaFeatures';

import { MitreChartRow, MitreDashboardPage } from '../style';
import BarChart from './BarChart';
import Stats from './Stats';
import Table from './Table';
import useMitreCoverage from './useMitreCoverage';

export function MitreCoverageDashboard(): JSX.Element {
  useTitle('MITRE ATT&CK Coverage Report');
  const coverageInterface = useMitreCoverage();

  return (
    <CanAlpha feature={AlphaFeature.MitreCoverageDashboard}>
      <MitreDashboardPage>
        <Typography variant='h1'>MITRE ATT&CK Coverage</Typography>
        <MitreChartRow>
          <Stats {...coverageInterface} />
          <BarChart {...coverageInterface} />
        </MitreChartRow>
        <Table {...coverageInterface} />
      </MitreDashboardPage>
    </CanAlpha>
  );
}
