import React from 'react';

import { DndProvider } from 'react-dnd';
import DndBackend from 'react-dnd-html5-backend';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { installMuiPro } from 'snap-ui/DataGrid';
import DatePickerProvider from 'snap-ui/DatePicker/DatePickerProvider';

import { AgentProvider } from 'aso/useAgents';

import { Path } from 'constants/paths';

import AnalyticRouter from 'module/Analytic/AnalyticRouter';
import { AttackScriptEdit, AttackScriptView } from 'module/AttackScript';
import {
  ForgotPassword,
  Login,
  LoginSuccess,
  Logout,
  Redirect as RedirectRouter,
  Registration,
  ResetPassword
} from 'module/Authentication';
import Multifactor from 'module/Authentication/Multifactor';
import { AttackSimulations } from 'module/BAS/AttackSimulations';
import CampaignReport from 'module/BAS/CampaignReport';
import { CoverageProvider, MitreCoverageDashboard, ProfileCoverageDashboard } from 'module/Coverage';
import Curation, { CurationEdit, CurationEditReset } from 'module/Curation';
import CurationAttach from 'module/Curation/CurationAttach';
import { ContentDashboard, DetectionDashboard, SocDashboard, UserActivityDashboard } from 'module/Dashboard';
import BulkConfidence from 'module/Dashboard/BulkRank/BulkConfidence';
import NistDashboard from 'module/Dashboard/NistDashboard';
import { DeployedHealth } from 'module/DeployedHealth';
import { FeedCardPage } from 'module/Feed';
import { GlobalFilterContextProvider } from 'module/GlobalFilter';
import FilterTest from 'module/GlobalFilter/FilterTest';
import Home from 'module/Home';
import { HuntRouter } from 'module/Hunt';
import IDE from 'module/IDE';
import IDEReset from 'module/IDE/Reset';
import { IOCParser } from 'module/IOC';
import { ImpersonateProvider } from 'module/Impersonate';
import { LanguagePlatformProvider } from 'module/Integration';
import { IntelEdit, IntelView } from 'module/Intel';
import { getAllLandingPaths } from 'module/Landing/Landing.util';
import { LandingCreate } from 'module/Landing/LandingEdit';
import LandingRouter from 'module/Landing/LandingRouter';
import Matrix from 'module/Matrix';
import ProcessGraph from 'module/ProcessGraph';
import PrivateRoute from 'module/Scaffold';
import { ScaffoldContainer } from 'module/Scaffold/Scaffold.style';
import UnsupportedBrowserBanner from 'module/Scaffold/core/Banner/UnsupportedBrowserBanner';
import { SecurityProfileProvider } from 'module/SecurityProfile';
import {
  SessionCreate,
  SessionCreateReset,
  SessionEdit,
  SessionVM,
  SessionVMConfig,
  SessionView
} from 'module/Session';
import Setting from 'module/Setting';
import { Leaderboard, Scoreboard } from 'module/Training';
import Tutorial from 'module/Tutorial';
import NotFound from 'module/Util/Fallback/NotFound';
import Feedback from 'module/Util/Feedback';
import ScrollToTop from 'module/Util/ScrollToTop';
import SentryErrorBoundary from 'module/Util/SentryErrorBoundary';

import {
  AccountProvider,
  CommissionedProvider,
  FilterCollectionProvider,
  FilterProvider,
  IntegrationProvider,
  LanguageProvider,
  LogsourceProvider,
  ManagedOrganizationProvider,
  MaterialProvider,
  NistTagProvider,
  ProtectedSettingsProvider,
  SidebarProvider,
  SigmaBackendProvider,
  SnackProvider,
  UserCatalogProvider,
  UserConfigProvider,
  UserbackProvider
} from 'provider';
import { MetadataSchemaProvider } from 'provider/MetadataSchema';

import Palette from 'utilities/Palette';

import history from './history';
import './userback.css';
import '/node_modules/flag-icons/css/flag-icons.min.css';

installMuiPro();

const App = () => (
  <Switch>
    <Route exact path={Path.Register} component={Registration} />
    <Route exact path={Path.ForgotPassword} component={ForgotPassword} />
    <Route exact path={Path.ResetPassword} component={ResetPassword} />
    <Route exact path={Path.MultifactorConfiguration} component={Multifactor} />
    <Route exact path={Path.Login} component={Login} />
    <Route exact path={Path.Logout} component={Logout} />
    <Route exact path={Path.LoginSuccess} component={LoginSuccess} />
    <PrivateRoute exact path={Path.Redirect} component={RedirectRouter} />
    <PrivateRoute exact path={Path.Home} component={Home} />
    <PrivateRoute exact path={Path.ContentDashboard} component={ContentDashboard} />
    <PrivateRoute exact path={Path.DetectionDashboard} component={DetectionDashboard} />
    <PrivateRoute exact path={Path.SocDashboard} component={SocDashboard} />
    <PrivateRoute exact path={Path.NistDashboard} component={NistDashboard} />
    <PrivateRoute exact path={Path.UserActivityDashboard} component={UserActivityDashboard} />
    <PrivateRoute exact path={Path.MitreCoverageDashboard} component={MitreCoverageDashboard} />
    <PrivateRoute exact path={Path.ProfileCoverageDashboard} component={ProfileCoverageDashboard} />
    <PrivateRoute path={Path.Setting} component={Setting} />
    <PrivateRoute exact path={Path.Tutorial} component={Tutorial} />
    {/* PrivateRoute does not support the render prop; this route is not intended for public use but there is no security implications here */}
    <Route
      exact
      path={Path.FeedReset}
      render={(props: any) => <Redirect to={`${Path.Feed}${props.location.search}`} />}
    />
    <PrivateRoute exact path={Path.Feed} component={FeedCardPage} />
    <PrivateRoute exact path={Path.ProcessGraph} component={ProcessGraph} />
    <PrivateRoute exact path={Path.Matrix} component={Matrix} />
    <PrivateRoute exact path={Path.AttackScriptCreate} component={AttackScriptEdit} />
    <PrivateRoute exact path={`${Path.AttackScriptEdit}/:guid`} component={AttackScriptEdit} />
    <Redirect from={`${Path.DeprecatedValidation}/:id`} to={`${Path.AttackScript}/:id`} />
    <PrivateRoute exact path={`${Path.AttackScript}/:guid`} component={AttackScriptView} />
    <PrivateRoute exact path={Path.BASJob} component={AttackSimulations} />
    <PrivateRoute exact path={`${Path.BASJob}/:guid`} component={CampaignReport} />
    <PrivateRoute exact path={`${Path.CollectionCreate}/:type`} component={LandingCreate} />
    <PrivateRoute exact path={Path.CollectionReset} component={CurationEditReset} />
    <PrivateRoute exact path={Path.CollectionCreate} component={CurationEdit} />
    <PrivateRoute exact path={`${Path.CollectionAttach}/:guid`} component={CurationAttach} />
    <PrivateRoute exact path={`${Path.CollectionEdit}/:guid`} component={CurationEdit} />
    <PrivateRoute exact path={`${Path.Collection}/:guid`} component={Curation} />
    <PrivateRoute path={getAllLandingPaths()} component={LandingRouter} />
    <PrivateRoute exact path={Path.ConfidenceTailoring} component={BulkConfidence} />
    <PrivateRoute exact path={Path.DetectionHealth} component={DeployedHealth} />
    <Redirect from={`${Path.DeprecatedAnalytic}/:id`} to={`${Path.Detection}/:id`} />
    <PrivateRoute path={`${Path.Detection}/:analyticGuid`} component={AnalyticRouter} />
    <PrivateRoute exact path={Path.IDEReset} component={IDEReset} />
    <PrivateRoute exact path={Path.IDE} component={IDE} />
    <PrivateRoute exact path={Path.IOC} component={IOCParser} />
    <PrivateRoute exact path={Path.IntelligenceCreate} component={IntelEdit} />
    <PrivateRoute exact path={`${Path.IntelligenceEdit}/:id`} component={IntelEdit} />
    <Redirect from={`${Path.DeprecatedIntelligence}/:id`} to={`${Path.Intelligence}/:id`} />
    <PrivateRoute path={`${Path.Intelligence}/:id`} component={IntelView} />
    <PrivateRoute exact path={Path.ThreatCreate} component={SessionVMConfig} />
    <PrivateRoute exact path={Path.ThreatExecute} component={SessionVM} />
    <PrivateRoute exact path={Path.ThreatSave} component={SessionCreate} />
    <PrivateRoute exact path={Path.ThreatUploadReset} component={SessionCreateReset} />
    <PrivateRoute exact path={Path.ThreatUpload} component={SessionCreate} />
    <PrivateRoute exact path={`${Path.ThreatEdit}/:guid`} component={SessionEdit} />
    <Redirect from={`${Path.DeprecatedSession}/:guid`} to={`${Path.Threat}/:guid`} />
    <PrivateRoute path={`${Path.Threat}/:guid`} component={SessionView} />
    <PrivateRoute path={Path.Hunt} component={HuntRouter} />
    <PrivateRoute path={Path.Leaderboard} component={Leaderboard} />
    <PrivateRoute path={Path.Scoreboard} component={Scoreboard} />
    {/* BEGIN Developer */}
    <PrivateRoute path={'/palette/v1'} component={Palette} />
    <PrivateRoute exact path={'/filter-test'} component={FilterTest} />
    {/* END Developer Routes */}
    <PrivateRoute component={NotFound} />
  </Switch>
);

export default function Boomerang() {
  return (
    <Router history={history}>
      <MaterialProvider>
        <SentryErrorBoundary>
          <SnackProvider>
            <UserbackProvider>
              <GlobalFilterContextProvider>
                <AccountProvider>
                  <ImpersonateProvider>
                    <UserConfigProvider>
                      <SecurityProfileProvider>
                        <ProtectedSettingsProvider>
                          <MetadataSchemaProvider>
                            <DndProvider backend={DndBackend}>
                              <IntegrationProvider>
                                <LogsourceProvider>
                                  <AgentProvider>
                                    <LanguageProvider>
                                      <LanguagePlatformProvider>
                                        <FilterCollectionProvider>
                                          <SigmaBackendProvider>
                                            <CommissionedProvider>
                                              <UserCatalogProvider>
                                                <ManagedOrganizationProvider>
                                                  <NistTagProvider>
                                                    <FilterProvider>
                                                      <DatePickerProvider>
                                                        <CoverageProvider>
                                                          <UnsupportedBrowserBanner />
                                                          <ScaffoldContainer>
                                                            <SidebarProvider>
                                                              <ScrollToTop />
                                                              <App />
                                                            </SidebarProvider>
                                                          </ScaffoldContainer>
                                                          <Feedback />
                                                        </CoverageProvider>
                                                      </DatePickerProvider>
                                                    </FilterProvider>
                                                  </NistTagProvider>
                                                </ManagedOrganizationProvider>
                                              </UserCatalogProvider>
                                            </CommissionedProvider>
                                          </SigmaBackendProvider>
                                        </FilterCollectionProvider>
                                      </LanguagePlatformProvider>
                                    </LanguageProvider>
                                  </AgentProvider>
                                </LogsourceProvider>
                              </IntegrationProvider>
                            </DndProvider>
                          </MetadataSchemaProvider>
                        </ProtectedSettingsProvider>
                      </SecurityProfileProvider>
                    </UserConfigProvider>
                  </ImpersonateProvider>
                </AccountProvider>
              </GlobalFilterContextProvider>
            </UserbackProvider>
          </SnackProvider>
        </SentryErrorBoundary>
      </MaterialProvider>
    </Router>
  );
}
