import React from 'react';

import useFeed from 'aso/useFeed';

import { useFilterRegistry } from 'module/GlobalFilter';
import { getTagIDs } from 'module/Landing/Landing.util';
import { SecurityProfileTag, useSecurityProfile } from 'module/SecurityProfile';
import { Discriminator, isTacticArtifact } from 'module/Tag';

import { Status } from 'storage';

import { Artifact, ArtifactType, Guid } from 'types/common';

import { useCoverage, CoverageInterface } from '../CoverageProvider';
import { CoverageReport, TagCoverage } from '../type';

export type TagCoverageInterface = {
  items: Artifact[];
  status: Status;
};

export type ProfileCoverageInterface = {
  actors: TagCoverageInterface;
  attacks: TagCoverageInterface;
  software: TagCoverageInterface;
  vulnerabilities: TagCoverageInterface;
  getProfile(tagType: Discriminator, guid: Guid): SecurityProfileTag;
  getStats(tagType: Discriminator, guid: Guid): TagCoverage;
  getTagTypeReport(tagType: Discriminator): CoverageReport;
  totalCoverage: CoverageInterface['totalCoverage'];
  isProfilePending: boolean;
  isCoverageReportPending: boolean;
  isTotalCoveragePending: boolean;
};

const PAGE_PARAMS = {
  page: 1,
  size: 3000 // software has over 2000 in profile?
};

const FILTER_BASE = { threatProfile: ['true'] };
const ACTOR_FILTER = { ...FILTER_BASE, contentType: 'actor' };
const SOFTWARE_FILTER = { ...FILTER_BASE, contentType: 'software' };
const VULNERABILITY_FILTER = { ...FILTER_BASE, contentType: 'vulnerability' };
const ATTACK_FILTER = { ...FILTER_BASE, contentType: 'attack' };

function useTagFeed(filter): TagCoverageInterface {
  const { generateQuery } = useFilterRegistry();
  const query = React.useMemo(() => generateQuery(ArtifactType.Collection, filter), [filter, generateQuery]);
  const feed = useFeed(ArtifactType.Collection, query, PAGE_PARAMS);

  return {
    items: filter.contentType === 'attack' ? feed.items.filter(i => !isTacticArtifact(i)) : feed.items,
    status: feed.status
  };
}

export default function useProfileCoverage(): ProfileCoverageInterface {
  const {
    loadCoverage,
    getTagCoverage,
    getTagTypeReport,
    totalCoverage,
    coverageReportIsPending,
    totalCoverageIsPending
  } = useCoverage();
  const { tagInProfile, tagsStatus } = useSecurityProfile();

  const actors = useTagFeed(ACTOR_FILTER);
  const attacks = useTagFeed(ATTACK_FILTER);
  const software = useTagFeed(SOFTWARE_FILTER);
  const vulnerabilities = useTagFeed(VULNERABILITY_FILTER);

  React.useEffect(() => {
    loadCoverage();
  }, [loadCoverage]);

  const getFeedItems = React.useCallback(
    (tagType: Discriminator): Artifact[] => {
      switch (tagType) {
        case Discriminator.Actor:
          return actors.items;
        case Discriminator.Attack:
          return attacks.items;
        case Discriminator.Software:
          return software.items;
        case Discriminator.Vulnerability:
          return vulnerabilities.items;
        default:
          return [];
      }
    },
    [actors.items, attacks.items, software.items, vulnerabilities.items]
  );

  const getProfile = React.useCallback(
    (tagType: Discriminator, guid: Guid) => {
      const tag = getFeedItems(tagType).find(t => t.guid === guid);
      if (!tag) return;
      const tagIds = getTagIDs(tag, tagType);
      return tagInProfile(tagIds);
    },
    [getFeedItems, tagInProfile]
  );

  const getStats = React.useCallback(
    (tagType: Discriminator, guid: Guid) => {
      const tag = getFeedItems(tagType).find(t => t.guid === guid);
      if (!tag) return;
      const tagIds = getTagIDs(tag, tagType);
      return getTagCoverage(tagIds[0]);
    },
    [getFeedItems, getTagCoverage]
  );

  return {
    actors,
    attacks,
    software,
    vulnerabilities,
    getProfile,
    getStats,
    getTagTypeReport,
    totalCoverage,
    isProfilePending: tagsStatus === Status.pending,
    isCoverageReportPending: coverageReportIsPending,
    isTotalCoveragePending: totalCoverageIsPending
  };
}
