import React from 'react';

import { useIntegrationCatalog } from 'provider';

import { useAsync } from 'storage';

import { Guid } from 'types/common';

import { getJobOverview_v2 } from './Job.api';
import { JobOverview } from './Job.type';

export default function useJobGroupOverview() {
  const { integrations } = useIntegrationCatalog();
  const {
    data: _jobsData,
    run: jobsRun,
    errorProps: jobErrorProps,
    status,
    task,
    setWith
  } = useAsync<JobOverview[]>([]);
  const [jobs, setJobs] = React.useState<JobOverview[]>([]);

  const refresh = React.useCallback(() => {
    jobsRun(getJobOverview_v2());
  }, [jobsRun]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  React.useEffect(() => {
    const jobGroupOccurrences = _jobsData.reduce((acc, job) => {
      if (!acc[job.analytic_job_group_guid]) {
        acc[job.analytic_job_group_guid] = { jobsByGroup: [] };
      }
      acc[job.analytic_job_group_guid].jobsByGroup.push(job.analytic_job_group_guid);
      return acc;
    }, {});

    const enhancedJobsData = _jobsData.map(job => ({
      ...job,
      jobsByGroup:
        jobGroupOccurrences[job.analytic_job_group_guid]?.jobsByGroup.length > 1
          ? jobGroupOccurrences[job.analytic_job_group_guid]?.jobsByGroup
          : null
    }));

    setJobs(enhancedJobsData);
  }, [integrations.all, _jobsData]);

  const getJobGroup = React.useCallback((guid: Guid) => jobs.find(job => job.analytic_job_group_guid === guid), [jobs]);

  return {
    jobs: jobs,
    getJobGroup,
    refresh,
    jobErrorProps,
    status,
    task,
    setWith
  };
}
