import { Ops, Query } from 'types/filter';

export const ValidationProductFilter = {
  field: 'product_name',
  op: Ops.in,
  value: ['SnapAttack', 'AtomicRedTeam']
};

export const DEFAULT_ATTACK_CODE = `name: New Attack Script
description: |
  a new attack script
supported_platforms:
  - windows
executor:
  name: command_prompt
  elevation_required: false
  command: |
    echo "hello world"`;

export const DEFAULT_VALIDATION_CODE = `exit_code: 0
output: success`;

export function addValidationProductFilter(query: Query) {
  return {
    op: Ops.and,
    items: [query, ValidationProductFilter]
  };
}
