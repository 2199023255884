import React from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import useQueryString from 'hooks/useQueryString';

import { useAuth } from 'provider';
import { useMetadataSchema } from 'provider/MetadataSchema';

import { Status } from 'storage';

import { ArtifactType, isArtifactType } from 'types/common';
import { Query } from 'types/filter';

import { getQueryParam, parseAnyQueryString } from 'utilities/SearchParam';

import AccessibleContentFilterConfig from './Filters/AccessibleContent';
import ActorMotivationFilterConfig from './Filters/ActorMotivation';
import ActorSourceRegionFilterConfig from './Filters/ActorSourceRegion';
import ActorTargetRegionFilterConfig from './Filters/ActorTargetRegion';
import AnalyticRankFilterConfig from './Filters/AnalyticRank';
import AnalyticSeverityFilterConfig from './Filters/AnalyticSeverity';
import BASCompatibilityFilterConfig from './Filters/BASCompatibility';
import ContentTypeFilterConfig from './Filters/ContentType';
import ContributorsFilterConfig from './Filters/Contributors';
import CreationTimeFilterConfig from './Filters/CreationTime';
import DataSourceFilterConfig from './Filters/DataSource';
import DefensivePostureFilterConfig from './Filters/DefensivePosture';
import DeployedEnvironmentFilterConfig from './Filters/DeployedEnvironment';
import FirstSeenFilterConfig from './Filters/FirstSeen';
import HasIndicatorsFilterConfig from './Filters/HasIndicators';
import HasThreatFilterConfig from './Filters/HasThreat';
import IdsFilterConfig from './Filters/Ids';
import IndicatorScore from './Filters/IndicatorScore';
import IndicatorType from './Filters/IndicatorType';
import LanguageFilterConfig from './Filters/Language';
import LastSeenFilterConfig from './Filters/LastSeen';
import LogsourceFilterConfig from './Filters/Logsource';
import MetadataFilterConfig from './Filters/MetadataConfig';
import MitreFilterConfig from './Filters/Mitre';
import NistActionFilterConfig from './Filters/NistAction';
import OrganizationsFilterConfig from './Filters/Organizations';
import PlatformFilterConfig from './Filters/Platform';
import RecommendedDetectionFilterConfig from './Filters/RecommendedDetection';
import SearchConfig from './Filters/Search';
import SelectedAnalyticFilterConfig from './Filters/SelectedAnalytic';
import SoftwareFilterConfig from './Filters/Software';
import TargetIndustryFilterConfig from './Filters/TargetIndustry';
import ThreatActorFilterConfig from './Filters/ThreatActor';
import ThreatProfileFilterConfig from './Filters/ThreatProfile';
import ThreatProfilePriorityFilterConfig from './Filters/ThreatProfilePriority';
import VisibilityFilterConfig from './Filters/Visibility';
import VulnerabilityFilterConfig from './Filters/Vulnerability';
import VulnerabilityCVSSFilterConfig from './Filters/VulnerabilityCVSS';
import VulnerabilityCharacteristicsFilterConfig from './Filters/VulnerabilityCharacteristics';
import VulnerabilityExploitationStatesFilterConfig from './Filters/VulnerabilityExploitationStates';
import VulnerabilityRiskRatingFilterConfig from './Filters/VulnerabilityRiskRating';
import WorkflowStateFilterConfig from './Filters/WorkflowState';
import { FilterConfig, FilterValues } from './GlobalFilter.type';
import { useGlobalFilterContext } from './GlobalFilterContext';
import { FilterRegistry } from './registry';

/**
 * INCLUDE NEW FILTERS BELOW IN THE ORDER THEY SHOULD APPEAR ON THE PAGE
 */
const REGISTERED_FILTERS = [
  RecommendedDetectionFilterConfig,
  ContentTypeFilterConfig,
  // these apply only to the faux indicator feed in collections
  IndicatorType,
  IndicatorScore,
  // end indicator filters
  // these apply only to landing pages
  ThreatProfileFilterConfig,
  ThreatProfilePriorityFilterConfig,
  TargetIndustryFilterConfig,
  VulnerabilityRiskRatingFilterConfig,
  VulnerabilityExploitationStatesFilterConfig,
  VulnerabilityCharacteristicsFilterConfig,
  VulnerabilityCVSSFilterConfig,
  ActorMotivationFilterConfig,
  ActorSourceRegionFilterConfig,
  ActorTargetRegionFilterConfig,
  FirstSeenFilterConfig,
  LastSeenFilterConfig,
  // end landing page filters
  HasThreatFilterConfig,
  HasIndicatorsFilterConfig,
  MitreFilterConfig,
  ThreatActorFilterConfig,
  AnalyticSeverityFilterConfig,
  AnalyticRankFilterConfig,
  DefensivePostureFilterConfig,
  PlatformFilterConfig,
  LanguageFilterConfig,
  LogsourceFilterConfig,
  DeployedEnvironmentFilterConfig,
  BASCompatibilityFilterConfig,
  DataSourceFilterConfig,
  SoftwareFilterConfig,
  VulnerabilityFilterConfig,
  SelectedAnalyticFilterConfig,
  NistActionFilterConfig,
  OrganizationsFilterConfig,
  ContributorsFilterConfig,
  CreationTimeFilterConfig,
  VisibilityFilterConfig,
  WorkflowStateFilterConfig,
  AccessibleContentFilterConfig,
  MetadataFilterConfig,
  // these don't provide a UI
  IdsFilterConfig,
  SearchConfig
];
/*
 * ^ INCLUDE NEW FILTERS ABOVE
 */

interface RegistryWrapper {
  _registry: FilterRegistry;
  appliedCount: number;
  fromQuery(topic: ArtifactType, query: Query): void;
  /* generates a query from arbitrary values */
  generateQuery(topic: ArtifactType, values: FilterValues): Query;
  render(): JSX.Element;
  renderByContainingSupportedTopics(supportTopics: ArtifactType[]): JSX.Element;
  parseSearch(topicOverride?: ArtifactType): FilterValues;
  reset(): void;
  search: string;
  topic: ArtifactType;
  /* generates a query from the values stored in URL state; defaults to the topic stored state, but can be overridden */
  toQuery(topicOverride?: ArtifactType): Query;
  update(update: FilterValues, onlyIncludedValues?: boolean): void;
  values: FilterValues;
}

type RegistryState = {
  topic: ArtifactType;
  values: FilterValues;
  search: string;
};

export function _useFilterRegistry(
  filters: FilterConfig[],
  defaultTopic: ArtifactType = ArtifactType.Session,
  resetPage?: () => void,
  replace = false
): RegistryWrapper {
  const { user } = useAuth();
  const [filterContext] = useGlobalFilterContext();
  const { schema: definition, orgId, schemaStatus } = useMetadataSchema();
  const [registry, setRegistry] = React.useState<FilterRegistry>();
  const { search, update: _update } = useQueryString(replace);
  const initialUrlTopic = getQueryParam(search, 'topic');
  const [state, setState] = React.useState<RegistryState>({
    topic: isArtifactType(initialUrlTopic) ? initialUrlTopic : defaultTopic,
    values: null,
    search
  });
  // deep objects in state apparently aren't memoized so we need to do that explicitly
  const stringifiedState = JSON.stringify(state);
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const { topic, values, search: stateSearch } = React.useMemo(() => state, [stringifiedState]);

  React.useEffect(() => {
    // generate the registry when authz state is available
    if (user.id && [Status.resolved, Status.rejected].includes(schemaStatus)) {
      const _registry = new FilterRegistry({
        metadataSchema: definition,
        userId: user.id,
        orgId: orgId,
        defaultFeedFilter: user.effective_preference?.default_feed_filter
      });
      filters.forEach(_registry.register.bind(_registry));
      setRegistry(_registry);
    }
  }, [definition, filters, schemaStatus, orgId, user.effective_preference?.default_feed_filter, user.id]);

  React.useEffect(() => {
    // update computed state when url changes
    setState((state: RegistryState): RegistryState => {
      let topicUpdate = {};
      let valuesUpdate = {};
      let _topic = state.topic; // tracks which topic should be used for parsing within this updater function

      // check the topic first, since it's used to compute values later
      const urlTopic = getQueryParam(search, 'topic') || defaultTopic;
      if (isArtifactType(urlTopic)) {
        topicUpdate = { topic: urlTopic };
        _topic = urlTopic;
      }

      if (registry) {
        valuesUpdate = { values: registry.parseSearch(search, _topic) };
      }

      return { ...state, search, ...topicUpdate, ...valuesUpdate };
    });
  }, [defaultTopic, registry, search]);

  const update = React.useCallback(
    (newState: FilterValues, onlyIncludedValues?: boolean) => {
      if (onlyIncludedValues) {
        // if values don't change, noop to avoid unnecessary effect loops
        if (isEqual(newState, values)) return;
        else _update(newState, onlyIncludedValues);
      } else {
        const newValues: Record<string, string | string[]> = Object.entries(newState).reduce((newVals, [key, val]) => {
          // for undefined (removed from url) values, set to the special null value so we can replace with default
          if ((val === undefined || isEqual(val, [])) && registry?.isDefaultFromSettings(topic, key)) {
            val = FilterRegistry.NULL;
          }

          return { ...newVals, [key]: val };
        }, {});
        if (isEmpty(newValues)) return;
        _update(newValues);
        if (resetPage) resetPage();
      }
    },
    [registry, resetPage, topic, values, _update]
  );

  const render = React.useCallback(() => {
    if (!registry) return null;
    if (!values) return null;
    return registry.render(topic, update, values, filterContext);
  }, [registry, topic, update, values, filterContext]);

  const renderByContainingSupportedTopics = React.useCallback(
    (supportTopics: ArtifactType[]) => {
      if (!registry) return null;
      if (!values) return null;
      return registry.renderByContainingSupportedTopics(topic, supportTopics, update, values, filterContext);
    },
    [registry, topic, update, values, filterContext]
  );

  const toQuery = React.useCallback(
    (topicOverride?: ArtifactType) => {
      if (!registry) return null;
      if (!values) return null;
      if (topicOverride) {
        return registry.toQuery(topicOverride, registry.parseSearch(stateSearch, topicOverride), filterContext);
      }
      return registry.toQuery(topic, values, filterContext);
    },
    // use`stateSearch` here because it's updated with `values`
    [registry, stateSearch, topic, values, filterContext]
  );

  const generateQuery = React.useCallback(
    (topic: ArtifactType, values: FilterValues): Query => {
      if (!registry) return null;
      return registry.toQuery(topic, values);
    },
    [registry]
  );

  const fromQuery = React.useCallback(
    (newTopic: ArtifactType, query: Query) => {
      if (!registry) return;
      const values = registry.fromQuery(topic, query);
      update({
        ...values,
        topic: newTopic
      });
    },
    [registry, topic, update]
  );

  const parseSearch = React.useCallback(
    (topicOverride?: ArtifactType) => {
      if (!registry) return {};
      return registry.parseSearch(search, topicOverride || topic);
    },
    [registry, search, topic]
  );

  const reset = React.useCallback(() => {
    const all = parseAnyQueryString(window?.location?.search || '');
    const otherNonFilterParams = Object.keys(all).reduce((result, key) => {
      if (!(key in values)) result[key] = all[key];
      return result;
    }, {});
    delete otherNonFilterParams['page'];
    update(
      {
        topic,
        ...otherNonFilterParams
      },
      true
    );
  }, [update, topic, values]);

  const appliedCount = React.useMemo(() => {
    if (!registry) return 0;
    if (!values) return 0;
    return registry.appliedCount(topic, values);
  }, [registry, topic, values]);

  return {
    _registry: registry,
    appliedCount,
    fromQuery,
    generateQuery,
    parseSearch,
    render,
    renderByContainingSupportedTopics,
    reset,
    search,
    topic,
    toQuery,
    update,
    values
  };
}

export default function useFilterRegistry(
  defaultTopic: ArtifactType = ArtifactType.Session,
  resetPage?: () => void,
  replace = false
): RegistryWrapper {
  return _useFilterRegistry(
    React.useMemo(() => REGISTERED_FILTERS, []),
    defaultTopic,
    resetPage,
    replace
  );
}
