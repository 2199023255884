import React from 'react';

import { useField } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { Autocomplete, Option } from 'snap-ui/Autocomplete';
import { CardProps } from 'snap-ui/Card/CheckableCards';
import Divider from 'snap-ui/Divider';
import { styled } from 'snap-ui/util';

import { getCountryBy } from 'constants/countries';

import { CheckableCardCheckboxFormik } from 'module/Form/CheckableCardFormik';

import SecurityProfileStep from './SecurityProfileStep';
import actors from './assets/actors.json';

const FlagContainer = styled('div')`
  font-size: 20px;
  line-height: 16px;
`;

type CountryDetail = {
  name: string;
  code: string;
  flag: JSX.Element;
};

const { target_locations } = actors;
const targetLocations: CountryDetail[] = target_locations
  .map(location => getCountryBy('name', location))
  .map(country => ({ name: country.name, code: country.alpha3, flag: country.flag }));

const targetLocationCodes = targetLocations.map(country => country.code);

export default function SecurityProfileStepRegions(): JSX.Element {
  const [field, , helpers] = useField('regions');
  const [searchTerm, setSearchTerm] = React.useState<string>('');

  const [curatedLocations, setCuratedLocations] = React.useState<string[]>(targetLocationCodes.slice(0, 12));

  const curatedLocationOptions: CardProps[] = curatedLocations.map(code => {
    const country = targetLocations.find(country => country.code === code);
    return {
      value: country.code,
      label: country.name,
      icon: <FlagContainer>{country.flag}</FlagContainer>
    };
  });

  const [additionalLocations, setAdditionalLocations] = React.useState<string[]>(targetLocationCodes.slice(12));
  const additionalLocationOptions: Option[] = additionalLocations.map(code => {
    const country = targetLocations.find(country => country.code === code);
    return {
      value: country.code,
      content: country.name
    };
  });

  React.useEffect(() => {
    setCuratedLocations(curatedLocations => {
      const newCuratedLocations = [...curatedLocations];
      if (!isEmpty(field.value))
        field.value.forEach(code => {
          if (!curatedLocations.includes(code)) newCuratedLocations.push(code);
        });
      return newCuratedLocations;
    });
  }, [field.value]);

  React.useEffect(() => {
    setAdditionalLocations(targetLocationCodes.filter(code => !curatedLocations.includes(code)));
  }, [curatedLocations]);

  return (
    <SecurityProfileStep
      title='In which regions do you operate?'
      description='Threat actors adeptly tailor their strategies to target different regions, exploiting geopolitical tensions, cultural nuances, and regulatory variations to maximize the impact of their attacks. By customizing their tactics to exploit local vulnerabilities, threat actors can effectively bypass defenses and gain a foothold in specific regions, underscoring the importance of a globally-aware cybersecurity approach.'
      content={
        <>
          <CheckableCardCheckboxFormik name='regions' options={curatedLocationOptions} size='sm' />
          <Divider textAlign='left'>Specify another region</Divider>
          <Autocomplete
            name=''
            onInputChange={setSearchTerm}
            inputValue={searchTerm}
            disableUserAdditions
            onChange={(countryCode: string) => {
              if (countryCode) {
                helpers.setValue([...field.value, countryCode]);
                setSearchTerm('');
              }
            }}
            value=''
            options={additionalLocationOptions}
          />
        </>
      }
    />
  );
}
