import React from 'react';

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Button, { ButtonProps } from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import Icon from 'snap-ui/Icon';
import { DL } from 'snap-ui/KeyValueList';
import Placeholder from 'snap-ui/Placeholder';
import Tooltip from 'snap-ui/Tooltip';

import {
  FREQUENCY_TYPE,
  JobOverview,
  JobOverviewDetail,
  JobOverviewDetailItem,
  JobStatus,
  JobType,
  JobTypeTableMap
} from 'module/Job';
import { getJobStatusMessage } from 'module/Job/Job.util';
import { JobGroupDetailInterface } from 'module/Job/useJobGroupDetail';
import { DetectionJobStateLabel } from 'module/Widgets/StateLabel';

import { Organization } from 'types/auth';
import { Guid, Ident } from 'types/common';

import {
  attemptToFormatDate,
  humanTimeBetween,
  transformISOtoHumanizeFormat,
  transformSecondsToHumanFormat
} from 'utilities/TimeUtils';

import { HitsContainer } from './Hunt.style';
import { getEndTime, getStartTime, removeDuplicateDetailItems } from './Hunt.util';

type HuntStatusLabelProps = {
  status: JobStatus;
  statusDetail?: React.ReactNode;
};

export function HuntStatusLabel({ status, statusDetail }: HuntStatusLabelProps): JSX.Element {
  return (
    <Tooltip arrow placement='top' title={statusDetail} wrap>
      <DetectionJobStateLabel value={status} className='jobStateLabel' />
    </Tooltip>
  );
}

export function HuntDetailStatusLabel({
  status,
  percent,
  statusDetail
}: {
  status: JobStatus;
  timeRemaining: number;
  percent: number;
  statusDetail: JSX.Element;
}): JSX.Element {
  const message = getJobStatusMessage(status, percent);
  return (
    <>
      <dt>Status:</dt>
      <dd>
        <Tooltip
          arrow
          placement='top'
          title={
            <div>
              <dt>{message}</dt>
              {statusDetail}
            </div>
          }
          wrap
        >
          <DetectionJobStateLabel value={status} />
        </Tooltip>
      </dd>
    </>
  );
}

export function TaskColumnsPlaceholders({ width = '100%' }: { width?: number | '100%' }): JSX.Element {
  return (
    <>
      <dt>
        <Placeholder variant='text' width={width} />
      </dt>
      <dd>
        <Placeholder variant='text' width={width} />
      </dd>
    </>
  );
}
export function OverviewPlaceholder({ width = '100%' }: { width?: number | '100%' }): JSX.Element {
  return (
    <DL>
      <TaskColumnsPlaceholders width={width} />
      <TaskColumnsPlaceholders width={width} />
      <TaskColumnsPlaceholders width={width} />
      <TaskColumnsPlaceholders width={width} />
      <TaskColumnsPlaceholders width={width} />
      <TaskColumnsPlaceholders width={width} />
    </DL>
  );
}

export function DetectionHits({
  detection,
  dispatchErrors
}: {
  detection: JobOverviewDetailItem;
  dispatchErrors: Record<Guid, JobOverviewDetailItem>;
}): JSX.Element {
  const itemErrors = dispatchErrors?.[detection.guid];
  return (
    <HitsContainer>
      <span>{detection.hit_count || 0}</span>
      {itemErrors && (
        <Tooltip
          id={`${detection.guid}_errors`}
          arrow
          placement='top'
          title={
            <dl>
              {Object.entries(itemErrors).map(([integrationName, error]) => (
                <div key={integrationName}>
                  <dt>{integrationName}</dt>
                  <dd>{error}</dd>
                </div>
              ))}
            </dl>
          }
          wrap
        >
          <Icon aria-labelledby={`${detection.guid}_errors`} color='warning' icon={faExclamationTriangle} />
        </Tooltip>
      )}
    </HitsContainer>
  );
}

export function JobGroupStartDate({ jobGroup }: { jobGroup: JobOverview }) {
  return (
    <>
      <dt>Start Date</dt>
      <dd>{attemptToFormatDate(getStartTime(jobGroup))}</dd>
    </>
  );
}

export function JobGroupEndDate({ jobGroup }: { jobGroup: JobOverview }) {
  return (
    <>
      <dt>End Date</dt>
      <dd>{attemptToFormatDate(getEndTime(jobGroup))}</dd>
    </>
  );
}

export function JobGroupDuration({ jobGroup }: { jobGroup: JobOverview }) {
  const timeDuration = humanTimeBetween(
    getStartTime(jobGroup) as unknown as Date,
    getEndTime(jobGroup) as unknown as Date
  );
  return (
    <>
      <dt>Lookback Period</dt>
      <dd>{timeDuration}</dd>
    </>
  );
}

export function JobGroupHits({ hits, type }: { hits: number; type: JobType }) {
  const hitCount = hits || 0;
  return (
    <>
      <dt>{type === JobTypeTableMap.IOC ? 'Indicator' : 'Detection'} Hits</dt>
      <dd>{hitCount.toLocaleString()}</dd>
    </>
  );
}

export function Hunted({ details, type }: { details: JobOverviewDetailItem[]; type: JobType }) {
  const unique = removeDuplicateDetailItems(details);

  return (
    <>
      <dt>{type === JobTypeTableMap.IOC ? 'Indicators' : 'Detections'} Hunted</dt>
      <dd>{unique.length.toLocaleString()}</dd>
    </>
  );
}

export function JobDuration({ lastModified, jobs }: { jobs: JobOverviewDetail[]; lastModified: string }) {
  const durationSeconds = Math.round(Math.max(...jobs.map(r => r?.estimated_remaining_sec || 0)));
  const duration = transformSecondsToHumanFormat(durationSeconds, 'minutes');
  return (
    <>
      <dt>Job Duration</dt>
      <dd>
        <Tooltip arrow placement='top' title={'Last Updated: ' + transformISOtoHumanizeFormat(lastModified)}>
          <span>{duration}</span>
        </Tooltip>
      </dd>
    </>
  );
}

export function JobOrganization({ orgId, organizations }: { orgId: Ident; organizations: Organization[] }) {
  const orgName = organizations?.find(org => org.id === orgId)?.name;
  return (
    <>
      <dt>Organization</dt>
      <dd>{orgName}</dd>
    </>
  );
}

export function JobUser({ name }: { name: string }) {
  if (isEmpty(name)) return null;
  return (
    <>
      <dt>User</dt>
      <dd>{name}</dd>
    </>
  );
}

export function JobFrequency({ scheduleId }: { scheduleId: Ident }) {
  const frequency = scheduleId ? FREQUENCY_TYPE.Recurring : FREQUENCY_TYPE.Once;
  return (
    <>
      <dt>Frequency</dt>
      <dd>{frequency}</dd>
    </>
  );
}
export function JobCreation({ created }: { created: string }) {
  return (
    <>
      <dt>Created</dt>
      <dd>{attemptToFormatDate(created)}</dd>
    </>
  );
}
export function JobModified({ modified }: { modified: string }) {
  return (
    <>
      <dt>Modified</dt>
      <dd>{attemptToFormatDate(modified)}</dd>
    </>
  );
}

export function JobProgress({ timeRemaining, percent }: { timeRemaining: number; percent: number }) {
  const progress = Math.floor(percent * 100);
  const time =
    timeRemaining === 0 ? undefined : `- ${transformSecondsToHumanFormat(timeRemaining, 'minutes')} remaining`;
  return (
    <>
      <dt>Progress</dt>
      <dd>
        {progress}% {time}
      </dd>
    </>
  );
}

export function ExportButton({
  exportDataAsCsv,
  isExportPending,
  children,
  ...ButtonProps
}: ButtonProps & Pick<JobGroupDetailInterface, 'exportDataAsCsv' | 'isExportPending'>): JSX.Element {
  return (
    <Button variant='outlined' hasOwnIcon disabled={isExportPending} onClick={exportDataAsCsv} {...ButtonProps}>
      {isExportPending ? <CircularProgress size={15} /> : null}
      {children || 'Export Detection Hits'}
    </Button>
  );
}
