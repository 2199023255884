import React, { SyntheticEvent } from 'react';

import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';

import { SlimAccordion as Accordion } from 'snap-ui/Accordion';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { AttackScript } from 'module/AttackScript/AttackScript.type';
import { BASProductName } from 'module/BAS/BAS.type';
import { getMSVUrl } from 'module/BAS/Launcher/util';

import { Status } from 'storage';

const Container = styled('div', { name: 'AttackScriptListItem' })`
  display: flex;
  padding: ${p => p.theme.spacing(1, 0, 0, 0)};
  align-items: baseline;
  justify-content: space-between;

  .summary-text {
    display: inline;
    padding-right: ${p => p.theme.spacing(2)};
  }
`;

type AttackScriptListItemProps = {
  script: AttackScript;
  index: number;
  onChange: (panel: number) => (_event: SyntheticEvent<Element, Event>, isExpanded: boolean) => void;
  expanded: number | false;
  status: Status;
};
export default function AttackScriptListItem({ index, script }: AttackScriptListItemProps) {
  return (
    <Container key={index}>
      <Accordion expanded={false} key={index} summary={<AccordionSummary script={script} />} />
      {script.product_name === BASProductName.MandiantSecurityValidation ? (
        <a
          aria-label='View script in Mandiant Security Validation'
          href={getMSVUrl(script)}
          rel='noopener noreferrer'
          target='_blank'
        >
          <Icon icon={faArrowUpRightFromSquare} />
        </a>
      ) : (
        <Link aria-label='View attack script' to={`${Path.AttackScript}/${script.guid}`} target='_blank'>
          <Icon icon={faArrowUpRightFromSquare} />
        </Link>
      )}
    </Container>
  );
}

export type AccordionSummaryProps = {
  script: AttackScript;
};

function AccordionSummary({ script }: AccordionSummaryProps) {
  return (
    <Typography className='summary-text' variant='body1'>
      {script.name}
    </Typography>
  );
}
