import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { convertAxiosError } from 'module/ApiError';

import { useMetadataSchema } from 'provider/MetadataSchema';

import { useAsync } from 'storage';

import { ArtifactType, Guid } from 'types/common';

import { getMetadata, setMetadata } from './Metadata.service';
import { ArtifactMetadataService, Metadata } from './Metadata.type';
import { buildRenderersFromSchema, transform } from './Metadata.util';

function promiseUpdate(type: ArtifactType, guid: Guid, record: Record<string, unknown>) {
  return setMetadata(type, guid, record).catch(e => {
    throw convertAxiosError(e);
  });
}

export function useMetadata(type: ArtifactType, guid: Guid) {
  const { orgId, schema } = useMetadataSchema();
  const { data, status, task, setData, error, setError, errorProps } = useAsync<Metadata>();

  const postProcess = React.useCallback(
    (payload: ArtifactMetadataService) => {
      const { metadata_schema, metadata_value } = payload;
      const renderers = buildRenderersFromSchema(schema);
      if (isEmpty(metadata_value)) {
        setData({
          activeMeta: schema,
          activeRenderers: renderers,
          meta: schema,
          invalid: isEmpty(schema?.properties),
          renderers: renderers,
          value: metadata_value
        });
      } else {
        const result = {
          activeMeta: schema,
          activeRenderers: renderers,
          meta: metadata_schema,
          invalid: isEmpty(metadata_schema?.properties),
          renderers: buildRenderersFromSchema(metadata_schema),
          value: metadata_value
        };
        setData(result);
      }
    },
    [schema, setData]
  );

  const refresh = React.useCallback(async () => {
    async function fetcher() {
      const artifact = await getMetadata(type, guid).catch(e => {
        if (e?.response?.status === 404) {
          const notFound: ArtifactMetadataService = {
            metadata_value: {},
            metadata_schema: schema
          };
          return notFound;
        }
        throw e;
      });
      postProcess(artifact);
    }

    if (guid && orgId) task(fetcher());
    else if (schema) postProcess({ metadata_schema: undefined, metadata_value: undefined });
  }, [schema, guid, orgId, postProcess, task, type]);

  const formUpdate = React.useCallback(
    (record: Metadata['value'], guidOverride?: Guid) =>
      task(promiseUpdate(type, guidOverride || guid, record)).then(() => refresh()),
    [guid, refresh, task, type]
  );

  const inlineUpdate = React.useCallback(
    async (path: string, value: unknown) => {
      const record = {
        ...data?.value,
        [path]: transform(value)
      };
      return promiseUpdate(type, guid, record).then(postProcess);
    },
    [data?.value, guid, postProcess, type]
  );

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    data,
    status,
    inlineUpdate,
    formUpdate,
    refresh,
    error,
    errorProps,
    setError
  };
}
