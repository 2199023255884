import React from 'react';

import { styled } from 'snap-ui/util';

import { LandingArtifact } from 'module/Landing/Landing.type';
import { getTagIDs } from 'module/Landing/Landing.util';
import useSecurityProfile from 'module/SecurityProfile/useSecurityProfile';
import { Discriminator } from 'module/Tag';

import Badge from '../Badge';

export const Container = styled('div', { label: 'ThreatProfile' })`
  display: flex;
  gap: ${p => p.theme.spacing(2)};
  align-items: center;
  font-size: 1.25em;
`;

export default function ThreatProfile({ item }: { item: LandingArtifact }) {
  const { tagInProfile } = useSecurityProfile();
  const tagIDs = getTagIDs(item, item.type as Discriminator);

  const tag = tagInProfile(tagIDs);

  if (!tag) return null;

  return (
    <Container className='ThreatProfile'>
      <Badge badgeSignature={tag.score_label} badgeName='PRIORITY' />
    </Container>
  );
}
