import React from 'react';

import { Link } from 'react-router-dom';

import CronForm from 'snap-ui/CronForm';
import { DataGrid, GridColDef, GridRowModel } from 'snap-ui/DataGrid';

import Paths from 'constants/paths';

import useTableControls from 'hooks/useTableControls';

import { Integration, JobSchedule } from 'module/Integration/Integration.type';
import { getSpecificJobSchema } from 'module/Integration/Integration.util';
import DeleteTaskButton from 'module/Integration/IntegrationConfidenceTailoring/DeleteTaskButton';
import EditTaskButton from 'module/Integration/IntegrationConfidenceTailoring/EditTaskButton';
import useJobSchema from 'module/Integration/useJobSchema';
import { JobType } from 'module/Job';
import { TimeAgoTimestamp } from 'module/Widgets/TimeAgoTimestamp';

import { useIntegrationCatalog } from 'provider';

import { Guid } from 'types/common';
import { SortDirection } from 'types/filter';

import { NOOP } from 'utilities/FunctionUtils';
import { formatMiniTimestamp } from 'utilities/TimeUtils';

import TablePagination from '../Widgets/TablePagination';

type IntegrationConfidenceJobsTablesProps = {
  jobs: JobSchedule[];
};

function filterFn(query: string, item: JobSchedule): boolean {
  return item?.name.toLowerCase().includes(query?.toLowerCase());
}

function getIntegrationNameByGuid(allIntegrations: Integration[], integrationGuid: Guid) {
  return allIntegrations?.find(i => i.guid === integrationGuid);
}

export default function HuntTaskTable({ jobs }: IntegrationConfidenceJobsTablesProps) {
  const { data: jobSchemaCatalog } = useJobSchema();

  const {
    integrations: { huntable: allHuntableIntegrations }
  } = useIntegrationCatalog();
  const {
    data: visibleRows,
    setPage,
    page,
    pageTotal
  } = useTableControls(
    jobs,
    filterFn,
    {
      field: 'name',
      direction: SortDirection.desc
    },
    20
  );

  const columns: GridColDef<GridRowModel>[] = [
    {
      field: 'name',
      headerName: 'Hunt',
      flex: 20,
      renderCell: params => (
        <Link to={`${Paths.Hunt}?scheduledHuntId=${params.row.id}`}>{params.row.name || <em>No Name</em>}</Link>
      )
    },
    {
      field: 'cron_trigger',
      headerName: 'Frequency (UTC)',
      flex: 10,
      renderCell: params => <CronForm value={params.row.cron_trigger} readOnly setValue={NOOP} />
    },
    {
      field: 'last_dispatch',
      headerName: 'Last Run',
      renderCell: params =>
        params.row.last_dispatch ? <TimeAgoTimestamp timestamp={params.row.last_dispatch} /> : 'Never Run',
      flex: 10
    },
    {
      field: 'next_dispatch',
      headerName: 'Next Run',
      renderCell: params => formatMiniTimestamp(params.row.next_dispatch),
      flex: 10
    },
    {
      field: 'integration_name',
      headerName: 'Integration',
      renderCell: params => params.row.integration_name,
      flex: 10
    },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: p => {
        return (
          <>
            <EditTaskButton
              name={p.row.name}
              type={JobType.Hunt}
              title='Edit Scheduled Hunt'
              integration_guid={p.row.integration_guid}
              extra_parameters={p.row?.parameters}
              criteria={p.row?.criteria}
              analytic_filter={p.row.analytic_filter}
              cron_trigger={p.row?.cron_trigger}
              schedule_guid={p.row.guid}
              jobSchema={getSpecificJobSchema(
                jobSchemaCatalog,
                getIntegrationNameByGuid(allHuntableIntegrations, p.row.integration_guid)?.type
              )}
            />
            <DeleteTaskButton name={p.row.name} integration_guid={p.row.integration_guid} schedule_guid={p.row.guid} />
          </>
        );
      },
      minWidth: 100,
      hideSortIcons: true
    }
  ];

  return (
    <>
      <DataGrid
        className='data-grid'
        columns={columns}
        rows={visibleRows}
        getRowId={row => row.guid}
        disableRowSelectionOnClick
        getRowClassName={p => (p.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        getCellClassName={() => 'dataGrid-row cell-space'}
        slots={{
          footer: () => <TablePagination changePage={setPage} numPages={pageTotal} page={page} zeroIndex />
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }]
          },
          filter: {
            filterModel: {
              items: [{ field: 'error', operator: 'isEmpty' }]
            }
          }
        }}
      />
    </>
  );
}
