import { styled } from 'snap-ui/util';

export const HuntTableContainer = styled('div')`
  width: calc(100vw - ${p => p.theme.spacing(9)} * 2);
  height: 700px;

  .non-group-cell {
    margin-left: ${p => p.theme.spacing(8)};
  }

  .parent-group-cell {
    margin-left: 0;
  }

  .child-group-cell {
    margin-left: ${p => p.theme.spacing(10)};
  }

  .truncate-label {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const HitsContainer = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  gap: ${p => p.theme.spacing(2)};
`;

export const OverviewContainer = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: stretch;
  align-items: flex-start;
  gap: ${p => p.theme.spacing(4)};

  & > * {
    flex: 1 0 400px;
  }
`;

export const WorkbenchSidebarContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${p => p.theme.spacing(3)};

  .header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: ${p => p.theme.spacing(2)};
  }

  .detection {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: ${p => p.theme.spacing(3)};
    white-space: initial;
  }
`;
