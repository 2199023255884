import React from 'react';

import { styled } from 'snap-ui/util';

import { Status } from 'storage';

import { ArtifactType, PageParams } from 'types/common';

import CreateButton from './CreateButton';
import { DisplayKind, FeedAsync } from './Feed.type';
import FeedMenu from './FeedMenu';
import FeedMessage from './FeedMessage';

const Container = styled('div', { name: 'Feed-subtitle' })`
  min-width: min(900px, 100%);
  margin-bottom: ${p => p.theme.spacing(3)};
  padding: ${p => p.theme.spacing(2)};
  background-color: ${p => p.theme.palette.secondary.main};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .actions {
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};
  }
`;

type FeedSubtitleProps = {
  display: DisplayKind;
  feed: Pick<FeedAsync, 'status' | 'total' | 'items' | 'message'>;
  pageParams: PageParams;
  topic: ArtifactType;
  handleDisplayKindChange(kind: DisplayKind): void;
  selectionModel: string[];
};

function FeedSubtitle({
  display,
  feed,
  pageParams,
  topic,
  handleDisplayKindChange,
  selectionModel
}: FeedSubtitleProps) {
  return (
    <Container>
      <FeedMessage display={display} feed={feed} pageParams={pageParams} topic={topic} />
      <div className='actions'>
        <CreateButton type={topic} />
        <FeedMenu
          feedTotal={feed.total}
          feedGuid={feed.items.map(i => i.guid)}
          hasActivity={Status.pending === feed.status}
          displayKind={display}
          onDisplayKindChange={handleDisplayKindChange}
          selectionModel={selectionModel}
        />
      </div>
    </Container>
  );
}

export default FeedSubtitle;
