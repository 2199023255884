import React from 'react';

import { FormControl, FormControlLabel, FormHelperText } from 'snap-ui/FormControl';
import FormLabel from 'snap-ui/FormLabel';
import Radio from 'snap-ui/Radio';
import RadioGroup from 'snap-ui/RadioGroup';

import { InlineEditorForm, JsonSchema, useInlineEditor, withJsonFormsControlProps } from 'module/JsonView';

import { isMetaEmpty, reform } from '../Metadata.util';
import { Control, ControlProps } from './Control.type';
import { isControlApplied } from './Control.util';

function InlineFlag(props: { data: string; enabled: boolean; path: string; schema: JsonSchema; type: string }) {
  const { data, enabled, path, schema, type } = props;
  const editor = useInlineEditor(data);
  return (
    <InlineEditorForm data={data} editor={editor} enabled={enabled} path={path} title={schema.title} type={type}>
      <FormControl className={`Control Control-${Control.Flag}`}>
        <FormLabel id={`${path}-radio-buttons-group-label`}>{schema.title}</FormLabel>
        <RadioGroup
          value={editor.queue || ''}
          row
          aria-labelledby={`${path}-radio-buttons-group-label`}
          name={`${path}-radio-buttons-group`}
          onChange={e => editor.setQueue(e.target.value)}
        >
          <FormControlLabel value='false' control={<Radio />} label='False' />
          <FormControlLabel value='true' control={<Radio />} label='True' />
        </RadioGroup>
        <FormHelperText>{schema.description}</FormHelperText>
      </FormControl>
    </InlineEditorForm>
  );
}

const _FlagControl = ({ config, data, enabled, handleChange, path, schema }: ControlProps) => {
  const flag = reform(data) as string;
  if (!isControlApplied(schema, config) || (config.truncated && isMetaEmpty(flag))) return null;
  if (config.asInline)
    return <InlineFlag data={flag} enabled={enabled} path={path} schema={schema} type={config.type} />;
  const options = [
    <FormControlLabel key='false' value='false' control={<Radio />} label='False' />,
    <FormControlLabel key='true' value='true' control={<Radio />} label='True' />
  ];
  if (config.asFilter) options.unshift(<FormControlLabel key='any' value='any' control={<Radio />} label='Any' />);
  return (
    <FormControl className={`Control Control-${Control.Flag}`} disabled={!enabled}>
      <FormLabel id={`${path}-radio-buttons-group-label`}>{schema.title}</FormLabel>
      <RadioGroup
        value={flag || 'any'}
        row
        aria-labelledby={`${path}-radio-buttons-group-label`}
        name={`${path}-radio-buttons-group`}
        onChange={e => handleChange(path, e.target.value === 'any' ? null : e.target.value)}
      >
        <>{options.map(o => o)}</>
      </RadioGroup>
      <FormHelperText>{schema.description}</FormHelperText>
    </FormControl>
  );
};
export const FlagControl = withJsonFormsControlProps(_FlagControl);
