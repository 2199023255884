import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { FilterAugment, FilterControl } from 'module/GlobalFilter';
import { TagWeightFilterOptions } from 'module/SecurityProfile/SecurityProfile.type';
import Badge from 'module/Widgets/Badge';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';
import { addOrRemoveValue } from '../GlobalFilter.util';

type ThreatPriorityKeys = {
  priority: TagWeightFilterOptions[];
};

type ThreatProfilePriorityFilterProps = {
  onChange(values: ThreatPriorityKeys): void;
  values: ThreatPriorityKeys;
};

function ThreatProfilePriorityFilter({ onChange, values }: ThreatProfilePriorityFilterProps): React.ReactElement {
  const { isSubscriber } = useAuth();
  return (
    <FilterControl disabled={!isSubscriber}>
      <FormLabel id='threat-priority-toggle-button-group-label' className='title-tooltip'>
        By Threat Profile Priority
        <Tooltip
          title={
            !isSubscriber
              ? 'This filter is only available to subscribers'
              : "Prioritization of threats in your organization's threat profile"
          }
          placement='right'
          arrow
          wrap
        >
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <FormGroup aria-labelledby='threat-priority-toggle-button-group-label'>
        {Object.values(TagWeightFilterOptions).map(option => (
          <FormControlLabel
            key={option}
            control={<Checkbox onChange={handleChange} value={option} checked={values.priority.includes(option)} />}
            label={<Badge badgeSignature={option} badgeName='PRIORITY' />}
          />
        ))}
      </FormGroup>
    </FilterControl>
  );

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as TagWeightFilterOptions;
    const priority = addOrRemoveValue(checked, values.priority, v);
    onChange({ priority });
  }
}

function checkForCompleteSet(values: TagWeightFilterOptions[]): boolean {
  return !values?.length || Object.values(TagWeightFilterOptions).every(weight => values?.includes(weight));
}

function toQuery(values: ThreatPriorityKeys, { orgId }: FilterAugment): Query {
  if (checkForCompleteSet(values.priority)) return;
  const value = values.priority;
  return {
    op: Ops.for_each,
    items: [
      {
        field: 'security_profile_tags.score_label',
        op: Ops.in,
        value: value
      },
      {
        field: 'security_profile_tags.organization_id',
        op: Ops.equals,
        value: orgId
      }
    ]
  };
}

const fromQuery = zod
  .object({
    op: zod.literal(Ops.for_each),
    items: zod.array(
      zod.union([
        zod
          .object({
            field: zod.literal('security_profile_tags.organization_id')
          })
          .transform(() => undefined),
        zod
          .object({
            field: zod.literal('security_profile_tags.score_label'),
            op: zod.literal(Ops.in),
            value: zod.array(zod.nativeEnum(TagWeightFilterOptions))
          })
          .transform(query => query.value.map(v => v))
      ])
    )
  })
  .transform(query => ({
    priority: query.items.filter(Boolean).flat()
  }));

const ThreatProfilePriorityFilterConfig: FilterConfig<ThreatPriorityKeys> = {
  defaults: { default: () => ({ priority: Object.values(TagWeightFilterOptions) }) },
  supportedTopics: [ArtifactType.Collection, ArtifactType.AttackTag],
  component: ThreatProfilePriorityFilter,
  toQuery: { default: toQuery, [ArtifactType.AttackTag]: () => null },
  fromQuery: { default: fromQuery }
};
export default ThreatProfilePriorityFilterConfig;
