import React from 'react';

import { faAlarmExclamation, faEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import { reportIncident } from 'apis/resources/user';

import { IncidentReport, User } from 'types/auth';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ubroutemenu: any;
      ubdiv: any;
      ubspan: any;
    }
  }
}

export type Props = {
  close(): void;
  sendUserInfoToHubspot(): void;
  showDemo: boolean;
  showIncidentResponse: boolean;
  user: User;
};

type IncidentForm = IncidentReport & {
  disclaimer: boolean;
};

const SelectContainer = styled('div')`
  display: flex;
  gap: 8px;
  flex-flow: row nowrap;

  img {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
  }

  select {
    flex: 1;
  }
`;

const Disclaimer = styled('div')`
  font-size: 80%;

  input {
    margin-right: 4px;
  }
`;

const Error = styled('div')`
  font-size: 80%;
  color: #ff8181;
`;

function IncidentForm(): JSX.Element {
  const { submitForm, values } = useFormikContext<IncidentForm>();

  let providerImage;
  switch (values.incident_provider) {
    case 'Avertium':
      providerImage = <img src='https://cdn.snapattack.com/images/organizations/avertium.png' alt='Avertium logo' />;
      break;
    case 'Mandiant':
      providerImage = <img src='https://cdn.snapattack.com/images/organizations/mandiant.jpg' alt='Mandiant logo' />;
      break;
    default:
      providerImage = null;
  }

  return (
    <Form className='userback-controls-options' id='incident-form'>
      <div className='userback-controls-help-message' style={{ marginTop: '30px' }}>
        Get help from experts
      </div>
      <ubdiv className='userback-controls-form'>
        <Error>
          <ErrorMessage name='incident_provider' />
        </Error>
        <SelectContainer>
          {providerImage}
          <Field as='select' name='incident_provider' placeholder='Provider'>
            <option value=''>Select a provider</option>
            <option value='Avertium'>Avertium</option>
            <option value='Mandiant'>Mandiant</option>
          </Field>
        </SelectContainer>
        <Error>
          <ErrorMessage name='first_name' />
        </Error>
        <Field type='text' name='first_name' placeholder='First Name' />
        <Error>
          <ErrorMessage name='last_name' />
        </Error>
        <Field type='text' name='last_name' placeholder='Last Name' />
        <Error>
          <ErrorMessage name='email' />
        </Error>
        <Field type='text' name='email' placeholder='Email' />
        <Error>
          <ErrorMessage name='company_name' />
        </Error>
        <Field type='text' name='company_name' placeholder='Company Name' />
        <Error>
          <ErrorMessage name='phone_number' />
        </Error>
        <Field type='text' name='phone_number' placeholder='Phone Number' />
        <Error>
          <ErrorMessage name='description' />
        </Error>
        <Field as='textarea' name='description' placeholder='Active Incident Description' />
        <Error>
          <ErrorMessage name='disclaimer' />
        </Error>
        <Disclaimer>
          <Field type='checkbox' name='disclaimer' /> By submitting this form, you accept that any included information
          will be shared with a third-party partner. This information is protected with a bi-directional NDA between our
          companies
        </Disclaimer>
        <button className='userback-controls-send userback-button-input' type='submit' onClick={submitForm}>
          <span className='userback-controls-send-text'>Submit</span>
        </button>
      </ubdiv>
    </Form>
  );
}
export default function CustomUserbackWidget({
  close,
  sendUserInfoToHubspot,
  showDemo,
  showIncidentResponse,
  user
}: Props) {
  const [visibleForm, setVisibleForm] = React.useState<'incident' | 'incidentConfirm' | 'demoConfirm'>(null);
  const validationSchema = Yup.object().shape({
    incident_provider: Yup.string().required('Required'),
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    company_name: Yup.string().required('Required'),
    phone_number: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    disclaimer: Yup.bool().oneOf([true], 'You need to accept the terms and conditions').required('Required')
  });

  function hideMenu() {
    document.querySelector('.userback-controls-step[data-step="1"]')['style'].display = 'none';
  }

  function handleRequestDemo() {
    sendUserInfoToHubspot();
    setVisibleForm('demoConfirm');
    close();
  }

  function handleIncidentForm() {
    setVisibleForm('incident');
    hideMenu();
  }

  function handleSubmit(formData: IncidentForm) {
    const payload: IncidentReport = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      company_name: formData.company_name,
      phone_number: formData.phone_number,
      description: formData.description,
      incident_provider: formData.incident_provider
    };
    reportIncident(payload);
    setVisibleForm('incidentConfirm');
    close();
  }

  return visibleForm === null ? (
    <div id='userback-custom-menu-options'>
      {showDemo && (
        <ubroutemenu className='userback-feedback-type-form' onClick={handleRequestDemo}>
          <ubdiv>
            <Icon icon={faEnvelopeOpenText} />
          </ubdiv>
          <ubdiv>Request a Demo</ubdiv>
          <ubdiv>See our enterprise features and integrations in action</ubdiv>
        </ubroutemenu>
      )}
      {showIncidentResponse && (
        <ubroutemenu className='userback-feedback-type-form' onClick={handleIncidentForm}>
          <ubdiv>
            <Icon icon={faAlarmExclamation} />
          </ubdiv>
          <ubdiv>Report an Incident</ubdiv>
          <ubdiv>Get help from expert responders</ubdiv>
        </ubroutemenu>
      )}
    </div>
  ) : visibleForm === 'demoConfirm' ? (
    <ubdiv className='userback-controls-send-success'>
      <style
        dangerouslySetInnerHTML={{
          __html: ':root {--widget-outro-icon: #d08ded !important; }'
        }}
      />
      <ubdiv data-icon='1'>
        <svg className='ub-checkmark' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
          <circle className='ub-checkmark__circle' cx='26' cy='26' r='25' fill='none'></circle>
          <path className='ub-checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8'></path>
        </svg>
        <ubspan>Thank you!</ubspan>
        <p>Thanks for reaching out! A member of our sales team will follow up with you to schedule a demo.</p>
      </ubdiv>
    </ubdiv>
  ) : visibleForm === 'incidentConfirm' ? (
    <ubdiv className='userback-controls-send-success'>
      <style
        dangerouslySetInnerHTML={{
          __html: ':root {--widget-outro-icon: #d08ded !important; }'
        }}
      />
      <ubdiv data-icon='1'>
        <svg className='ub-checkmark' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
          <circle className='ub-checkmark__circle' cx='26' cy='26' r='25' fill='none'></circle>
          <path className='ub-checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8'></path>
        </svg>
        <ubspan>Thank you!</ubspan>
        <p>
          Your incident information has been passed along to our partner. They will be reaching out with additional
          information shortly.
        </p>
      </ubdiv>
    </ubdiv>
  ) : visibleForm === 'incident' ? (
    <>
      <ubdiv className='userback-controls-header'>
        <ubdiv className='userback-controls-back' style={{ display: 'block' }}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'>
            <path d='M15 239c-9.4 9.4-9.4 24.6 0 33.9L207 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L65.9 256 241 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L15 239z'></path>
          </svg>
        </ubdiv>
        <ubdiv className='userback-controls-header-text'>Report an Incident</ubdiv>
        <ubdiv className='userback-controls-close'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'>
            <path d='M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z'></path>
          </svg>
        </ubdiv>
      </ubdiv>
      <ubdiv className='userback-controls-form'>
        <Formik
          initialValues={{
            incident_provider: '',
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            company_name: '',
            phone_number: '',
            description: '',
            disclaimer: false
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <IncidentForm />
        </Formik>
      </ubdiv>
    </>
  ) : null;
}
