import React from 'react';

import { FormDialog } from 'snap-ui/Dialog';

import { IDEAction, IDEState } from 'module/IDE/reducer';

import { Status } from 'storage';

import TranslateAnalytic from './TranslateAnalytic';
import { hasLogsource } from './TranslateAnalytic.util';
import useIDETranslateControls from './useIDETranslateControls';

type EditAsNativeDialogProps = {
  onClose(): void;
  onChange(action: IDEAction): void;
  ideState: IDEState;
  isOpen: boolean;
};

export default function EditAsNativeDialog({
  onChange,
  ideState,
  isOpen,
  onClose
}: EditAsNativeDialogProps): JSX.Element {
  const shouldTranslate = React.useMemo(() => isOpen && hasLogsource(ideState), [ideState, isOpen]);

  const {
    integrationOptions,
    languageOptions,
    languageStatus,
    selectedLanguage,
    setSelectedLanguageId,
    setSelectedIntegrationOptionValue,
    selectedIntegrationOptionValue,
    translation,
    translationError,
    translationStatus
  } = useIDETranslateControls(ideState, shouldTranslate);
  const translationIsPending = translationStatus === Status.pending;

  const disableSubmit = !translation;

  function handleSubmit() {
    onChange({
      type: 'EditAsNativeUpdate',
      languageId: selectedLanguage.id,
      backendKey: selectedLanguage.backend_key,
      raw: translation
    });
    onClose();
  }

  const submitTitle = selectedLanguage?.name ? `Edit ${selectedLanguage.name} Detection` : 'Edit Native Detection';

  return (
    <FormDialog
      title='Convert to Native Detection'
      DialogProps={{ onClose, open: isOpen, maxWidth: 'md' }}
      FormikConfig={{
        enableReinitialize: true,
        initialValues: {}, // form dialog is just for styling; formik doesn't help here
        onSubmit: handleSubmit
      }}
      SubmitProps={{
        children: submitTitle,
        disabled: disableSubmit
      }}
    >
      {() => (
        <TranslateAnalytic
          analyticGuid={undefined}
          analyticName={undefined}
          disableCopy
          disableDownload
          disableSearch
          integrationOptions={integrationOptions}
          languageOptionStatus={languageStatus}
          languageOptions={languageOptions}
          onEditAsNative={undefined}
          onIntegrationChange={setSelectedIntegrationOptionValue}
          onLanguageChange={setSelectedLanguageId}
          selectedIntegrationValue={selectedIntegrationOptionValue}
          selectedLanguage={selectedLanguage}
          translation={translation}
          translationError={translationError}
          translationIsPending={translationIsPending}
        />
      )}
    </FormDialog>
  );
}
