import React from 'react';

import z from 'zod';

import Button from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import { FieldsLayout } from 'snap-ui/Layout';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { Collection } from 'module/Collection/Collection.type';
import { standardFormikBaseProps } from 'module/Form';
import TextFieldFormik from 'module/Form/TextFieldFormik';

import { useAuth, useFilter, useFilterCollection } from 'provider';

import { ArtifactType } from 'types/common';

import { sleep } from 'utilities/FunctionUtils';

type Props = {
  collection?: Collection;
  onChange(collection: Collection): void;
  count: number;
};

const emptyForm = {
  name: '',
  description: ''
};

const ModalTitle = styled('div')`
  display: flex;
  justify-content: space-between;

  .icon-list {
    display: flex;
    gap: ${p => p.theme.spacing(1)};
    margin-right: ${p => p.theme.spacing(6)};
  }
`;

export default function FilterModeCollectionEdit(props: Props) {
  const { topic: filterTopic } = useFilter();
  const { refresh, add, update, erase, isActive } = useFilterCollection();
  const { defaultOrgId } = useAuth();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggleOpen = () => {
    setIsOpen(prev => !prev);
  };

  const handleDelete = async () => {
    await erase(props.collection?.guid);
    refresh();
    await sleep(500);
    props.onChange(null);
    setIsOpen(false);
  };

  const handleSubmit = async (values: any) => {
    const entity = {
      id: defaultOrgId,
      name: values.name,
      guid: props.collection?.guid
    };

    const collection = await (props.collection ? update : add)(entity, values.description);
    refresh();
    await sleep(500);
    if (collection) props.onChange(collection);
    setIsOpen(false);
  };

  return (
    <>
      {props.count > 0 && (
        <Tooltip arrow placement='top' title={props.collection ? 'Edit filter' : 'Save filter'}>
          <Button
            sx={{
              minWidth: '35px'
            }}
            id='FilterSaveButton'
            ariaLabel={`${props.collection ? 'Edit' : 'Save'} as filter a collection`}
            onClick={handleToggleOpen}
          >
            {props.collection ? <Icon.Edit /> : <Icon.Save />}
          </Button>
        </Tooltip>
      )}
      <FormDialog
        title={
          <ModalTitle>
            {props.collection ? 'Update Filter' : 'Create a New Filter '}
            <div className='icon-list'>
              <Icon.Intel {...iconTopicProps(ArtifactType.Intel)} />
              <Icon.Session {...iconTopicProps(ArtifactType.Session)} />
              <Icon.Analytic {...iconTopicProps(ArtifactType.Analytic)} />
            </div>
          </ModalTitle>
        }
        DialogProps={{
          open: isOpen,
          onClose: () => setIsOpen(false)
        }}
        SubmitProps={{
          children: isActive ? <CircularProgress size={25} /> : 'Save',
          disabled: isActive
        }}
        SecondaryActionProps={
          props.collection?.name
            ? {
                onClick: handleDelete,
                children: isActive ? <CircularProgress size={25} /> : 'Delete',
                disabled: isActive
              }
            : null
        }
        FormikConfig={{
          ...standardFormikBaseProps,
          initialValues: {
            name: props.collection?.name || emptyForm.name,
            description: props.collection?.description || emptyForm.description
          },
          onSubmit: handleSubmit,
          zodSchema: z.object({
            name: z.string().min(1, 'Name is required'),
            description: z.string().optional()
          }),
          validateOnBlur: false
        }}
      >
        <FieldsLayout>
          <TextFieldFormik
            label='Name'
            aria-label='Filter name'
            name='name'
            type='text'
            required
            placeholder='Enter a name for this saved filter'
          />
          <TextFieldFormik
            label='Description'
            id='FilterCollectionDescription'
            aria-label='Filter description'
            name='description'
            placeholder='Enter a description for this saved filter'
          />
        </FieldsLayout>
      </FormDialog>
    </>
  );

  function iconTopicProps(topic: ArtifactType) {
    const result = { color: undefined, style: { border: undefined, padding: '2px' } };

    switch (filterTopic) {
      case ArtifactType.Intel:
        if (topic !== ArtifactType.Intel) result.color = 'grey';
        break;
      case ArtifactType.Session:
        if (topic !== ArtifactType.Session) result.color = 'grey';
        break;
      case ArtifactType.Analytic:
        if (topic !== ArtifactType.Analytic) result.color = 'grey';
        break;
    }

    return result;
  }
}
