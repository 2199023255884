import { Integration } from 'module/Integration/Integration.type';
import { SupplementalArtifact } from 'module/Search/Search.type';

import { AnalyticDeploymentDetail, AnalyticLanguage, DeploymentIntegration } from 'types/analytic';

import { getCompatibleIntegration } from './util';

export function isCustomerManaged(integration: Integration): boolean {
  return integration.type === 'CustomerManaged';
}

export function isNotDeployable(integration: Integration, deployables: Integration[]): boolean {
  return !deployables?.some(deployment => deployment.id === integration.id);
}

export function isSupplementallyDeployable(integration: Integration, supplemental: SupplementalArtifact): boolean {
  return supplemental?.deployable_integrations?.some(sd => sd.guid === integration.guid);
}

export function createDeployableIntegration(
  integration: Integration,
  analytic: SupplementalArtifact,
  orgDeployments: AnalyticDeploymentDetail[],
  languageList: AnalyticLanguage[]
): DeploymentIntegration {
  return {
    ...integration,
    deployment: findDeploymentForIntegration(integration, orgDeployments),
    compatible: getCompatibleIntegration(analytic, languageList, integration)
  };
}

export function findDeploymentForIntegration(integration: Integration, orgDeployments: AnalyticDeploymentDetail[]) {
  return orgDeployments?.find(dep => dep.integrations?.some(i => i.guid === integration.guid));
}
