import React from 'react';

import isArray from 'lodash/isArray';

import { useAuth } from 'provider';

import { ErrorProps, Status, useAsync } from 'storage';

import { User } from 'types/auth';
import { Guid, Ident } from 'types/common';

import { getProfile, getProfileTags, postProfile, putProfileBuild, putProfileTags } from './SecurityProfile.api';
import {
  SecurityProfilePayload,
  SecurityProfileResponse,
  SecurityProfileTag,
  SecurityProfileTagPayload,
  TagWeight
} from './SecurityProfile.type';

type SecurityProfileWizardInterface = {
  data: SecurityProfileResponse;
  noProfileFound: boolean;
  status: Status;
  refresh(): void;
  incrementalUpdate(update: Partial<SecurityProfilePayload>): void;
};

export function useSecurityProfileWizard(): SecurityProfileWizardInterface {
  const { organization } = useSecurityProfile();
  const orgGuid = organization.guid;
  const { data, setData, error, run, task, status } = useAsync<SecurityProfileResponse>();

  const refresh = React.useCallback(() => {
    run(getProfile(orgGuid));
  }, [orgGuid, run]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const incrementalUpdate = React.useCallback(
    (update: Partial<SecurityProfilePayload>) => {
      task(postProfile(orgGuid, update).then(updated => setData(updated)));
    },
    [orgGuid, setData, task]
  );

  return {
    data,
    noProfileFound: error?.response?.status === 404,
    status,
    refresh,
    incrementalUpdate
  };
}

type BuildSecurityProfileInterface = {
  buildProfile(): Promise<void>;
  buildStatus: Status;
  buildErrorProps: ErrorProps;
};

export function useBuildSecurityProfile(): BuildSecurityProfileInterface {
  const { organization } = useSecurityProfile();
  const orgGuid = organization.guid;
  const { task: buildTask, status: buildStatus, errorProps: buildErrorProps } = useAsync();

  const buildProfile = React.useCallback(() => {
    return buildTask(putProfileBuild(orgGuid));
  }, [buildTask, orgGuid]);

  return {
    buildProfile,
    buildStatus,
    buildErrorProps
  };
}

export type SecurityProfileTagInterface = {
  tags: SecurityProfileTag[];
  tagsStatus: Status;
  taskStatus: Status;
  refresh(): void;
  addTag(tagId: SecurityProfileTagPayload['tag']): void;
  removeTag(tagId: SecurityProfileTagPayload['tag']): void;
  updateTagWeight(tag: SecurityProfileTagPayload): void;
  isTagInProfile(tagIDs: Ident | Ident[]): boolean;
  tagInProfile(tagIDs: Ident | Ident[]): SecurityProfileTag;
};

export type SecurityProfileInterface = SecurityProfileTagInterface & {
  organization: User['preferred_organization'];
};

export function useSecurityProfileTags(orgGuid: Guid): SecurityProfileTagInterface {
  const { data, run, status: tagsStatus } = useAsync<SecurityProfileTag[]>([]);
  const { task, status: taskStatus } = useAsync<SecurityProfileTag[]>([]);

  const refresh = React.useCallback(() => {
    if (orgGuid) run(getProfileTags(orgGuid), true);
  }, [orgGuid, run]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const addTag = React.useCallback(
    (tagId: SecurityProfileTagPayload['tag']) => {
      task(putProfileTags(orgGuid, { tag: tagId, score: TagWeight.Medium }).then(refresh));
    },
    [orgGuid, refresh, task]
  );

  const removeTag = React.useCallback(
    (tagId: SecurityProfileTagPayload['tag']) => {
      task(
        putProfileTags(orgGuid, {
          tag: tagId,
          score: TagWeight.Ignored
        }).then(refresh)
      );
    },
    [orgGuid, refresh, task]
  );

  const updateTagWeight = React.useCallback(
    (tag: SecurityProfileTagPayload) => {
      task(putProfileTags(orgGuid, tag).then(refresh));
    },
    [orgGuid, refresh, task]
  );

  const tagInProfile = React.useCallback(
    (tagIDs: Ident | Ident[]): SecurityProfileTag => {
      const ids = isArray(tagIDs) ? tagIDs : [tagIDs];
      return data.find(t => ids.some(id => id === t.tag));
    },
    [data]
  );

  const isTagInProfile = React.useCallback(
    (tagIDs: Ident | Ident[]): boolean => {
      const ids = isArray(tagIDs) ? tagIDs : [tagIDs];

      return data.some(t => ids.some(id => id === t.tag) && t.score_label !== TagWeight.Ignored);
    },
    [data]
  );

  return {
    tags: data,
    tagsStatus,
    taskStatus,
    refresh,
    addTag,
    removeTag,
    updateTagWeight,
    isTagInProfile,
    tagInProfile
  };
}

type SecurityProfileProps = {
  children: React.ReactNode;
};

const SecurityProfileProviderContext = React.createContext<SecurityProfileInterface>(null);

export function SecurityProfileProvider(props: SecurityProfileProps) {
  const { children } = props;
  const { user } = useAuth();
  const organization = user.preferred_organization;

  const securityProfileTags = useSecurityProfileTags(organization?.guid);

  return (
    <SecurityProfileProviderContext.Provider value={{ organization, ...securityProfileTags }}>
      {children}
    </SecurityProfileProviderContext.Provider>
  );
}

export default function useSecurityProfile() {
  const context = React.useContext(SecurityProfileProviderContext);
  if (!context) {
    throw new Error('useSecurityProfile must be used within an SecurityProfileProviderContext');
  }
  return context;
}
