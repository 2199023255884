import { GridColDef } from 'snap-ui/DataGrid';

import { Artifact, ArtifactScore } from 'types/common';

const ArtifactScoreIndex = Object.values(ArtifactScore);

export function getInitiallyHiddenColumns(columns: (GridColDef<Artifact> & { hideToStart?: boolean })[]) {
  return columns
    .filter(c => c.hideToStart)
    .reduce(
      (p, c) => ({
        ...p,
        [c.field]: false
      }),
      {}
    );
}

/**
 * Could be used for Severity And Rank (Confidence) fields. However, our data isn't 2d. Is's 3d. We cannot
 * sort on the given field since supplemental calls are done. Need a better solution.
 */
export function scoreComparator(_v1, _v2, cellParams1, cellParams2) {
  return (
    ArtifactScoreIndex.indexOf(cellParams1.value || ArtifactScore.UNKNOWN) -
    ArtifactScoreIndex.indexOf(cellParams2.value || ArtifactScore.UNKNOWN)
  );
}

export function hitsComparator(v1, v2) {
  if (!v1 || !v2) return 0;
  const v1Total = v1.validated + v1.validated_gaps + v1.unvalidated;
  const v2Total = v2.validated + v2.validated_gaps + v2.unvalidated;
  return v1Total - v2Total;
}

export function validationValue(validationObj) {
  if (validationObj.validated > 0) return 1;
  else if (validationObj.validated_gaps > 0) return 2;
  else if (validationObj.unvalidated > 0) return 3;
  else if (validationObj.validated === 0 && validationObj.validated_gaps === 0 && validationObj.unvalidated === 0) {
    return 4;
  }
}

export function validationComparator(v1, v2) {
  return validationValue(v1) - validationValue(v2);
}
