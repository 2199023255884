import React from 'react';

import { faCopy, faDownload, faFilePen } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import Alert, { AlertTitle } from 'snap-ui/Alert';
import { Option, getDisplayValue } from 'snap-ui/Autocomplete';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import { CommonEvent, Engage, Fingerprint, Widget } from 'lib/Engagement';

import { PanePlaceholder } from 'module/Analytic/core/ViewAnalyticTabs';
import { IntegrationLink } from 'module/Integration';
import { TargetSigmaMessage } from 'module/Widgets/ApiErrorPopper';
import CopyButton from 'module/Widgets/CopyButton';
import DownloadLink from 'module/Widgets/DownloadLink';
import { Highlight, SyntaxViewer } from 'module/Widgets/SyntaxEditor';

import { Status } from 'storage';

import { AnalyticLanguage, Language } from 'types/analytic';
import { Guid } from 'types/common';

import { IntegrationOption } from '../Integration/IntegrationAutocomplete';
import {
  CodeMirrorContainer,
  Container,
  StyledActionIconButton,
  StyledAutocomplete,
  StyledAutocompletePaper,
  StyledIntegrationAutocomplete,
  StyledToolbar,
  StyledToolbarButtons
} from './TranslateAnalytic.helper';

interface TranslateAnalyticProps {
  analyticGuid: Guid;
  analyticName: string;
  className?: string;
  disableCopy?: boolean;
  disableDownload?: boolean;
  disableEditAsNative?: boolean;
  disableSearch?: boolean;
  editAsNativeAction?: 'Edit' | 'Clone';
  integrationOptions: IntegrationOption[];
  languageOptionStatus: Status;
  languageOptions: Option[];
  languageTargetTypes?: ('deployment' | 'hunt' | 'search')[];
  native?: boolean;
  onEditAsNative?(languageId: number, raw: string): void;
  onIntegrationChange?(integrationId: string): void;
  onLanguageChange(languageId: number): void;
  selectedIntegrationValue?: string;
  selectedLanguage?: Language | AnalyticLanguage;
  translation: string;
  translationError: string;
  translationIsPending: boolean;
}

export default function TranslateAnalytic(props: TranslateAnalyticProps): JSX.Element {
  const { pathname } = useLocation();

  const disableIntegrationOption = props.integrationOptions.length <= 1;
  const disableLanguageOption = props.languageOptions.length <= 1;

  const filename = props.analyticName || 'detection';

  function handleLanguageChange(value: string | Option[]) {
    Engage.track(
      Fingerprint.of(Widget.TranslateAnalytic).withCommon(CommonEvent.Change).withData({ name: 'language', value })
    );
    props.onLanguageChange(Number.parseInt(value as string, 10));
  }

  function handleIntegrationChange(value: string) {
    props.onIntegrationChange(value);
  }

  const trackCopyButton = () => {
    Engage.track(
      Fingerprint.of(Widget.TranslateAnalytic).withCommon(CommonEvent.Copy).withQualifier('icon button').withData({
        name: 'code',
        pathname,
        analytic: props.analyticGuid,
        language: props.selectedLanguage,
        translation: props.translation
      })
    );
  };

  const trackCopyOfUserSelection = () => {
    Engage.track(
      Fingerprint.of(Widget.TranslateAnalytic).withCommon(CommonEvent.Copy).withQualifier('user selection').withData({
        name: 'code',
        pathname,
        analytic: props.analyticGuid,
        language: props.selectedLanguage,
        selection: document.getSelection().toString()
      })
    );
  };
  const editAsNativeAction = props.editAsNativeAction || 'Edit';
  const editAsNativeTooltip = `${editAsNativeAction} as ${props.selectedLanguage?.name || 'native'} code`;

  return (
    <Container className={classNames('TranslateAnalytic', props.className)}>
      <StyledToolbar className='TranslateAnalytic-integrationToolbar MuiToolBar-override'>
        <Typography variant='h4' aria-label='Translate detection widget' component='div'>
          Detection
        </Typography>
        <StyledToolbarButtons>
          <StyledIntegrationAutocomplete
            className='IntegrationSelection'
            onChange={handleIntegrationChange}
            name='IntegrationSelection'
            value={props.selectedIntegrationValue}
            disabled={disableIntegrationOption}
            disableClearable
            disableUserAdditions
            options={props.integrationOptions}
          />
          {!props.disableSearch && (
            <IntegrationLink
              language={props.selectedLanguage}
              translatedCode={props.translation}
              analyticGuid={props.analyticGuid}
              native={props.native}
            />
          )}
        </StyledToolbarButtons>
      </StyledToolbar>
      <CodeMirrorContainer className='TranslateAnalytic-codeContainer'>
        {props.translationIsPending ? (
          <PanePlaceholder />
        ) : (
          <>
            {!!props.translationError && props.selectedLanguage && (
              <Alert severity='error' icon={false}>
                <AlertTitle>{props.translationError}</AlertTitle>
                <TargetSigmaMessage />
              </Alert>
            )}
            {props.translation && props.selectedLanguage && (
              <SyntaxViewer
                value={props.translation}
                className='syntaxEditor'
                disableCopyButton
                disableLineNumbers
                name='translatedAnalytic'
                highlight={Highlight[props.selectedLanguage?.backend_key]}
                onCopy={trackCopyOfUserSelection}
              />
            )}
          </>
        )}
      </CodeMirrorContainer>
      <StyledToolbar className='TranslateAnalytic-languageToolbar MuiToolBar-override'>
        <div>
          {props.languageOptions.length > 0 && (
            <StyledAutocomplete
              name='LanguageSelection'
              onChange={handleLanguageChange}
              options={props.languageOptions}
              value={getDisplayValue(props.languageOptions, props.selectedLanguage?.id.toString())}
              disabled={disableLanguageOption}
              disableUserAdditions
              disableClearable
              isOptionEqualToValue={(option: Option, value: Option) => option.value === value.value}
              PaperComponent={StyledAutocompletePaper}
            />
          )}
        </div>
        <StyledToolbarButtons>
          {props.onEditAsNative && (
            <Tooltip title={editAsNativeTooltip} placement='top' arrow wrap>
              <StyledActionIconButton
                aria-label={editAsNativeTooltip}
                disabled={
                  props.disableEditAsNative ||
                  !props.selectedLanguage ||
                  !props.translation ||
                  props.translationIsPending
                }
                icon={faFilePen}
                onClick={() => {
                  props.onEditAsNative(props.selectedLanguage?.id, props.translation);
                }}
              />
            </Tooltip>
          )}
          {!props.disableCopy && (
            <CopyButton
              onSuccess={trackCopyButton}
              tooltip='Copy to clipboard'
              placement='top'
              value={props.translation}
            >
              <StyledActionIconButton aria-label='Copy to clipboard' icon={faCopy} />
            </CopyButton>
          )}
          {!props.disableDownload && (
            <Tooltip title='Download' placement='top' arrow wrap>
              <DownloadLink filename={filename} format={props.selectedLanguage} value={props.translation}>
                <StyledActionIconButton aria-label='Download' icon={faDownload} />
              </DownloadLink>
            </Tooltip>
          )}
        </StyledToolbarButtons>
      </StyledToolbar>
    </Container>
  );
}
