import React from 'react';

import useCurationArtifactCount from 'module/Curation/useCurationArtifactCount';
import { useMayI } from 'module/May';
import ReferenceCounts from 'module/Widgets/ReferenceCounts';

import { useAuth } from 'provider/Account';

import { FunctionalPermission } from 'types/auth';
import { Artifact } from 'types/common';

import { PosturePlaceholder } from '../Layout/Placeholders';

export default function CollectionCounter({ item }: { item: Artifact }): JSX.Element {
  const counts = useCurationArtifactCount(item);
  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);
  const { isSubscriber } = useAuth();

  if (counts.active) return <PosturePlaceholder className='CardCounter' showTotal={false} />;
  return (
    <div className='CardCounter'>
      <ReferenceCounts
        artifactType={item.type}
        analytics={counts.analyticTotal}
        attackScripts={isBasUser ? counts.attackScriptTotal : undefined}
        indicators={isSubscriber ? counts.indicatorTotal : undefined}
        intels={counts.intelTotal}
        sessions={counts.sessionTotal}
      />
    </div>
  );
}
