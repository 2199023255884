import React from 'react';

import { faCaretUp, faPen } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import _Accordion, { AccordionDetails, AccordionSummary, AccordionSummaryActionsWrapper } from 'snap-ui/Accordion';
import { ActionIconButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Placeholder from 'snap-ui/Placeholder';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import withFunctionalPermission from 'module/Util/withFunctionalPermission';

import { useMetadataSchema } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType, Guid } from 'types/common';

import { JsonView } from '../JsonView/JsonView';
import { InlineUpdateContext } from '../JsonView/useInlineEditor';
import { MetadataBulkModal } from './MetadataBulkModal';
import { MetadataMigrate } from './MetadataMigrate';
import { useMetadata } from './useMetadata';

const Accordion = styled(_Accordion)`
  background-color: inherit;

  .MuiAccordionSummary-root {
    padding-left: 0;
  }
`;

const Content = styled(AccordionDetails)`
  padding: 0;

  .padded {
    padding: ${p => p.theme.spacing(2)};
  }

  .Content-alert {
    margin: ${p => p.theme.spacing(3, 0)};
    display: flex;
    gap: ${p => p.theme.spacing(3)};
    align-items: center;
  }

  .group-layout {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(6)};
  }

  .group-layout-item {
    min-width: 250px;
  }

  .group-layout-item:empty {
    display: none;
  }
`;

type MetadataView = {
  className?: string;
  guid: Guid /** Exception is Landing pages which should pass the (translated discriminator endpoint) / name combination here */;
  type: ArtifactType;
};

function _MetadataView({ className, guid, type }: MetadataView) {
  const { invalid } = useMetadataSchema();
  const [expanded, setExpanded] = React.useState(true);
  const [isBulkOpen, setIsBulkOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const { data, formUpdate, inlineUpdate, status, refresh } = useMetadata(type, guid);
  const isDataEmpty = isEmpty(Object.values(data?.value || {}).flat());

  const handleSubmit = async (values: Record<string, unknown>) => {
    setIsOpen(false);
    formUpdate(values);
  };

  return (
    <InlineUpdateContext.Provider value={inlineUpdate}>
      <Accordion className={classNames('MetaView', className)} expanded={expanded} elevation={0}>
        <AccordionSummaryActionsWrapper>
          <Typography variant='h4'>Metadata</Typography>
          {status !== Status.pending && (
            <Tooltip title={invalid ? '' : 'Open metadata editor'} arrow placement='right'>
              <ActionIconButton
                aria-label='Metadata bulk edit mode'
                icon={faPen}
                color='stealth'
                size='small'
                disabled={invalid}
                onClick={() => setIsBulkOpen(true)}
              />
            </Tooltip>
          )}
          <AccordionSummary
            aria-controls='metadata-content'
            id='metadata-header'
            expandIcon={<Icon icon={faCaretUp} />}
            onClick={() => setExpanded(!expanded)}
          />
        </AccordionSummaryActionsWrapper>
        <Content>
          {status === Status.pending && (
            <>
              <Placeholder variant='text' animation='pulse' />
              <Placeholder variant='text' animation='wave' />
              <Placeholder variant='text' animation='pulse' />
            </>
          )}
          {status !== Status.pending && invalid && (
            <em>
              No metadata fields have been defined. Go to <Link to={Path.Meta}>metadata settings</Link> and configure
              your first fields.
            </em>
          )}
          {status !== Status.pending && data?.meta && data?.meta.uischema && (
            <>
              {!data?.invalid && isDataEmpty ? (
                <em>No metadata set for this object.</em>
              ) : (
                <div className='padded'>
                  <JsonView
                    asInline
                    truncated
                    schema={data.meta}
                    uischema={data.meta.uischema}
                    renderers={data.renderers}
                    data={data.value}
                    type={type}
                  />
                </div>
              )}
            </>
          )}
        </Content>
      </Accordion>
      <MetadataBulkModal
        guid={guid}
        type={type}
        isOpen={isBulkOpen}
        onClose={() => setIsBulkOpen(false)}
        onUpdated={() => {
          setIsBulkOpen(false);
          refresh();
        }}
      />
      <MetadataMigrate
        data={data}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={handleSubmit}
        type={type}
      />
    </InlineUpdateContext.Provider>
  );
}

export const MetadataView = withFunctionalPermission(_MetadataView, FunctionalPermission.MetadataFeatures, true);
