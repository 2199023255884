import React from 'react';

import Chip from 'snap-ui/Chip';

import PlatformBadge from 'module/Widgets/PlatformBadge';

import { useAttackScriptCatalog } from './AttackScriptProvider';

export function AttackScriptPlatforms(): JSX.Element {
  const { attackScript } = useAttackScriptCatalog();
  return (
    <div className='AttackScriptHeader-platforms'>
      <PlatformBadge platforms={attackScript.platforms} />
    </div>
  );
}

export const CountChip = ({ count }: { count: number }) => <Chip size='small' label={count?.toLocaleString()} />;
