import omit from 'lodash/omit';
import { z } from 'zod';

import { StepDetail } from 'snap-ui/Stepper';

import { MandiantSeverityStatus } from 'module/Landing/Landing.type';
import { Discriminator } from 'module/Tag';

export const ExploitationStates = omit(MandiantSeverityStatus, 'Yes');

export type ExploitationStates = keyof typeof MandiantSeverityStatus;

export const ExploitationStateOrderedList = [
  ExploitationStates.NoKnown,
  ExploitationStates.Anticipated,
  ExploitationStates.Available,
  ExploitationStates.Confirmed,
  ExploitationStates.Wide
] as const;

export const SecurityProfilePayloadSchema = z.object({
  industries: z.array(z.string()),
  regions: z.array(z.string()),
  scale: z.number(),
  motivations: z.array(z.string()),
  applications: z.array(z.string()),

  platforms: z.array(z.string()),
  threat_maturity: z.number().min(0).max(1),
  confidence_min: z.number().min(0).max(1),
  severity_min: z.number().min(0).max(1),
  vulnerability_cvss_min: z.number().min(0).max(10),
  vulnerability_epss_min: z.number().min(0).max(1),
  vulnerability_exploit_min: z.nativeEnum(ExploitationStates),
  vulnerability_exploit_wild: z.boolean(),
  vulnerability_exploit_zero_day: z.boolean(),
  observation_age_max: z.number() // TODO - what unit?
});

export type SecurityProfilePayload = Required<z.infer<typeof SecurityProfilePayloadSchema>>;

export const SecurityProfileResponseSchema = SecurityProfilePayloadSchema.extend({
  organization_id: z.number()
});

export type SecurityProfileResponse = Required<z.infer<typeof SecurityProfileResponseSchema>>;

export const SecurityProfileSchema = SecurityProfileResponseSchema.omit({
  scale: true,
  threat_maturity: true,
  confidence_min: true,
  severity_min: true,
  vulnerability_cvss_min: true,
  vulnerability_epss_min: true,
  vulnerability_exploit_min: true,
  vulnerability_exploit_wild: true,
  vulnerability_exploit_zero_day: true,
  observation_age_max: true
}).extend({
  scale: z.string(),
  severity_thresholds: z.array(z.number()).max(2),
  confidence_thresholds: z.array(z.number()).max(2),

  // vulnerability risk tolerance
  risk_tolerance: z.array(z.number()).max(2),

  // vulnerability characteristics
  exploitation_state: z.array(z.number()).max(2),
  exploited_zero_day: z.string(),
  exploited_in_wild: z.string(),

  // exploit likelihood tolerance
  exploit_likelihood: z.array(z.number()).max(2),

  // threat recency priority
  threat_recency_threshold: z.number(),

  // last observed
  last_observed_threshold: z.array(z.number()).max(2)
});

export type SecurityProfile = Required<z.infer<typeof SecurityProfileSchema>>;

export type SecurityProfileStepDetail = StepDetail & {
  keys?: (keyof SecurityProfile)[];
};

// BE Enum:
// Highest = 1.0
// High = 0.75
// Medium = 0.5
// Low = 0.25
// Lowest = 0.1
// Ignored = 0.0
export const TagWeight = {
  Highest: 'Highest',
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
  Lowest: 'Lowest',
  Ignored: 'Ignored'
} as const;

export type TagWeight = keyof typeof TagWeight;

export const TagWeightFilterOptions = omit(TagWeight, 'Ignored');
export type TagWeightFilterOptions = keyof typeof TagWeightFilterOptions;

export const SecurityProfileTagPayloadSchema = z.object({
  tag: z.string() || z.number(), // the BE lets you PUT/DELETE a tag by name or ID; in GET, tag is the ID
  score: z.number() || z.nativeEnum(TagWeight)
});
export type SecurityProfileTagPayload = Required<z.infer<typeof SecurityProfileTagPayloadSchema>>;

export const SecurityProfileTagSchema = SecurityProfileTagPayloadSchema.extend({
  name: z.string(),
  source: z.string(),
  type: z.nativeEnum(Discriminator),
  override: z.boolean(),
  organization_id: z.number(),
  score_label: z.nativeEnum(TagWeight),
  score: z.nativeEnum(TagWeight),
  score_projected: z.number(),
  score_raw: z.number(),
  extracted: z.boolean()
});

export type SecurityProfileTag = Required<z.infer<typeof SecurityProfileTagSchema>>;
