import mean from 'lodash/mean';

import { AttackNode } from 'module/Matrix/Matrix.type';
import { SecurityProfileTag, TagWeight } from 'module/SecurityProfile';

import { Ident } from 'types/common';
import { Entry } from 'types/core';

import { AggregatedCoverage, ScoreAggregation, TagCoverage, RawValues, Calculated, CoverageStatName } from '../type';

function average(nums: RawValues): Calculated {
  const count = nums.length;
  const average = count ? Math.floor(mean(nums) * 100) / 100 : 0;
  return { average, count: nums.length };
}

export function emptyStats(): ScoreAggregation<Calculated>;
export function emptyStats(asArray: true): ScoreAggregation<RawValues>;
export function emptyStats(asArray?: true): ScoreAggregation<Calculated | RawValues> {
  return Object.values(TagWeight).reduce(
    (s, score) => ({
      ...s,
      [score]: {
        coverage: asArray ? [] : { average: 0, count: 0 },
        depth: asArray ? [] : { average: 0, count: 0 },
        breadth: asArray ? [] : { average: 0, count: 0 }
      }
    }),
    {}
  ) as ScoreAggregation;
}

export function aggregateCoverage(
  tactics: AttackNode[],
  getCoverage: (id: Ident) => TagCoverage,
  getProfile: (id: Ident) => SecurityProfileTag
): AggregatedCoverage {
  /** this will be mutated */
  const rawTacticStats = emptyStats(true);
  /** this will be mutated */
  const rawTechniqueStats = emptyStats(true);
  /** this will be mutated */
  const rawSubtechniqueStats = emptyStats(true);

  tactics.forEach(tactic => {
    processNode(tactic, rawTacticStats);
    tactic.attack_children.forEach(technique => {
      processNode(technique, rawTechniqueStats);
      technique.attack_children?.forEach(subtechnique => {
        processNode(subtechnique, rawSubtechniqueStats);
      });
    });
  });

  return {
    tactics: reduceToAverages(rawTacticStats),
    techniques: reduceToAverages(rawTechniqueStats),
    subtechniques: reduceToAverages(rawSubtechniqueStats)
  };

  function processNode(node: AttackNode, stats: ScoreAggregation<RawValues>) {
    const coverage = getCoverage(node.id);
    const priorityScore = getProfile(node.id)?.score_label;
    pushValues(stats, coverage, priorityScore);
  }

  function pushValues(stats: ScoreAggregation<RawValues>, coverage: TagCoverage, score: TagWeight) {
    if (!score || score === TagWeight.Ignored) return;
    stats[score].coverage.push(coverage?.score_coverage || 0);
    stats[score].breadth.push(coverage?.score_breadth || 0);
    stats[score].depth.push(coverage?.score_depth || 0);
  }

  function reduceToAverages(stats: ScoreAggregation<RawValues>): ScoreAggregation<Calculated> {
    const averages: ScoreAggregation<Calculated> = Object.fromEntries(
      Object.entries(stats).map(([score, valuesByStat]: Entry<ScoreAggregation<RawValues>>) => [
        score,
        Object.fromEntries(
          Object.entries(valuesByStat).map(([statName, rawValues]: [CoverageStatName, RawValues]) => [
            statName,
            average(rawValues)
          ])
        )
      ])
    ) as ScoreAggregation;

    return averages;
  }
}
