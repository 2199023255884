import React from 'react';

import union from 'lodash/union';

import { AutocompleteV2, createFilterOptions } from 'snap-ui/Autocomplete/AutocompleteV2';
import Icon from 'snap-ui/Icon';
import { default as TextField } from 'snap-ui/TextField';

import useDebounce from 'hooks/useDebounce';

import { InlineEditorForm, useInlineEditor, withJsonFormsControlProps } from 'module/JsonView';
import { searchMetadataTagField } from 'module/Search/Search.api';

import { Status, useAsync } from 'storage';

import { isMetaEmpty } from '../Metadata.util';
import { Control, ControlProps, TypeOption } from './Control.type';
import { isControlApplied } from './Control.util';

export type CustomTypeOption = TypeOption & {
  custom?: string;
};

const _Base = (props: {
  disabled?: boolean;
  label: string;
  onChange: (value: string[]) => void;
  path: string;
  preview?: boolean;
  value: string[];
}) => {
  const { disabled, label, onChange, path, preview, value } = props;
  const [inputValue, setInputValue] = React.useState('%');
  const debouncedValue = useDebounce(inputValue, 300);
  const { data: suggestions, run, status } = useAsync<string[]>();

  React.useEffect(() => {
    if (!preview && debouncedValue) run(searchMetadataTagField(path, debouncedValue));
  }, [debouncedValue, path, preview, run]);

  const options = React.useMemo(() => union(value, suggestions), [value, suggestions]);

  return (
    <AutocompleteV2
      disabled={disabled}
      className={`Control Control-${Control.OpenSelect}`}
      value={value}
      options={options}
      autoHighlight
      onChange={(_e, value: unknown[]) =>
        onChange(value?.map((v: string | CustomTypeOption) => (typeof v === 'string' ? v : v.custom || v.label)))
      }
      noOptionsText=''
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          elevated
          InputProps={
            status === Status.pending
              ? {
                  ...params.InputProps,
                  endAdornment: <Icon.SpinnerProgress />
                }
              : params.InputProps
          }
        />
      )}
      multiple
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      onInputChange={(_e, newInputValue) => setInputValue(newInputValue || '%')}
      filterOptions={(o: string[], s) => {
        const filter = createFilterOptions();
        const filtered = filter(o, s) as CustomTypeOption[];
        if (status === Status.pending || s.inputValue?.length <= 0) return filtered;
        if (!o.some(value => value.toLowerCase() === inputValue?.toLowerCase())) {
          filtered.push({
            key: inputValue,
            value: inputValue,
            label: `Add "${inputValue}"`,
            custom: inputValue
          });
        }
        return filtered;
      }}
    />
  );
};

const _OpenSelectControl = (props: ControlProps<string[]>) => {
  const { config, data, enabled, handleChange, path, schema } = props;
  const editor = useInlineEditor(props.data);

  if (!isControlApplied(schema, config) || (config.truncated && isMetaEmpty(data))) return null;
  if (config.asInline)
    return (
      <InlineEditorForm
        enabled={enabled}
        data={data}
        editor={editor}
        path={path}
        title={schema.title}
        type={config.type}
      >
        <_Base path={props.path} label={schema.title} value={editor.queue} onChange={editor.setQueue} />
      </InlineEditorForm>
    );
  return (
    <_Base
      path={props.path}
      label={schema.title}
      value={data || []}
      onChange={values => handleChange(path, values)}
      preview={config.asPreview}
      disabled={!enabled}
    />
  );
};
export const OpenSelectControl = withJsonFormsControlProps(_OpenSelectControl);
