import React from 'react';

import { faBookmark as faBookmarkOutlined, faTriangleExclamation, faWarning } from '@fortawesome/pro-regular-svg-icons';
import { faBookmark as faBookmarkSolid, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import startCase from 'lodash/startCase';

import Button from 'snap-ui/Button';
import Fade from 'snap-ui/Fade';
import Icon from 'snap-ui/Icon/Icon';
import { MenuTrigger } from 'snap-ui/Menu';
import { IconMenuItem } from 'snap-ui/MenuItem';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { useLandingCatalog } from 'module/Landing/LandingProvider';
import { useMayI } from 'module/May';
import { Discriminator, isTactic } from 'module/Tag';

import { useAuth } from 'provider/Account';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Ident } from 'types/common';

import { NOOP } from 'utilities/FunctionUtils';

import { SecurityProfileTag, TagWeight, TagWeightFilterOptions } from './SecurityProfile.type';
import useSecurityProfile from './useSecurityProfile';

const tagStyleString = p => `
:not(.disabled) {

  &.${TagWeight.Highest}, &.${TagWeight.Highest} svg {
      color: ${p.theme.palette.error.main};
  }
  &.${TagWeight.High}, &.${TagWeight.High} svg {
    color: ${p.theme.palette.orange.main};
  }
  &.${TagWeight.Medium}, &.${TagWeight.Medium} svg {
    color: ${p.theme.palette.warning.main};
  }
  &.${TagWeight.Low}, &.${TagWeight.Low} svg {
    color: ${p.theme.palette.success.main};
  }
  &.${TagWeight.Lowest}, &.${TagWeight.Lowest} svg {
    color: ${p.theme.palette.info.main};
  }
}
`;

const Container = styled('div', { label: 'AddToProfileContainer' })`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};

  .SecurityProfile-button,
  .TagPriority {
    display: flex;
    justify-content: center;
    gap: ${p => p.theme.spacing(3)};
    width: 190px;
  }

  .TagPriority {
    padding-right: ${p => p.theme.spacing(7)}; // accounts for caret
    .MuiButton-endIcon {
      position: absolute;
      right: ${p => p.theme.spacing(3)};
    }

    ${p => tagStyleString(p)}
  }

  .SecurityProfile-button.disabled {
    pointer-events: none;
    color: ${p => p.theme.palette.secondary.main};
  }
`;

const StyledMenuItem = styled(IconMenuItem, { label: 'WeightMenuItem' })`
  ${p => tagStyleString(p)}
`;

const CardBadgeContainer = styled('div', { label: 'CardBadgeProfileContainer' })`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(3)};

  .weight-icon {
    ${p => tagStyleString(p)}
  }
`;

type AddToSecurityProfileProps = {
  className?: string;
};

export function AddToSecurityProfile({ className }: AddToSecurityProfileProps) {
  const { isSubscriber } = useAuth();
  const canEdit = useMayI(FunctionalPermission.SetSecurityProfile);

  const { data, type } = useLandingCatalog();
  const { isTagInProfile, tagInProfile, addTag, removeTag, taskStatus, tagsStatus } = useSecurityProfile();
  const isAttackTactic = type === Discriminator.Attack && isTactic({ name: data.combined.name, discriminator: type });

  if (isAttackTactic) return null;

  const tagName = data.combined.name;
  const tagIDs = data.items?.map(item => item.id);
  const isInProfile = isTagInProfile(tagIDs);
  const tag: SecurityProfileTag = tagInProfile(tagIDs);
  const isPending = [tagsStatus, taskStatus].includes(Status.pending);

  function handleAddToSecurityProfile() {
    addTag(tagName);
  }
  function handleRemoveFromSecurityProfile() {
    removeTag(tag.name);
  }

  return (
    <Fade in timeout={1000}>
      <Container className={classnames('AddToSecurityProfile', className)}>
        {canEdit ? (
          <>
            {isInProfile ? (
              <>
                <Tooltip arrow title='Remove from Threat Profile' wrap>
                  <Button
                    className='SecurityProfile-button'
                    onClick={handleRemoveFromSecurityProfile}
                    disabled={isPending}
                  >
                    <Icon icon={faBookmarkSolid} /> In Threat Profile
                  </Button>
                </Tooltip>
                <TagPriority tag={tag} readonly={isPending} />
              </>
            ) : (
              <Button
                className='SecurityProfile-button'
                variant='outlined'
                onClick={handleAddToSecurityProfile}
                disabled={isPending}
              >
                <Icon icon={faBookmarkOutlined} />
                Add to Threat Profile
              </Button>
            )}
          </>
        ) : (
          <>
            {isInProfile ? (
              <>
                <Button className='SecurityProfile-button disabled' onClick={NOOP} disabled={isPending}>
                  <Icon icon={faBookmarkSolid} /> In Threat Profile
                </Button>
                <TagPriority readonly tag={tag} />
              </>
            ) : (
              <Tooltip
                arrow
                title={
                  isSubscriber
                    ? 'Contact an Organization Administrator to make changes.'
                    : 'Threat Profiles are only available to subscribers.'
                }
                wrap
              >
                <Button
                  className='SecurityProfile-button disabled'
                  variant='outlined'
                  onClick={NOOP}
                  disabled={isPending}
                >
                  <Icon icon={faBookmarkOutlined} />
                  Not In Threat Profile
                </Button>
              </Tooltip>
            )}
          </>
        )}
      </Container>
    </Fade>
  );
}

function TagPriority({ tag, readonly }: { tag: SecurityProfileTag; readonly?: boolean }): JSX.Element {
  const { updateTagWeight } = useSecurityProfile();
  const { isSubscriber } = useAuth();

  if (readonly)
    return (
      <Tooltip
        arrow
        title={
          isSubscriber
            ? 'Contact an Organization Administrator to make changes.'
            : 'Threat Profiles are only available to subscribers.'
        }
        wrap
      >
        <Button
          className={classnames('TagPriority SecurityProfile-button disabled', tag.score_label)}
          variant='outlined'
          color='inherit'
          onClick={NOOP}
        >
          <Icon icon={faWarning} /> {startCase(tag.score_label ?? 'no')} Priority
        </Button>
      </Tooltip>
    );

  return (
    <MenuTrigger
      className={classnames('TagPriority', tag.score_label)}
      trigger={
        <Button variant='outlined' color='inherit' endIcon={<Icon icon={faCaretDown} />}>
          <Icon icon={faWarning} /> {startCase(tag.score_label ?? 'no')} Priority
        </Button>
      }
    >
      {Object.values(TagWeightFilterOptions).map(priority => (
        <StyledMenuItem
          key={priority}
          className={priority}
          onClick={() => {
            updateTagWeight({ tag: tag.name, score: priority });
          }}
          disabled={tag.score_label === priority}
          icon={faWarning}
          text={`${startCase(priority)} Priority`}
        />
      ))}
    </MenuTrigger>
  );
}

type CardBadgeSecurityProfileProps = {
  className?: string;
  tagIDs: Ident[];
};
export function CardBadgeSecurityProfile({ tagIDs }: CardBadgeSecurityProfileProps) {
  const { isTagInProfile, tagInProfile } = useSecurityProfile();

  const isInProfile = isTagInProfile(tagIDs);
  const tag: SecurityProfileTag = tagInProfile(tagIDs);

  return (
    <CardBadgeContainer>
      {isInProfile ? (
        <>
          <Tooltip arrow title={`${startCase(tag?.score_label)} Priority`} wrap>
            <Icon icon={faTriangleExclamation} className={classnames('weight-icon', startCase(tag?.score_label))} />
          </Tooltip>
          <Tooltip arrow title='In Threat Profile' wrap>
            <Icon icon={faBookmarkSolid} color='primary' />
          </Tooltip>
        </>
      ) : (
        <Tooltip arrow title='Not in Threat Profile' wrap>
          <Icon icon={faBookmarkOutlined} color='primary' />
        </Tooltip>
      )}
    </CardBadgeContainer>
  );
}
