import z from 'zod';

import { JsonFormsRendererRegistryEntry, JsonSchema4, Layout } from 'module/JsonView';

import { Guid } from 'types/common';

import { Control } from './Control/Control.type';
import { convertNameToIdentifier } from './Metadata.util';

export type MetadataValue = Record<string, unknown>;

export type MetadataSchema = JsonSchema4 & {
  uischema: Layout;
  migrations?: Migration[];
};

export type ArtifactMetadataService = {
  metadata_schema: MetadataSchema;
  metadata_value: MetadataValue;
};

export type MetadataCatalog = Record<Guid, ArtifactMetadataService>;

export type Metadata = {
  activeMeta: MetadataSchema;
  activeRenderers: JsonFormsRendererRegistryEntry[];
  meta: MetadataSchema;
  invalid: boolean; // schema.properties is empty
  renderers: JsonFormsRendererRegistryEntry[];
  value: MetadataValue;
};

export const MetadataFieldInput = z
  .object({
    label: z.string().regex(/^[a-zA-Z][a-zA-Z ]*$/, {
      message: 'Value must be alphabetic characters.'
    }),
    description: z.string().optional(),
    type: z.string().nonempty('Please select a type'),
    applied: z.array(z.string()).min(1, 'At least one applied to type is needed'),
    options: z
      .array(
        z.union([
          z.string(),
          z.object({
            option: z.string(),
            id: z.string()
          })
        ])
      )
      .optional()
      .refine(items => new Set(items.map(i => (typeof i === 'string' ? i : i.option))).size === items.length, {
        message: 'Must be an array of unique values'
      }),
    _preview: z.string().optional(),
    _blacklist: z.array(z.string()).optional()
  })
  .refine(
    schema =>
      (schema.type === Control.Select || schema.type === Control.MultiSelect) && schema.options.length === 0
        ? false
        : true,
    {
      message: 'At least one option should be added',
      path: ['options']
    }
  )
  .refine(schema => (schema._blacklist.includes(convertNameToIdentifier(schema.label)) ? false : true), {
    message: 'Field Name already in use',
    path: ['label']
  });

export type MetadataFieldInput = Required<z.infer<typeof MetadataFieldInput>>;

export const Operation = {
  FieldAdd: 'FieldAdd',
  FieldDelete: 'FieldDelete',
  FieldRename: 'FieldRename',
  SelectionAdd: 'SelectionAdd',
  SelectionDelete: 'SelectionDelete',
  SelectionRename: 'SelectionRename',
  UIPropertyChange: 'UIPropertyChange'
} as const;

export type Operation = (typeof Operation)[keyof typeof Operation];

export type Migration = {
  id?: string;
  operation: Operation;
  field: string;
  value?: unknown;
  selection?: unknown;
};
