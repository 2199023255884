import React, { SyntheticEvent } from 'react';

import { faDiamondExclamation, faHexagon, faHexagonCheck, faHexagonXmark } from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { SlimAccordion as Accordion } from 'snap-ui/Accordion';
import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { AnalyticLanguagesProvider } from 'module/Analytic/AnalyticLanguages';
import { AnalyticVersionProvider } from 'module/Analytic/core/AnalyticVersionProvider';
import Deployment from 'module/Analytic/core/MetadataPanel/Deployment';
import { LevelMenuBadgeContainer } from 'module/Widgets/LevelMenuBadge/LevelMenuBadge.style';

import { useAuth } from 'provider';

import { Status } from 'storage';

import { AnalyticRecommendation } from 'types/analytic';

import { getPreferredOrgScore } from 'utilities/ArtifactUtils';

import { RecommendedContainer } from './SessionRecommendations.style';

type RecommendedDetectionProps = {
  analytic: AnalyticRecommendation;
  index: number;
  onChange: (panel: number) => (_event: SyntheticEvent<Element, Event>, isExpanded: boolean) => void;
  expanded: number | false;
  status: Status;
};
export default function RecommendedDetection({
  index,
  analytic,
  onChange,
  expanded,
  status
}: RecommendedDetectionProps) {
  return (
    <RecommendedContainer key={index}>
      <Accordion
        expanded={expanded === index}
        onChange={onChange(index)}
        key={index}
        summary={<AccordionSummary analytic={analytic} />}
        details={<AccordionDetail analytic={analytic} status={status} />}
      />
      <Link aria-label='Go to view detection page' to={`${Path.Detection}/${analytic.guid}`} target='_blank'>
        <Icon icon={faArrowUpRightFromSquare} />
      </Link>
    </RecommendedContainer>
  );
}

export type AccordionSummaryProps = {
  analytic: AnalyticRecommendation;
};

function AccordionSummary({ analytic }: AccordionSummaryProps) {
  return (
    <>
      <Typography className='summary-text' variant='body1'>
        {analytic.name}
      </Typography>
      <div className='summary-icons'>
        {!analytic.deployable && <Icon className='negative' icon={faHexagonXmark} />}
        {analytic.deployable && (
          <Icon
            icon={analytic.deployments[0]?.integrations?.length > 0 ? faHexagonCheck : faHexagon}
            className={classNames({
              positive: analytic.deployments[0]?.integrations?.length > 0
            })}
          />
        )}
      </div>
    </>
  );
}

const Scores = styled('div')`
  padding: ${p => p.theme.spacing(0, 0, 2, 0)};
  display: flex;
  gap: 10px;
  margin-bottom: ${p => p.theme.spacing(1)};
`;

type AccordionDetailProps = {
  analytic: AnalyticRecommendation;
  status: Status;
};

function AccordionDetail({ analytic, status }: AccordionDetailProps) {
  const { user } = useAuth();
  const severity = getPreferredOrgScore(user.preferred_organization.id, analytic.severities, analytic.severity);
  const severityText = severity?.toLocaleUpperCase();
  const rank = getPreferredOrgScore(user.preferred_organization.id, analytic.ranks, analytic.rank);
  const rankText = rank?.toLocaleUpperCase();

  return (
    <AnalyticVersionProvider analyticGuid={analytic.guid}>
      <AnalyticLanguagesProvider guid={analytic.guid}>
        <Scores>
          <LevelMenuBadgeContainer className='Severity'>
            <Button className={classNames(severity, 'no-click')} variant='outlined' color='inherit'>
              <Icon icon={faDiamondExclamation} />
              <div className='text'>{severityText} SEVERITY</div>
            </Button>
          </LevelMenuBadgeContainer>
          <LevelMenuBadgeContainer className='Confidence'>
            <Button className={classNames(rank, 'no-click')} variant='outlined' color='inherit'>
              <Icon icon={faHexagonCheck} />
              <div className='text'>{rankText} CONFIDENCE</div>
            </Button>
          </LevelMenuBadgeContainer>
        </Scores>
        <Deployment analytic={analytic} status={status} />
      </AnalyticLanguagesProvider>
    </AnalyticVersionProvider>
  );
}
