import { FormikTouched } from 'formik';
import isEqual from 'lodash/isEqual';

import { ExploitationStateOrderedList, SecurityProfile, SecurityProfilePayload } from './SecurityProfile.type';

export function requiresUpdate(
  keys: (keyof SecurityProfile)[],
  values: SecurityProfile,
  touched: FormikTouched<SecurityProfile>,
  initial: SecurityProfile
): boolean {
  return keys.some(key => touched[key] && !isEqual(initial[key], values[key]));
}

export function transformValue(key: keyof SecurityProfile, values: SecurityProfile): Partial<SecurityProfilePayload> {
  switch (key) {
    case 'risk_tolerance':
      return { vulnerability_cvss_min: values[key][0] };

    case 'confidence_thresholds':
      return {
        confidence_min: values[key][0]
      };

    case 'severity_thresholds':
      return {
        severity_min: values[key][0]
      };

    case 'exploitation_state':
      return { vulnerability_exploit_min: ExploitationStateOrderedList[values[key][0]] };

    case 'exploited_zero_day':
      return {
        vulnerability_exploit_zero_day: values[key] === 'yes'
      };

    case 'exploited_in_wild':
      return {
        vulnerability_exploit_wild: values[key] === 'yes'
      };

    case 'exploit_likelihood':
      return {
        vulnerability_epss_min: Number((values[key][0] / 100).toFixed(2))
      };

    case 'threat_recency_threshold':
      return {
        threat_maturity: Number((values[key] / 100).toFixed(2))
      };

    case 'last_observed_threshold':
      return {
        observation_age_max: Number(values[key][1])
      };
    default:
      return { [key]: values[key] };
  }
}

export function transformValues(
  keys: (keyof SecurityProfile)[],
  values: SecurityProfile
): Partial<SecurityProfilePayload> {
  return keys.reduce((updates, key) => {
    const update = transformValue(key, values);
    return { ...updates, ...update };
  }, {});
}
