import Button from 'snap-ui/Button';
import { GridToolbar } from 'snap-ui/DataGrid';
import Paper from 'snap-ui/Paper';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { DetectionJobStateLabel } from 'module/Widgets/StateLabel';

export const Container = styled('div')`
  height: calc(100vh - ${p => p.theme.spacing(9)} * 2 - ${p => p.theme.spacing(7)});
  max-width: calc(98vw - var(--Scaffold-drawer) - (var(--Scaffold-spacing) * 2));
  margin-bottom: ${p => p.theme.spacing(7)};
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(5)};

  .job-target {
    margin-right: ${p => p.theme.spacing(3)};
    height: 25px;
  }

  .standard-row-height {
    height: 64px;
    display: flex;
  }

  .truncate-label {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .non-group-cell {
    margin-left: ${p => p.theme.spacing(8)};
  }

  .parent-group-cell {
    margin-left: 0;
  }

  .child-group-cell {
    margin-left: ${p => p.theme.spacing(10)};
  }

  @media (max-width: 1401px) {
    .MuiDataGrid-main {
      margin-top: ${p => p.theme.spacing(9)};
    }
  }

  .MuiDataGrid-main {
    margin-top: ${p => p.theme.spacing(0)};
  }

  .Tooltip-wrapper {
    width: 100%;
  }
`;

export const ArrowButton = styled(Button)`
  &.arrow-button {
    border-radius: 50%;
    min-width: 29px;
    padding: ${p => p.theme.spacing(2)};
    margin-right: ${p => p.theme.spacing(3)};
    transform: rotate(0deg);
    transition: transform 0.3s ease;
    span {
      margin: 0;
      svg {
        color: ${p => p.theme.palette.common.white};
      }
    }
  }

  &.group-cell-expanded {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
  }
`;

export const JobGridToolbar = styled(GridToolbar)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &.MuiToolbar-gutters {
    gap: ${p => p.theme.spacing(2)};
    padding: ${p => p.theme.spacing(2, 2)};
  }

  .organization-search {
    display: flex;
    gap: ${p => p.theme.spacing(2)};
  }

  .organization-select {
    min-width: 200px;
  }

  .search-field {
    width: 275px;
  }

  .MuiButtonBase-root.MuiToggleButton-root {
    height: ${p => p.theme.spacing(8)};
  }

  .MuiToggleButtonGroup-firstButton {
  }

  .MuiToggleButtonGroup-root {
    @media (max-width: 1401px) {
      width: 100%;
    }
    & .MuiToggleButtonGroup-grouped:first-of-type {
      border-top-left-radius: ${p => p.theme.spacing(1)};
      border-bottom-left-radius: ${p => p.theme.spacing(1)};
    }
    & .MuiToggleButtonGroup-grouped:last-of-type {
      border-top-right-radius: ${p => p.theme.spacing(1)};
      border-bottom-right-radius: ${p => p.theme.spacing(1)};
    }
    .MuiToggleButton-root.Mui-selected {
      background-color: transparent;
      border-bottom: 1px solid ${p => p.theme.palette.primary.main};
    }
  }
`;

export const GridSizer = styled(Paper)`
  height: 90%;
  width: 95%;

  &.data-grid {
    width: 100%;
    height: 500px;
  }
`;

export const TaskGuidContainer = styled('div')`
  margin: ${p => p.theme.spacing(5)};
  margin-left: 0;
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(5)};
`;

export const TaskResultsContainer = styled('div')`
  margin: ${p => p.theme.spacing(5, 0)};
  display: flex;
  flex-wrap: wrap;
  gap: ${p => p.theme.spacing(5)};

  .TaskResult-wrapper {
    display: flex;
    gap: ${p => p.theme.spacing(3)};
    align-items: center;
  }

  .TaskResult-value {
    font-family: monospace;
  }

  .TaskResult-label {
    text-transform: capitalize;
  }
`;

export const TaskInfoContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(4)};
`;

export const DispatchErrors = styled('div')`
  margin: ${p => p.theme.spacing(3, 0)};

  .DispatchErrors-content {
    display: flex;
    flex-wrap: wrap;
    gap: ${p => p.theme.spacing(4)};
  }

  .DispatchErrors-item {
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(4)};
  }
`;

export const JobStateLabel = styled(DetectionJobStateLabel)`
  width: 100%;
`;

export const JobStatusTooltip = styled(Tooltip)`
  &.Tooltip-wrapper {
    width: 100%;
  }
`;

export const GuidContainer = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(3)};
  align-items: center;
  min-width: 100px;

  .copy-icon {
    visibility: hidden;
  }

  &:hover {
    .copy-icon {
      visibility: visible;
    }
  }
`;
