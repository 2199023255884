import React from 'react';

import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { Link, LinkProps } from 'react-router-dom';

import CircularProgress from 'snap-ui/CircularProgress';
import Icon from 'snap-ui/Icon';
import Paper from 'snap-ui/Paper';
import { TablePlaceholder } from 'snap-ui/Table';

import Path from 'constants/paths';

import { ApiError } from 'module/ApiError';
import { DetectionDeploymentStatus } from 'module/GlobalFilter/Filters/DeployedEnvironment';
import { useMayI } from 'module/May';
import EmptyState from 'module/Widgets/EmptyState';
import { Toolbar } from 'module/Widgets/TableToolbar';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';
import { StrictReactNode } from 'types/core';

import { formatQueryString } from 'utilities/SearchParam';

import { AsyncInterface, DeployedFeeds } from './';
import { ErrorContainer, StatGrid, StatPanel } from './DeployedHealth.style';

const PENDING_STATUS = [Status.pending, Status.idle];

export function RenderTable({
  errorProps,
  items,
  status,
  supplemental,
  willFetchSupplemental,
  toolbar,
  table,
  emptyMessage
}: AsyncInterface & {
  supplemental: DeployedFeeds['supplemental'];
  willFetchSupplemental: boolean;
  toolbar: (args: { disableButtons: boolean }) => JSX.Element;
  table: StrictReactNode;
  emptyMessage: string;
}): JSX.Element {
  const isPending =
    PENDING_STATUS.includes(status) || (willFetchSupplemental && PENDING_STATUS.includes(supplemental.status));
  return (
    <Paper>
      <Toolbar>{toolbar({ disableButtons: !items?.length || isPending })}</Toolbar>
      {isPending ? (
        <TablePlaceholder />
      ) : errorProps ? (
        <ErrorContainer>
          <ApiError {...errorProps} />
        </ErrorContainer>
      ) : supplemental.errorProps ? (
        <ErrorContainer>
          <ApiError {...supplemental.errorProps} />
        </ErrorContainer>
      ) : !items.length ? (
        <EmptyState icon={<Icon icon={faCircleCheck} color='success' />} title={emptyMessage} />
      ) : (
        table
      )}
    </Paper>
  );
}

function Panel({
  className,
  href,
  title,
  to,
  total,
  status
}: AsyncInterface & { className: string; href?: string; title: string; to?: LinkProps['to'] }): JSX.Element {
  return (
    <StatPanel>
      {React.createElement(
        to ? Link : 'a',
        {
          className,
          href,
          to
        },
        [
          <div key='title' className='title'>
            {title}
          </div>,
          <div key='description' className='description'>
            {PENDING_STATUS.includes(status) ? <CircularProgress color='secondary' size={100} /> : total}
          </div>
        ]
      )}
    </StatPanel>
  );
}

export function Stats({
  healthy,
  outdated,
  tuning,
  errors
}: {
  healthy: AsyncInterface;
  outdated: AsyncInterface;
  tuning: AsyncInterface;
  errors: AsyncInterface;
}): JSX.Element {
  const canTune = useMayI(FunctionalPermission.Tuning);

  return (
    <StatGrid className={canTune ? 'four' : 'three'}>
      <Panel
        className='healthy'
        to={{
          pathname: Path.Feed,
          search: formatQueryString({ topic: ArtifactType.Analytic, deployedStatus: DetectionDeploymentStatus.success })
        }}
        title='Deployed and Healthy'
        {...healthy}
      />
      <Panel className='outdated' href='#outdated' title='New Version Available' {...outdated} />
      {canTune && <Panel className='tuning' href='#tuning' title='Needs Tuning' {...tuning} />}
      <Panel className='errors' href='#errors' title='Deployment Errors' {...errors} />
    </StatGrid>
  );
}
