import Drawer from 'snap-ui/Drawer';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { NAVIGATION_HEADER_HEIGHT } from 'module/Scaffold/Scaffold.const';

const versionListWidth = '300px';

export const Head = styled('div')`
  margin: ${p => p.theme.spacing(2)};
  .PlaceholderContentHeader {
    margin-bottom: ${p => p.theme.spacing(8)};
  }

  .ContentHeader {
    flex: 2 0;
    min-width: 350px;
    padding-bottom: ${p => p.theme.spacing(9)};
  }

  .actions {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};
  }
`;

export const ContentFooter = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: ${p => p.theme.spacing(7)};
  padding: ${p => p.theme.spacing(1)};

  .footerDetailContainer {
    display: flex;
    gap: ${p => p.theme.spacing(6)};
    flex-direction: row nowrap;
  }
`;

export const Container = styled('div')`
  .Analytic-details {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin-bottom: ${p => p.theme.spacing(9)};
    gap: ${p => p.theme.spacing(6)};
  }

  .Artifact-type {
    font-size: 1.125rem;
  }

  .overview {
    flex: 10 0 575px;
  }

  .MetadataPanel {
    flex: 1 0 320px;
  }

  .VersionList-surface {
    width: ${versionListWidth};
    margin-top: ${NAVIGATION_HEADER_HEIGHT}px;
  }

  .matching-log {
    margin-top: ${p => p.theme.spacing(9)};
  }

  .VersionList-content {
    margin-bottom: 5rem;
  }

  .TranslateAnalytic-codeContainer {
    min-height: 300px;
  }
`;

export const VersionContainer = styled('div')`
  display: grid;
  grid-template-columns: ${versionListWidth} 1fr;
  flex: 1;

  @media (max-width: 999px) {
    grid-template-columns: 1fr;

    .Version-content {
      order: -1;
    }
  }

  .Version-faux-table-header {
    background-color: ${p => p.theme.palette.common.black};
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .Version-faux-table-header {
    div + div {
      border-left: 2px solid ${p => p.theme.palette.background.default};
    }
  }

  .VersionList {
    min-height: 100%;
  }

  .VersionList-surface {
    position: static;
  }
`;

export const VersionNumberTypography = styled(Typography)`
  color: ${p => p.theme.palette.cert.main};
` as typeof Typography;

export const VersionDiff = styled(VersionContainer)`
  .Version-details {
    padding: unset;
  }

  .VersionDiff-selector--title {
    padding: ${p => p.theme.spacing(4)};
    display: flex;
    justify-content: space-between;
  }

  .VersionDiff-selector--content {
    display: flex;
    flex-direction: column;
  }

  .Version-header-options {
    gap: ${p => p.theme.spacing(4)};
    display: flex;
    align-items: flex-start;
  }

  .VersionDiff-language {
    min-width: 300px;
  }

  /** Target ReactDiffViewer specific things */
  tr[class$='line'] {
    pre[class$='content-text'] {
      word-break: break-all;
    }
  }
`;

export const VersionList = styled(Drawer)`
  .VersionList-surface {
    padding: ${p => p.theme.spacing(0, 2)};
  }

  .VersionList-title {
    padding: ${p => p.theme.spacing(0, 4)};
  }

  .VersionList-close {
    text-align: right;
    padding-top: ${p => p.theme.spacing(4)};
  }

  .VersionList-subheader {
    color: ${p => p.theme.palette.common.white};
    padding-left: 16px; /* Override MUI default padding of 72px */
    font-weight: bold;
  }

  .VersionsList-diff {
    align-self: stretch;
    max-width: 50px;
    .MuiListItemIcon-root {
      min-width: unset;
    }
  }

  .VersionsList-diff--left {
    border-left: 3px dashed ${p => p.theme.palette.cert.main};
  }

  .VersionsList-diff--right {
    border-right: 3px dashed ${p => p.theme.palette.cert.main};
  }
`;
