import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import Icon from 'snap-ui/Icon';
import Paper from 'snap-ui/Paper';
import Typography from 'snap-ui/Typography';

import { useCannotRedirect } from 'aso/useCannotRedirect';

import useTitle from 'hooks/useTitle';

import { ApiError } from 'module/ApiError';
import { CollectionDiscriminator } from 'module/Collection/Collection.type';
import { collectionPath, detectionPath } from 'module/Feed/Feed.util';
import useJobGroupOverview from 'module/Job/useTaskFeed';
import { Page } from 'module/Layout/Styled';
import EmptyState from 'module/Widgets/EmptyState';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';

import { getQueryParam } from 'utilities/SearchParam';

import { HuntGroup } from './Hunt.type';
import HuntTable from './HuntTable';

export default function Hunt(): JSX.Element {
  useTitle('Hunt Results | SnapAttack');
  useCannotRedirect(FunctionalPermission.OpenSearchUIAccess);
  const { search } = useLocation();
  const scheduledHuntId = getQueryParam(search, 'scheduledHuntId');

  const {
    jobs: jobGroupsOverview,
    status: jobsGroupStatus,
    task,
    refresh,
    jobErrorProps: jobGroupErrorProps
  } = useJobGroupOverview();
  const huntData = scheduledHuntId
    ? jobGroupsOverview.filter(hunt => hunt?.schedule_id?.toString() === scheduledHuntId)
    : jobGroupsOverview;

  return (
    <Page>
      <Typography variant='h1'>Hunt Results</Typography>
      <p>
        Hunt for threats based on <Link to={collectionPath(CollectionDiscriminator.Actor)}>threat actors</Link>,{' '}
        <Link to={collectionPath(CollectionDiscriminator.Software)}>software</Link>,{' '}
        <Link to={collectionPath(CollectionDiscriminator.Vulnerability)}>vulnerabilities</Link>, or{' '}
        <Link to={collectionPath(CollectionDiscriminator.Attack)}>MITRE ATT&CK techniques</Link>; from a specific{' '}
        <Link to={collectionPath(CollectionDiscriminator.Static)}>collection</Link>; or from the{' '}
        <Link to={detectionPath()}>detection library</Link>. To start a hunt, click the <Icon.Menu /> and select
        &quot;Hunt Detections&quot;.
      </p>
      <Paper>
        <ApiError {...jobGroupErrorProps} />
        {jobsGroupStatus === Status.pending || jobGroupsOverview.length ? (
          <HuntTable
            hunts={huntData as HuntGroup[]}
            isPending={jobsGroupStatus === Status.pending}
            refresh={refresh}
            task={task}
          />
        ) : (
          <EmptyState title='No Results'>There are no hunt results available</EmptyState>
        )}
      </Paper>
    </Page>
  );
}
