import React from 'react';

import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import { z } from 'zod';

import { ButtonBaseProps, DivButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import { FieldsLayout } from 'snap-ui/Layout';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import useAgents, { AgentInterface } from 'aso/useAgents';

import { CommonEvent, Engage, Fingerprint, Widget } from 'lib/Engagement';

import TextFieldFormik from 'module/Form/TextFieldFormik';

import { useAuth } from 'provider';

import { checkTaskPermission } from 'services/authService';

import { Status, useMountedRef } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { BASProductNameKey, BulkLaunchBASFormValues } from 'types/bas';
import { ArtifactType, Guid } from 'types/common';
import { Query } from 'types/filter';

import { BASCampaignPreview, OSName } from '../BAS.type';
import { sendPostCampaign, sendPostCampaignPreview } from '../Bas.service';
import AgentField from './AgentField';
import BASButton from './BASButton';
import Disclaimer from './Disclaimer';
import LauncherModal from './Modal';

const PlatformContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
`;

const ITEM_TYPES = {
  [ArtifactType.Analytic]: 'detections',
  [ArtifactType.Session]: 'threats',
  [ArtifactType.AttackScript]: 'attack scripts'
};

export type BASParams = {
  collectionName?: string;
  tagName?: string;
  collectionGuid?: Guid;
};

type BulkLauncherInterfaceProps = {
  children: React.ReactNode;
  topic: ArtifactType;
  criteria: Query;
  basProduct: BASProductNameKey[];
  basParams?: BASParams;
};

type BulkLauncherButtonProps = {
  noAttacksLabel?: string;
  onClick?: () => void;
};

type DivButton = Partial<Omit<ButtonBaseProps, 'onClick'>> & { count: number };

export interface BulkLauncherInterface {
  agentsInterface: AgentInterface;
  basParams?: BASParams;
  canBas: boolean;
  criteria: Query;
  isOpen: boolean;
  setIsOpen(open: boolean): void;
  preview: BASCampaignPreview;
  setPreview(preview: BASCampaignPreview): void;
  topic: ArtifactType;
  shouldShow: boolean;
}

const BulkLauncherContext = React.createContext<BulkLauncherInterface>(null);

export function BulkLauncherInterface(props: BulkLauncherInterfaceProps) {
  const { children, topic, criteria, basParams = {}, basProduct = [] } = props;
  const { collectionGuid, tagName } = basParams;
  const { permission } = useAuth();
  const agentsInterface = useAgents();
  const [isOpen, setIsOpen] = React.useState(false);
  const [preview, setPreview] = React.useState<BASCampaignPreview>(null);

  const mountedRef = useMountedRef();
  const stableCriteria = JSON.stringify(criteria);

  const canBas = checkTaskPermission(permission, FunctionalPermission.TaskBASAgent);

  const shouldShow =
    (basProduct.length > 0 &&
      [ArtifactType.Session, ArtifactType.Analytic].some(artifactType => artifactType === topic) &&
      basProduct.includes('SnapAttack')) ||
    topic === ArtifactType.AttackScript;

  React.useEffect(() => {
    if (mountedRef.current && shouldShow && criteria) {
      sendPostCampaignPreview(topic, criteria, collectionGuid, tagName).then(preview => {
        if (mountedRef.current) {
          setPreview(preview);
        }
      });
    }
  }, [collectionGuid, stableCriteria, shouldShow, tagName, topic]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BulkLauncherContext.Provider
      value={{
        agentsInterface,
        basParams,
        canBas,
        criteria,
        isOpen,
        setIsOpen,
        preview,
        setPreview,
        topic,
        shouldShow
      }}
    >
      {children}
    </BulkLauncherContext.Provider>
  );
}

function useBulkLauncher() {
  const context = React.useContext(BulkLauncherContext);
  if (!context) {
    throw new Error('useBulkLauncher must be used within an BulkLauncherInterface');
  }
  return context;
}

export function BulkLauncherButton({ noAttacksLabel }: BulkLauncherButtonProps) {
  const {
    agentsInterface,
    basParams: { collectionGuid, tagName },
    criteria,
    setIsOpen,
    preview,
    topic,
    shouldShow
  } = useBulkLauncher();
  const { filteredAgents, status: agentStatus } = agentsInterface;
  return (
    <BASButton
      agentStatus={agentStatus}
      hasAgents={filteredAgents?.length > 0}
      hasScripts={preview?.platforms?.length > 0}
      noAttacksLabel={noAttacksLabel}
      onClick={() => {
        Engage.track(
          Fingerprint.of(Widget.Modal).open().withQualifier('attack simulation').withData({
            topic,
            criteria,
            campaign: collectionGuid,
            tag: tagName
          })
        );
        setIsOpen(true);
      }}
      TooltipProps={shouldShow ? undefined : { title: 'Enable "By Attack Simulation Compatibility" filter selection' }}
      disabled={!shouldShow}
    />
  );
}

export function BulkLauncherDivButton({ count, ...props }: DivButton) {
  const {
    basParams: { collectionGuid, tagName },
    canBas,
    criteria,
    setIsOpen,
    topic
  } = useBulkLauncher();

  return (
    <DivButton
      disabled={count === 0}
      onClick={() => {
        Engage.track(
          Fingerprint.of(Widget.LandingRecommender).open().withQualifier('attack simulation').withData({
            topic,
            criteria,
            campaign: collectionGuid,
            tag: tagName
          })
        );
        setIsOpen(true);
      }}
      TooltipProps={{
        title: canBas ? '' : 'You do not have permissions to launch an attack simulation.'
      }}
      {...props}
    >
      <Icon icon={faPlus} /> Start Validation
    </DivButton>
  );
}

export function BulkLauncherDialog({ onLaunched }: { onLaunched?: () => void }) {
  const { agentsInterface, basParams, criteria, isOpen, setIsOpen, preview, topic } = useBulkLauncher();
  const { collectionGuid, collectionName, tagName } = basParams;
  const { filteredAgents, status: agentStatus } = agentsInterface;

  const itemType = ITEM_TYPES[topic];

  const handleSubmit = (values: BulkLaunchBASFormValues) => {
    Engage.track(
      Fingerprint.of(Widget.Modal).withQualifier('attack simulation').withCommon(CommonEvent.Submit).withData(values)
    );
    return sendPostCampaign(topic, criteria, collectionGuid, values, tagName);
  };

  const agentOptions = (preview?.platforms || []).reduce(
    (options, platform) => ({
      ...options,
      [platform]: filteredAgents.filter(agent => agent.os_name === platform)
    }),
    {}
  );

  let initialValues = (preview?.platforms || []).reduce(
    (options, platform) => {
      return { ...options, agents: { ...options.agents, [platform]: get(agentOptions, `${platform}.0.guid`) } };
    },
    {
      name: `${collectionName || tagName || 'New'} Attack Simulation`,
      agents: {}
    }
  );

  initialValues = {
    ...initialValues,
    agents: Object.fromEntries(Object.entries(initialValues.agents).filter(([, v]) => v != null))
  };

  return (
    <LauncherModal
      initialValues={initialValues}
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        onLaunched && onLaunched();
      }}
      onSubmit={handleSubmit}
      submitDisabled={isEmpty(filteredAgents) || agentStatus === Status.pending}
      subtitle={`Launch ${!!preview && !!preview.count ? preview.count : 'all'} attacks from the queried ${itemType}`}
      zodSchema={z.object({
        agents: z.record(z.nativeEnum(OSName), z.string())
      })}
    >
      <FieldsLayout>
        <TextFieldFormik label='Attack Simulation Name' name='name' />
        {preview?.platforms
          .filter(platform => !!agentOptions[platform]?.length)
          .map(platform => (
            <PlatformContainer key={platform}>
              <Typography variant='h4'>{startCase(platform)}</Typography>
              <AgentField
                agentInterface={agentsInterface}
                name={`agents[${platform}]`}
                target_platforms={[platform] as OSName[]}
              />
            </PlatformContainer>
          ))}
        <Disclaimer />
      </FieldsLayout>
    </LauncherModal>
  );
}
