import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import useLocationState from 'hooks/useLocationState';

import useMayI from 'module/May/useMayI';
import NotFound from 'module/Util/Fallback/NotFound';

import { FunctionalPermission } from 'types/auth';

import { AttackScriptRouterState } from '../AttackScript.type';
import { AttackScriptProvider } from '../AttackScriptProvider';
import AttackScriptView from './AttackScriptView';

type AttackScriptViewWrapperProps = RouteComponentProps<{ guid: string }>;

function AttackScriptViewWrapper(props: AttackScriptViewWrapperProps): JSX.Element {
  const guid = props.match.params.guid;

  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);
  const { item, supplementalItem } = useLocationState<AttackScriptRouterState>() || {};

  if (!isBasUser) return <NotFound />;
  return (
    <AttackScriptProvider guid={guid} item={item} supplementalItem={supplementalItem}>
      <AttackScriptView />;
    </AttackScriptProvider>
  );
}

export default React.memo(AttackScriptViewWrapper, (p, n) => p.match.params.guid === n.match.params.guid);
