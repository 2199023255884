import React from 'react';

import { ExternalLink } from 'snap-ui/Link';

import Path from 'constants/paths';

import Can from 'module/Can';

import { useAuth } from 'provider';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import { formatQueryString } from 'utilities/SearchParam';

import ListItemButton from './ListItemButton';
import ListItemLink from './ListItemLink';

export default function ListItemsHunt({ onClose }: { onClose(): void }) {
  const { isSubscriber } = useAuth();
  const viewPath =
    Path.FeedReset +
    formatQueryString({
      topic: ArtifactType.Analytic,
      page: 1
    });

  return (
    <>
      <ListItemLink onClick={onClose} title='Detection Library' to={viewPath} />
      <Can I={FunctionalPermission.OpenSearchUIAccess}>
        <Can.Grant>
          <ListItemLink onClick={onClose} title='Hunt Results' to={Path.Hunt} />
          <ListItemLink onClick={onClose} title='Scheduled Hunts' to={Path.HuntScheduled} />
        </Can.Grant>
        <Can.Block>
          <ListItemButton
            title='Hunt Results'
            disabled
            tooltip={
              isSubscriber ? (
                "You do not have permission to use the hunter's workbench."
              ) : (
                <>
                  The Hunter&apos;s Workbench is only available to subscribers.
                  <br />
                  Please{' '}
                  <ExternalLink icon primary href='https://www.snapattack.com/contact/'>
                    contact us
                  </ExternalLink>{' '}
                  to discuss subscription plans.
                </>
              )
            }
          />
          <ListItemButton
            title='Scheduled Hunts'
            disabled
            tooltip={
              isSubscriber ? (
                'You do not have permission to view scheduled hunts.'
              ) : (
                <>
                  Scheduled hunts are only available to subscribers.
                  <br />
                  Please{' '}
                  <ExternalLink icon primary href='https://www.snapattack.com/contact/'>
                    contact us
                  </ExternalLink>{' '}
                  to discuss subscription plans.
                </>
              )
            }
          />
        </Can.Block>
      </Can>
      <Can I={FunctionalPermission.IocHunt}>
        <Can.Grant>
          <ListItemLink onClick={onClose} title='IOC Hunter' to={Path.IOC} />
        </Can.Grant>
        <Can.Block>
          <ListItemButton
            title='IOC Hunter'
            disabled
            tooltip={
              isSubscriber ? (
                'You do not have permission to use the IOC Hunter.'
              ) : (
                <>
                  The IOC Hunter is only available to subscribers.
                  <br />
                  Please <ExternalLink href={'https://www.snapattack.com/contact/'}>contact us</ExternalLink> to discuss
                  subscription plans.
                </>
              )
            }
          />
        </Can.Block>
      </Can>
    </>
  );
}
