import React, { ReactElement } from 'react';

import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import Button from 'snap-ui/Button';
import { MenuButton, MenuTrigger } from 'snap-ui/Menu';
import { Placeholders } from 'snap-ui/Placeholder';

import { Section, SectionTitle } from 'module/Analytic/core/MetadataPanel/util';
import { ScriptsAsyncInterface } from 'module/BAS/Launcher/useScripts';
import { useMayI } from 'module/May';
import { SimplePayWall } from 'module/PayWall/PayWall';

import { useAuth } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType, Guid } from 'types/common';

import { RecommendedHeader } from '../Session.style';
import {
  AddAttackScriptToSessionButton,
  AddAttackScriptToSessionDialog,
  AddAttackScriptToSessionInterface
} from './AddAttackScriptToSessionInterface';
import AttackScriptListItem from './AttackScriptListItem';

type AttackScriptListProps = {
  guid: Guid;
  type: ArtifactType;
  preview: boolean;
  scripts: ScriptsAsyncInterface;
};
type AttackScriptListWrapperProps = AttackScriptListProps & {
  className?: string;
};

export default function AttackScriptListWrapper(props: AttackScriptListWrapperProps): JSX.Element {
  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);
  if (!isBasUser) return null;

  return (
    <Section className={classNames('Section', props.className, { 'overflow-hidden': props.preview })}>
      <AttackScriptListHeader {...props} />
      <AttackScriptList {...props} />
    </Section>
  );
}

function AttackScriptList(props: AttackScriptListProps): ReactElement {
  const { scripts } = props;
  const { isSubscriber } = useAuth();
  const [expanded, setExpanded] = React.useState<number | false>(false);
  const isLoading = scripts.status === Status.pending;
  const isValidationRecommended = scripts.data?.length > 0;

  const [isCollapsed, setIsCollapsed] = React.useState(true);

  function toggleCollapsed(): void {
    setIsCollapsed(collapsed => !collapsed);
  }

  const handleChange = (panel: number) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const slicedScripts = isCollapsed ? scripts.data.slice(0, 4) : scripts.data;

  if (isLoading) return <Placeholders />;
  if (!isSubscriber) return <SimplePayWall message='Recommended attack scripts are only available to subscribers' />;

  if (isValidationRecommended)
    return (
      <>
        {slicedScripts.map((script, index) => (
          <AttackScriptListItem
            key={index}
            script={script}
            index={index}
            onChange={handleChange}
            expanded={expanded}
            status={scripts.status}
          />
        ))}
        {scripts.data.length > 4 && (
          <Button
            variant='text'
            onClick={toggleCollapsed}
            aria-label={isCollapsed ? 'show all attack scripts' : 'hide attack scripts'}
          >
            {isCollapsed ? 'view all attack scripts' : 'hide attack scripts'}
          </Button>
        )}
      </>
    );

  return <em>No Recommended Attack Scripts</em>;
}

function AttackScriptListHeader({ guid, type, scripts }: AttackScriptListProps) {
  const { user } = useAuth();

  return (
    <RecommendedHeader>
      <SectionTitle>Attack Scripts</SectionTitle>
      {user.superuser && ArtifactType.Session === type && (
        <AddAttackScriptToSessionInterface>
          <MenuTrigger trigger={<MenuButton aria-label='attack scripts menu' icon={faEllipsisVertical} />}>
            <AddAttackScriptToSessionButton />
          </MenuTrigger>
          <AddAttackScriptToSessionDialog guid={guid} scripts={scripts} />
        </AddAttackScriptToSessionInterface>
      )}
    </RecommendedHeader>
  );
}
