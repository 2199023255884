import React from 'react';

import { RJSFSchema } from '@rjsf/utils';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { z } from 'zod';

import { SlimAccordion } from 'snap-ui/Accordion';
import { InlineAlert } from 'snap-ui/Alert';
import { FormDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import { CancelToken } from 'apis';

import useDeployableDetections from 'module/DetectionDeployment/useDetectionDeployment';
import { standardFormikBaseProps } from 'module/Form';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import CronJobFormik from 'module/Form/CronJobFormik';
import DataGridFormik from 'module/Form/DataGridFormik';
import RadioGroupFormik from 'module/Form/RadioGroupFormik';
import RjsfFormik from 'module/Form/RjsfFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import useFilterRegistry from 'module/GlobalFilter/useFilterRegistry';
import { JobType } from 'module/Job';
import { WEEKLY, maxConfidenceTailoringSearchWindowOptions, maxResultsPerQuery } from 'module/Job/Job.const';

import { useIntegrationCatalog } from 'provider';

import { Status } from 'storage';

import { ArtifactType } from 'types/common';
import { Query } from 'types/filter';

import { TimeInSeconds } from 'utilities/TimeUtils';

import { CreateJobSchedulePayload } from '../Integration.type';

const CRITERIA = 'All';

const ConfigureTaskContainer = styled('div')`
  .task-params {
    margin: ${p => p.theme.spacing(3, 0)};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${p => p.theme.spacing(3)};
    grid-row-gap: ${p => p.theme.spacing(7)};

    .task-name,
    .criteria,
    .frequency,
    .advancedConfigurationToggle,
    .integrationTable {
      grid-column: 1 / -1;
    }
  }
`;

type ConfigureTaskModalProps = {
  onClose: () => void;
  onSubmit: (payload: CreateJobSchedulePayload) => void;
  isOpen: boolean;
  initialValues?: CreateJobSchedulePayload & { extra_parameters: Record<string, unknown> };
  status: Status;
  title: string;
  type: JobType;
  jobSchema?: RJSFSchema;
  integration_guid?: string;
  analytic_filter?: Query;
};

export const TaskFormSchema = z.object({
  name: z.string().min(1, 'Name is required.'),
  criteria: z.string().min(1, 'Criteria is required.'),
  cron_trigger: z.string().min(1)
});

function ConfigureTaskModal({
  onClose,
  isOpen,
  onSubmit,
  status,
  title,
  type,
  initialValues,
  jobSchema,
  integration_guid,
  analytic_filter
}: ConfigureTaskModalProps) {
  const { integrations, status: integrationsStatus } = useIntegrationCatalog();
  const { isLoading, refresh, items } = useDeployableDetections(analytic_filter);
  const { generateQuery } = useFilterRegistry();
  const isActing = integrationsStatus === Status.pending || status === Status.pending || isLoading;
  const deployedDetectionFilterQuery = generateQuery(ArtifactType.Analytic, { deployedEnvironment: integration_guid });

  const initValues: typeof initialValues = {
    name: '',
    type,
    cron_trigger: WEEKLY,
    parameters: {
      max_results_per_query: maxResultsPerQuery,
      max_search_window_seconds: TimeInSeconds.sevenDays.toString()
    },
    ...initialValues
  };

  if (initialValues?.criteria) {
    initValues['criteria'] = initialValues.criteria;
  } else if (type === JobType.Rank) initValues['criteria'] = CRITERIA;

  // prevent collision between these two fields (fixed) and "extra" parameters
  // managed dynamically in RjsfFormik
  if (!isEmpty(jobSchema))
    jobSchema.properties = omit(jobSchema?.properties, ['max_results_per_query', 'max_search_window_seconds']);

  const jobSchemaPropertyNames = jobSchema?.properties && Object.keys(jobSchema.properties);

  if (!isEmpty(initValues) && !isEmpty(jobSchemaPropertyNames))
    initValues.extra_parameters = pick(initValues.extra_parameters, jobSchemaPropertyNames);

  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    if (isOpen) refresh(cancelSource);
    return () => cancelSource.cancel();
  }, [isOpen, refresh]);

  const choices = React.useMemo(() => {
    return isLoading
      ? [{ guid: '1' }]
      : integrations.huntable
          .map(integration => {
            const huntable = items.filter(
              i => i.preview === false && i.huntable_integrations?.some(d => d.guid === integration.guid)
            ).length;
            return {
              ...integration,
              huntable,
              incompatible: items.length - huntable
            };
          })
          .filter(i => i.guid === integration_guid);
  }, [integrations, isLoading, items, integration_guid]);

  const handleSubmit = (values: any) => {
    const payload = { ...values };

    if (type === JobType.Rank && payload.criteria === 'Filter') {
      payload.analytic_filter = deployedDetectionFilterQuery;
    }

    onSubmit(payload);
  };

  return (
    <FormDialog
      title={title}
      DialogProps={{ open: isOpen, onClose, className: 'extra-space' }}
      SubmitProps={{
        children: 'Save',
        disabled: status === Status.pending,
        startIcon: status === Status.pending && <Icon.SpinnerProgress />
      }}
      FormikConfig={{
        ...standardFormikBaseProps,
        initialValues: initValues,
        nestedRjsf: { extra_parameters: jobSchema },
        zodSchema: TaskFormSchema,
        onSubmit: handleSubmit
      }}
    >
      <ConfigureTaskContainer>
        <div className='task-params'>
          <TextFieldFormik name='name' label='Name' className='task-name' required />
          <AutocompleteFormik
            required
            label='Max Search Window'
            name='parameters.max_search_window_seconds'
            disableClearable
            disableUserAdditions
            options={[...maxConfidenceTailoringSearchWindowOptions]}
          />
          <TextFieldFormik
            required
            label='Max Results Per Query'
            name='parameters.max_results_per_query'
            type='number'
            inputProps={{ step: '1', min: 1 }}
          />
          {type === JobType.Rank && isOpen && (
            <RadioGroupFormik
              className='criteria'
              label='Detections'
              name='criteria'
              options={[
                { label: 'Run all compatible detections', value: 'All' },
                { label: 'Run only "unknown" confidence detections', value: 'Unranked' },
                { label: 'Run only deployed detections', value: 'Filter' }
              ]}
            />
          )}
          {type === JobType.Hunt && isOpen && (
            <DataGridFormik
              className='integrationTable'
              name='selections'
              disableColumnSelector
              columns={[
                {
                  field: 'name',
                  headerName: 'Integration',
                  hideable: false,
                  flex: 1,
                  renderCell: () => (isActing ? <Placeholder variant='text' width={250} /> : undefined)
                },
                {
                  field: 'huntable',
                  headerName: 'Compatible',
                  align: 'right',
                  hideable: false,
                  renderCell: p =>
                    isActing ? (
                      <Placeholder variant='text' width={100} />
                    ) : (
                      <InlineAlert severity='success' icon={p.value === 0 ? false : undefined}>
                        {p.value}
                      </InlineAlert>
                    )
                },
                {
                  field: 'incompatible',
                  headerName: 'Incompatible',
                  align: 'right',
                  hideable: false,
                  renderCell: p =>
                    isActing ? (
                      <Placeholder variant='text' width={100} />
                    ) : p.value > 0 ? (
                      <InlineAlert severity='warning'>{p.value}</InlineAlert>
                    ) : null
                }
              ]}
              rows={choices}
              getRowId={row => row.guid}
            />
          )}
          <div className='frequency'>
            Frequency
            <CronJobFormik name='cron_trigger' label='Schedule Time' />
          </div>
          <SlimAccordion
            className='advancedConfigurationToggle'
            details={<RjsfFormik name='extra_parameters' schema={jobSchema} />}
            summary='Advanced Configuration'
          />
        </div>
      </ConfigureTaskContainer>
    </FormDialog>
  );
}

export default ConfigureTaskModal;
