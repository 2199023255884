import { snapattack } from 'apis';

import { Guid } from 'types/common';

import { CoverageReport, TagCoverage } from './type';

export function getCoverage(orgId: Guid): Promise<TagCoverage[]> {
  return snapattack.post(`/stats/coverage/${orgId}/`, { op: 'and', items: [] }).then(r => r.data);
}

export function getCoverageReport(orgId: Guid): Promise<CoverageReport[]> {
  return snapattack.get(`/stats/coverage/${orgId}/report/`).then(r => r.data);
}

export function getTotalCoverage(orgId: Guid): Promise<number> {
  return snapattack.get(`/stats/coverage/${orgId}/profile/`).then(r => r.data);
}
