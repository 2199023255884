import { ReactNode } from 'react';

import { JsonSchema, JsonView } from 'module/JsonView';

export function mapOptions(schema: JsonSchema): { value: string; content: ReactNode }[] {
  return (
    schema?.enum?.map(o => ({
      value: o,
      content: o
    })) || []
  );
}

/**
 * Checks to see if a control is applied to a type
 */
export function isControlApplied(schema: JsonSchema, config: JsonView['config']) {
  if (config.asPreview) return true;
  const applied = (schema?.properties?.appliesTo?.enum || []) as string[];
  if (applied.includes(config.type)) return true;
  return false;
}
