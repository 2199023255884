import React from 'react';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';

import useQueryString from 'hooks/useQueryString';

import { SortOrder } from 'module/Feed/Feed.type';
import { FilterControl } from 'module/GlobalFilter';

import DelayedTextField from './DelayedTextField';

export default function Search(): JSX.Element {
  const { update, getByKey } = useQueryString();
  const urlQuery = getByKey('query');
  const [value, setValue] = React.useState(urlQuery || '');

  React.useEffect(() => {
    if (urlQuery !== value) handleChange(urlQuery);
  }, [urlQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleChange(value: string) {
    setValue(value);
    update({ page: '1', query: value, sort_by: SortOrder.relevance });
  }

  return (
    <FilterControl className='TextSearchFilter'>
      <DelayedTextField
        data-testid='search-filter'
        label='Search'
        variant='outlined'
        onChange={handleChange}
        value={value}
        InputProps={{
          endAdornment: <Icon icon={faSearch} />
        }}
      />
    </FilterControl>
  );
}
