import React from 'react';

import { DetectionSummary, DetectionSummaryCatalog } from 'module/Detection';
import { ArtifactMetadataService, MetadataCatalog } from 'module/Metadata';
import { SupplementalArtifact, SupplementalCatalog } from 'module/Search';

import { Guid } from 'types/common';

export type FeedProvider = {
  detectionCatalog: DetectionSummaryCatalog;
  supplementalCatalog: SupplementalCatalog;
  metadataCatalog: MetadataCatalog;
  getDetection: (value: Guid) => DetectionSummary;
  getSupplemental: (value: Guid) => SupplementalArtifact;
  getMetadata: (value: Guid) => ArtifactMetadataService;
  detectionPending: boolean;
  supplementalPending: boolean;
  metadataPending: boolean;
};

const FeedExtraContext = React.createContext<FeedProvider>(null);
FeedExtraContext.displayName = 'FeedContext';

function useFeedExtra(): FeedProvider {
  const context = React.useContext(FeedExtraContext);

  if (!context) {
    throw new Error('useFeedExtra must be used within the FeedExtraContext');
  }

  return context;
}

function FeedExtraProvider({
  children,
  detection,
  supplemental,
  metadata = {},
  detectionPending,
  supplementalPending,
  metadataPending = false
}: {
  children: any;
  detection: DetectionSummaryCatalog;
  supplemental: SupplementalCatalog;
  metadata?: MetadataCatalog;
  detectionPending: boolean;
  supplementalPending: boolean;
  metadataPending?: boolean;
}) {
  const value = React.useMemo(() => {
    return {
      detectionCatalog: detection,
      supplementalCatalog: supplemental,
      metadataCatalog: metadata,
      getDetection: (value: Guid) => detection[value],
      getSupplemental: (value: Guid) => supplemental[value],
      getMetadata: (value: Guid) => metadata[value],
      detectionPending,
      supplementalPending,
      metadataPending
    };
  }, [detection, detectionPending, metadata, metadataPending, supplemental, supplementalPending]);

  return <FeedExtraContext.Provider value={value}>{children}</FeedExtraContext.Provider>;
}

export { FeedExtraProvider, useFeedExtra };
