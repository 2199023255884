import React from 'react';

import classNames from 'classnames';

import TextField from 'snap-ui/TextField';
import Typography from 'snap-ui/Typography';

import { InlineEditorForm, JsonSchema, useInlineEditor, withJsonFormsControlProps } from 'module/JsonView';
import { MarkdownEdit, MarkdownRead } from 'module/Widgets/Markdown';

import { isMetaEmpty } from '../Metadata.util';
import { EditMeLabel } from '../Metadata.widgets';
import { Control, ControlProps } from './Control.type';
import { isControlApplied } from './Control.util';
import { FilterText } from './ControlText';

function InlineArea(props: { data: string; enabled: boolean; path: string; schema: JsonSchema; type: string }) {
  const { data, enabled, path, schema, type } = props;
  const editor = useInlineEditor(data);
  return (
    <InlineEditorForm
      controlled
      data={data}
      editor={editor}
      enabled={enabled}
      path={path}
      title={schema.title}
      type={type}
    >
      <div
        className={classNames(`Control Control-${Control.Text} Control-${Control.TextArea}`, {
          disabled: !enabled || editor.isEditing
        })}
      >
        <EditMeLabel
          disabled={!enabled}
          htmlFor={`inlineArea-${path}`}
          title={schema.title}
          onClick={() => {
            // handler is here rather than on parent div for now since click are clickable in MarkdownRead
            if (enabled && !editor.isEditing) editor.toggleEditing();
          }}
        />
        {!editor.isEditing && <MarkdownRead id='inlineArea' value={editor.queue || ''} />}
        {editor.isEditing && (
          <MarkdownEdit id={`inlineArea-${path}`} value={editor.queue || ''} onChange={v => editor.setQueue(v)} />
        )}
      </div>
    </InlineEditorForm>
  );
}

const _AreaControl = (props: ControlProps) => {
  const { config, data, enabled, handleChange, schema, path } = props;

  if (!isControlApplied(schema, config) || (config.truncated && isMetaEmpty(data))) return null;
  if (config.asInline)
    return <InlineArea data={data} enabled={enabled} path={path} schema={schema} type={config.type} />;
  if (config.asFilter) return <FilterText data={data} onChange={handleChange} path={path} schema={schema} />;
  if (config.asPreview)
    return (
      <TextField
        value={data || ''}
        label={schema.title}
        helperText={schema.description}
        onChange={e => handleChange(path, e.target.value)}
        multiline
        rows={5}
        placeholder='Non preview component is markdown enabled'
        className={`Control Control-${Control.Text} Control-${Control.TextArea}`}
        disabled={!enabled}
        elevated
      />
    );
  return (
    <div
      className={classNames(`Control Control-${Control.Text} Control-${Control.TextArea}`, {
        disabled: !enabled
      })}
    >
      <Typography className={'EditMeLabel'} variant='h5' component='label' htmlFor={`defaultArea-${path}`}>
        {schema.title}
      </Typography>
      <MarkdownEdit id={`defaultArea-${path}`} value={data || ''} onChange={v => handleChange(path, v)} />
    </div>
  );
};
export const AreaControl = withJsonFormsControlProps(_AreaControl);
