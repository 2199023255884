import React from 'react';

import ReactDiffViewer from 'react-diff-viewer-continued';

import { Autocomplete, Option, getDisplayValue } from 'snap-ui/Autocomplete';
import Button from 'snap-ui/Button';
import Placeholder from 'snap-ui/Placeholder';
import { useTheme } from 'snap-ui/util';

import useRawTranslation from 'aso/useRawTranslation';

import { IDETranslationStateProvider } from 'module/IDE';
import { Page } from 'module/Layout/Styled';
import { AutoCompleteOptionContent, StyledAutocompletePaper } from 'module/TranslateAnalytic/TranslateAnalytic.helper';

import { useAuth, useIntegrationCatalog, usePushSnack } from 'provider';

import { Status } from 'storage';

import { DeploymentIntegration } from 'types/analytic';

import { getQueryParam } from 'utilities/SearchParam';
import { STANDARD, formatCustomTimestamp } from 'utilities/TimeUtils';

import { VersionDiff } from '../Analytic.style';
import { AnalyticRouterProps, AnalyticVersion } from '../Analytic.type';
import { useAnalyticLanguageCatalog } from '../AnalyticLanguages';
import AnalyticVersionList from '../AnalyticVersion/AnalyticVersionList';
import { useAnalyticCatalog } from '../core/AnalyticProvider';
import { useAnalyticVersionCatalog } from '../core/AnalyticVersionProvider';
import { DeployIntegrationButton } from '../core/MetadataPanel/Deployment';
import { getCompatibleIntegration } from '../core/MetadataPanel/util';
import { AnalyticDiffSelector } from './AnalyticDiffSelector';

export function AnalyticDiff(props: AnalyticRouterProps) {
  const pushSnack = usePushSnack();
  const { palette } = useTheme();
  const deployedEnvironment = getQueryParam(location.search, 'deployedEnvironment');
  const leftId = props.match.params.versionId;
  const rightId = props.match.params.otherId;
  const { defaultOrgId } = useAuth();
  const [{ supplemental, supplementalRefresh }] = useAnalyticCatalog();

  const { integrations, status: integrationsStatus } = useIntegrationCatalog();
  const { status, versions: allVersion } = useAnalyticVersionCatalog();

  const { data: languageList, status: languageListStatus } = useAnalyticLanguageCatalog();
  const [language, setLanguage] = React.useState<string>();
  const selectedLanguageId = language ? parseInt(language, 10) : 0;
  const previousLanguageId = React.useRef<number>();
  const previousLeftId = React.useRef<string>();
  const previousRightId = React.useRef<string>();

  const isPending = [languageListStatus, integrationsStatus].some(s => s === Status.pending) && !!supplemental;

  React.useEffect(() => {
    previousLanguageId.current = selectedLanguageId;
  });

  React.useEffect(() => {
    previousLeftId.current = leftId;
  });

  React.useEffect(() => {
    previousRightId.current = rightId;
  });

  const leftVersion = allVersion?.find(version => version.id.toString() === leftId);
  const rightVersion = allVersion?.find(version => version.id.toString() === rightId);
  const orgDeployments = supplemental?.deployments?.filter(d => d.organization_id === defaultOrgId);

  const deployableIntegrations: DeploymentIntegration[] = integrations.deployable.map(integration => {
    return {
      ...integration,
      deployment: orgDeployments?.find(dep => dep.integrations?.some(i => i.guid === integration.guid)),
      compatible: getCompatibleIntegration(supplemental, languageList, integration)
    };
  });
  const deployedIntegration = deployableIntegrations.find(integration => integration.guid === deployedEnvironment);

  const shouldTranslateLeft = React.useMemo(() => {
    if (selectedLanguageId !== previousLanguageId.current) return true;
    if (leftId !== previousLeftId.current && leftVersion?.raw) return true;
    return false;
  }, [selectedLanguageId, leftId, leftVersion]);
  const shouldTranslateRight = React.useMemo(() => {
    if (selectedLanguageId !== previousLanguageId.current) return true;
    if (rightId !== previousRightId.current && rightVersion?.raw) return true;
    return false;
  }, [selectedLanguageId, rightId, rightVersion]);

  const {
    translation: leftTranslation,
    status: leftTranslationStatus,
    error: leftTranslationError
  } = useRawTranslation(shouldTranslateLeft, leftVersion?.raw, null, selectedLanguageId);
  const {
    translation: rightTranslation,
    status: rightTranslationStatus,
    error: rightTranslationError
  } = useRawTranslation(shouldTranslateRight, rightVersion?.raw, null, selectedLanguageId);

  const isTranslated = !!language;

  const leftContent = React.useMemo(() => {
    if (isTranslated) {
      if (leftId === previousLeftId.current) {
        return leftTranslation || '';
      } else {
        return '';
      }
    }
    return leftVersion?.raw || '';
  }, [isTranslated, leftId, leftTranslation, leftVersion?.raw]);

  const rightContent = React.useMemo(() => {
    if (isTranslated) {
      if (rightId === previousRightId.current) {
        return rightTranslation || '';
      } else {
        return '';
      }
    }
    return rightVersion?.raw || '';
  }, [isTranslated, rightId, rightTranslation, rightVersion?.raw]);

  const options = languageList.map(
    (language): Option => ({
      label: language.name,
      content: <AutoCompleteOptionContent supported={language.supported} text={language.name} />,
      value: language.id.toString()
    })
  );

  const option = getDisplayValue(options, language) as Option;

  React.useEffect(() => {
    if (leftTranslationError) pushSnack(leftTranslationError, 'warning', 'center', 'bottom');
    if (rightTranslationError) pushSnack(rightTranslationError, 'warning', 'center', 'bottom');
  }, [leftTranslationError, rightTranslationError, pushSnack]);
  const existingAnalytic = allVersion.filter(v => v.guid === supplemental?.guid);
  const parentAnalytic = allVersion.filter(v => v.guid === supplemental?.parent?.guid);
  const isDeployable = allVersion?.filter(v => v.guid === supplemental?.guid)?.[0]?.id === rightVersion?.id;

  function filterByCurrentOrParentVersions(version?: AnalyticVersion): AnalyticVersion[] {
    if (existingAnalytic.some(c => c.id === version?.id)) {
      return existingAnalytic;
    } else {
      return parentAnalytic;
    }
  }

  return (
    <IDETranslationStateProvider>
      <VersionDiff>
        <AnalyticVersionList
          open={true}
          versions={allVersion}
          versionStatus={status}
          guid={supplemental?.guid}
          parentGuid={supplemental?.parent?.guid}
        />
        <div className='Version-content'>
          <Page className='Version-details'>
            <div className='Version-faux-table-header'>
              <AnalyticDiffSelector
                busy={leftTranslationStatus === Status.pending}
                title={
                  leftVersion ? (
                    `Version ${
                      filterByCurrentOrParentVersions(leftVersion).length -
                      filterByCurrentOrParentVersions(leftVersion).indexOf(leftVersion)
                    }`
                  ) : (
                    <Placeholder variant='text' width={50} />
                  )
                }
                subtitle={
                  leftVersion ? (
                    `${leftVersion?.created_by?.name} - ${formatCustomTimestamp(leftVersion?.creation, STANDARD)}`
                  ) : (
                    <Placeholder variant='text' width={100} />
                  )
                }
              />
              <AnalyticDiffSelector
                busy={rightTranslationStatus === Status.pending}
                title={
                  (rightVersion &&
                    `Version ${
                      filterByCurrentOrParentVersions(rightVersion).length -
                      filterByCurrentOrParentVersions(rightVersion).indexOf(rightVersion)
                    }`) ||
                  'Version ?'
                }
                subtitle={
                  (rightVersion &&
                    `${rightVersion?.created_by?.name} - ${formatCustomTimestamp(rightVersion?.creation, STANDARD)}`) ||
                  'make another version selection'
                }
              >
                <div className='Version-header-options'>
                  {isDeployable && !isPending && (
                    <DeployIntegrationButton
                      guid={supplemental?.guid}
                      existingDeployment={orgDeployments?.[0]}
                      integration={deployedIntegration}
                      onDeploy={() => supplementalRefresh()}
                      showIfDisabled
                    >
                      {({ disabled, handleClickDeploy }) => (
                        <Button disabled={disabled} onClick={handleClickDeploy}>
                          Deploy
                        </Button>
                      )}
                    </DeployIntegrationButton>
                  )}
                  <Autocomplete
                    className='VersionDiff-language'
                    name='LanguageSelection'
                    label='Language'
                    onChange={(v: string) => setLanguage(v)}
                    options={options}
                    value={option}
                    disabled={leftTranslationStatus === Status.pending || rightTranslationStatus === Status.pending}
                    disableUserAdditions
                    PaperComponent={StyledAutocompletePaper}
                  />
                </div>
              </AnalyticDiffSelector>
            </div>
            <ReactDiffViewer
              oldValue={leftContent}
              newValue={rightContent}
              splitView={true}
              showDiffOnly={false}
              useDarkTheme
              styles={{
                variables: {
                  dark: {
                    diffViewerTitleBackground: palette.background.paper,
                    diffViewerTitleColor: palette.greyish.main,
                    gutterBackground: palette.surface.auxiliaryTab,
                    diffViewerBackground: palette.surface.auxiliaryTab,
                    emptyLineBackground: palette.background.default,
                    codeFoldBackground: palette.background.paper,
                    codeFoldGutterBackground: palette.background.paper,
                    codeFoldContentColor: palette.primary.main
                    // addedGutterBackground: palette.lime.main,
                    // addedBackground: palette.lime.main,
                    // wordAddedBackground: palette.lime.light,
                    // addedColor: palette.getContrastText(palette.lime.dark)
                  }
                }
                /** various styles can be overridden here as well.
                 * But we are limited in what we can target and what
                 * selectors are available. See Analytic.style VersionDiff
                 * to see how we can target with wildcards */
              }}
            />
          </Page>
        </div>
      </VersionDiff>
    </IDETranslationStateProvider>
  );
}
