import React from 'react';

import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import startCase from 'lodash/startCase';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Menu from 'snap-ui/Menu';
import MenuItem from 'snap-ui/MenuItem';
import { styled } from 'snap-ui/util';

import { Collection } from 'module/Collection/Collection.type';
import { useFilterRegistry } from 'module/GlobalFilter';

import { useFilterCollection } from 'provider/FilterCollection';

import { ArtifactType, Guid } from 'types/common';

type Props = {
  onChange(filter: Collection): void;
  value: Guid;
};

const Container = styled('div')`
  width: 100%;
  min-width: 72%;
  > button {
    width: 100%;
    justify-content: start;
    color: ${p => p.theme.palette.common.white};
    border-color: ${p => p.theme.palette.common.white};
    border-radius: ${p => p.theme.spacing(1)};
  }

  .MuiPaper-root {
    width: 50%;
  }

  .MuiButton-endIcon {
    margin-left: auto;
  }

  .MuiModal-root {
    position: fixed;
  }
`;

const CurrentFilter = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default function FilterModeCollectionSelect(props: Props) {
  const { data } = useFilterCollection();
  const { topic } = useFilterRegistry();

  const buttonRef = React.useRef();
  const [showMenu, setShowMenu] = React.useState<boolean>(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const store = React.useMemo(() => {
    if (!data) return [];
    return data.map(c => ({
      id: c.id,
      content: <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{c.name}</div>,
      value: c.guid,
      describe: c.description,
      hasIntel: !isEmpty(c.threat_intelligence_filter),
      hasSession: !isEmpty(c.session_filter),
      hasAnalytic: !isEmpty(c.analytic_filter)
    }));
  }, [data]);

  const [activePageSaveFilter, nonActivePageSaveFilter] = partition(store, savedFilter => {
    switch (topic) {
      case ArtifactType.Analytic:
        return savedFilter.hasAnalytic === true;
      case ArtifactType.Intel:
        return savedFilter.hasIntel === true;
      case ArtifactType.Session:
        return savedFilter.hasSession === true;
    }
  });

  const handleChange = (value: string) => () => {
    const filter = data.find(c => c.guid === value);
    props.onChange(filter);
    toggleMenu();
  };

  return (
    <Container>
      <Button
        aria-label='Select a filter'
        aria-controls={showMenu ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={showMenu ? 'true' : undefined}
        color='primary'
        variant='outlined'
        endIcon={<Icon className='down-arrow' icon={faCaretDown} />}
        onClick={toggleMenu}
        ref={buttonRef}
      >
        <CurrentFilter>
          {props.value ? data?.find(c => c.guid === props.value)?.name : 'Select a filter '}
        </CurrentFilter>
      </Button>
      <Menu anchorEl={buttonRef.current} onClose={toggleMenu} open={showMenu} keepMounted={true} disablePortal={true}>
        <MenuItem divider disabled>
          Filters for {startCase(topic)}s
        </MenuItem>
        {activePageSaveFilter.map((activeFilter, index) => (
          <MenuItem
            key={activeFilter.value}
            onClick={handleChange(activeFilter.value)}
            selected={activeFilter.value == props.value}
            divider={index == activePageSaveFilter.length - 1} // divider on last item
          >
            {activeFilter.content}
          </MenuItem>
        ))}
        <MenuItem divider disabled>
          Filters for Other Pages
        </MenuItem>
        {nonActivePageSaveFilter.map(inactiveFilter => (
          <MenuItem key={inactiveFilter.value} onClick={handleChange(inactiveFilter.value)}>
            {inactiveFilter.content}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
}
