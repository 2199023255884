import React from 'react';

import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import { Engage, Fingerprint, Widget } from 'lib/Engagement';

import { useAnalyticVersionCatalog } from 'module/Analytic/core/AnalyticVersionProvider';
import { useIDETranslationState } from 'module/IDE';
import { IDEAction, IDEState } from 'module/IDE/reducer';

import { useLogsourceContext } from 'provider';

import { Status } from 'storage';

import { AnalyticForm } from 'types/analytic';

import TranslateAnalytic from './TranslateAnalytic';
import { hasLogsource } from './TranslateAnalytic.util';
import useIDETranslateControls from './useIDETranslateControls';

type DetectionFields = Pick<AnalyticForm, 'logsource' | 'detection'> & {
  customization: IDEState['_customization_raw'];
};

interface IDEWrapperProps {
  emitChange?(action: IDEAction): void;
  ideState: IDEState;
  fetchStatus?: Status;
}

export default function IDEWrapper(props: IDEWrapperProps): JSX.Element {
  const { setSelectedLanguage: setProviderSelectedLanguage } = useIDETranslationState();
  const { status: logSourceStatus } = useLogsourceContext();
  const { status: versionStatus } = useAnalyticVersionCatalog();
  const isLoading =
    props.fetchStatus === Status.pending || versionStatus === Status.pending || logSourceStatus === Status.pending;
  const previousDetectionFields = React.useRef<DetectionFields>();

  const previousLanguageId = React.useRef<number>();

  const shouldTranslate = React.useCallback(
    (languageId: number) => {
      if (props.ideState.isNative) return false;
      const logsource = props.ideState.analyticForm.logsource;
      const detection = props.ideState.analyticForm.detection;
      const customization = props.ideState._customization_raw;

      // don't try to translate if the logsource is missing
      if (!hasLogsource(props.ideState)) {
        return false;
      }

      // if we selected a new language, definitely translate
      if (languageId !== previousLanguageId.current) {
        return true;
      }

      // don't fetch a translation if we haven't changed logic fields
      if (isEqual({ logsource, detection, customization }, previousDetectionFields.current)) {
        return false;
      }

      return true;
    },
    [props.ideState]
  );

  const {
    integrationOptions,
    languageList,
    languageOptions,
    languageStatus,
    selectedIntegrationOptionValue,
    selectedLanguage,
    setSelectedIntegrationOptionValue,
    setSelectedLanguageId,
    translation,
    translationError,
    translationStatus
  } = useIDETranslateControls(props.ideState, shouldTranslate);

  const translationIsPending = translationStatus === Status.pending;

  React.useEffect(() => {
    setProviderSelectedLanguage(selectedLanguage);
  }, [selectedLanguage, setProviderSelectedLanguage]);

  React.useEffect(() => {
    previousLanguageId.current = selectedLanguage?.id;
  });
  React.useEffect(() => {
    previousDetectionFields.current = {
      ...pick(props.ideState.analyticForm, ['logsource', 'detection']),
      customization: props.ideState._customization_raw
    };
  });

  const handleEditAsNative = props.ideState.guid
    ? undefined // TODO: implement IDE state via router. Until then, Edit As Native is only available for fresh, unsaved detections
    : (languageId: number, raw: string) => {
        Engage.track(
          Fingerprint.of(Widget.TranslateAnalytic).withQualifier('convert to native').withData({
            guid: props.ideState.guid,
            languageId: languageId
          })
        );
        const language = languageList.find(l => l.id === languageId);
        props.emitChange({ type: 'EditAsNativeUpdate', languageId, backendKey: language.backend_key, raw });
      };

  if (props.ideState.isNative) {
    return null;
  }

  if (!isLoading)
    return (
      <TranslateAnalytic
        analyticGuid={props.ideState.guid}
        analyticName={props.ideState.analyticForm.title}
        integrationOptions={integrationOptions}
        languageOptionStatus={languageStatus}
        languageOptions={languageOptions}
        onEditAsNative={handleEditAsNative}
        onIntegrationChange={setSelectedIntegrationOptionValue}
        onLanguageChange={setSelectedLanguageId}
        selectedIntegrationValue={selectedIntegrationOptionValue}
        selectedLanguage={selectedLanguage}
        translation={translation}
        translationError={translationError}
        translationIsPending={translationIsPending}
      />
    );

  return null;
}
