import { CollectionCountResponse } from './Curation.type';

export const COLLECTION_COUNT_SHELL: CollectionCountResponse = {
  analytics: 0,
  bas_scripts: 0,
  indicators: 0,
  sessions: 0,
  threat_intelligences: 0
};

export const EXISTING_COMPOSITE_SHELL = {
  analytics: [],
  bas_scripts: [],
  sessions: [],
  threat_intelligences: []
};
