import React from 'react';

import { faList, faTimer } from '@fortawesome/pro-regular-svg-icons';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import { Link } from 'react-router-dom';

import { ActionIconButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Placeholder from 'snap-ui/Placeholder';

import Path from 'constants/paths';

import { useAuth } from 'provider/Account';

import { getPreferredOrgRuntime } from 'utilities/ArtifactUtils';
import { transformSecondsToHumanFormat } from 'utilities/TimeUtils';

import AnalyticFooterItem from './AnalyticFooterItem';
import { useAnalyticCatalog } from './AnalyticProvider';
import { useAnalyticVersionCatalog } from './AnalyticVersionProvider';
import LogsourceBadge from './LogsourceBadge';

function FooterDetail({ onShowVersionList }: { onShowVersionList(): void }) {
  const { versions } = useAnalyticVersionCatalog();
  const [{ analytic, supplemental }] = useAnalyticCatalog();
  const { defaultOrgId } = useAuth();
  const detectionGuid = supplemental?.guid;
  const parentGuid = supplemental?.parent?.guid;
  const detectionVersions = versions.filter(v => v.guid === detectionGuid);
  const parentVersions = versions.filter(v => v.guid === parentGuid);
  const parentHasUpdate = parentVersions.some(pv => pv.creation > analytic.modified);
  const runtime = getPreferredOrgRuntime(defaultOrgId, supplemental?.complexities);

  return (
    <>
      {isEmpty(supplemental) ? (
        <Placeholder height={48} width={400} variant='rectangular' />
      ) : (
        <div className='footerDetailContainer'>
          {detectionVersions.length >= 1 && (
            <AnalyticFooterItem
              label={'VERSIONS'}
              toolTipMessage={'Previous versions'}
              content={
                <span>
                  {detectionVersions.length} Version{detectionVersions.length === 1 ? '' : 's'}
                </span>
              }
              action={
                <ActionIconButton
                  className='versionIcon'
                  aria-label='Previous Versions'
                  color='primary'
                  icon={faList}
                  onClick={onShowVersionList}
                  size='large'
                />
              }
            />
          )}
          {supplemental?.parent && (
            <AnalyticFooterItem
              label={'PARENT'}
              toolTipMessage={parentHasUpdate ? 'Parent detection has new version available' : null}
              content={
                <Link className='versionAvailable' to={`${Path.Detection}/${supplemental?.parent.guid}`}>
                  {parentHasUpdate && <Icon icon={faCircleExclamation} />}
                  {truncate(supplemental?.parent.name, {
                    omission: '...',
                    length: 30
                  })}
                </Link>
              }
            />
          )}
          {runtime && (
            <AnalyticFooterItem
              label='RUNTIME'
              toolTipMessage={'The max detection runtime based on confidence tailoring or hunt jobs'}
              content={
                <div className='complexityTime'>
                  <Icon icon={faTimer} />
                  {transformSecondsToHumanFormat(runtime, 'seconds', true)}
                </div>
              }
            />
          )}
          <AnalyticFooterItem
            label='LOGSOURCE'
            content={
              <LogsourceBadge
                display='card'
                languageId={supplemental?.source_analytic_compilation_target_id}
                value={analytic.logsource}
              />
            }
          />
        </div>
      )}
    </>
  );
}

export default FooterDetail;
