import React from 'react';

import { AutocompleteV2 } from 'snap-ui/Autocomplete';
import TextField from 'snap-ui/TextField';

import { JsonSchema, withJsonFormsControlProps } from 'module/JsonView';

import { InlineEditorForm } from '../../JsonView/InlineEditorForm';
import { useInlineEditor } from '../../JsonView/useInlineEditor';
import { isMetaEmpty, sortControlOptions } from '../Metadata.util';
import { Control, ControlProps } from './Control.type';
import { isControlApplied } from './Control.util';

type Value = string | string[];

const _Base = (props: {
  description: string;
  disabled?: boolean;
  label: string;
  multiple?: boolean;
  options: readonly string[];
  onChange: (value: Value) => void;
  value: Value;
}) => (
  <AutocompleteV2
    disabled={props.disabled}
    value={props.value || (props.multiple ? [] : null)}
    options={props.options}
    multiple={props.multiple}
    onChange={(_e, v: Value) => props.onChange(v)}
    className={`Control Control-${Control.Select}`}
    renderInput={params => <TextField {...params} label={props.label} helperText={props.description} elevated />}
  />
);

function InlineSelect(props: {
  data: Value;
  enabled: boolean;
  options: readonly string[];
  path: string;
  schema: JsonSchema;
  type: string;
}) {
  const { data, enabled, options, path, schema, type } = props;
  const editor = useInlineEditor(data);
  return (
    <InlineEditorForm data={data} editor={editor} enabled={enabled} path={path} title={schema.title} type={type}>
      <_Base
        description={schema.description}
        label={schema.title}
        onChange={editor.setQueue}
        options={options}
        value={editor.queue}
      />
    </InlineEditorForm>
  );
}

const _SelectControl = (props: ControlProps<Value>) => {
  const { config, data, enabled, handleChange, path, schema } = props;

  if (!isControlApplied(schema, config) || (config.truncated && isMetaEmpty(data))) return null;
  const options = (schema.enum || []).sort(sortControlOptions);
  if (config.asInline)
    return (
      <InlineSelect data={data} enabled={enabled} options={options} path={path} schema={schema} type={config.type} />
    );
  return (
    <_Base
      description={schema.description}
      label={schema.title}
      multiple={config.asFilter}
      onChange={v => handleChange(path, v)}
      options={options}
      value={data}
      disabled={!enabled}
    />
  );
};
export const SelectControl = withJsonFormsControlProps(_SelectControl);
