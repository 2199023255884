import React from 'react';

import Typography from 'snap-ui/Typography';

import useTitle from 'hooks/useTitle';

import { AlphaFeature, CanAlpha } from 'module/AlphaFeatures';
import { Discriminator } from 'module/Tag';

import { Status } from 'storage';

import { Guid } from 'types/common';

import {
  CoverageTable,
  nameCol,
  mitreIdCol,
  mitreNameCol,
  priorityCol,
  deployedCol,
  recommendedCol,
  coverageCol,
  breadthCol,
  depthCol
} from '../Table';
import { ProfileDashboardPage } from '../style';
import Stats from './Stats';
import useProfileCoverage, { TagCoverageInterface } from './useProfileCoverage';

export function ProfileCoverageDashboard(): JSX.Element {
  useTitle('Threat Profile Coverage Report');
  const coverageInterface = useProfileCoverage();
  const { actors, attacks, software, vulnerabilities, getProfile, getStats, isProfilePending } = coverageInterface;

  return (
    <CanAlpha feature={AlphaFeature.ProfileCoverageDashboard}>
      <ProfileDashboardPage>
        <Typography variant='h1'>Threat Profile Coverage</Typography>
        <Stats {...coverageInterface} />
        <div>
          <Typography variant='h2'>Threat Actors</Typography>
          {renderTable(Discriminator.Actor, actors)}
        </div>
        <div>
          <Typography variant='h2'>Software</Typography>
          {renderTable(Discriminator.Software, software)}
        </div>
        <div>
          <Typography variant='h2'>Vulnerabilities</Typography>
          {renderTable(Discriminator.Vulnerability, vulnerabilities)}
        </div>
        <div>
          <Typography variant='h2'>MITRE ATT&CK</Typography>
          {renderTable(Discriminator.Attack, attacks)}
        </div>
      </ProfileDashboardPage>
    </CanAlpha>
  );

  function renderTable(tagType: Discriminator, tci: TagCoverageInterface): JSX.Element {
    return (
      <CoverageTable
        dataGridClassName='CoverageTable'
        columns={[
          tagType === Discriminator.Attack ? mitreIdCol : undefined,
          tagType === Discriminator.Attack ? mitreNameCol : nameCol,
          priorityCol(false, (guid: Guid) => getProfile(tagType, guid)),
          deployedCol((guid: Guid) => getStats(tagType, guid)),
          recommendedCol((guid: Guid) => getStats(tagType, guid)),
          coverageCol((guid: Guid) => getStats(tagType, guid)),
          breadthCol((guid: Guid) => getStats(tagType, guid)),
          depthCol((guid: Guid) => getStats(tagType, guid))
        ].filter(Boolean)}
        isPending={tci.status === Status.pending}
        isProfilePending={isProfilePending}
        rows={tci.items}
      />
    );
  }
}
