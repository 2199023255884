import React from 'react';

import { Alert, AlertTitle } from 'snap-ui/Alert';

import May from 'module/May';

import { FunctionalPermission } from 'types/auth';

const withFunctionalPermission =
  // eslint-disable-next-line react/display-name
  (WrappedComponent: React.FunctionComponent<any>, permission: FunctionalPermission, asNull?: boolean) => props =>
    (
      <May I={permission}>
        <May.Yes>
          <WrappedComponent {...props} />
        </May.Yes>
        <May.No>
          {asNull ? null : (
            <Alert severity='info' width='max-content'>
              <AlertTitle>This feature is not part of your SnapAttack subscription.</AlertTitle>
              Please contact{' '}
              <a href='mailto:sales@snapattack.com' target='_blank' rel='noreferrer' className='mailto'>
                sales@snapattack.com
              </a>{' '}
              to discuss subscription plans.
            </Alert>
          )}
        </May.No>
      </May>
    );

export default withFunctionalPermission;
