import React from 'react';

import useLocationState from 'hooks/useLocationState';

import useMayI from 'module/May/useMayI';
import NotFound from 'module/Util/Fallback/NotFound';

import { FunctionalPermission } from 'types/auth';

import { AttackScriptRouterState } from '../AttackScript.type';
import { AttackScriptProvider } from '../AttackScriptProvider';
import AttackScriptEdit, { AttackScriptEditProps } from './AttackScriptEdit';

export default function AttackScriptEditWrapper(props: AttackScriptEditProps): JSX.Element {
  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);
  const guid = props.match.params.guid;
  const { item, supplementalItem } = useLocationState<AttackScriptRouterState>() || {};

  if (!isBasUser) return <NotFound />;
  return (
    <>
      <AttackScriptProvider guid={guid} item={item} supplementalItem={supplementalItem}>
        <AttackScriptEdit {...props} />;
      </AttackScriptProvider>
    </>
  );
}
